import { Route, Router } from '../../routing';
import CreateDeclaration from './views/CreateDeclaration';
import DeclarationsOverview from './views/DeclarationsOverview';
import UpdateDeclaration from './views/UpdateDeclaration';
import ViewDeclaration from './views/ViewDeclaration';

export const DECLARATIONS_BASE_ROUTE = '/declaraties';

const declarationsOverview = new Route(
  'Declaraties',
  DECLARATIONS_BASE_ROUTE,
  <DeclarationsOverview />,
).role('ROLE_ADMIN');
Router.addRoute(declarationsOverview);

const viewDeclaration = new Route(
  'Declaratie bekijken',
  `${DECLARATIONS_BASE_ROUTE}/:id`,
  <ViewDeclaration />,
).role(['ROLE_ADMIN', 'ROLE_DELEGATE']);
Router.addRoute(viewDeclaration);

const createDeclaration = new Route(
  'Declaratie bewerken',
  `${DECLARATIONS_BASE_ROUTE}/aanmaken`,
  <CreateDeclaration />,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(createDeclaration);

const updateDeclaration = new Route(
  'Declaratie bewerken',
  `${DECLARATIONS_BASE_ROUTE}/:id/bewerken`,
  <UpdateDeclaration />,
).role(['ROLE_ADMIN', 'ROLE_DELEGATE']);
Router.addRoute(updateDeclaration);
