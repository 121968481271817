import { useState } from 'react';

import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DIPLOMAS,
  COLUMN_EXAM_REGION,
  COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
} from '../../../exams/domain/columns';
import ExamDashboardSection from '../../../exams/components/ExamDashboardSection';
import { FUTURE_EXAM_OVERVIEW_ROUTE } from '../../../exams';
import PrioExamRepository from '../../repository/PrioExamRepository';

const ExamsWithPrio = () => {
  const [examsCount, setExamsCount] = useState<number | null>(null);

  const examRepository = new PrioExamRepository();

  const columns = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
    COLUMN_EXAM_DIPLOMAS,
    COLUMN_EXAM_REGION,
  ];

  // all exams in this table must be highlighted
  const styleRow = () => 'trHighlight' as const;

  return (
    <ExamDashboardSection
      id="exams-with-prio"
      title="Hoge prioriteit"
      repository={examRepository}
      itemCount={examsCount}
      columns={columns}
      styleRow={styleRow}
      onLoad={(_items, totalCount) => setExamsCount(totalCount)}
      allExamsUrl={FUTURE_EXAM_OVERVIEW_ROUTE}
      allExamsText="Zie alle aangemelde examens"
      canView
      canEdit
      canViewInfo
    />
  );
};

export default ExamsWithPrio;
