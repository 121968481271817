import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core';

import { Examiner, SwimmingLessonProvider } from '../../../types';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';
import InlineButton from '../../../components/InlineButton';
import ButtonRow from '../../../components/ButtonRow';

interface SLPExaminerSelectProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  onSubmit: (examiner: Examiner) => void;
}

const SLPExaminerSelector = (props: SLPExaminerSelectProps) => {
  const { swimmingLessonProvider, onSubmit } = props;

  const [selectedExaminer, setSelectedExaminer] = useState<Examiner | null>(
    null,
  );
  const [examiners, setExaminers] = useState<Examiner[] | null>(null);

  const repository = new SwimmingLessonProviderRepository();

  const handleExaminerSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    if (!examiners) {
      return;
    }

    const examiner = examiners.find(
      (examiner) => examiner.id === event.target.value,
    );

    setSelectedExaminer(examiner || null);
  };

  const handleSubmit = () => {
    if (selectedExaminer === null) {
      return;
    }

    onSubmit(selectedExaminer);
  };

  useEffect(
    () => {
      repository
        .getExaminers(swimmingLessonProvider.id)
        .then((response) => setExaminers(response.data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swimmingLessonProvider.id],
  );

  if (!examiners) {
    return null;
  }

  return (
    <>
      <FormControl>
        <FormLabel id="examiner-select-label">Koppel examinator</FormLabel>
        <Select
          labelId="examiner-select-label"
          id="examiner-select"
          value={selectedExaminer?.id || '-'}
          name="examinerId"
          onChange={handleExaminerSelect}
          MenuProps={{ elevation: 1 }}
          displayEmpty
        >
          <MenuItem value="-">
            <i>Maak een keuze</i>
          </MenuItem>
          {examiners.map((examiner) => (
            <MenuItem key={examiner.id} value={examiner.id}>
              {examiner.fullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonRow>
        <InlineButton type="primary" onClick={handleSubmit}>
          Koppelen
        </InlineButton>
      </ButtonRow>
    </>
  );
};

export default SLPExaminerSelector;
