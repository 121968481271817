import { useEffect, useState } from 'react';

import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { LessonLocation, User } from '../../../types';
import { formatAddress } from '../../../utils/common';
import UserRepository from '../repository/UserRepository';

interface ExaminerLessonLocationListProps {
  user: User;
}

const ExaminerLessonLocationList = (props: ExaminerLessonLocationListProps) => {
  const { user } = props;

  const [lessonLocations, setLessonLocations] = useState<
    LessonLocation[] | null
  >(null);

  const repository = new UserRepository();

  const columns = [
    {
      name: 'Zwemlesaanbieder',
      field: 'swimmingLessonProvider',
      render: (lessonLocation: LessonLocation) =>
        lessonLocation.swimmingLessonProvider.name,
    },
    {
      name: 'Naam',
      field: 'name',
      render: (lessonLocation: LessonLocation) => lessonLocation.name,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (lessonLocation: LessonLocation) => lessonLocation.email,
    },
    {
      name: 'Telefoon',
      field: 'telephone',
      render: (lessonLocation: LessonLocation) => lessonLocation.telephone,
    },
    {
      name: 'Adres',
      field: 'visitingAddress',
      render: (lessonLocation: LessonLocation) =>
        formatAddress(lessonLocation.visitingAddress),
    },
  ];

  useEffect(
    () => {
      repository
        .getLessonLocations(user.id)
        .then((response) => setLessonLocations(response.data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.id],
  );

  if (lessonLocations === null) {
    return <Loader inline />;
  }

  return <SimpleTable items={lessonLocations} columns={columns} />;
};

export default ExaminerLessonLocationList;
