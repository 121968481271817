import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Sepa,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class SepaRepository implements Repository<Sepa> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<Sepa>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });

    return ApiClient.get('/api/v1/sepa', params);
  }

  find(id: string): Promise<AxiosResponse<Sepa>> {
    return ApiClient.get(`/api/v1/sepa/${id}`);
  }
}

export default SepaRepository;
