import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeclarationDto } from '../../../types';
import { formatCurrency } from '../../../utils/common';
import { colors } from '../../../config/theme';

interface DeclarationTotalAmountProps {
  declaration: DeclarationDto;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: 5,
    maxWidth: 300,
    background: colors.whiteLilac,
  },
  label: {
    display: 'block',
    fontWeight: 300,
  },
  currency: {
    fontFamily: '"Baloo Bhaina 2", sans-serif',
    fontSize: 36,
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
}));

const DeclarationTotalAmount = (props: DeclarationTotalAmountProps) => {
  const classes = useStyles();
  const { declaration } = props;

  let totalAmount = declaration.baseCompensation + declaration.additionalCosts;

  if (declaration.travelMethod === 'own') {
    totalAmount +=
      (declaration.kilometers || 0) * declaration.kilometerCompensation;
  } else {
    totalAmount += declaration.travelCompensation || 0;
  }

  return (
    <Paper className={classes.root}>
      <span className={classes.label}>Totaal bedrag:</span>
      <span className={classes.currency}>{formatCurrency(totalAmount)}</span>
    </Paper>
  );
};

export default DeclarationTotalAmount;
