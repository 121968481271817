import { filterXSS } from 'xss';
import { Typography } from '@material-ui/core';

interface SafeHtmlProps {
  html: string;
  className?: string;
}

const SafeHtml = ({ html, className = '' }: SafeHtmlProps) => (
  <Typography
    variant="body1"
    dangerouslySetInnerHTML={{
      __html: filterXSS(html, {
        // eslint-disable-next-line consistent-return
        onIgnoreTag: (tag) => {
          // Turn <figure> into empty string instead of text.
          if (tag === 'figure') {
            return '';
          }
        },
      }),
    }}
    className={className}
  />
);

export default SafeHtml;
