import { useSelector } from 'react-redux';

import { UserState } from '../../../reducers/user';
import ChangePassword from './ChangePassword';

const UpdateAccountPassword = () => {
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  return <ChangePassword id={account?.id} isProfile />;
};

export default UpdateAccountPassword;
