import { useState } from 'react';
import ExamDelegatesMethod from './ExamDelegatesMethod';
import ExamDelegatesAssign from './ExamDelegatesAssign';

export const METHOD_ASSIGN_DIRECTLY = 'assignDirectly';
export const METHOD_INVITE_MANUALLY = 'inviteManually';

type ExamDelegatesFormProps = {
  examId: string;
  onClose: () => void;
};

const ExamDelegatesForm = (props: ExamDelegatesFormProps) => {
  const { examId, onClose } = props;

  const [method, setMethod] = useState<string | null>(null);

  const selectMethodHandler = (method: string) => {
    setMethod(method);
  };

  return (
    <>
      {method === null ? (
        <ExamDelegatesMethod onSelectMethod={selectMethodHandler} />
      ) : (
        <ExamDelegatesAssign
          examId={examId}
          method={method}
          onBack={() => setMethod(null)}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ExamDelegatesForm;
