import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as Wave } from './assets/images/wave.svg';
import { ReactComponent as Logo } from './assets/images/logo_nrz_badge.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    flexShrink: 0,
    marginTop: theme.spacing(8),
  },
  container: {
    position: 'relative',
    padding: 0,
  },
  firstBlock: {
    padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
    paddingLeft: theme.spacing(2),
  },
  block: {
    color: theme.palette.primary.contrastText,
    padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
    '& a': {
      color: theme.palette.primary.contrastText,
    },
    '& a.highlight': {
      color: '#db3700',
      fontWeight: 'bold',
    },
    '& ul': {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      listStyleType: 'none',
      paddingLeft: 0,
      '& li': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  logo: {
    marginTop: -75,
    height: 200,
    '& .logo-nrz-badge-background': {
      fill: '#FFF',
    },
  },
  blockTitle: {
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(1),
  },
  socialLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    width: 32,
    height: 32,
    transition: theme.transitions.create('background'),
    '&:hover': {
      background: theme.palette.secondary.light,
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
    },
  },
  wave: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100% + 175px)',
    top: '-175px',
    '& > path': {
      fill: theme.palette.primary.main,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Wave className={classes.wave} />
      <Container className={classes.container}>
        <Grid container>
          <Grid item md={4} sm={12} xs={12} className={classes.firstBlock}>
            <a
              href="https://www.nrz-nl.nl"
              title="NRZ website"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo className={classes.logo} />
            </a>
          </Grid>
          <Grid item md={5} sm={7} xs={12} className={classes.block}>
            <Typography variant="h4" className={classes.blockTitle}>
              Overige informatie
            </Typography>
            <Grid container>
              <Grid item md={6}>
                <ul>
                  <li>
                    <a
                      className="highlight"
                      href="https://www.nrz-nl.nl/veelgestelde-vragen-mijn-diplomazwemmen/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Veelgestelde vragen
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} sm={5} xs={12} className={classes.block}>
            <Typography variant="h4" className={classes.blockTitle}>
              Contact
            </Typography>
            <Box mt={2}>
              <Typography variant="body1">
                <a href="mailto:info@nrz-nl.nl">info@nrz-nl.nl</a>
                <br />
                <a href="tel:0318830250">(0318) 83 02 50</a>
              </Typography>
            </Box>
            <Box mt={2}>
              <Grid container spacing={1}>
                <Grid item>
                  <a
                    href="https://facebook.com/nrznl"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://twitter.com/nrz_nl"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.instagram.com/nationale_raad_zwemveiligheid/"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.linkedin.com/company/nationale-raad-zwemveiligheid/"
                    className={classes.socialLink}
                  >
                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
