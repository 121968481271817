import { useSelector } from 'react-redux';
import Router from '../../../routing/Router';
import Route from '../../../routing/Route';
import { Operator } from '../../../types';
import { OPERATOR_BASE_ROUTE, ORGANIZATION_BASE_ROUTE } from '../index';
import { UserState } from '../../../reducers/user';
import OperatorUserList from '../../operators/components/OperatorUserList';
import OperatorSwimmingLessonProviderList from '../components/OperatorSwimmingLessonProviderList';
import { RoleInterface } from '../../users/domain/Roles';

const useOperatorTabs = (
  operator: Operator | null,
  isOrganization: boolean,
) => {
  let baseRoute: string;
  let role: keyof RoleInterface;

  const { roleView } = useSelector((selector: { user: UserState }) => ({
    roleView: selector.user.roleView,
  }));

  if (!operator) {
    return null;
  }

  if (isOrganization) {
    baseRoute = ORGANIZATION_BASE_ROUTE;
    role = 'ROLE_OPERATOR_USER';
  } else {
    baseRoute = `${OPERATOR_BASE_ROUTE}/${operator.id}`;
    role = 'ROLE_ADMIN';
  }

  const router = new Router();

  router.addRoute(
    new Route(
      'Gebruikers',
      `${baseRoute}/gebruikers`,
      <OperatorUserList operator={operator} />,
    ).role([role]),
  );

  router.addRoute(
    new Route(
      'Leslocaties',
      `${baseRoute}/leslocaties`,
      <OperatorSwimmingLessonProviderList operator={operator} />,
    ).role([role]),
  );

  return router.processTabs(roleView);
};

export default useOperatorTabs;
