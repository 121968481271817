import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ExamLocation } from '../../../types';
import InlineButton from '../../../components/InlineButton';
import ButtonRow from '../../../components/ButtonRow';
import ExamLocationRepository from '../repository/ExamLocationRepository';

interface SLPExamLocationSelectorProps {
  onSubmit: (examLocation: ExamLocation) => void;
}

const SLPExamLocationSelector = (props: SLPExamLocationSelectorProps) => {
  const { onSubmit } = props;

  const [location, setLocation] = useState<ExamLocation | null>(null);
  const [locations, setLocations] = useState<ExamLocation[]>(
    [] as ExamLocation[],
  );

  const handleSubmit = () => {
    if (location === null) return;
    onSubmit(location);
  };

  const handleExamLocation = (
    _event: React.ChangeEvent<{}>,
    value: ExamLocation | null,
  ) => {
    setLocation(value);
  };

  useEffect(() => {
    const repository = new ExamLocationRepository();

    repository.findAll().then((response) => setLocations(response.data.items));
  }, []);

  if (!locations) return null;

  return (
    <>
      <FormControl>
        <FormLabel id="exam-location-select-label">
          Voeg afzwemlocatie toe
        </FormLabel>
        <Autocomplete
          onChange={handleExamLocation}
          value={location}
          options={locations}
          getOptionLabel={(examLocation) => examLocation.name}
          style={{ minWidth: 320 }}
          renderInput={(params) => (
            <TextField
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...params}
              variant="outlined"
              placeholder="Selecteer afzwemlocatie..."
            />
          )}
        />
      </FormControl>
      <ButtonRow>
        <InlineButton type="primary" onClick={handleSubmit}>
          Toevoegen
        </InlineButton>
      </ButtonRow>
    </>
  );
};

export default SLPExamLocationSelector;
