import { PropsWithChildren } from 'react';
import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 160,
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  description: {
    color: theme.palette.primary.dark,
  },
  welcome: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.h1.fontFamily,
  },
  contentRight: {
    background: theme.palette.primary.light,
  },
}));

const Home = (props: PropsWithChildren<any>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box mb={5}>
              <Typography
                variant="body1"
                component="h2"
                className={classes.welcome}
              >
                Welkom!
              </Typography>
              <Typography variant="h1" component="h1" className={classes.title}>
                NRZ Mijn diplomazwemmen
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Log hier in op Mijn diplomazwemmen voor de administratie
                diplomazwemmen voor de Nationale Zwemdiploma’s van de Nationale
                Raad Zwemveiligheid.
              </Typography>
            </Box>
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
