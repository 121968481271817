import { Box } from '@material-ui/core';

import Button from '../../../components/Button';
import Page from '../../../components/Page';
import { EXAM_CREATE_ROUTE } from '../../exams';

import ExamsWithPrio from './dashboard/ExamsWithPrio';
import ExamsWithUncheckedReports from './dashboard/ExamsWithUncheckedReports';

const Dashboard = () => (
  <Page title="Dashboard">
    <Box mb={2}>
      <Button
        label="Examen aanmelden"
        color="primary"
        variant="contained"
        icon={['fal', 'plus']}
        to={EXAM_CREATE_ROUTE}
      />
    </Box>
    <ExamsWithPrio />
    <ExamsWithUncheckedReports />
  </Page>
);

export default Dashboard;
