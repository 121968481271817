import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { Operator } from '../../../types';
import OperatorRepository from '../../operators/repository/OperatorRepository';

interface OperatorSelectProps {
  onSelect: (operator: Operator | null) => void;
}

const OperatorSelect = (props: OperatorSelectProps) => {
  const notifications = useSnackbar();

  const [operatorOptions, setOperatorOptions] = useState<Operator[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const onChangeHandler = (_event: ChangeEvent<{}>, value: Operator | null) => {
    setSelectedOperator(value);
    props.onSelect(value);
  };

  const fetchOperatorOptions = useCallback(() => {
    new OperatorRepository()
      .fetchAll()
      .then((response) => {
        setOperatorOptions(response.data.items);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het ophalen van de gegevens.', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [setOperatorOptions, setLoaded]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    fetchOperatorOptions();
  }, [loaded, fetchOperatorOptions]);

  return (
    <Autocomplete
      onChange={onChangeHandler}
      value={selectedOperator}
      options={operatorOptions}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          variant="outlined"
          placeholder="Selecteer exploitant..."
        />
      )}
    />
  );
};

export default OperatorSelect;
