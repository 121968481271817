import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import DiplomaTypeForm from '../forms/DiplomaTypeForm';
import DiplomaTypeRepository from '../repository/DiplomaTypeRepository';
import { DiplomaType, DiplomaTypeDTO } from '../domain/types';
import { DIPLOMA_CATEGORY_OVERVIEW_ROUTE } from '../index';

const EditDiplomaType = () => {
  const { categoryId, id } = useParams<{ categoryId: string; id: string }>();
  const history = useHistory();
  const notifications = useSnackbar();
  const repository = new DiplomaTypeRepository(categoryId);
  const [diplomaType, setDiplomaType] = useState<DiplomaType | null>(null);

  useEffect(
    () => {
      repository
        .get(id)
        .then(({ data }) => {
          setDiplomaType(data);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van de gegevens',
            { variant: 'error' },
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const submit = (data: DiplomaTypeDTO) => {
    repository
      .update(data)
      .then(() => {
        history.push(
          `${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/${data.diplomaCategoryId}/diplomatypen`,
        );
        notifications.enqueueSnackbar('Succesvol bijgewerkt', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het bijwerken', {
          variant: 'error',
        });
      });
  };

  if (!diplomaType) {
    return <Loader />;
  }

  return (
    <Page title="Diplomatype bewerken">
      <DiplomaTypeForm diplomaType={diplomaType} onSubmit={submit} />
    </Page>
  );
};

export default EditDiplomaType;
