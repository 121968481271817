import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import BooleanOutput from '../../../components/form/BooleanOutput';
import FormSection from '../../../components/form/FormSection';
import TextOutput from '../../../components/form/TextOutput';
import BackButton from '../../../components/BackButton';
import ButtonRow from '../../../components/ButtonRow';
import InlineLink from '../../../components/InlineLink';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import { SwimmingLessonProvider, User } from '../../../types';
import { formatAddress } from '../../../utils/common';
import useSwimmingLessonProviderTabs from '../hooks/swimming-lesson-provider-tabs';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';
import ConvertToSLPButton from '../components/ConvertToSLPButton';
import PlaceUnderSLPButton from '../components/PlaceUnderSLPButton';
import {
  SWIMMING_LESSON_PROVIDER_BASE_ROUTE,
  ORGANIZATION_BASE_ROUTE,
} from '../index';

declare type TViewSwimmingLessonProvider = {
  id?: string;
  canEdit?: boolean;
  isOrganization?: boolean;
};

const ViewSwimmingLessonProvider = (props: TViewSwimmingLessonProvider) => {
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const isSuperAdmin = account.roles.includes('ROLE_SUPER_ADMIN');

  const { id: passedId, isOrganization = false, canEdit = true } = props;
  const { id: paramId } = useParams<{ id?: string }>();
  const id = passedId || paramId || '';

  const [swimmingLessonProvider, setSwimmingLessonProvider] =
    useState<SwimmingLessonProvider | null>(null);

  const tabs = useSwimmingLessonProviderTabs(
    swimmingLessonProvider,
    isOrganization,
  );

  const swimmingLessonProviderRepository =
    new SwimmingLessonProviderRepository();

  const loadSwimmingLessonProvider = () => {
    swimmingLessonProviderRepository
      .find(id)
      .then((response) => setSwimmingLessonProvider(response.data));
  };

  useEffect(
    () => {
      loadSwimmingLessonProvider();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const title = isOrganization ? (
    'Mijn Organisatie'
  ) : (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1">Zwemlesaanbieder bekijken</Typography>
    </Box>
  );

  if (!swimmingLessonProvider) {
    return <Loader />;
  }

  const isIndependentLessonLocation =
    swimmingLessonProvider.type === 'independent_lesson_location';

  return (
    <Page title={title}>
      <FormSection title="Gegevens">
        <TextOutput label="Naam" value={swimmingLessonProvider.name} />
        <TextOutput label="Klantcode" value={swimmingLessonProvider.afasId} />
        {isSuperAdmin && (
          <TextOutput
            label="Type"
            value={swimmingLessonProvider.typeReadable}
          />
        )}
        <TextOutput label="E-mail" value={swimmingLessonProvider.email} />
        <TextOutput
          label="Telefoonnummer"
          value={swimmingLessonProvider.phoneNumber}
        />
        <TextOutput
          label="Bezoekadres"
          value={
            formatAddress(swimmingLessonProvider?.visitingAddress || null) ||
            '—'
          }
        />
        <TextOutput
          label="Correspondentieadres"
          value={
            formatAddress(swimmingLessonProvider?.shippingAddress || null) ||
            '—'
          }
        />
        <TextOutput
          label="Beheerder"
          value={swimmingLessonProvider.manager?.fullName || '—'}
        />
        <BooleanOutput
          label="Opleidingsinstituut C"
          value={swimmingLessonProvider.trainingInstituteC}
        />
        <BooleanOutput
          label="Centrale examens"
          value={swimmingLessonProvider.centralExams}
        />
      </FormSection>
      {canEdit && (
        <ButtonRow>
          <InlineLink
            type="primary"
            to={
              isOrganization
                ? `${ORGANIZATION_BASE_ROUTE}/bewerken`
                : `${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}/${swimmingLessonProvider.id}/bewerken`
            }
          >
            Bewerken
          </InlineLink>
          {isIndependentLessonLocation && isSuperAdmin && (
            <>
              <ConvertToSLPButton
                swimmingLessonProvider={swimmingLessonProvider}
                onSuccess={loadSwimmingLessonProvider}
              />
              <PlaceUnderSLPButton
                swimmingLessonProvider={swimmingLessonProvider}
                onSuccess={loadSwimmingLessonProvider}
              />
            </>
          )}
        </ButtonRow>
      )}
      {tabs}
    </Page>
  );
};

export default ViewSwimmingLessonProvider;
