import { useCallback, useEffect, useState } from 'react';

import { DelegateAssignmentOfExam, Exam } from '../../../../types';
import ButtonRow from '../../../../components/ButtonRow';
import ExamDelegateList from '../../components/ExamDelegateList';
import InlineButton from '../../../../components/InlineButton';
import ExamDelegatesDialog from '../../components/ExamDelegatesDialog';
import ExamRepository from '../../repository/ExamRepository';

interface ExamFormSectionProps {
  exam: Exam;
  withActions: boolean;
}

type DisplayMode = 'detail' | 'handle' | 'invite';

const ExamDelegateSection = (props: ExamFormSectionProps) => {
  const { exam, withActions } = props;

  const [delegateAssignments, setDelegateAssignments] = useState<
    DelegateAssignmentOfExam[]
  >([]);
  const [displayMode, setDisplayMode] = useState<DisplayMode>('detail');

  const inDetailMode = displayMode === 'detail';
  const inHandleMode = displayMode === 'handle';

  const setDetailMode = () => {
    setDisplayMode('detail');
  };
  const setHandleMode = () => {
    setDisplayMode('handle');
  };

  const loadDelegates = useCallback(() => {
    new ExamRepository()
      .getDelegates(exam.id)
      .then((response) => setDelegateAssignments(response.data));
  }, [exam.id]);

  const [examDelegatesDialogOpen, setExamDelegatesDialogOpen] =
    useState<boolean>(false);

  const openExamDelegatesDialog = () => {
    setExamDelegatesDialogOpen(true);
  };

  const closeExamDelegatesDialog = () => {
    setExamDelegatesDialogOpen(false);
    loadDelegates();
  };

  useEffect(loadDelegates, [loadDelegates]);

  return (
    <>
      <ExamDelegateList
        exam={exam}
        delegateAssignments={delegateAssignments}
        withActions={inHandleMode}
        onRefresh={loadDelegates}
      />
      <ExamDelegatesDialog
        open={examDelegatesDialogOpen}
        examId={exam.id}
        onClose={closeExamDelegatesDialog}
      />
      {withActions && (
        <>
          {inDetailMode && (
            <ButtonRow>
              <InlineButton
                type="primary"
                onClick={() => openExamDelegatesDialog()}
              >
                Uitnodigen
              </InlineButton>
              <InlineButton type="primary" onClick={() => setHandleMode()}>
                Uitnodigingen verwerken
              </InlineButton>
            </ButtonRow>
          )}
          {inHandleMode && (
            <ButtonRow>
              <InlineButton onClick={() => setDetailMode()}>Terug</InlineButton>
            </ButtonRow>
          )}
        </>
      )}
    </>
  );
};

export default ExamDelegateSection;
