import { IconName } from '@fortawesome/fontawesome-svg-core';
import { RoleInterface } from '../../users/domain/Roles';
import { Notification } from '../../../types';
import { EXAM_BASE_ROUTE } from '../../exams';

const notificationTypes: {
  [key: string]: {
    label: string;
    roles: (keyof RoleInterface)[];
    icon: IconName;
    target?: (notification: Notification) => string | Promise<string>;
    newWindow?: boolean;
  };
} = {
  exam_deleted: {
    label: 'Examen verwijderd',
    roles: ['ROLE_ADMIN'],
    icon: 'minus-circle',
  },
  exam_report_entry_valuated: {
    label: 'Examen rapport beoordeeld',
    roles: ['ROLE_ADMIN'],
    icon: 'minus-circle',
  },
  declaration: {
    label: 'Declaratie',
    roles: ['ROLE_DELEGATE'],
    icon: 'envelope-open-text',
    target: (notification: Notification) =>
      `${EXAM_BASE_ROUTE}/${notification.references.examId}/declaraties/${notification.references.declarationId}`,
  },
  manager_creation_failed: {
    label: 'Beheerder',
    roles: ['ROLE_SUPER_ADMIN'],
    icon: 'user',
  },
};

export default notificationTypes;
