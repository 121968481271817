import { RoleInterface } from '../modules/users/domain/Roles';

export default class Route {
  private title: string;

  private readonly path: string;

  private readonly element: JSX.Element;

  private readonly exact: boolean;

  private parent: Route | null = null;

  private children: Route[] = [];

  private allowedRoles: (keyof RoleInterface)[] = [];

  isRequiringLogin: boolean = false;

  isAnonymous: boolean = false;

  constructor(
    title: string,
    path: string,
    element: JSX.Element,
    exact: boolean = false,
  ) {
    this.title = title;
    this.path = path;
    this.element = element;
    this.exact = exact;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title: string) {
    this.title = title;

    return this;
  }

  getPath() {
    let { path } = this;

    if (this.parent) {
      path = this.parent.getPath() + path;
    }

    return path;
  }

  getElement() {
    return this.element;
  }

  isExact() {
    return this.exact;
  }

  getChildren() {
    return this.children;
  }

  getParent() {
    return this.parent;
  }

  setParent(node: Route) {
    this.parent = node;
  }

  addChild(node: Route) {
    node.setParent(this);

    if (this.isRequiringLogin) {
      node.requireLogin();
    }

    this.children.push(node);
  }

  requireLogin() {
    this.isRequiringLogin = true;
    return this;
  }

  role(role: keyof RoleInterface | (keyof RoleInterface)[]) {
    this.requireLogin();

    if (typeof role === 'string') {
      this.allowedRoles.push(role);
      return this;
    }

    this.allowedRoles = this.allowedRoles.concat(role);
    return this;
  }

  getRoles() {
    let roles = this.allowedRoles;

    if (this.parent) {
      roles = roles.concat(this.parent.getRoles());
    }

    return roles;
  }

  anonymous() {
    this.isAnonymous = true;
    return this;
  }
}
