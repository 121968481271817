export interface RoleInterface {
  ROLE_SUPER_ADMIN: string;
  ROLE_ADMIN: string;
  ROLE_SWIMMING_LESSON_PROVIDER_MANAGER: string;
  ROLE_SWIMMING_LESSON_PROVIDER_USER: string;
  ROLE_EXAMINER: string;
  ROLE_DELEGATE: string;
  ROLE_OPERATOR_USER: string;
}

const Roles: RoleInterface = {
  ROLE_SUPER_ADMIN: 'Super admin',
  ROLE_ADMIN: 'Admin',
  ROLE_SWIMMING_LESSON_PROVIDER_MANAGER: 'Zwemlesaanbieder Beheerder',
  ROLE_SWIMMING_LESSON_PROVIDER_USER: 'Zwemlesaanbieder Gebruiker',
  ROLE_EXAMINER: 'Examinator',
  ROLE_DELEGATE: 'Gedelegeerde',
  ROLE_OPERATOR_USER: 'Exploitant Gebruiker',
};

export default Roles;
