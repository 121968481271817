import { useCallback, useEffect, useState } from 'react';

import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { Declaration, User } from '../../../types';
import UserRepository from '../repository/UserRepository';
import {
  COLUMN_DECLARATION_EXAM_DATE,
  COLUMN_DECLARATION_STATUS,
  COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER,
  COLUMN_DECLARATION_TOTAL_COMPENSATION,
  DeclarationColumn,
} from '../../declarations/domain/columns';

interface UserDeclarationListProps {
  user: User;
}

const UserDeclarationList = (props: UserDeclarationListProps) => {
  const [declarations, setDeclarations] = useState<Declaration[]>([]);

  const [loaded, setLoaded] = useState<boolean>(false);

  const columns: DeclarationColumn[] = [
    COLUMN_DECLARATION_EXAM_DATE,
    COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER,
    COLUMN_DECLARATION_TOTAL_COMPENSATION,
    COLUMN_DECLARATION_STATUS,
  ];

  const loadDeclarations = useCallback(() => {
    new UserRepository()
      .getDeclarations(props.user.id)
      .then((response) => setDeclarations(response.data))
      .finally(() => {
        setLoaded(true);
      });
  }, [props.user.id, setDeclarations, setLoaded]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    loadDeclarations();
  }, [loaded, loadDeclarations]);

  if (!loaded) {
    return <Loader />;
  }

  return <SimpleTable items={declarations} columns={columns} />;
};

export default UserDeclarationList;
