import { formatDate } from '../../utils/common';
import TextOutput, { OutputProps } from './TextOutput';

interface DateOutputProps extends OutputProps {
  value: string | null;
}

const DateOutput = (props: DateOutputProps) => {
  const { label, value, hideIfEmpty } = props;

  const formattedValue = value ? formatDate(new Date(value)) : '';

  return (
    <TextOutput
      label={label}
      value={formattedValue}
      hideIfEmpty={hideIfEmpty}
    />
  );
};

export default DateOutput;
