import { FC } from 'react';
import { ExamReportField, ExamReportFieldType } from '../../domain/types';
import BoolField from './BoolField';
import ScaleField from './ScaleField';
import TextField from './TextField';
import { ValidationViolation } from '../../../../types';

export type FormFieldProps = {
  field: ExamReportField;
  error: ValidationViolation | undefined;
  value: unknown;
};

const fields: {
  type: ExamReportFieldType;
  render: FC<FormFieldProps>;
}[] = [
  {
    type: 'contact',
    render: BoolField,
  },
  {
    type: 'bool',
    render: BoolField,
  },
  {
    type: 'scale',
    render: ScaleField,
  },
  {
    type: 'text',
    render: TextField,
  },
];

export default fields;
