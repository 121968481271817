import { useContext, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/styles';
import DataTable, {
  cancelOnRowClick,
  Column,
  Filter,
} from '../../../components/DataTable';
import UserRepository from '../repository/UserRepository';
import { User } from '../../../types';
import ApiClient from '../../../api/ApiClient';
import StatusIndicator from '../../../components/StatusIndicator';
import SearchContext from '../../../components/search/SearchContext';
import SearchInput from '../../../components/search/SearchInput';
import UserImitateButton from '../components/UserImitateButton';
import AppContext from '../../../AppContext';
import Page from '../../../components/Page';
import Button from '../../../components/Button';
import { USER_CREATE_ROUTE, USER_BASE_ROUTE } from '../index';
import Roles, { RoleInterface } from '../domain/Roles';
import DataTableRowAction from '../../../components/DataTableRowAction';
import DataTableRowLink from '../../../components/DataTableRowLink';
import { SWIMMING_LESSON_PROVIDER_BASE_ROUTE } from '../../swimming-lesson-provider';

const useStyles = makeStyles(() => ({
  userLink: {
    display: 'inline-block',
  },
}));

const UserOverview = () => {
  const history = useHistory();
  const notifications = useSnackbar();
  const classes = useStyles();

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const repository = new UserRepository();
  const { roleViewManager } = useContext(AppContext);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  /**
   * Sends activation mail to user.
   */
  const handleSendActivationMail = (email: string) => {
    ApiClient.sendActivationMail(email)
      .then(() => {
        notifications.enqueueSnackbar(
          'De activatie mail is opnieuw verzonden.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het versturen van de activate mail.',
          { variant: 'error' },
        );
      });
  };

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'lastName',
      sortable: true,
      render: (user: User) => {
        return (
          <>
            <StatusIndicator indicator={user.active ? 'green' : 'red'} />
            <Box className={classes.userLink} ml={1}>
              {user.fullNameLastNameFirst}
            </Box>
          </>
        );
      },
    },
    {
      name: 'Zwemlesaanbieder',
      field: 'swimmingLessonProvider',
      sortable: false,
      render: (user: User) => {
        if (user.swimmingLessonProvider === undefined) {
          return '';
        }

        if (roleViewManager.isAdminView()) {
          return (
            <Box onClick={cancelOnRowClick}>
              <Link
                to={`${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}/${user.swimmingLessonProvider.id}`}
              >
                {user.swimmingLessonProvider.name}
              </Link>
            </Box>
          );
        }

        return user.swimmingLessonProvider.name;
      },
    },
    {
      name: 'Rollen',
      field: 'roles',
      render: (user: User) => {
        return user?.explicitRoles
          ? user.explicitRoles
              .map((role: keyof RoleInterface) => Roles[role])
              .join(', ')
          : null;
      },
    },
  ];

  if (roleViewManager.isAdminView()) {
    columns.unshift({
      name: 'Afas ID',
      field: 'AfasId',
      render: (user: User) => {
        return user.afasId;
      },
    });
  }

  const itemActions = (user: User) => (
    <div>
      {(roleViewManager.isAdminView() ||
        roleViewManager.isSwimmingLessonProviderManagerView()) &&
        !user.active && (
          <DataTableRowAction
            title="Activatiemail versturen"
            icon="envelope"
            onClick={() => handleSendActivationMail(user.email)}
          />
        )}
      {roleViewManager.isAdminView() && <UserImitateButton user={user} />}
      {(roleViewManager.isAdminView() ||
        roleViewManager.isSwimmingLessonProviderManagerView()) && (
        <DataTableRowLink
          title="Bewerken"
          icon="edit"
          to={`${USER_BASE_ROUTE}/${user.id}/bewerken`}
        />
      )}
    </div>
  );

  const title = (
    <>
      {totalCount !== null && `Gebruikers (${totalCount})`}
      {totalCount === null && 'Gebruikers'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <Button
          icon={['fal', 'plus']}
          to={USER_CREATE_ROUTE}
          label="Gebruiker aanmaken"
        />
      </Box>
      <SearchInput id="user-overview-query" persistQuery />
    </Box>
  );

  const filters: Filter[] = [
    {
      field: 'roles',
      name: 'Rol',
      type: 'checkbox',
      options: Object.entries(Roles).map(([role, label]) => ({
        value: role,
        label,
      })),
    },
  ];

  const deleteItemMessage = (user: User) =>
    `Weet je zeker dat je ${
      user ? user.fullName : 'deze gebruiker'
    } wilt verwijderen?`;

  const onRowClick = (user: User) => {
    history.push(`${USER_BASE_ROUTE}/${user.id}`);
  };

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="users-overview"
          repository={repository}
          columns={columns}
          actions={itemActions}
          onRowClick={onRowClick}
          filters={filters}
          persistFilters
          deletable={roleViewManager.isAdminView()}
          deleteItemMessage={deleteItemMessage}
          resultCounterText={{
            singular: 'gebruiker',
            plural: 'gebruikers',
          }}
          contained={false}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default UserOverview;
