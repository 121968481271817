import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Page';
import UserRepository from '../repository/UserRepository';
import { UserDTO } from '../../../types';
import UserForm from '../forms/UserForm';
import { USER_BASE_ROUTE } from '../index';

const CreateUser = () => {
  const repository = new UserRepository();
  const notifications = useSnackbar();
  const history = useHistory();

  const handleSubmit = (user: UserDTO) => {
    repository
      .create(user)
      .then(() => {
        history.push(USER_BASE_ROUTE);
        notifications.enqueueSnackbar('Gebruiker is succesvol aangemaakt!', {
          variant: 'success',
        });
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het aanmaken van de gebruiker.',
          { variant: 'error' },
        ),
      );
  };

  return (
    <Page title="Gebruiker aanmaken" breadcrumbs>
      <UserForm onSubmit={handleSubmit} />
    </Page>
  );
};

export default CreateUser;
