import { Route, Router } from '../../routing';
import CreateOrUpdateExam from './views/CreateOrUpdateExam';
import FutureExamOverview from './views/FutureExamOverview';
import PastExamOverview from './views/PastExamOverview';
import ViewExam from './views/ViewExam';

export const EXAM_BASE_ROUTE = '/examens';
export const EXAM_CREATE_URI = '/aanmelden';
export const EXAM_CREATE_ROUTE = EXAM_BASE_ROUTE + EXAM_CREATE_URI;
export const FUTURE_EXAM_OVERVIEW_ROUTE = '/aangemelde-examens';
export const PAST_EXAM_OVERVIEW_ROUTE = '/afgelegde-examens';

const exams = new Route(
  'Aangemelde examens',
  EXAM_BASE_ROUTE,
  <FutureExamOverview />,
  true,
).role([
  'ROLE_ADMIN',
  'ROLE_SWIMMING_LESSON_PROVIDER_USER',
  'ROLE_OPERATOR_USER',
]);
Router.addRoute(exams);

const futureExams = new Route(
  'Aangemelde examens',
  FUTURE_EXAM_OVERVIEW_ROUTE,
  <FutureExamOverview />,
  true,
).role([
  'ROLE_ADMIN',
  'ROLE_SWIMMING_LESSON_PROVIDER_USER',
  'ROLE_OPERATOR_USER',
]);
Router.addRoute(futureExams);

const pastExams = new Route(
  'Afgelegde examens',
  PAST_EXAM_OVERVIEW_ROUTE,
  <PastExamOverview />,
  true,
).role([
  'ROLE_ADMIN',
  'ROLE_SWIMMING_LESSON_PROVIDER_USER',
  'ROLE_OPERATOR_USER',
]);
Router.addRoute(pastExams);

const createExam = new Route(
  'Examen aanmelden',
  EXAM_CREATE_URI,
  <CreateOrUpdateExam />,
).role(['ROLE_ADMIN', 'ROLE_SWIMMING_LESSON_PROVIDER_USER']);
exams.addChild(createExam);

const updateExam = new Route(
  'Examen bewerken',
  '/:id/bewerken',
  <CreateOrUpdateExam />,
).role(['ROLE_ADMIN', 'ROLE_SWIMMING_LESSON_PROVIDER_USER']);
exams.addChild(updateExam);

const viewExam = new Route('Examen bekijken', '/:id', <ViewExam />).role([
  'ROLE_ADMIN',
  'ROLE_SWIMMING_LESSON_PROVIDER_USER',
  'ROLE_DELEGATE',
  'ROLE_EXAMINER',
]);
exams.addChild(viewExam);
