import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
} from '../../../types';
import { ExamReportField, ExamReportFieldDTO } from '../domain/types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class ExamReportFieldRepository implements Repository<ExamReportField> {
  private readonly reportId: string;

  constructor(reportId: string) {
    this.reportId = reportId;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<ExamReportField>> {
    const params = parseCriteria(criteria, { page: page || 1 });

    return ApiClient.get(
      `/api/v1/exam-reports/${this.reportId}/fields`,
      params,
    );
  }

  find(id: string): Promise<AxiosResponse<ExamReportField>> {
    return ApiClient.get(`/api/v1/exam-reports/${this.reportId}/fields/${id}`);
  }

  create(field: ExamReportFieldDTO) {
    return ApiClient.post(
      `/api/v1/exam-reports/${this.reportId}/fields`,
      field,
    );
  }

  update(id: string, field: ExamReportFieldDTO) {
    return ApiClient.put(
      `/api/v1/exam-reports/${this.reportId}/fields/${id}`,
      field,
    );
  }
}

export default ExamReportFieldRepository;
