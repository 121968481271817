import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Page from '../../../components/Page';
import DataTable, { Column } from '../../../components/DataTable';
import ExamReportRepository from '../repository/ExamReportRepository';
import { ExamReport } from '../domain/types';
import Button from '../../../components/Button';
import {
  EXAM_REPORT_CREATE_ROUTE,
  EXAM_REPORT_OVERVIEW_ROUTE,
} from '../../admin';
import SearchInput from '../../../components/search/SearchInput';
import DataTableRowLink from '../../../components/DataTableRowLink';
import { formatTimestamp } from '../../../utils/common';

const ExamReportOverview = () => {
  const repository = new ExamReportRepository();

  const columns: Column[] = [
    {
      name: 'Titel',
      field: 'title',
      render: (report: ExamReport) => (
        <Link to={`${EXAM_REPORT_OVERVIEW_ROUTE}/${report.id}/velden`}>
          {report.title}
        </Link>
      ),
    },
    {
      name: 'Beschrijving',
      field: 'description',
    },
    {
      name: 'Aangemaakt op',
      field: 'createdAt',
      render: (report: ExamReport) =>
        formatTimestamp(new Date(report.createdAt)),
    },
  ];

  const itemActions = (report: ExamReport) => (
    <Box>
      <DataTableRowLink
        title="Velden bekijken"
        icon="clipboard-list"
        to={`${EXAM_REPORT_OVERVIEW_ROUTE}/${report.id}/velden`}
      />
      <DataTableRowLink
        title="Bewerken"
        icon="edit"
        to={`${EXAM_REPORT_OVERVIEW_ROUTE}/${report.id}/bewerken`}
      />
    </Box>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <Button
          icon={['fal', 'plus']}
          to={EXAM_REPORT_CREATE_ROUTE}
          label="Rapport aanmaken"
        />
      </Box>
      <SearchInput />
    </Box>
  );

  return (
    <Page title="Rapporten" actions={actions}>
      <DataTable
        id="exam-report-table"
        repository={repository}
        columns={columns}
        actions={itemActions}
      />
    </Page>
  );
};

export default ExamReportOverview;
