import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ExamReportFieldDTO, ExamReportFieldType } from '../domain/types';
import Page from '../../../components/Page';
import { DiplomaCategory } from '../../diploma/domain/types';
import DiplomaCategoryRepository from '../../diploma/repository/DiplomaCategoryRepository';
import { EXAM_REPORT_OVERVIEW_ROUTE } from '../../admin';
import ExamReportFieldRepository from '../repository/ExamReportFieldRepository';
import HtmlEditor from '../../../components/HtmlEditor';
import Loader from '../../../components/Loader';

const REPORT_FIELD_TYPES: { [key in ExamReportFieldType]: string } = {
  bool: 'Ja/nee',
  scale: 'Goed/voldoende/te verbeteren',
  text: 'Tekstveld',
  contact: 'Wel/geen contact',
};

const ReportFieldForm = () => {
  const { id, reportId } = useParams<{ id?: string; reportId: string }>();
  const [field, setField] = useState<ExamReportFieldDTO>({
    sortOrder: 1,
    label: '',
    type: 'bool',
    description: '',
    explanation: false,
    required: false,
    diplomaCategories: [],
  });
  const [selectedDiplomaCategories, setSelectedDiplomaCategories] = useState<
    DiplomaCategory[]
  >([]);
  const [diplomaCategories, setDiplomaCategories] = useState<DiplomaCategory[]>(
    [],
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const notifications = useSnackbar();
  const history = useHistory();
  const repository = useMemo(
    () => new ExamReportFieldRepository(reportId),
    [reportId],
  );

  const { sortOrder, label, type, description, explanation, required } = field;

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setField({
      ...field,
      [event.target.name]: parseInt(event.target.value, 10),
    });
  };

  const handleCheckboxChange = (
    e: ChangeEvent<{ name: string; checked: boolean }>,
  ) => {
    const { name, checked } = e.target;
    setField({ ...field, [name]: checked });
  };

  const handleSelectChange = (
    e: ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setField({
      ...field,
      [e.target.name as string]: e.target.value as ExamReportFieldType,
    });
  };

  const handleDescriptionChange = (_e: any, editor: any) => {
    setField({ ...field, description: editor.getData() });
  };

  const handleCategoryChange = (_event: any, options: DiplomaCategory[]) => {
    setSelectedDiplomaCategories(options);
  };

  const handleSubmit = () => {
    const data = {
      ...field,
      diplomaCategories: selectedDiplomaCategories.map((c) => c.id),
    };

    (id ? repository.update(id, data) : repository.create(data))
      .then(() => {
        notifications.enqueueSnackbar('Het veld is succesvol aangemaakt!', {
          variant: 'success',
        });
        history.push(`${EXAM_REPORT_OVERVIEW_ROUTE}/${reportId}/velden`);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het opslaan van het veld', {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    new DiplomaCategoryRepository()
      .getAll()
      .then((response) => setDiplomaCategories(response.data.items));

    if (id) {
      repository
        .find(id)
        .then((response) => {
          const newField = response.data;

          setField({
            ...newField,
            diplomaCategories: newField.diplomaCategories.map((c) => c.id),
          });

          setSelectedDiplomaCategories(newField.diplomaCategories);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van het rapport!',
            { variant: 'error' },
          );
          history.push(EXAM_REPORT_OVERVIEW_ROUTE);
        })
        .finally(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
  }, [id, history, notifications, repository]);

  if (!loaded) {
    return <Loader />;
  }

  const reportTypeRenderFunc = (value: any) =>
    REPORT_FIELD_TYPES[value as ExamReportFieldType];

  const requiredControl = (
    <Checkbox
      name="required"
      checked={required}
      onChange={handleCheckboxChange}
    />
  );

  const explanationControl = (
    <Checkbox
      name="explanation"
      checked={explanation}
      onChange={handleCheckboxChange}
    />
  );

  return (
    <Page breadcrumbs>
      <Box mb={2}>
        <FormControl fullWidth margin="normal">
          <FormLabel>Label</FormLabel>
          <TextField
            value={label}
            style={{ maxWidth: 400 }}
            onChange={handleFieldChange}
            name="label"
          />
        </FormControl>
      </Box>

      <Box mb={2}>
        <FormControl>
          <FormLabel id="report-field-type-select-label">Type</FormLabel>
          <Select
            labelId="report-field-type-select-label"
            id="report-field-type-select"
            value={type}
            name="type"
            onChange={handleSelectChange}
            MenuProps={{ elevation: 1 }}
            renderValue={reportTypeRenderFunc}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Selecteer een type...
            </MenuItem>
            {Object.entries(REPORT_FIELD_TYPES).map(([type, label]) => (
              <MenuItem value={type as string}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={2}>
        <FormControl fullWidth margin="normal">
          <FormLabel>Beschrijving</FormLabel>
          <Box maxWidth={500}>
            <HtmlEditor data={description} onChange={handleDescriptionChange} />
          </Box>
        </FormControl>
      </Box>

      <Box>
        <FormControlLabel control={requiredControl} label="Verplicht" />
      </Box>

      <Box mb={2}>
        <FormControlLabel
          control={explanationControl}
          label="Toelichtingsveld"
        />
      </Box>

      <Box mb={2}>
        <FormControl margin="normal">
          <FormLabel>Sorteervolgorde</FormLabel>
          <TextField
            type="number"
            name="sortOrder"
            value={sortOrder}
            onChange={handleSortOrderChange}
          />
        </FormControl>
      </Box>

      <Box mb={2}>
        <FormControl fullWidth>
          <FormLabel id="report-field-type-select-label">
            Diplomacategorieën (optioneel)
          </FormLabel>
          <Box mt={2}>
            <Autocomplete
              multiple
              onChange={handleCategoryChange}
              value={selectedDiplomaCategories}
              options={diplomaCategories}
              getOptionLabel={(category) => category.name}
              style={{ maxWidth: 500 }}
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  variant="outlined"
                  placeholder="Selecteer categorieën..."
                />
              )}
            />
          </Box>
        </FormControl>
      </Box>

      <Box mt={3}>
        <Button
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
        >
          {id ? 'Opslaan' : 'Aanmaken'}
        </Button>
      </Box>
    </Page>
  );
};

export default ReportFieldForm;
