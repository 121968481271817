import { ReactComponent as WarningIllustration } from '../../assets/images/warning_illustration.svg';
import ErrorPage from './ErrorPage';

const Error404 = () => (
  <ErrorPage
    title="Oeps!"
    description="We kunnen de pagina die u zoekt niet vinden."
    illustration={WarningIllustration}
  />
);

export default Error404;
