import React, { useEffect, useState } from 'react';
import {
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ExamDiplomaDTO } from '../repository/ExamRepository';
import { DiplomaCategory } from '../../diploma/domain/types';
import { DiplomaViolation } from './ExamDiplomaList';
import { Examiner } from '../../../types';
import TimeInput from '../../../components/form/TimeInput';

interface ExamDiplomaListItemProps {
  item: ExamDiplomaDTO;
  onChange: (item: ExamDiplomaDTO) => void;
  onDelete: () => void;
  diplomaCategories: DiplomaCategory[];
  examinerOptions: Examiner[];
  oneExaminerOverall: boolean;
  violation?: DiplomaViolation;
}

const useStyles = makeStyles(() => ({
  tableCell: {
    paddingTop: 0,
    paddingLeft: 0,
    borderBottom: 'none',
  },
  select: {
    width: '280px',
  },
  time: {
    width: '100px',
  },
  placeholder: {
    color: grey[500],
  },
}));

const ExamDiplomaListItem = (props: ExamDiplomaListItemProps) => {
  const classes = useStyles();
  const {
    item,
    onChange,
    onDelete,
    diplomaCategories,
    examinerOptions,
    oneExaminerOverall,
    violation,
  } = props;

  const [examDiplomaItem, setExamDiplomaItem] = useState<ExamDiplomaDTO>(item);

  const handleAmountChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setExamDiplomaItem((prev) => ({
      ...prev,
      amount: parseInt(event.target.value as string, 10),
    }));
  };

  const handleBeginTimeChange = (date: Date | null) => {
    setExamDiplomaItem((prev) => ({
      ...prev,
      beginTime: date,
    }));
  };

  const handleEndTimeChange = (date: Date | null) => {
    setExamDiplomaItem((prev) => ({
      ...prev,
      endTime: date,
    }));
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const name = event.target.name as 'diplomaTypeId' | 'examinerId';

    setExamDiplomaItem((prev) => ({
      ...prev,
      [name]: event.target.value as string,
    }));
  };

  useEffect(
    () => {
      onChange(examDiplomaItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [examDiplomaItem],
  );

  /**
   * Refresh item when the exam line changes at the top level
   */
  useEffect(
    () => {
      setExamDiplomaItem(item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item],
  );

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        <Select
          name="diplomaTypeId"
          value={examDiplomaItem.diplomaTypeId}
          className={classes.select}
          onChange={handleSelectChange}
          MenuProps={{ elevation: 1 }}
          displayEmpty
          error={violation && violation.diplomaTypeId}
          autoWidth
        >
          <MenuItem value="">
            <em className={classes.placeholder}>Maak een keuze</em>
          </MenuItem>
          {diplomaCategories
            .filter(({ diplomaTypes }) => diplomaTypes.length > 0)
            .map(({ name, diplomaTypes }) => [
              <ListSubheader>{name}</ListSubheader>,
              ...diplomaTypes.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              )),
            ])}
        </Select>
      </TableCell>
      <>
        {!oneExaminerOverall && (
          <TableCell className={classes.tableCell}>
            <Select
              name="examinerId"
              value={examDiplomaItem.examinerId}
              className={classes.select}
              onChange={handleSelectChange}
              MenuProps={{ elevation: 1 }}
              displayEmpty
              error={violation && violation.examinerId}
            >
              <MenuItem value="">
                <em className={classes.placeholder}>Maak een keuze</em>
              </MenuItem>
              {examinerOptions.map((examiner) => (
                <MenuItem key={examiner.id} value={examiner.id}>
                  {examiner.extendedName}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        )}
      </>
      <TableCell className={classes.tableCell}>
        <TextField
          type="number"
          name="amount"
          value={examDiplomaItem.amount}
          onChange={handleAmountChange}
          error={violation && violation.amount}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <TimeInput
          name="beginTime"
          value={examDiplomaItem.beginTime}
          onBlur={handleBeginTimeChange}
          hasServerViolation={violation && violation.beginTime}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <TimeInput
          name="endTime"
          value={examDiplomaItem.endTime}
          onBlur={handleEndTimeChange}
          hasServerViolation={violation && violation.endTime}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <IconButton onClick={onDelete}>
          <FontAwesomeIcon color="red" icon={['fal', 'times']} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ExamDiplomaListItem;
