import React, { useState } from 'react';
import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core';

import InlineButton from '../../../components/InlineButton';
import ButtonRow from '../../../components/ButtonRow';
import { ExamReportEntryValuation } from '../domain/types';

interface ExamReportEntryValuationSelectorProps {
  onSubmit: (valuation: keyof typeof ExamReportEntryValuation) => void;
  type: string;
}

const ExamReportEntryValuationSelector = (
  props: ExamReportEntryValuationSelectorProps,
) => {
  const { onSubmit } = props;

  const [selectedValuation, setSelectedValuation] = useState<
    keyof typeof ExamReportEntryValuation | null
  >(null);

  const handleValuationSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setSelectedValuation(
      event.target.value as keyof typeof ExamReportEntryValuation,
    );
  };

  const handleSubmit = () => {
    if (selectedValuation === null) {
      return;
    }

    onSubmit(selectedValuation);
  };

  return (
    <>
      <FormControl>
        <FormLabel id="valuation-select-label">Beoordeel verslag</FormLabel>
        <Select
          labelId="valuation-select-label"
          id="valuation-select"
          value={selectedValuation || '-'}
          name="valuation"
          onChange={handleValuationSelect}
          MenuProps={{ elevation: 1 }}
          displayEmpty
        >
          <MenuItem value="-">
            <i>Maak een keuze</i>
          </MenuItem>
          {Object.entries(ExamReportEntryValuation)
            .filter(
              ([key]) =>
                (props.type === 'aspirant' && key !== 'attention') ||
                (props.type !== 'aspirant' && key !== 'terminated'),
            )
            .map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <ButtonRow>
        <InlineButton type="primary" onClick={handleSubmit}>
          Opslaan
        </InlineButton>
      </ButtonRow>
    </>
  );
};

export default ExamReportEntryValuationSelector;
