import { Box, FormControl, FormLabel } from '@material-ui/core';

import { File as FileInterface } from '../../types';
import FileChip from '../file/FileChip';
import FileUpload from '../file/FileUpload';

interface FileInputProps {
  label: string;
  file?: FileInterface;
  multiple?: boolean;
  documents?: boolean;
  video?: boolean;
  audio?: boolean;
  images?: boolean;
  onChange: (files: File[]) => void;
  onDelete: () => void;
}

const FileInput = (props: FileInputProps) => {
  return (
    <Box>
      <FormControl>
        <FormLabel>
          <Box>{props.label}</Box>
        </FormLabel>
        {props?.file ? (
          <Box>
            <FileChip file={props.file} onDelete={props.onDelete} />
          </Box>
        ) : (
          <FileUpload
            onChange={props.onChange}
            multiple={props.multiple}
            documents={props.documents}
            video={props.video}
            audio={props.audio}
            images={props.images}
          />
        )}
      </FormControl>
    </Box>
  );
};

export default FileInput;
