import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Chip, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Page from '../../../components/Page';
import DashboardSection from '../../../components/dashboard/DashboardSection';
import { colors } from '../../../config/theme';
import AppContext from '../../../AppContext';
import InvitesRepository from '../repository/InvitesRepository';
import {
  COLUMN_EXAM_ADDRESS,
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DECLARATION,
  COLUMN_EXAM_DIPLOMAS,
  COLUMN_EXAM_LOCATION,
  COLUMN_EXAM_REPORT_DELEGATE,
} from '../../exams/domain/columns';
import DataTable, { cancelOnRowClick } from '../../../components/DataTable';
import {
  ApiFilterCriteria,
  Exam,
  ExamAssignmentOfDelegate,
  ExamDiploma,
} from '../../../types';
import Button from '../../../components/Button';
import CandidateExamsRepository from '../repository/CandidateExamsRepository';
import ExamsRepository from '../repository/ExamsRepository';
import {
  DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE,
  DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE,
} from '..';
import ExamDashboardSection from '../../exams/components/ExamDashboardSection';
import CandidateExamRegisterDialog from '../components/CandidateExamRegisterDialog';
import InviteReplyDialog from '../components/InviteReplyDialog';
import { formatTime } from '../../../utils/common';
import DelegateAssignmentRepository from '../repository/DelegateAssignmentRepository';
import { EXAM_BASE_ROUTE } from '../../exams';

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    marginTop: -theme.spacing(1),
  },
  chip: {
    background: colors.linkWater,
    fontSize: 12,
    height: 18,
  },
}));

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const [confirmedCount, setConfirmedCount] = useState<number | null>(null);
  const [invitesCount, setInvitesCount] = useState<number | null>(null);
  const [candidateExamsCount, setCandidateExamsCount] = useState<number | null>(
    null,
  );
  const [examsCount, setExamsCount] = useState<number | null>(null);

  const [invitationDialogProps, setInvitationDialogProps] = useState<{
    invite: ExamAssignmentOfDelegate | null;
    open: boolean;
  }>({ invite: null, open: false });
  const [registrationDialogProps, setRegistrationDialogProps] = useState<{
    exam: Exam | null;
    open: boolean;
  }>({ exam: null, open: false });
  const { roleViewManager } = useContext(AppContext);

  const delegateId = roleViewManager.getUser().id || '';

  const [confirmedRepository, setConfirmedRepository] =
    useState<InvitesRepository>(new InvitesRepository(delegateId, 'confirmed'));

  const invitesRepository = new InvitesRepository(delegateId);

  const candidateExamsRepository = new CandidateExamsRepository(delegateId);
  const examsRepository = new ExamsRepository(delegateId);

  const handleInvitationDialogOpen = (assignment: ExamAssignmentOfDelegate) => {
    setInvitationDialogProps({ invite: assignment, open: true });
  };
  const handleInvitationDialogClose = () =>
    setInvitationDialogProps({ invite: null, open: false });

  const handleRegistrationDialogOpen = (exam: Exam) =>
    setRegistrationDialogProps({ exam, open: true });
  const handleRegistrationDialogClose = () =>
    setRegistrationDialogProps({ exam: null, open: false });

  const handleCancelInvite = (assignment: ExamAssignmentOfDelegate) => {
    new DelegateAssignmentRepository(assignment.exam.id)
      .cancel(assignment.id)
      .then(() =>
        setConfirmedRepository(new InvitesRepository(delegateId, 'confirmed')),
      );
  };

  const confirmedColumns = [
    {
      ...COLUMN_EXAM_DATE,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_DATE.render(assignment.exam),
    },
    {
      ...COLUMN_EXAM_LOCATION,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_LOCATION.render(assignment.exam),
    },
    {
      ...COLUMN_EXAM_DIPLOMAS,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_DIPLOMAS.render(assignment.exam),
    },
    {
      ...COLUMN_EXAM_ADDRESS,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_ADDRESS.render(assignment.exam),
    },
    {
      name: '',
      field: 'confirmed',
      render: (assignment: ExamAssignmentOfDelegate) => {
        let examLines: ExamDiploma[];
        if (assignment.examLines.length >= 1) {
          examLines = assignment.examLines;
        } else if (assignment.exam.examLines.length >= 1) {
          examLines = assignment.exam.examLines;
        } else {
          return null;
        }

        const beginTime = Math.min(
          ...examLines.map((el) => new Date(el.beginTime).getTime()),
        );
        const endTime = Math.max(
          ...examLines.map((el) => new Date(el.endTime).getTime()),
        );

        return (
          <Box display="flex" flexDirection="column">
            <Box>
              {beginTime &&
                endTime &&
                `Aanwezig: ${formatTime(new Date(beginTime))} tot ${formatTime(
                  new Date(endTime),
                )}`}
            </Box>
            <Box onClick={cancelOnRowClick}>
              <Button
                variant="default"
                icon={['fal', 'minus']}
                label="Afmelden"
                iconPosition="right"
                onClick={() => handleCancelInvite(assignment)}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  const invitesColumns = [
    {
      ...COLUMN_EXAM_DATE,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_DATE.render(assignment.exam),
    },
    {
      ...COLUMN_EXAM_LOCATION,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_LOCATION.render(assignment.exam),
    },
    {
      ...COLUMN_EXAM_DIPLOMAS,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_DIPLOMAS.render(assignment.exam),
    },
    {
      ...COLUMN_EXAM_ADDRESS,
      render: (assignment: ExamAssignmentOfDelegate) =>
        COLUMN_EXAM_ADDRESS.render(assignment.exam),
    },
    {
      name: '',
      field: 'invites',
      render: (assignment: ExamAssignmentOfDelegate) => (
        <Box onClick={cancelOnRowClick}>
          <Button
            icon={['fal', 'plus']}
            label="Reageer op uitnodiging"
            iconPosition="right"
            onClick={() => handleInvitationDialogOpen(assignment)}
          />
        </Box>
      ),
    },
  ];

  const candidateExamsColumns = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_LOCATION,
    COLUMN_EXAM_DIPLOMAS,
    COLUMN_EXAM_ADDRESS,
    {
      name: '',
      field: 'register',
      render: (exam: Exam) => (
        <Box onClick={cancelOnRowClick}>
          <Button
            icon={['fal', 'plus']}
            label="Aanmelden"
            iconPosition="right"
            onClick={() => handleRegistrationDialogOpen(exam)}
          />
        </Box>
      ),
    },
  ];

  const examsColumns = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_LOCATION,
    COLUMN_EXAM_REPORT_DELEGATE,
    COLUMN_EXAM_DECLARATION,
  ];

  const confirmedTitle = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>Op de planning</Box>
      <Chip
        label={confirmedCount !== undefined ? confirmedCount : ''}
        className={classes.chip}
      />
    </Box>
  );

  const invitesTitle = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>Uitnodigingen</Box>
      <Chip
        label={invitesCount !== undefined ? invitesCount : ''}
        className={classes.chip}
      />
    </Box>
  );

  const onAssignmentRowClick = (assignment: ExamAssignmentOfDelegate) => {
    history.push(`${EXAM_BASE_ROUTE}/${assignment.exam.id}`);
  };

  const visitedExamsFilters = {
    filters: {
      past: true,
      needsDelegateReport: true,
    },
  } as ApiFilterCriteria;

  const styleCandidateExamsRow = (item: Exam) =>
    item?.highPriority ? 'trHighlight' : 'tr';

  return (
    <Page title="Dashboard">
      {invitationDialogProps.invite && (
        <InviteReplyDialog
          invite={invitationDialogProps.invite}
          open={invitationDialogProps.open}
          onClose={handleInvitationDialogClose}
        />
      )}
      <DashboardSection title={confirmedTitle}>
        <DataTable
          id="invites-overview"
          repository={confirmedRepository}
          columns={confirmedColumns}
          onLoad={(_items, totalCount) => setConfirmedCount(totalCount)}
          onRowClick={onAssignmentRowClick}
          className={classes.dataTable}
          noHeader
          noResultCounter
        />
      </DashboardSection>
      <DashboardSection title={invitesTitle}>
        <DataTable
          id="invites-overview"
          repository={invitesRepository}
          columns={invitesColumns}
          onLoad={(_items, totalCount) => setInvitesCount(totalCount)}
          onRowClick={onAssignmentRowClick}
          className={classes.dataTable}
          noHeader
          noResultCounter
        />
      </DashboardSection>
      {registrationDialogProps.exam && (
        <CandidateExamRegisterDialog
          exam={registrationDialogProps.exam}
          open={registrationDialogProps.open}
          onClose={handleRegistrationDialogClose}
        />
      )}
      <ExamDashboardSection
        id="candidate-exams-overview"
        title="Geselecteerd voor jou"
        repository={candidateExamsRepository}
        itemCount={candidateExamsCount}
        columns={candidateExamsColumns}
        onLoad={(_items, totalCount) => setCandidateExamsCount(totalCount)}
        styleRow={styleCandidateExamsRow}
        allExamsUrl={DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE}
        allExamsText="Zie alle beschikbare examens"
        canView
        canEdit={false}
        canViewInfo={false}
      />
      <ExamDashboardSection
        id="visited-exams"
        title="Nog in te vullen verslagen"
        repository={examsRepository}
        itemCount={examsCount}
        columns={examsColumns}
        defaultFilters={visitedExamsFilters}
        onLoad={(_items, totalCount) => setExamsCount(totalCount)}
        allExamsUrl={DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE}
        allExamsText="Zie alle bijgewoonde examens"
        canView
        canEdit={false}
        canViewInfo={false}
      />
    </Page>
  );
};

export default Dashboard;
