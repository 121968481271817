import { AxiosResponse } from 'axios';
import ApiClient from '../../../api/ApiClient';

class DelegateAssignmentRepository {
  private examId: string;

  constructor(examId: string) {
    this.examId = examId;
  }

  confirm(assignmentId: string): Promise<AxiosResponse> {
    return ApiClient.put(
      `/api/v1/exams/${this.examId}/delegate-assignments/${assignmentId}/confirm`,
    );
  }

  decline(assignmentId: string): Promise<AxiosResponse> {
    return ApiClient.put(
      `/api/v1/exams/${this.examId}/delegate-assignments/${assignmentId}/decline`,
    );
  }

  cancel(assignmentId: string): Promise<AxiosResponse> {
    return ApiClient.put(
      `/api/v1/exams/${this.examId}/delegate-assignments/${assignmentId}/cancel`,
    );
  }

  updateExamLines(
    assignmentId: string,
    examLineIds: string[],
  ): Promise<AxiosResponse> {
    return ApiClient.post(
      `/api/v1/exams/${this.examId}/delegate-assignments/${assignmentId}/exam-lines`,
      { examLineIds },
    );
  }

  register(examLineIds: string[]): Promise<AxiosResponse> {
    return ApiClient.post(`/api/v1/exams/${this.examId}/register`, {
      examLineIds,
    });
  }
}

export default DelegateAssignmentRepository;
