import { useState } from 'react';

import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_LESSON_LOCATIONS,
  COLUMN_EXAM_REGION,
  COLUMN_EXAM_REPORT_DELEGATE,
  COLUMN_EXAM_REPORT_EXAMINER,
  COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
} from '../../../exams/domain/columns';
import ExamRepository from '../../../exams/repository/ExamRepository';
import ExamDashboardSection from '../../../exams/components/ExamDashboardSection';
import { PAST_EXAM_OVERVIEW_ROUTE } from '../../../exams';
import { ApiFilterCriteria } from '../../../../types';

const ExamsWithUncheckedReports = () => {
  const [examsCount, setExamsCount] = useState<number | null>(null);

  const examRepository = new ExamRepository(true);

  const columns = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
    COLUMN_EXAM_LESSON_LOCATIONS,
    COLUMN_EXAM_REGION,
    COLUMN_EXAM_REPORT_DELEGATE,
    COLUMN_EXAM_REPORT_EXAMINER,
  ];

  const defaultFilters = {
    filters: {
      invitationStatus: 'confirmed',
      delegateReportStatus: 'toAdd',
    },
  } as ApiFilterCriteria;

  return (
    <ExamDashboardSection
      id="exams-with-unchecked-reports"
      title="Beoordeel verslag"
      repository={examRepository}
      defaultFilters={defaultFilters}
      itemCount={examsCount}
      columns={columns}
      onLoad={(_items, totalCount) => setExamsCount(totalCount)}
      allExamsUrl={PAST_EXAM_OVERVIEW_ROUTE}
      allExamsText="Zie alle afgelegde examens"
      canView
      canEdit
      canViewInfo
    />
  );
};

export default ExamsWithUncheckedReports;
