import { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Loader from '../../../components/Loader';
import OperatorRepository from '../repository/OperatorRepository';
import Page from '../../../components/Page';
import BackButton from '../../../components/BackButton';
import { Operator, OperatorDTO } from '../../../types';
import OperatorForm from '../forms/OperatorForm';
import { OPERATOR_BASE_ROUTE } from '../index';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    height: theme.typography.h1.fontSize,
  },
}));

const UpdateOperator = () => {
  const { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const operatorRepository = new OperatorRepository();
  const notifications = useSnackbar();
  const history = useHistory();

  const [operator, setOperator] = useState<Operator | null>(null);

  const loadOperator = useCallback(() => {
    new OperatorRepository()
      .find(id)
      .then((response) => setOperator(response.data));
  }, [setOperator]);

  useEffect(() => {
    loadOperator();
  }, [loadOperator]);

  const handleSubmit = (operator: OperatorDTO) => {
    operatorRepository
      .update(id, operator)
      .then(() => {
        history.push(OPERATOR_BASE_ROUTE);
        notifications.enqueueSnackbar('Gebruiker is succesvol opgeslagen!', {
          variant: 'success',
        });
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het opslaan van de gebruiker.',
          { variant: 'error' },
        ),
      );
  };

  const title = (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1" className={classes.h1}>
        Exploitant bewerken
      </Typography>
    </Box>
  );

  if (!operator) {
    return <Loader />;
  }

  return (
    <Page title={title}>
      <OperatorForm operator={operator} onSubmit={handleSubmit} />
    </Page>
  );
};

export default UpdateOperator;
