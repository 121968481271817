import { Dialog } from '@material-ui/core';
import ExamDelegatesForm from './ExamDelegatesForm';

type ExamDelegatesDialogProps = {
  open: boolean;
  examId: string;
  onClose: () => void;
};

const ExamDelegatesDialog = (props: ExamDelegatesDialogProps) => {
  const { open, examId, onClose } = props;

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <ExamDelegatesForm examId={examId} onClose={onClose} />
    </Dialog>
  );
};

export default ExamDelegatesDialog;
