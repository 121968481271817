import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Page';
import { mailTemplateDTOFromResponse } from '../utils';
import { ROUTE_MAIL_TEMPLATE_OVERVIEW } from '../index';
import MailTemplateRepository from '../repository/MailTemplateRepository';
import { MailTemplateDTO } from '../types';
import MailTemplateForm from '../forms/MailTemplateForm';
import Loader from '../../../components/Loader';

const MailTemplateDetail = () => {
  const { id } = useParams<{ id: string }>();

  const notifications = useSnackbar();
  const history = useHistory();

  const [mailTemplateDTO, setMailTemplateDTO] =
    useState<MailTemplateDTO | null>(null);

  const repository = new MailTemplateRepository();

  const handleSubmit = (mailTemplateDTO: MailTemplateDTO) => {
    repository
      .update(id, mailTemplateDTO)
      .then(() => {
        history.push(ROUTE_MAIL_TEMPLATE_OVERVIEW);
        notifications.enqueueSnackbar(
          'E-mailtemplate is succesvol opgeslagen!',
          {
            variant: 'success',
          },
        );
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het opslaan van het e-mailtemplate.',
          { variant: 'error' },
        ),
      );
  };

  useEffect(
    () => {
      repository
        .find(id)
        .then((response) =>
          setMailTemplateDTO(mailTemplateDTOFromResponse(response)),
        )
        .catch(() => history.push(ROUTE_MAIL_TEMPLATE_OVERVIEW));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!mailTemplateDTO) {
    return <Loader />;
  }

  return (
    <Page title="E-mailtemplate bewerken" breadcrumbs>
      {mailTemplateDTO && (
        <MailTemplateForm
          mailTemplateDTO={mailTemplateDTO}
          onSubmit={handleSubmit}
        />
      )}
    </Page>
  );
};

export default MailTemplateDetail;
