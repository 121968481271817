import { Router, Route } from '../../routing';
import UserOverview from './views/UserOverview';
import CreateUser from './views/CreateUser';
import EditUser from './views/EditUser';
import ViewUser from './views/ViewUser';
import MyAccount from './views/MyAccount';
import ChangePassword from './views/ChangePassword';
import ForgotPassword from './views/ForgotPassword';
import UserActivation from './views/UserActivation';
import UpdateAccount from './views/UpdateAccount';
import UpdateAccountPassword from './views/UpdateAccountPassword';

export const USER_BASE_ROUTE = '/gebruikers';
export const USER_CREATE_URI = '/aanmaken';
export const USER_CREATE_ROUTE = USER_BASE_ROUTE + USER_CREATE_URI;

export const ACCOUNT_BASE_ROUTE = '/account';

const users = new Route(
  'Gebruikers',
  USER_BASE_ROUTE,
  <UserOverview />,
  true,
).role(['ROLE_ADMIN', 'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER']);
users.addChild(
  new Route('Gebruiker aanmaken', USER_CREATE_URI, <CreateUser />),
);
users.addChild(
  new Route(
    'Gebruiker bewerken',
    '/:id/bewerken',
    <EditUser isProfile={false} />,
  ).role(['ROLE_ADMIN', 'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER']),
);
users.addChild(
  new Route('Gebruiker bekijken', '/:id', <ViewUser isProfile={false} />).role([
    'ROLE_ADMIN',
    'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER',
  ]),
);
users.addChild(
  new Route(
    'Wachtwoord veranderen',
    '/:id/wachtwoord',
    <ChangePassword isProfile={false} />,
  ),
);
Router.addRoute(users);

const account = new Route(
  'Profiel',
  `${ACCOUNT_BASE_ROUTE}`,
  <MyAccount />,
).requireLogin();
Router.addRoute(account);

const updateAccount = new Route(
  'Profiel bewerken',
  `${ACCOUNT_BASE_ROUTE}/bewerken`,
  <UpdateAccount />,
).requireLogin();
Router.addRoute(updateAccount);

const updateAccountPassword = new Route(
  'Wachtwoord veranderen',
  `${ACCOUNT_BASE_ROUTE}/wachtwoord`,
  <UpdateAccountPassword />,
).requireLogin();
Router.addRoute(updateAccountPassword);

Router.addRoute(
  new Route(
    'Wachtwoord vergeten',
    '/wachtwoord-vergeten',
    <ForgotPassword />,
  ).anonymous(),
);
Router.addRoute(
  new Route('Account activeren', '/activeren', <UserActivation />).anonymous(),
);
