import { ReactNode } from 'react';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Box, Chip } from '@material-ui/core';

import { Column } from '../../../components/DataTable';
import RoleViewManager from '../../../RoleViewManager';
import { Declaration } from '../../../types';
import Button from '../../../components/Button';
import { colors } from '../../../config/theme';
import { formatCurrency, formatDate } from '../../../utils/common';
import { COLUMN_EXAM_DATE } from '../../exams/domain/columns';
import { DECLARATIONS_BASE_ROUTE } from '../index';

export interface DeclarationColumn extends Column {
  render(
    declaration: Declaration,
    roleViewManager?: RoleViewManager,
  ): ReactNode;
}

export const COLUMN_DECLARATION_EXAM_DATE: DeclarationColumn = {
  name: 'Examendatum',
  field: 'examDate',
  render: (declaration: Declaration) =>
    declaration.exam ? COLUMN_EXAM_DATE.render(declaration.exam) : '',
};

export const COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER: DeclarationColumn = {
  name: 'Aanbieder',
  field: 'swimmingLessonProvider',
  render: (declaration: Declaration) => {
    if (!declaration.exam) {
      return null;
    }

    return (
      <Box display="flex" flexDirection="column">
        <Box>{declaration.exam.swimmingLessonProvider.name}</Box>
        <Box style={{ fontWeight: 300 }}>
          {declaration.exam.paddedExamNumber}
        </Box>
      </Box>
    );
  },
};

export const COLUMN_DECLARATION_DELEGATE: DeclarationColumn = {
  name: 'Gedelegeerde',
  field: 'delegate',
  render: (declaration: Declaration) => declaration.delegate.fullName,
};

export const COLUMN_DECLARATION_TOTAL_COMPENSATION: DeclarationColumn = {
  name: 'Totaal bedrag',
  field: 'totalCompensation',
  render: (declaration: Declaration) =>
    formatCurrency(declaration.totalCompensation),
};

export const COLUMN_DECLARATION_STATUS: DeclarationColumn = {
  name: 'Status',
  field: 'status',
  render: (declaration: Declaration) => {
    let label: string;
    let icon: IconName;

    if (declaration.status === 'approved') {
      label = 'Geaccordeerd';
      icon = 'envelope-open-text';
    } else if (declaration.status === 'exported') {
      label = 'Uitbetaald';
      icon = 'envelope-open-text';
    } else {
      label = 'Nieuw';
      icon = 'plus';
    }

    return (
      <Button
        icon={['fal', icon]}
        label={label}
        iconPosition="right"
        to={`${DECLARATIONS_BASE_ROUTE}/${declaration.id}`}
      />
    );
  },
};

export const COLUMN_DECLARATION_LATEST_ACTION: DeclarationColumn = {
  name: 'Laatste actie',
  field: 'latestAction',
  render: (declaration: Declaration) => {
    let label: string | null = null;

    switch (declaration.status) {
      case 'approved':
        label = declaration.approvedOn
          ? `${formatDate(new Date(declaration.approvedOn))} geaccordeerd`
          : '';
        break;
      case 'exported':
        label = declaration.exportedOn
          ? `${formatDate(new Date(declaration.exportedOn))} geëxporteerd`
          : '';
        break;
      default:
        return '';
    }

    return <Chip label={label} style={{ background: colors.softPeach }} />;
  },
};
