import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Column } from '../../../components/DataTable';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { ExamLocation, SwimmingLessonProvider } from '../../../types';
import { formatAddress } from '../../../utils/common';
import SwimmingExamProviderRepository from '../repository/SwimmingLessonProviderRepository';
import SLPExamLocationSelector from './SLPExamLocationSelector';

interface SLPExamLocationListProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  isAllowedToEdit: boolean;
}

const SLPExamLocationList = (props: SLPExamLocationListProps) => {
  const { swimmingLessonProvider, isAllowedToEdit } = props;

  const notifications = useSnackbar();

  const [examLocations, setExamLocations] = useState<ExamLocation[] | null>(
    null,
  );

  const repository = new SwimmingExamProviderRepository();

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (examLocation: ExamLocation) => examLocation.name,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (examLocation: ExamLocation) => examLocation.email,
    },
    {
      name: 'Telefoon',
      field: 'telephone',
      render: (examLocation: ExamLocation) => examLocation.telephone,
    },
    {
      name: 'Adres',
      field: 'visitingAddress',
      render: (examLocation: ExamLocation) =>
        formatAddress(examLocation.visitingAddress),
    },
  ];

  const loadExamLocations = () => {
    repository
      .getExamLocations(swimmingLessonProvider.id)
      .then((response) => setExamLocations(response.data));
  };

  const handleSubmit = (examLocation: ExamLocation) => {
    repository
      .addExamLocation(swimmingLessonProvider.id, examLocation.id)
      .then(() => {
        notifications.enqueueSnackbar(
          'De afzwemlocatie is succesvol toegevoegd!',
          {
            variant: 'success',
          },
        );
        loadExamLocations();
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het toevoegen van de afzwemlocatie.',
          {
            variant: 'error',
          },
        );
      });
  };

  const handleDelete = (id: string) => {
    repository
      .removeExamLocation(swimmingLessonProvider.id, id)
      .then(() => {
        notifications.enqueueSnackbar(
          'De afzwemlocatie is succesvol verwijderd.',
          {
            variant: 'success',
          },
        );
        loadExamLocations();
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het verwijderen van de afzwemlocatie.',
          {
            variant: 'error',
          },
        );
      });
  };

  useEffect(
    () => {
      loadExamLocations();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!examLocations) {
    return <Loader inline />;
  }

  return (
    <>
      <SimpleTable
        items={examLocations}
        columns={columns}
        canDeleteItems={isAllowedToEdit}
        onItemDelete={handleDelete}
      />
      {isAllowedToEdit && <SLPExamLocationSelector onSubmit={handleSubmit} />}
    </>
  );
};

export default SLPExamLocationList;
