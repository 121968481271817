import React from 'react';

import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { colors } from '../config/theme';
import { Tab } from '../routing/Router';
import PageTab from './PageTab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& + $root': {
      marginTop: theme.spacing(4),
    },
  },
  tabBar: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    color: colors.primaryDark,
    background: colors.whiteLilac,
  },
  contents: {
    padding: theme.spacing(2),
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

interface PageTabBarProps {
  tabs: Tab[];
  children: React.ReactElement | React.ReactElement[];
}

const PageTabBar = (props: PageTabBarProps) => {
  const { tabs, children } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.tabBar}>
        {tabs.map((tab: Tab) => (
          <PageTab key={tab.url} tab={tab} />
        ))}
      </Box>
      <Box className={classes.contents}>{children}</Box>
    </Box>
  );
};

export default PageTabBar;
