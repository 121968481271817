import { Route, Router } from '../../routing';
import SepaOverview from './views/SepaOverview';
import SepaDetails from './views/SepaDetails';

export const SEPA_OVERVIEW_ROUTE = '/sepa';

const sepaOverview = new Route(
  'Sepa exports',
  SEPA_OVERVIEW_ROUTE,
  <SepaOverview />,
).role('ROLE_SUPER_ADMIN');
Router.addRoute(sepaOverview);

const inspectSepaExport = new Route(
  'Sepa export details',
  `${SEPA_OVERVIEW_ROUTE}/:id`,
  <SepaDetails />,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(inspectSepaExport);
