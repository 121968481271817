import { ChangeEvent, useState } from 'react';

import { Box, Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Operator } from '../../../types';
import OperatorRepository from '../repository/OperatorRepository';

interface OperatorAddSLPProps {
  operator: Operator;
  onSuccess: () => void;
}

const OperatorAddSwimmingLessonProvider = (props: OperatorAddSLPProps) => {
  const { operator, onSuccess } = props;

  const notifications = useSnackbar();

  const [code, setCode] = useState<string>('');
  const repository = new OperatorRepository();

  const handleCodeChange = (
    e: ChangeEvent<{ name: string; value: string }>,
  ) => {
    const { value } = e.target;
    setCode(value);
  };

  const addSwimmingLessonProvider = () => {
    repository
      .addSwimmingLessonProvider(operator.id, code)
      .then(() => {
        setCode('');
        onSuccess();
        notifications.enqueueSnackbar('Leslocatie succesvol toegevoegd.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het toevoegen van de leslocatie.',
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <>
      <Box mb={1}>
        <TextField
          label="Leslocatie toevoegen o.b.v. code"
          name="code"
          value={code}
          fullWidth
          onChange={handleCodeChange}
        />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={addSwimmingLessonProvider}
      >
        Toevoegen
      </Button>
    </>
  );
};

export default OperatorAddSwimmingLessonProvider;
