import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import { User } from '../../../types';
import { setAccount, setImitating, setRoleView } from '../../../actions';
import {
  RoleViewInterface,
  getInitialRoleView,
  ROLE_VIEW_STORE_KEY,
} from '../../../RoleViewManager';

class UserImitator {
  /**
   * Dispatcher for dispatching Redux actions.
   */
  private readonly dispatch: Dispatch<any>;

  /**
   * Interface for interacting with local storage.
   */
  private readonly localStore: LocalForage;

  constructor(dispatch: Dispatch, localStore: LocalForage) {
    this.dispatch = dispatch;
    this.localStore = localStore;
  }

  async imitate(email: string) {
    await ApiClient.get(`/auth/impersonate?_switch_user=${email}`);

    this.dispatch(setImitating(true));
    await this.localStore.setItem('imitating', '1');

    await this.refreshAccount();
  }

  async stopImitating() {
    try {
      await ApiClient.get('/auth/impersonate?_switch_user=_exit');
    } catch (e) {
      // if this didn't work, the backend already didn't think you were imitating
    }

    this.dispatch(setImitating(false));
    await this.localStore.removeItem('imitating');

    await this.refreshAccount();
  }

  /**
   * Refresh the user account.
   */
  async refreshAccount(callback?: () => void) {
    const account: AxiosResponse<User> = await ApiClient.get('/api/v1/account');

    setTimeout(() => {
      const initialRoleView = getInitialRoleView(account.data.roles);

      this.dispatch(setAccount(account.data));
      this.dispatch(setRoleView(initialRoleView));

      this.localStore.setItem<keyof RoleViewInterface | null>(
        ROLE_VIEW_STORE_KEY,
        initialRoleView,
      );
    }, 0);

    if (callback) {
      callback();
    }
  }
}

export default UserImitator;
