import { User } from '../../../types';

const NullUser: User = {
  id: '',
  active: false,
  email: '',
  firstName: '',
  insertion: '',
  lastName: '',
  fullName: '',
  roles: [],
};

export default NullUser;
