import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { SwimmingLessonProviderWithLessonLocations } from '../../../types';
import SwimmingLessonProviderSelect from '../../admin/forms/SwimmingLessonProviderSelect';

interface SLPDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations,
  ) => void;
}

const SLPDialog = (props: SLPDialogProps) => {
  const { isOpen, onClose, onConfirm } = props;

  const [swimmingLessonProvider, setSwimmingLessonProvider] =
    useState<SwimmingLessonProviderWithLessonLocations | null>(null);

  const selectSwimmingLessonProvider = (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations,
  ) => {
    setSwimmingLessonProvider(swimmingLessonProvider);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Koppel een zwemlesaanbieder</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <SwimmingLessonProviderSelect
            onSelect={selectSwimmingLessonProvider}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Annuleren
        </Button>
        <Button
          onClick={
            swimmingLessonProvider
              ? () => onConfirm(swimmingLessonProvider)
              : undefined
          }
          color="secondary"
          disabled={!swimmingLessonProvider}
        >
          Koppelen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SLPDialog;
