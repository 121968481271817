import { useEffect, useState } from 'react';

import InfoText from '../../../components/InfoText';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { DelegateTimeSlot, User } from '../../../types';
import DelegateRepository from '../../delegates/repository/DelegateRepository';
import { formatDayOfWeek, formatTime } from '../../../utils/common';
import AddDelegateTimeSlot from '../../delegates/components/AddDelegateTimeSlot';

interface DelegateTimeSlotListProps {
  user: User;
}

const DelegateTimeSlotList = (props: DelegateTimeSlotListProps) => {
  const { user } = props;

  const [timeSlots, setTimeSlots] = useState<DelegateTimeSlot[] | null>(null);

  const repository = new DelegateRepository();

  const columns = [
    {
      name: 'Dag',
      field: 'dayOfWeek',
      render: (timeSlot: DelegateTimeSlot) =>
        formatDayOfWeek(timeSlot.dayOfWeek),
    },
    {
      name: 'Van',
      field: 'from',
      render: (timeSlot: DelegateTimeSlot) =>
        formatTime(new Date(timeSlot.from)),
    },
    {
      name: 'Tot',
      field: 'until',
      render: (timeSlot: DelegateTimeSlot) =>
        formatTime(new Date(timeSlot.until)),
    },
  ];

  const loadTimeSlots = () => {
    repository
      .getTimeSlots(user.id)
      .then((response) => setTimeSlots(response.data));
  };

  const handleDelete = (timeSlotId: string): void => {
    repository.deleteTimeSlot(user.id, timeSlotId).then(() => loadTimeSlots());
  };

  useEffect(
    () => {
      loadTimeSlots();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.id],
  );

  if (timeSlots === null) {
    return <Loader inline />;
  }

  return (
    <>
      <InfoText>
        Stel minimaal één tijdvak in waarin je beschikbaar bent om suggesties
        voor examens te ontvangen.
      </InfoText>
      <SimpleTable
        items={timeSlots}
        columns={columns}
        canDeleteItems
        onItemDelete={handleDelete}
      />
      <AddDelegateTimeSlot delegate={user} onSuccess={loadTimeSlots} />
    </>
  );
};

export default DelegateTimeSlotList;
