import {
  ApiFilterCriteria,
  CollectionResponse,
  ExamAssignmentOfDelegate,
  ExamDelegateAssignmentStatus,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class InvitesRepository implements Repository<ExamAssignmentOfDelegate> {
  private readonly delegateId: string;

  private status: ExamDelegateAssignmentStatus | null = null;

  constructor(
    delegateId: string,
    status: ExamDelegateAssignmentStatus | null = null,
  ) {
    this.delegateId = delegateId;
    this.status = status;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<ExamAssignmentOfDelegate>> {
    const extraParams: {
      [key: string]: string | number | null | boolean;
    } = { page: page || 0 };

    if (this.status) {
      extraParams.status = this.status;
    }

    const params = parseCriteria(criteria, extraParams);

    return ApiClient.get(
      `/api/v1/delegates/${this.delegateId}/invites`,
      params,
    );
  }
}

export default InvitesRepository;
