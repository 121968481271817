import { ReactNode } from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../config/theme';

interface FormSectionProps {
  title: string;
  children: ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  header: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    color: colors.primaryDark,
    background: colors.whiteLilac,
    '& h2': {
      fontSize: 18,
      height: 18,
      margin: 0,
    },
  },
  contents: {
    padding: theme.spacing(2),
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

const FormSection = (props: FormSectionProps) => {
  const { title, children, className } = props;
  const classes = useStyles();

  const rootClasses = [classes.root];

  if (className) {
    rootClasses.push(className);
  }

  return (
    <Box className={rootClasses.join(' ')}>
      <Box className={classes.header}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Box className={classes.contents}>{children}</Box>
    </Box>
  );
};

export default FormSection;
