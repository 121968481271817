import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';

interface StatusIndicatorProps {
  indicator: 'green' | 'orange' | 'red';
}

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    opacity: 0.8,
    display: 'inline-block',
    height: 12,
    width: 12,
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    transform: 'translateY(1px)',
  },
  green: {
    background: green[700],
  },
  orange: {
    background: orange[700],
  },
  red: {
    background: red[700],
  },
}));

const StatusIndicator = (props: StatusIndicatorProps) => {
  const { indicator } = props;
  const classes = useStyles();

  return <div className={`${classes.circle} ${classes[indicator]}`} />;
};

export default StatusIndicator;
