import { useCallback, useEffect, useState } from 'react';

import { Column } from '../../../components/DataTable';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { Operator, User } from '../../../types';
import OperatorRepository from '../repository/OperatorRepository';

interface OperatorUserListProps {
  operator: Operator;
}

const OperatorUserList = (props: OperatorUserListProps) => {
  const { operator } = props;

  const [users, setUsers] = useState<User[] | null>(null);

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (user: User) => user.fullName,
    },
  ];

  const loadUsers = useCallback(() => {
    new OperatorRepository()
      .getUsers(operator.id)
      .then((response) => setUsers(response.data));
  }, [operator.id]);

  useEffect(loadUsers, [loadUsers]);

  if (!users) {
    return <Loader inline />;
  }

  return <SimpleTable items={users} columns={columns} />;
};

export default OperatorUserList;
