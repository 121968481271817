import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import AppContext from '../../../AppContext';
import Button from '../../../components/Button';
import DataTable, { cancelOnRowClick } from '../../../components/DataTable';
import Page from '../../../components/Page';
import {
  COLUMN_EXAM_ADDRESS,
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DIPLOMAS,
  COLUMN_EXAM_LOCATION,
} from '../../exams/domain/columns';
import { Exam } from '../../../types';
import { EXAM_BASE_ROUTE } from '../../exams';
import CandidateExamRegisterDialog from '../components/CandidateExamRegisterDialog';
import CandidateExamsRepository from '../repository/CandidateExamsRepository';

const SelectedExamOverview = () => {
  const history = useHistory();
  const { roleViewManager } = useContext(AppContext);

  const delegateId = roleViewManager.getUser().id || '';
  const repository = new CandidateExamsRepository(delegateId);

  const [registrationDialogProps, setRegistrationDialogProps] = useState<{
    exam: Exam | null;
    open: boolean;
  }>({ exam: null, open: false });

  const handleRegistrationDialogOpen = (exam: Exam) =>
    setRegistrationDialogProps({ exam, open: true });
  const handleRegistrationDialogClose = () =>
    setRegistrationDialogProps({ exam: null, open: false });

  const columns = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_LOCATION,
    COLUMN_EXAM_DIPLOMAS,
    COLUMN_EXAM_ADDRESS,
    {
      name: '',
      field: 'register',
      render: (exam: Exam) => (
        <Box onClick={cancelOnRowClick}>
          <Button
            icon={['fal', 'plus']}
            label="Aanmelden"
            iconPosition="right"
            onClick={() => handleRegistrationDialogOpen(exam)}
          />
        </Box>
      ),
    },
  ];

  const onRowClick = (exam: Exam) => {
    history.push(`${EXAM_BASE_ROUTE}/${exam.id}`);
  };

  const styleRow = (item: Exam) =>
    item?.highPriority ? ('trHighlight' as const) : ('tr' as const);

  return (
    <Page title="Geselecteerd voor jou">
      {registrationDialogProps.exam && (
        <CandidateExamRegisterDialog
          exam={registrationDialogProps.exam}
          open={registrationDialogProps.open}
          onClose={handleRegistrationDialogClose}
        />
      )}
      <DataTable
        id="delegate-selected-exam-overview"
        repository={repository}
        columns={columns}
        onRowClick={onRowClick}
        styleRow={styleRow}
      />
    </Page>
  );
};

export default SelectedExamOverview;
