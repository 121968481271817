import {
  ApiFilterCriteria,
  CollectionResponse,
  Exam,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class ExaminerExamRepository implements Repository<Exam> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<Exam>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });

    return ApiClient.get('/api/examiner/exams', params);
  }
}

export default ExaminerExamRepository;
