import { useEffect, useState } from 'react';

import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Column } from './DataTable';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
    minWidth: '50%',
  },
  tr: {
    '& > th': {
      fontWeight: theme.typography.h1.fontWeight,
      fontFamily: theme.typography.h1.fontFamily,
    },
    '& > th, & > td': {
      padding: theme.spacing(1),
      borderBottom: 0,
    },
  },
  checkbox: {
    padding: 0,
  },
}));

interface SimpleTableProps {
  items: any[];
  columns: Column[];
  canSelectItems?: boolean;
  onItemSelect?: (ids: string[]) => void;
  canDeleteItems?: boolean;
  onItemDelete?: (id: string) => void;
}

const SimpleTable = (props: SimpleTableProps) => {
  const classes = useStyles();
  const {
    items,
    columns,
    canSelectItems = false,
    onItemSelect,
    canDeleteItems = false,
    onItemDelete,
  } = props;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const toggleChecked = (id: string) => {
    const index = selectedIds.indexOf(id);
    if (index !== -1) {
      setSelectedIds((ids) => {
        const updatedIds = [...ids];
        updatedIds.splice(index, 1);
        return updatedIds;
      });
    } else {
      setSelectedIds((ids) => [...ids, id]);
    }
  };

  useEffect(() => {
    if (canSelectItems && onItemSelect) {
      onItemSelect(selectedIds);
    }
  }, [canSelectItems, selectedIds, onItemSelect]);

  return (
    <Box mb={1}>
      {items.length ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tr}>
              {canSelectItems && <TableCell />}
              {columns.map((column) => {
                return (
                  <TableCell key={`cell-header-${column.field}`}>
                    {column.name}
                  </TableCell>
                );
              })}
              {canDeleteItems && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {items.map((item) => (
                <TableRow
                  className={classes.tr}
                  key={(item as { id: string }).id}
                >
                  {canSelectItems && (
                    <TableCell>
                      <Checkbox
                        className={classes.checkbox}
                        checked={selectedIds.includes(item.id)}
                        onChange={() => toggleChecked(item.id)}
                        name="selected-ids"
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={`${(item as { id: string }).id}-cell-${
                        column.field
                      }`}
                    >
                      <Box>{column.render && column.render(item)}</Box>
                    </TableCell>
                  ))}
                  {canDeleteItems && onItemDelete && (
                    <TableCell>
                      <Tooltip title="Verwijderen">
                        <IconButton
                          onClick={() => onItemDelete(item.id)}
                          size="small"
                        >
                          <FontAwesomeIcon
                            color="red"
                            icon={['fal', 'trash']}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      ) : (
        'Geen resultaten'
      )}
    </Box>
  );
};

export default SimpleTable;
