import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, FormLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { uniq } from 'lodash';
import { useSnackbar } from 'notistack';

import { ConfigValue, ExamReport } from '../../exams/domain/types';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import ExamReportRepository from '../../exams/repository/ExamReportRepository';
import ConfigRepository from '../../config/ConfigRepository';

const Config = () => {
  const notifications = useSnackbar();

  const [changeSet, setChangeSet] = useState<{ [key: string]: string | null }>(
    {},
  );
  const [config, setConfig] = useState<{ [key: string]: ConfigValue } | null>(
    null,
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const [reports, setReports] = useState<ExamReport[]>([]);

  const handleReportAutocompleteChange = (
    key: string,
    value: ExamReport | null,
  ) => {
    setChangeSet({ ...changeSet, [key]: value?.id || null });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeSet({ ...changeSet, [event.target.name]: event.target.value });
  };

  const handleSaveChangeSet = () => {
    new ConfigRepository()
      .saveChangeSet(changeSet)
      .then(() => {
        notifications.enqueueSnackbar(
          'De configuratie is succesvol bijgewerkt.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets mis gegaan tijdens het bijwerken van de configuratie.',
          { variant: 'error' },
        );
      });
  };

  useEffect(() => {
    new ConfigRepository().getConfig().then((response) => {
      setConfig(response.data);

      const types = uniq(Object.values(response.data).map((c) => c.type));

      const promises: Promise<unknown>[] = [];

      if (types.includes('report')) {
        promises.push(
          new ExamReportRepository().findAll().then((response) => {
            setReports(response.data.items);
          }),
        );
      }

      Promise.all(promises).then(() => setLoaded(true));
    });
  }, []);

  if (!loaded || !config) {
    return <Loader />;
  }

  return (
    <Page title="Configuratie">
      {Object.entries(config).map(([key, value]) => {
        let field: React.ReactElement | null = null;

        if (value.type === 'report') {
          field = (
            <Autocomplete
              onChange={(_e, v) => handleReportAutocompleteChange(key, v)}
              options={reports}
              getOptionLabel={(report) => report.title}
              style={{ maxWidth: 500 }}
              defaultValue={reports.find((r) => r.id === value.value)}
              renderInput={(params) => (
                <TextField
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...params}
                  variant="outlined"
                  placeholder="Selecteer rapport..."
                />
              )}
            />
          );
        } else if (value.type === 'numeric') {
          field = (
            <TextField
              type="number"
              name={key}
              onChange={handleInputChange}
              defaultValue={config[key]?.value}
            />
          );
        } else if (value.type === 'string') {
          field = (
            <TextField
              name={key}
              onChange={handleInputChange}
              defaultValue={config[key]?.value}
            />
          );
        }

        if (!field) {
          return <div />;
        }

        return (
          <Box mb={3} mt={3}>
            <FormLabel>{value.label}</FormLabel>
            <Box mt={1}>{field}</Box>
          </Box>
        );
      })}
      <Button
        variant="contained"
        type="submit"
        color="primary"
        startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
        onClick={handleSaveChangeSet}
      >
        Opslaan
      </Button>
    </Page>
  );
};

export default Config;
