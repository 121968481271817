import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';
import { uniqueId } from 'lodash';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import Loader from '../../../components/Loader';
import {
  ConflictingExam,
  SwimmingLessonProviderWithLessonLocations,
  User,
  ValidationViolation,
} from '../../../types';
import ExamRepository, {
  ExamDiplomaDTO,
  ExamDTO,
} from '../repository/ExamRepository';
import ExamForm from '../forms/ExamForm';
import SwimmingLessonProviderForm from '../forms/SwimmingLessonProviderForm';
import { examToExamDTO } from '../utils';
import Page from '../../../components/Page';
import BackButton from '../../../components/BackButton';
import { FUTURE_EXAM_OVERVIEW_ROUTE } from '../index';
import { formatDate } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    height: theme.typography.h1.fontSize,
  },
}));

export type Display = 'minimal' | 'full';

const CreateOrUpdateExam = () => {
  const { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const history = useHistory();
  const notifications = useSnackbar();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );
  const isAdmin = account.roles.includes('ROLE_ADMIN');

  const isNew = id === undefined;
  const initialDate = addDays(new Date(), isAdmin ? 0 : 10);
  const minDate = addDays(new Date(), isAdmin ? -3650 : 10);

  const [exam, setExam] = useState<ExamDTO>(() => ({
    swimmingLessonProviderId: '',
    date: initialDate,
    examLocationId: '',
    lessonLocationIds: [],
    examinerId: '',
    oneExaminerOverall: true,
    diplomas: [
      {
        id: uniqueId(),
        diplomaTypeId: '',
        examinerId: '',
        beginTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
        amount: 1,
        amountPassed: 0,
      },
    ],
    menOnly: false,
    womenOnly: false,
  }));

  const [swimmingLessonProvider, setSwimmingLessonProvider] =
    useState<SwimmingLessonProviderWithLessonLocations | null>(
      account.swimmingLessonProvider || null,
    );

  const [display, setDisplay] = useState<Display>(isNew ? 'minimal' : 'full');

  const [violations, setViolations] = useState<ValidationViolation[]>([]);
  const [conflictingExam, setConflictingExam] =
    useState<ConflictingExam | null>(null);
  const [permissionError, setPermissionError] = useState<any | null>(null);

  const examRepository = new ExamRepository(false);

  const [loaded, setLoaded] = useState<boolean>(false);

  const handleSubmit = () => {
    setViolations([]);
    setConflictingExam(null);
    setPermissionError(null);

    // merge the exam date with the begin and end time of the exam lines, before sending the data to the server
    exam.diplomas = exam.diplomas.map(
      (examLine: ExamDiplomaDTO): ExamDiplomaDTO => {
        const beginTime =
          exam.date && examLine.beginTime
            ? new Date(
                exam.date.setHours(
                  examLine.beginTime.getHours(),
                  examLine.beginTime.getMinutes(),
                  0,
                  0,
                ),
              )
            : null;
        const endTime =
          exam.date && examLine.endTime
            ? new Date(
                exam.date.setHours(
                  examLine.endTime.getHours(),
                  examLine.endTime.getMinutes(),
                  0,
                  0,
                ),
              )
            : null;

        return {
          ...examLine,
          beginTime,
          endTime,
        };
      },
    );

    const promise = id
      ? examRepository.update(id, exam)
      : examRepository.create(exam);

    promise
      .then(() => {
        notifications.enqueueSnackbar(
          `Het examen is succesvol ${id ? 'opgeslagen' : 'aangemeld'}!`,
          {
            variant: 'success',
          },
        );
        history.push(FUTURE_EXAM_OVERVIEW_ROUTE);
      })
      .catch((err) => {
        const { data } = err.response;

        // Conflicting exam response.
        if ('error' in data && data.error === 'conflicting_exam') {
          setConflictingExam({
            beginTime: data.beginTime,
            endTime: data.endTime,
          });
        }

        // No permission.
        if ('status' in data && data.status === 403) {
          setPermissionError(data.status);
        }

        // Validation failed response.
        if ('violations' in data) {
          setViolations(data.violations);
        }
      });
  };

  useEffect(
    () => {
      if (!id) {
        setLoaded(true);
        return;
      }

      examRepository
        .find(id)
        .then((response) => {
          if (swimmingLessonProvider === null) {
            setSwimmingLessonProvider(response.data.swimmingLessonProvider);
          }
          setExam(examToExamDTO(response.data));
        })
        .finally(() => setLoaded(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (!isNew) {
      return;
    }

    setDisplay(
      swimmingLessonProvider !== null && swimmingLessonProvider.centralExams
        ? 'full'
        : 'minimal',
    );
  }, [swimmingLessonProvider, isNew]);

  if (!loaded) {
    return <Loader />;
  }

  const title = (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1" className={classes.h1}>
        {id ? `Examen ${exam.examNumber} bewerken` : 'Examen aanmelden'}
      </Typography>
    </Box>
  );

  return (
    <Page title={title}>
      <SwimmingLessonProviderForm
        swimmingLessonProvider={swimmingLessonProvider}
        setSwimmingLessonProvider={setSwimmingLessonProvider}
        exam={exam}
        setExam={setExam}
        isNew={isNew}
        display={display}
        setDisplay={setDisplay}
      />
      {display === 'full' && (
        <>
          <ExamForm
            exam={exam}
            setExam={setExam}
            minDate={minDate}
            violations={violations}
          />
          <Box>
            {violations.length > 0 && (
              <Box mb={2} mt={2}>
                <Alert severity="error">
                  Een aantal velden zijn niet correct ingevuld.
                </Alert>
              </Box>
            )}
          </Box>
          <Box>
            {conflictingExam && exam.date && (
              <Box mb={2} mt={2}>
                <Alert severity="error">
                  {`Er is al een examen geboekt op ${formatDate(
                    new Date(exam.date),
                  )} van ${conflictingExam.beginTime} tot ${
                    conflictingExam.endTime
                  }`}
                </Alert>
              </Box>
            )}
            {permissionError && exam.date && (
              <Box mb={2} mt={2}>
                <Alert severity="error">
                  {`U bent niet gemachtigd dit examen aan te passen`}
                </Alert>
              </Box>
            )}
          </Box>
          <Box mt={3}>
            <Button
              onClick={handleSubmit}
              size="large"
              variant="contained"
              color="primary"
            >
              {id ? 'Opslaan' : 'Aanmelden'}
            </Button>
          </Box>
        </>
      )}
    </Page>
  );
};

export default CreateOrUpdateExam;
