import { useCallback, useEffect, useState } from 'react';

import { Column } from '../../../components/DataTable';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { SwimmingLessonProvider, User } from '../../../types';
import DelegateRepository from '../../delegates/repository/DelegateRepository';

interface BlockedSLPListProps {
  user: User;
}

const BlockedSLPList = (props: BlockedSLPListProps) => {
  const [swimmingLessonProviders, setSwimmingLessonProviders] = useState<
    SwimmingLessonProvider[] | null
  >(null);

  const columns: Column[] = [
    {
      name: 'Afas Id',
      field: 'afasId',
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        swimmingLessonProvider.afasId,
    },
    {
      name: 'Naam',
      field: 'name',
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        swimmingLessonProvider.name,
    },
  ];

  const loadSwimmingLessonProviders = useCallback(() => {
    new DelegateRepository()
      .getBlockedSwimmingLessonProviders(props.user.id)
      .then((response) => setSwimmingLessonProviders(response.data));
  }, [props.user.id]);

  useEffect(loadSwimmingLessonProviders, [loadSwimmingLessonProviders]);

  if (!swimmingLessonProviders) {
    return <Loader inline />;
  }

  return <SimpleTable items={swimmingLessonProviders} columns={columns} />;
};

export default BlockedSLPList;
