import {
  Route as DOMRoute,
  Switch,
  matchPath,
  Redirect,
} from 'react-router-dom';

import Route from './Route';
import PageTabBar from '../components/PageTabBar';
import Error404 from '../components/error/Error404';
import { RoleViewInterface, RoleViews } from '../RoleViewManager';

export interface Tab {
  label: string;
  url: string;
}

export default class Router {
  routes: Route[] = [];

  addRoute(node: Route) {
    this.routes.push(node);
  }

  getNodeByPath(path: string): Route | null {
    let match = null;

    this.getRoutes().forEach((route) => {
      if (
        matchPath(path, { path: route.getPath(), exact: true, strict: false })
      ) {
        match = route;
      }
    });

    return match;
  }

  filterRoutes(routes: Route[], roleView: keyof RoleViewInterface | null) {
    return routes.filter((route) => {
      if (route.isAnonymous && roleView) {
        return false;
      }

      return (
        !route.isRequiringLogin ||
        (roleView && this.canViewRoute(roleView, route))
      );
    });
  }

  sortRoutes(routes: Route[]) {
    return routes.sort((r1: Route, r2: Route) => {
      // since we're using the Switch component,
      // React follows up on the first route that matches (instead of all),
      // which means we must offer the more specific routes first
      let p1 = r1.getPath();
      let p2 = r2.getPath();
      if (p1.startsWith(p2)) {
        return -1;
      }
      if (p2.startsWith(p1)) {
        return 1;
      }
      // discard the beginning of p1 and p2 as long as there is no difference
      while (p1[0] === p2[0]) {
        p1 = p1.substr(1);
        p2 = p2.substr(1);
      }
      // if the first differing character involves a colon,
      // place the route with the colon last,
      // so that routes with params have lower priority
      if (p1.startsWith(':')) {
        return 1;
      }
      if (p2.startsWith(':')) {
        return -1;
      }
      // sort alphabetically
      return p1 < p2 ? -1 : 1;
    });
  }

  convertToDOMRoutes(routes: Route[], fallback?: JSX.Element) {
    const domRoutes = routes.map((route) => (
      <DOMRoute
        key={`route-${route.getTitle()}`}
        exact={route.isExact()}
        path={route.getPath()}
      >
        {route.getElement()}
      </DOMRoute>
    ));

    if (fallback !== undefined) {
      // matches all (undefined) urls, so we must offer this route last
      domRoutes.push(
        <DOMRoute key="not-found" path="*">
          {fallback}
        </DOMRoute>,
      );
    }

    return domRoutes;
  }

  processPages(roleView: keyof RoleViewInterface | null) {
    // use getRoutes() to expand child routes
    let routes = this.getRoutes();
    routes = this.filterRoutes(routes, roleView);
    routes = this.sortRoutes(routes);

    const domRoutes = this.convertToDOMRoutes(routes, <Error404 />);

    return <Switch>{domRoutes}</Switch>;
  }

  processTabs(roleView: keyof RoleViewInterface | null) {
    // use getRoutes() to expand child routes,
    // even though child routes are not recommended for tabs
    let routes = this.getRoutes();
    routes = this.filterRoutes(routes, roleView);

    if (routes.length === 0) {
      return null;
    }

    const tabs: Tab[] = routes.map((route) => ({
      label: route.getTitle(),
      url: route.getPath(),
    }));

    // sort routes after setting up tabs
    routes = this.sortRoutes(routes);

    // redirect to the first tab if an undefined page is requested
    const fallback = <Redirect to={tabs[0].url} />;

    const domRoutes = this.convertToDOMRoutes(routes, fallback);

    return (
      <PageTabBar tabs={tabs}>
        <Switch>{domRoutes}</Switch>
      </PageTabBar>
    );
  }

  private getRoutes() {
    let routes: Route[] = [];

    this.routes.forEach((route) => {
      routes = routes.concat(this.getChildrenOfNode(route));
    });

    return routes;
  }

  private getChildrenOfNode(node: Route, result: Route[] = []) {
    result.push(node);
    const children = node.getChildren();
    if (children.length > 0) {
      children.forEach((child) => this.getChildrenOfNode(child, result));
    }

    return result;
  }

  private canViewRoute(
    roleView: keyof RoleViewInterface,
    route: Route,
  ): boolean {
    const allowedRoles = route.getRoles();

    if (allowedRoles.length === 0) {
      return true;
    }

    return (
      allowedRoles.find((allowedRole) =>
        RoleViews[roleView].roles.includes(allowedRole),
      ) !== undefined
    );
  }
}
