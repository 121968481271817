import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  amount: {
    width: '160px',
  },
}));

interface AmountInputProps {
  name: string;
  // amount in (euro-)cents
  value?: number | null;
  onBlur?: (value: number | null) => void;
  hasServerViolation?: boolean;
  disabled?: boolean;
}

const AmountInput = (props: AmountInputProps) => {
  const {
    name,
    value: initialValue = null,
    onBlur = () => {},
    hasServerViolation = false,
    disabled = false,
  } = props;

  const classes = useStyles();

  const initialInputValue = initialValue ? (initialValue / 100).toFixed(2) : '';
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [hasError, setHasError] = useState<boolean>(hasServerViolation);

  // used to prevent validation until the user finishes inputting a value
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const inputIsValid = (value: string) => {
    return value.length === 0 || !Number.isNaN(parseFloat(value));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    // consider the input untouched if the user corrects a value
    // until the user focuses out again
    if (isTouched && inputIsValid(event.target.value)) {
      setIsTouched(false);
      setHasError(false);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(event.target.value);

    let amount = null;
    if (!Number.isNaN(parsedValue)) {
      // return the amount in (euro-)cents
      amount = Math.round(parsedValue * 100);

      // put the formatted amount in the input field, so the user sees what amount was parsed
      setInputValue(`${(amount / 100).toFixed(2)}`);
      setHasError(false);
    } else {
      setHasError(true);
    }

    setIsTouched(true);
    onBlur(amount);
  };

  useEffect(() => {
    setHasError(hasServerViolation);
  }, [hasServerViolation]);

  return (
    <TextField
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
      }}
      className={classes.amount}
      name={name}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
      error={hasError}
      placeholder="0.00"
      required
    />
  );
};

export default AmountInput;
