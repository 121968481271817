import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Box, Typography } from '@material-ui/core';
import { OPERATOR_BASE_ROUTE } from '../index';

import { Operator, User } from '../../../types';

import OperatorRepository from '../repository/OperatorRepository';
import useOperatorTabs from '../hooks/operator-tabs';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import BackButton from '../../../components/BackButton';
import ButtonRow from '../../../components/ButtonRow';
import FormSection from '../../../components/form/FormSection';
import InlineLink from '../../../components/InlineLink';
import InlineButton from '../../../components/InlineButton';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import TextOutput from '../../../components/form/TextOutput';

declare type TViewOperator = {
  id?: string;
  canEdit?: boolean;
  isOrganization?: boolean;
};

const ViewOperator = (props: TViewOperator) => {
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const isSuperAdmin = account.roles.includes('ROLE_SUPER_ADMIN');

  const history = useHistory();
  const notifications = useSnackbar();

  const { id: passedId, isOrganization = false, canEdit = true } = props;
  const { id: paramId } = useParams<{ id?: string }>();
  const id = passedId || paramId || '';

  const [operator, setOperator] = useState<Operator | null>(null);

  const tabs = useOperatorTabs(operator, isOrganization);

  const loadOperator = useCallback(() => {
    new OperatorRepository()
      .find(id)
      .then((response) => setOperator(response.data));
  }, [setOperator]);

  const canDelete = isSuperAdmin;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = (operator: Operator) => {
    new OperatorRepository()
      .delete(operator.id)
      .then(() => {
        notifications.enqueueSnackbar('De exploitant is verwijderd.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het verwijderen van de exploitant.',
          {
            variant: 'error',
          },
        );
      })
      .finally(() => {
        history.push(OPERATOR_BASE_ROUTE);
      });
  };

  useEffect(() => {
    loadOperator();
  }, [loadOperator]);

  const title = isOrganization ? (
    'Mijn Organisatie'
  ) : (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1">Exploitant bekijken</Typography>
    </Box>
  );

  if (!operator) {
    return <Loader />;
  }

  return (
    <Page title={title}>
      <FormSection title="Gegevens">
        <TextOutput label="Naam" value={operator.name} />
      </FormSection>
      <ButtonRow>
        {canEdit && (
          <InlineLink
            type="primary"
            to={`${OPERATOR_BASE_ROUTE}/${id}/bewerken`}
          >
            Bewerken
          </InlineLink>
        )}
        {canDelete && (
          <InlineButton type="dangerous" onClick={() => openDeleteDialog()}>
            Verwijderen
          </InlineButton>
        )}
      </ButtonRow>
      {canDelete && (
        <ConfirmationDialog
          title="Exploitant verwijderen"
          query="Weet je zeker dat je deze exploitant wilt verwijderen?"
          buttonText="Verwijderen"
          isOpen={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onConfirm={() => handleDelete(operator)}
        />
      )}
      {tabs}
    </Page>
  );
};

export default ViewOperator;
