import TextOutput, { OutputProps } from './TextOutput';

interface BooleanOutputProps extends OutputProps {
  value: boolean;
  textIfTrue?: string;
  textIfFalse?: string;
}

const BooleanOutput = (props: BooleanOutputProps) => {
  const { label, value, hideIfEmpty } = props;

  const textIfTrue = props?.textIfTrue || 'Ja';
  const textIfFalse = props?.textIfFalse || 'Nee';

  return (
    <TextOutput
      label={label}
      value={value ? textIfTrue : textIfFalse}
      hideIfEmpty={hideIfEmpty}
    />
  );
};

export default BooleanOutput;
