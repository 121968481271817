import { Route, Router } from '../../routing';
import Dashboard from './views/Dashboard';
import DelegateExamOverview from './views/DelegateExamOverview';
import SelectedExamOverview from './views/SelectedExamOverview';

export const DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE = '/bijgewoonde-examens';
export const DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE = '/geselecteerde-examens';

const delegateDashboard = new Route('Dashboard', '/', <Dashboard />, true).role(
  'ROLE_DELEGATE',
);
Router.addRoute(delegateDashboard);

const delegateExamOverview = new Route(
  'Bijgewoonde examens',
  DELEGATE_VISITED_EXAM_OVERVIEW_ROUTE,
  <DelegateExamOverview />,
  true,
).role('ROLE_DELEGATE');
Router.addRoute(delegateExamOverview);

const delegateExamTimeline = new Route(
  'Geselecteerd voor jou',
  DELEGATE_SELECTED_EXAM_OVERVIEW_ROUTE,
  <SelectedExamOverview />,
  true,
).role('ROLE_DELEGATE');
Router.addRoute(delegateExamTimeline);
