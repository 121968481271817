import { useSelector } from 'react-redux';
import { UserState } from '../../reducers/user';
import ViewSwimmingLessonProvider from './views/ViewSwimmingLessonProvider';

const MyOrganization = () => {
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  return (
    <ViewSwimmingLessonProvider
      id={account?.swimmingLessonProvider?.id}
      canEdit={false}
      isOrganization
    />
  );
};

export default MyOrganization;
