import { Grid } from '@material-ui/core';
import { File } from '../../types';
import FileChip from './FileChip';

interface FileChipsProps {
  files: File[];
  onDelete?: (file: File) => void;
}

const FileChips = (props: FileChipsProps) => {
  const { files, onDelete } = props;

  return (
    <Grid container spacing={1}>
      {files.map((file) => (
        <Grid key={file.id} item>
          <FileChip file={file} onDelete={onDelete} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FileChips;
