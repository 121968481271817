import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import {
  COLUMN_EXAM_NUMBER,
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DELEGATE,
  COLUMN_EXAM_DIPLOMAS,
  COLUMN_EXAM_REGION,
  COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
  COLUMN_EXAM_LOCATION,
  COLUMN_EXAM_DELEGATE_FOR_ZLA,
} from '../domain/columns';
import ExamRepository from '../repository/ExamRepository';
import { Exam, User } from '../../../types';
import SearchContext from '../../../components/search/SearchContext';
import Page from '../../../components/Page';
import DataTable, { Filter, ItemAction } from '../../../components/DataTable';
import ExamDelegatesDialog from '../components/ExamDelegatesDialog';
import DataTableRowLink from '../../../components/DataTableRowLink';
import DataTableRowAction from '../../../components/DataTableRowAction';
import ExamInfoPopover from '../components/ExamInfoPopover';
import SearchInput from '../../../components/search/SearchInput';
import { EXAM_BASE_ROUTE, EXAM_CREATE_ROUTE } from '../index';
import { canCreate, canUpdate } from '../hooks/useExamPermissions';

const FutureExamOverview = () => {
  const history = useHistory();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const repository = new ExamRepository(false);

  const [examId, setExamId] = useState<string>('');
  const [examDelegatesDialogOpen, setExamDelegatesDialogOpen] =
    useState<boolean>(false);

  const openExamDelegatesDialog = (examId: string) => {
    setExamId(examId);
    setExamDelegatesDialogOpen(true);
  };

  const closeExamDelegatesDialog = () => {
    // TODO: refresh table to show the updated delegates
    setExamDelegatesDialogOpen(false);
  };

  const isAdmin = account.roles.includes('ROLE_ADMIN');

  let columns;

  if (isAdmin) {
    columns = [
      COLUMN_EXAM_NUMBER,
      COLUMN_EXAM_DATE,
      COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
      COLUMN_EXAM_DIPLOMAS,
      COLUMN_EXAM_REGION,
      COLUMN_EXAM_DELEGATE,
    ];
  } else {
    columns = [
      COLUMN_EXAM_NUMBER,
      COLUMN_EXAM_DATE,
      COLUMN_EXAM_LOCATION,
      COLUMN_EXAM_DIPLOMAS,
      COLUMN_EXAM_DELEGATE_FOR_ZLA,
    ];
  }

  const examCreateAction = (
    <Button
      icon={['fal', 'plus']}
      to={EXAM_CREATE_ROUTE}
      label="Examen aanmelden"
    />
  );

  const filters: Filter[] = [];

  if (isAdmin) {
    filters.push({
      field: 'invitationStatus',
      name: 'Uitnodigingsstatus',
      type: 'checkbox',
      options: [
        { value: 'noneInvitedOrConfirmed', label: 'Geen' },
        { value: 'invited', label: 'Uitgenodigd' },
        { value: 'confirmed', label: 'Bevestigd' },
      ],
    });
    filters.push({
      field: 'particularities',
      name: 'Bijzonderheden',
      type: 'checkbox',
      options: [
        { value: 'menOnly', label: 'Alleen mannen' },
        { value: 'womenOnly', label: 'Alleen vrouwen' },
      ],
    });
  }

  const itemActions = (exam: Exam) => {
    const actions: ItemAction[] = [];

    actions.push({
      key: 'info',
      element: <ExamInfoPopover exam={exam} />,
    });

    if (canUpdate({ exam, account })) {
      actions.push({
        key: 'edit',
        element: (
          <DataTableRowLink
            title="Bewerken"
            icon="edit"
            to={`${EXAM_BASE_ROUTE}/${exam.id}/bewerken`}
          />
        ),
      });
    }

    if (isAdmin) {
      actions.push({
        key: 'assigningDelegates',
        element: (
          <DataTableRowAction
            title="Gedelegeerde toevoegen"
            icon="user-edit"
            onClick={() => openExamDelegatesDialog(exam.id)}
          />
        ),
      });
    }

    return (
      <Box>
        {actions.map((action) => (
          <React.Fragment key={action.key}>{action.element}</React.Fragment>
        ))}
      </Box>
    );
  };

  const title = (
    <>
      {totalCount !== null && `Aangemelde examens (${totalCount})`}
      {totalCount === null && 'Aangemelde examens'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      {canCreate({ account }) && <Box mr={2}>{examCreateAction}</Box>}
      <SearchInput id="future-exam-overview-query" persistQuery />
    </Box>
  );

  const styleRow = (exam: Exam) => (exam?.highPriority ? 'trHighlight' : 'tr');

  const onRowClick = (exam: Exam) => {
    history.push(`${EXAM_BASE_ROUTE}/${exam.id}`);
  };

  const deleteItemMessage = (exam: Exam) =>
    `Weet je zeker dat je examen ${exam.examNumber} wilt verwijderen?`;

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="future-exam-overview"
          repository={repository}
          columns={columns}
          filters={filters}
          persistFilters
          actions={itemActions}
          styleRow={isAdmin ? styleRow : undefined}
          onRowClick={onRowClick}
          resultCounterText={{ singular: 'examen', plural: 'examens' }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          deletable={account.roles.includes('ROLE_ADMIN')}
          deleteItemMessage={deleteItemMessage}
        />
      </Page>
      <ExamDelegatesDialog
        open={examDelegatesDialogOpen}
        examId={examId}
        onClose={closeExamDelegatesDialog}
      />
    </SearchContext.Provider>
  );
};

export default FutureExamOverview;
