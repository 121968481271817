import { AxiosResponse } from 'axios';

import { DiplomaType, DiplomaTypeDTO } from '../domain/types';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class DiplomaTypeRepository implements Repository<DiplomaType> {
  private readonly diplomaCategoryId: string;

  constructor(diplomaCategoryId: string) {
    this.diplomaCategoryId = diplomaCategoryId;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<DiplomaType>> {
    const params = parseCriteria(criteria, { page: page || 0 });

    return ApiClient.get(
      `/api/v1/diploma-categories/${this.diplomaCategoryId}/diploma-types`,
      params,
    );
  }

  get(id: string): Promise<AxiosResponse<DiplomaType>> {
    return ApiClient.get(`/api/v1/diploma-types/${id}`);
  }

  create(diplomaType: DiplomaTypeDTO): Promise<AxiosResponse<DiplomaType>> {
    return ApiClient.post('/api/v1/diploma-types', diplomaType);
  }

  update(diplomaType: DiplomaTypeDTO): Promise<AxiosResponse<DiplomaType>> {
    return ApiClient.put(
      `/api/v1/diploma-types/${diplomaType.id}`,
      diplomaType,
    );
  }

  delete(id: string) {
    return ApiClient.delete(`/api/v1/diploma-types/${id}`);
  }
}

export default DiplomaTypeRepository;
