import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { SnackbarProvider } from 'notistack';
import 'moment/locale/nl';

import './index.css';
import App from './App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';
import { loginUser, setAccount, setRoleView } from './actions';
import { getInitialRoleView } from './RoleViewManager';
import UserRepository from './modules/users/repository/UserRepository';

let root: Root;
const dom = document.getElementById('root');

if (dom !== null) root = createRoot(dom);
const store = createStore(rootReducer, applyMiddleware(thunk));

// Attempt to get account from API.
new UserRepository()
  .getAccount()
  .then((response) => {
    store.dispatch(loginUser());
    store.dispatch(setAccount(response.data));
    store.dispatch(setRoleView(getInitialRoleView(response.data.roles)));
  })
  .catch(() => {})
  .finally(() => {
    if (!root) return;
    // Render the DOM once login check is finished.
    root.render(
      <>
        <Provider store={store}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            maxSnack={3}
            autoHideDuration={3500}
          >
            <App />
          </SnackbarProvider>
        </Provider>
      </>,
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
