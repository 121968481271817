import React from 'react';

import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface ButtonRowProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

const ButtonRow = (props: ButtonRowProps) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <Box display="flex" className={classes.root}>
      {children}
    </Box>
  );
};

export default ButtonRow;
