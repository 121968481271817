import { ChangeEvent, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';
import { ExamAssignmentOfDelegate, ExamDiploma } from '../../../types';
import DelegateAssignmentRepository from '../repository/DelegateAssignmentRepository';
import ExamLineSelector from './ExamLineSelector';

interface InviteReplyDialogProps {
  invite: ExamAssignmentOfDelegate;
  open: boolean;
  onClose: () => void;
}

const InviteReplyDialog = (props: InviteReplyDialogProps) => {
  const { invite, open, onClose } = props;
  const notifications = useSnackbar();
  const [visit, setVisit] = useState<boolean | null>(null);
  const [step, setStep] = useState<1 | 2>(1);
  const [selectedExamLines, setSelectedExamLines] = useState<ExamDiploma[]>([]);
  const [errors, setErrors] = useState<
    ('select_option' | 'missing_examline')[]
  >([]);

  const onChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVisit(event.target.value === '1');
  };

  const handlePrevious = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleExamLinesChange = (examLines: ExamDiploma[]) => {
    setSelectedExamLines(examLines);
  };

  const validateStep = (step: number) => {
    let newErrors = [...errors];

    if (step === 1) {
      if (visit === null) {
        newErrors.push('select_option');
      } else {
        newErrors = newErrors.filter((e) => e !== 'select_option');
      }
    }

    if (step === 2) {
      if (selectedExamLines.length === 0) {
        newErrors.push('missing_examline');
      } else {
        newErrors = newErrors.filter((e) => e !== 'missing_examline');
      }
    }

    setErrors([...newErrors]);

    return newErrors.length === 0;
  };

  const handleSubmit = async () => {
    if (!validateStep(step)) {
      return;
    }

    if (step === 1 && visit) {
      setStep(2);

      return;
    }

    const repository = new DelegateAssignmentRepository(invite.exam.id);

    if (!visit) {
      repository
        .decline(invite.id)
        .then(() => {
          notifications.enqueueSnackbar(
            'De uitnodiging is succesvol afgewezen.',
            { variant: 'success' },
          );
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het afwijzen van de uitnodiging.',
            { variant: 'error' },
          );
        })
        .finally(onClose);

      return;
    }

    const promises = Promise.all([
      visit ? repository.confirm(invite.id) : repository.decline(invite.id),
      repository.updateExamLines(
        invite.id,
        selectedExamLines.map((el) => el.id),
      ),
    ]);

    promises
      .then(() => {
        notifications.enqueueSnackbar(
          'De uitnodiging is succesvol geaccepteerd.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het accepteren van de uitnodiging.',
          { variant: 'error' },
        );
      })
      .finally(onClose);
  };

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Reageer op uitnodiging</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display={step === 1 ? 'block' : 'none'}>
          {errors.includes('select_option') && (
            <Box mb={2}>
              <Alert severity="error">Selecteer een optie.</Alert>
            </Box>
          )}
          <FormControl>
            <FormLabel>Wil je het examen bezoeken?</FormLabel>
            <RadioGroup
              value={visit !== null ? (visit ? 1 : 0) : null}
              name="action"
              onChange={onChoiceChange}
            >
              <FormControlLabel value={1} control={<Radio />} label="Ja" />
              <FormControlLabel value={0} control={<Radio />} label="Nee" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box display={step === 2 ? 'block' : 'none'}>
          {errors.includes('missing_examline') && (
            <Box mb={2}>
              <Alert severity="error">Selecteer minimaal een onderdeel.</Alert>
            </Box>
          )}
          <ExamLineSelector
            examLines={invite.exam.examLines}
            onChange={handleExamLinesChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {step === 1 && (
          <Button variant="contained" onClick={onClose}>
            Annuleren
          </Button>
        )}
        {step === 2 && (
          <Button variant="contained" onClick={handlePrevious}>
            Vorige
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Ga verder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteReplyDialog;
