import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Popover, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}));

interface InfoPopoverProps {
  contents: React.ReactNode;
}

const InfoPopover = (props: InfoPopoverProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title="Informatie">
        <IconButton size="small" className={classes.icon} onClick={handleClick}>
          <FontAwesomeIcon icon={['fal', 'info-circle']} />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>{props.contents}</Box>
      </Popover>
    </>
  );
};

export default InfoPopover;
