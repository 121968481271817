import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { grey } from '@material-ui/core/colors';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';
import { ExamDiplomaDTO, ExamDTO } from '../repository/ExamRepository';
import { Examiner, ValidationViolation } from '../../../types';
import ExamDiplomaList from '../components/ExamDiplomaList';
import { hasViolationForField } from '../../../utils/common';
import Loader from '../../../components/Loader';
import SwimmingLessonProviderRepository from '../../swimming-lesson-provider/repository/SwimmingLessonProviderRepository';

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    maxWidth: 400,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  },
  placeholder: {
    color: grey[500],
  },
}));

interface ExamLinesFormProps {
  exam: ExamDTO;
  setExam: Dispatch<SetStateAction<ExamDTO>>;
  violations: ValidationViolation[];
}

const ExamLinesForm = (props: ExamLinesFormProps) => {
  const { exam, setExam, violations } = props;

  const classes = useStyles();
  const notifications = useSnackbar();

  const [examinerOptions, setExaminerOptions] = useState<Examiner[]>([]);
  const [oneExaminerOverall, setOneExaminerOverall] = useState<boolean>(
    exam.oneExaminerOverall,
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const swimmingLessonProviderRepository =
    new SwimmingLessonProviderRepository();

  // not the prettiest solution, but it does the trick
  // it checks for the property path 'examinerId' and paths like 'diploma[0][examinerId]'
  const hasExaminerViolation =
    violations.filter((violation) =>
      violation.propertyPath.includes('examinerId'),
    ).length >= 1;

  const handleOneExaminerOverallChange = () => {
    if (oneExaminerOverall) {
      // if the old value was 'one examinator overall', clear this examinator
      setExam((oldExam) => ({
        ...oldExam,
        oneExaminerOverall: false,
        examinerId: '',
      }));
    } else {
      // if the old value was 'one examinator per diploma line', then clear these examinators
      setExam((oldExam) => ({
        ...oldExam,
        oneExaminerOverall: true,
        diplomas: oldExam.diplomas.map((diplomaLine) => ({
          ...diplomaLine,
          examinerId: '',
        })),
      }));
    }

    setOneExaminerOverall((oldValue) => !oldValue);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setExam({ ...exam, [event.target.name as string]: event.target.value });
  };

  const handleExamDiplomaListChange = (diplomas: ExamDiplomaDTO[]) => {
    setExam({ ...exam, diplomas });
  };

  /**
   * Load all options for the form on load.
   */
  useEffect(() => {
    swimmingLessonProviderRepository
      .getExaminersForExam(
        exam.swimmingLessonProviderId,
        exam.lessonLocationIds,
      )
      .then((response) => {
        setExaminerOptions(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het ophalen van de examinatoren.',
          { variant: 'error' },
        );
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [exam.swimmingLessonProviderId, exam.lessonLocationIds]);

  useEffect(
    () => {
      if (!isLoaded) {
        return;
      }

      const examinerIds = examinerOptions.map((examiner) => examiner.id);

      // unset examiners that can no longer be selected, because their licenses are insufficient
      setExam((prev) => ({
        ...prev,
        examinerId: examinerIds.includes(prev.examinerId)
          ? prev.examinerId
          : '',
        diplomas: prev.diplomas.map((diploma) => ({
          ...diploma,
          examinerId: examinerIds.includes(diploma.examinerId)
            ? diploma.examinerId
            : '',
        })),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded, examinerOptions],
  );

  if (!examinerOptions) {
    return <Loader inline />;
  }

  return (
    <>
      <Box className={classes.formField} mt={3}>
        <FormControl fullWidth>
          <FormLabel>Examinatorselectie</FormLabel>
          <RadioGroup
            value={oneExaminerOverall}
            name="action"
            onChange={handleOneExaminerOverallChange}
          >
            <FormControlLabel
              value
              control={<Radio />}
              label="één examinator voor het hele examen"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="één examinator per diplomatype of tijdslot"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <>
        {oneExaminerOverall && (
          <Box className={classes.formField} mt={3}>
            <FormControl fullWidth>
              <FormLabel id="examiner-select-label">
                Kies een examinator
              </FormLabel>
              <Select
                labelId="examiner-select-label"
                id="examiner-select"
                value={exam.examinerId}
                name="examinerId"
                onChange={handleSelectChange}
                MenuProps={{ elevation: 1 }}
                displayEmpty
                error={hasViolationForField('examinerId', violations)}
              >
                <MenuItem value="">
                  <em className={classes.placeholder}>Maak een keuze</em>
                </MenuItem>
                {examinerOptions.map((examiner) => (
                  <MenuItem key={examiner.id} value={examiner.id}>
                    {examiner.extendedName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </>
      {hasExaminerViolation && (
        <Alert severity="error">
          {`Controleer of de gekozen ${
            oneExaminerOverall ? 'examinator' : 'examinatoren'
          } de juiste licentie ${oneExaminerOverall ? 'heeft' : 'hebben'}.`}
        </Alert>
      )}
      <Box mt={4}>
        <ExamDiplomaList
          exam={exam}
          oneExaminerOverall={oneExaminerOverall}
          examinerOptions={examinerOptions}
          onChange={handleExamDiplomaListChange}
          allViolations={violations}
        />
      </Box>
    </>
  );
};

export default ExamLinesForm;
