import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Breadcrumbs as BreadcrumbsUI,
  Link as LinkUI,
  Theme,
  Typography,
} from '@material-ui/core';

import { Router } from '../routing';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
}));

const Breadcrumbs = () => {
  const classes = useStyles();
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<any>(null);

  const createCrumbsFromNode = (node: any, result: any[] = []) => {
    result.push(node);
    const parent = node.getParent();
    if (parent) {
      createCrumbsFromNode(parent, result);
    }

    return result
      .map((node, i) => {
        if (i === 0) {
          return (
            <Typography key={node.getTitle()} color="textPrimary">
              {node.getTitle()}
            </Typography>
          );
        }

        return (
          <LinkUI key={node.getTitle()} component={Link} to={node.getPath()}>
            {node.getTitle()}
          </LinkUI>
        );
      })
      .reverse();
  };

  const currentNode = Router.getNodeByPath(location.pathname);

  useEffect(
    () => {
      if (!currentNode) {
        return;
      }

      setCrumbs(createCrumbsFromNode(currentNode));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentNode],
  );

  if (!crumbs || crumbs.length <= 1) {
    return <div />;
  }

  return (
    <BreadcrumbsUI className={classes.container} aria-label="breadcrumb">
      {crumbs}
    </BreadcrumbsUI>
  );
};

export default Breadcrumbs;
