import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Page';
import OperatorRepository from '../repository/OperatorRepository';
import { OperatorDTO } from '../../../types';
import { OPERATOR_BASE_ROUTE } from '../index';
import OperatorForm from '../forms/OperatorForm';

const CreateOperator = () => {
  const repository = new OperatorRepository();
  const notifications = useSnackbar();
  const history = useHistory();

  const handleSubmit = (operator: OperatorDTO) => {
    repository
      .create(operator)
      .then(() => {
        history.push(OPERATOR_BASE_ROUTE);
        notifications.enqueueSnackbar('Exploitant is succesvol aangemaakt!', {
          variant: 'success',
        });
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het aanmaken van de exploitant.',
          { variant: 'error' },
        ),
      );
  };

  return (
    <Page title="Exploitant aanmaken" breadcrumbs>
      <OperatorForm onSubmit={handleSubmit} />
    </Page>
  );
};

export default CreateOperator;
