import { useState } from 'react';

import { useSnackbar } from 'notistack';

import InlineButton from '../../../components/InlineButton';
import { SwimmingLessonProvider } from '../../../types';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';
import PlaceUnderSLPDialog from './PlaceUnderSLPDialog';

interface PlaceUnderSLPButtonProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  onSuccess: () => void;
}

const PlaceUnderSLPButton = (props: PlaceUnderSLPButtonProps) => {
  const notifications = useSnackbar();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const placeUnderSwimmingLessonProvider = (
    id: string,
    targetProviderId: string,
  ) => {
    new SwimmingLessonProviderRepository()
      .placeUnderSwimmingLessonProvider(id, targetProviderId)
      .then(() => {
        notifications.enqueueSnackbar(
          'De zelfstandige leslocatie is succesvol onder een hoofdlocatie geplaatst',
          { variant: 'success' },
        );
        props.onSuccess();
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het plaatsen van de zelfstandige leslocatie onder een hoofdlocatie.',
          { variant: 'error' },
        );
      })
      .finally(() => closeDialog());
  };

  return (
    <>
      <InlineButton type="dangerous" onClick={openDialog}>
        Plaatsen onder hoofdlocatie
      </InlineButton>
      <PlaceUnderSLPDialog
        isOpen={dialogOpen}
        onClose={closeDialog}
        onConfirm={(targetProviderId: string) =>
          placeUnderSwimmingLessonProvider(
            props.swimmingLessonProvider.id,
            targetProviderId,
          )
        }
      />
    </>
  );
};

export default PlaceUnderSLPButton;
