import moment from 'moment';
import { DelegateAssignmentOfExam, Exam, ExamDiploma } from '../../types';
import { formatTimestamp } from '../../utils/common';
import { ExamDiplomaDTO, ExamDTO } from './repository/ExamRepository';

export const examLineToExamLineDTO = (
  examLine: ExamDiploma,
): ExamDiplomaDTO => ({
  id: examLine.id,
  diplomaTypeId: examLine.diplomaType.id,
  examinerId: examLine?.examiner?.id,
  beginTime: new Date(examLine.beginTime),
  endTime: new Date(examLine.endTime),
  amount: examLine.numberParticipants,
  amountPassed: examLine.numberPassed,
});

export const examToExamDTO = (exam: Exam): ExamDTO => ({
  swimmingLessonProviderId: exam.swimmingLessonProvider.id,
  examNumber: exam.examNumber,
  date: new Date(exam.date),
  examLocationId: exam.examLocation.id,
  lessonLocationIds: exam.lessonLocations.map((l) => l.id),
  oneExaminerOverall: exam?.examiner !== undefined,
  examinerId: exam?.examiner?.id || '',
  diplomas: exam.examLines.map((examLine) => examLineToExamLineDTO(examLine)),
  menOnly: exam.menOnly,
  womenOnly: exam.womenOnly,
});

export const moreThanOneDayBeforeExam = (exam: Exam) => {
  const tomorrow = new Date().setDate(new Date().getDate() + 1);

  return (
    moment(tomorrow).format('YYYYMMDD') < moment(exam.date).format('YYYYMMDD')
  );
};

export const examHasStarted = (exam: Exam) => {
  return (
    moment(exam.date).format('YYYYMMDD') <=
    moment(new Date()).format('YYYYMMDD')
  );
};

export const delegateAssignmentToText = (
  delegateAssignment: DelegateAssignmentOfExam,
) => {
  let status;
  let timestamp;
  switch (delegateAssignment.status) {
    case 'cancelled': {
      status = 'Geannuleerd';
      timestamp = delegateAssignment?.cancelledAt
        ? formatTimestamp(new Date(delegateAssignment.cancelledAt))
        : 'N.B.';
      break;
    }
    case 'confirmed': {
      status = 'Bevestigd';
      timestamp = delegateAssignment?.confirmedAt
        ? formatTimestamp(new Date(delegateAssignment.confirmedAt))
        : 'N.B.';
      break;
    }
    case 'declined': {
      status = 'Geweigerd';
      timestamp = delegateAssignment?.declinedAt
        ? formatTimestamp(new Date(delegateAssignment.declinedAt))
        : 'N.B.';
      break;
    }
    case 'invited': {
      status = 'Uitgenodigd';
      timestamp = delegateAssignment?.invitedAt
        ? formatTimestamp(new Date(delegateAssignment.invitedAt))
        : 'N.B.';
      break;
    }
    default: {
      return 'N.B.';
    }
  }

  return `${status} op ${timestamp}`;
};

export const allowedDelegateAssignmentActions = (
  delegateAssignment: DelegateAssignmentOfExam,
) => {
  let canCancel = false;
  let canConfirm = false;
  let canDecline = false;
  let canDelete = true;
  switch (delegateAssignment.status) {
    case 'confirmed': {
      canCancel = true;
      break;
    }
    case 'invited': {
      canConfirm = true;
      canDecline = true;
      break;
    }
    default: {
      break;
    }
  }

  return {
    canCancel,
    canConfirm,
    canDecline,
    canDelete,
  };
};
