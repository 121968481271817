import { createTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';
import { grey } from '@material-ui/core/colors';
import { nlNL } from '@material-ui/core/locale';

export const colors = {
  textPrimary: '#343434',
  primary: '#009DDF',
  primaryLight: '#ADDEF1',
  primaryDark: '#10458C',
  whiteLilac: '#F7F9FC',
  whiteLilacShade: '#EDF1F8',
  whiteIce: '#E6F5FB',
  blackSqueeze: '#F7FAFC',
  linkWater: '#DAEEF7',
  gallery: '#EBEBEB',
  alto: '#D6D6D6',
  red: '#DF0A20',
  softPeach: '#F5EDEF',
  softPeachDark: '#F0E4E7',
  green: '#6CAC30',
};

export const gradients = {
  primaryLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,241,245,1) 100%)',
  primaryUltraLight:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(245,248,250,1) 100%)',
};

const theme = createTheme(
  {
    palette: {
      primary: {
        dark: colors.primaryDark,
        // light: will be calculated from palette.primary.main,
        main: colors.primary,
        light: colors.primaryLight,
        // dark: will be calculated from palette.primary.main,
        contrastText: '#fff',
      },
      secondary: {
        main: '#E42828',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#fff',
      },
      text: {
        primary: colors.textPrimary,
      },
    },

    shape: {
      borderRadius: 8,
    },

    shadows: [
      'none',
      '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
      '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
      '1px 3px 9px -1px rgba(0,0,0,0.07), 0px 1px 1px 0px rgba(0,0,0,0.10), 0px 2px 4px 0px rgba(0,0,0,0.11)',
      ...Array(22).fill('none'),
    ] as Shadows,

    typography: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontWeightBold: 600,
      fontWeightMedium: 400,
      fontWeightLight: 300,
      h1: {
        fontFamily: '"Baloo Bhaina 2", sans-serif',
        fontSize: 35,
        fontWeight: 600,
        color: '#10458C',
      },
      h2: {
        fontFamily: '"Baloo Bhaina 2", sans-serif',
        fontSize: 26,
        fontWeight: 600,
        color: '#10458C',
      },
      h3: {
        fontFamily: '"Baloo Bhaina 2", sans-serif',
        fontSize: 23,
        fontWeight: 600,
        color: '#10458C',
      },
      h4: {
        fontFamily: '"Baloo Bhaina 2", sans-serif',
        fontSize: 20,
        fontWeight: 600,
        color: '#10458C',
      },
      h5: {
        fontFamily: '"Baloo Bhaina 2", sans-serif',
        fontSize: 18,
        fontWeight: 600,
        color: '#10458C',
      },
      h6: {
        fontFamily: '"Baloo Bhaina 2", sans-serif',
        color: '#10458C',
        fontWeight: 600,
        fontSize: 16,
      },
    },

    overrides: {
      MuiButton: {
        root: {
          borderRadius: 50,
          textTransform: 'none',
        },
        containedSecondary: {
          color: colors.primaryDark,
          backgroundColor: colors.whiteLilac,
          '&:hover': {
            color: '#FFF',
            backgroundColor: colors.primaryDark,
          },
        },
        contained: {
          backgroundColor: '#D2E7EB',
          '&:hover': {
            backgroundColor: '#D2E7EB',
          },
        },
      },
      MuiInput: {
        root: {
          padding: '6px 14px',
          borderRadius: 8,
          boxShadow:
            '1px 2px 7px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.09), 0px 2px 4px 0px rgba(0,0,0,0.10)',
          transition: 'box-shadow 0.3s ease',
          '&.Mui-focused, &:hover': {
            boxShadow:
              '1px 3px 9px -1px rgba(0,0,0,0.09), 0px 1px 1px 0px rgba(0,0,0,0.11), 0px 2px 4px 0px rgba(0,0,0,0.12)',
          },
          '&.Mui-error': {
            border: `1px solid ${colors.red}`,
          },
        },
        underline: {
          '&::before': {
            display: 'none',
          },
          '&::after': {
            display: 'none',
          },
        },
      },
      MuiTabs: {
        indicator: {
          display: 'none',
        },
      },
      MuiTab: {
        root: {
          marginRight: 8,
          background: grey[100],
          transition: 'all 0.3s ease',
          '&[aria-selected="true"]': {
            background: '#FFF',
            boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.09)',
          },
          textTransform: 'none',
          fontWeight: 600,
        },
      },
      MuiFormLabel: {
        root: {
          color: colors.primaryDark,
          fontWeight: 600,
          fontSize: 18,
          fontFamily: '"Baloo Bhaina 2", sans-serif',
        },
      },
    },
  },
  nlNL,
);

export default theme;
