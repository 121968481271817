import { Operator, User } from '../../../types';

export function canCreate(subject: { account: User }): boolean {
  return subject.account.roles.includes('ROLE_ADMIN');
}

export function canRead(subject: { operator?: Operator; account: User }) {
  return (
    subject.account.roles.includes('ROLE_ADMIN') ||
    subject.account.operator?.id === subject.operator?.id
  );
}

export function canUpdate(subject: { operator?: Operator; account: User }) {
  return subject.account.roles.includes('ROLE_ADMIN');
}

export function canDelete(subject: { operator?: Operator; account: User }) {
  return subject.account.roles.includes('ROLE_ADMIN');
}
