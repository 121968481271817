import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from '../../config/theme';

interface DashboardSectionProps {
  title: string | React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& + $root': {
      marginTop: theme.spacing(1),
    },
  },
  header: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    color: colors.primaryDark,
    background: colors.whiteLilacShade,
    '& h2': {
      fontSize: 18,
      height: 18,
      margin: 0,
    },
  },
}));

const DashboardSection = (props: DashboardSectionProps) => {
  const { title, children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Box pt={2} pb={2}>
        {children}
      </Box>
    </Box>
  );
};

export default DashboardSection;
