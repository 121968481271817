import { ChangeEvent, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import {
  METHOD_ASSIGN_DIRECTLY,
  METHOD_INVITE_MANUALLY,
} from './ExamDelegatesForm';

type Props = {
  onSelectMethod: (method: string) => void;
};

const ExamDelegatesMethod = (props: Props) => {
  const { onSelectMethod } = props;

  const [action, setAction] = useState<string>(METHOD_ASSIGN_DIRECTLY);

  const onActionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value);
  };

  return (
    <>
      <DialogTitle>Voeg een gedelegeerde toe</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>Maak een keuze</FormLabel>
          <RadioGroup value={action} name="action" onChange={onActionChange}>
            <FormControlLabel
              value={METHOD_ASSIGN_DIRECTLY}
              control={<Radio />}
              label="Direct toewijzen"
            />
            <FormControlLabel
              value={METHOD_INVITE_MANUALLY}
              control={<Radio />}
              label="Handmatig uitnodigen"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSelectMethod(action)}>Verder</Button>
      </DialogActions>
    </>
  );
};

export default ExamDelegatesMethod;
