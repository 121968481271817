import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';
import { colors } from '../config/theme';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    backgroundColor: `${grey[300]}`,
    fontWeight: 600,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    marginRight: theme.spacing(1),
  },
  primary: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: colors.whiteIce,
    },
  },
  dangerous: {
    backgroundColor: colors.softPeachDark,
    '&:hover': {
      backgroundColor: colors.softPeach,
    },
  },
}));

interface InlineLinkProps {
  children: React.ReactNode;
  type?: 'primary' | 'dangerous';
  to: string;
}

const InlineLink = (props: InlineLinkProps) => {
  const { children, type, to } = props;

  const classes = useStyles();

  let additionalClassName = '';
  if (type !== undefined) {
    additionalClassName = classes[type];
  }

  return (
    <Button
      component={Link}
      className={`${classes.link} ${additionalClassName}`}
      to={to}
    >
      {children}
    </Button>
  );
};

export default InlineLink;
