import { useState } from 'react';
import ExaminerExamRepository from '../repository/ExaminerExamRepository';

import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_REPORT_EXAMINER,
  COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
} from '../../exams/domain/columns';
import Page from '../../../components/Page';
import { ExamColumn } from '../../exams/domain/types';
import ExamDashboardSection from '../../exams/components/ExamDashboardSection';
import { ApiFilterCriteria } from '../../../types';
import {
  EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE,
  EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE,
} from '../index';

const Dashboard = () => {
  const [plannedExamsCount, setPlannedExamsCount] = useState<number | null>(
    null,
  );
  const [visitedExamsCount, setVisitedExamsCount] = useState<number | null>(
    null,
  );

  const examRepository = new ExaminerExamRepository();

  const plannedExamsColumns: ExamColumn[] = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
  ];

  const visitedExamsColumns: ExamColumn[] = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
    COLUMN_EXAM_REPORT_EXAMINER,
  ];

  const plannedExamsFilters = {
    filters: {
      past: false,
    },
  } as ApiFilterCriteria;

  const visitedExamsFilters = {
    filters: {
      past: true,
      needsExaminerReport: true,
    },
  } as ApiFilterCriteria;

  return (
    <Page title="Dashboard">
      <ExamDashboardSection
        id="planned-exams"
        title="Op de planning"
        repository={examRepository}
        defaultFilters={plannedExamsFilters}
        itemCount={plannedExamsCount}
        columns={plannedExamsColumns}
        onLoad={(_items, totalCount) => setPlannedExamsCount(totalCount)}
        allExamsUrl={EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE}
        allExamsText="Zie alle geplande examens"
        canView
        canEdit={false}
        canViewInfo={false}
      />
      <ExamDashboardSection
        id="visited-exams"
        title="Nog in te vullen verslagen"
        repository={examRepository}
        defaultFilters={visitedExamsFilters}
        itemCount={visitedExamsCount}
        columns={visitedExamsColumns}
        onLoad={(_items, totalCount) => setVisitedExamsCount(totalCount)}
        allExamsUrl={EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE}
        allExamsText="Zie alle bijgewoonde examens"
        canView
        canEdit={false}
        canViewInfo={false}
      />
    </Page>
  );
};

export default Dashboard;
