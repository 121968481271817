import React from 'react';

import { IconButton, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}));

interface DataTableLinkActionProps {
  title: string;
  icon: IconName;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const DataTableRowAction = (props: DataTableLinkActionProps) => {
  const { title, icon, onClick } = props;

  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <IconButton size="small" className={classes.icon} onClick={onClick}>
        <FontAwesomeIcon icon={['fal', icon]} />
      </IconButton>
    </Tooltip>
  );
};

export default DataTableRowAction;
