import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Declaration,
  DeclarationDto,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class DeclarationsRepository implements Repository<Declaration> {
  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/v1/declarations/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<Declaration>> {
    const params = parseCriteria(criteria, {
      page: page || 1,
      perPage: perPage || 10,
    });

    return ApiClient.get('/api/v1/declarations', params);
  }

  find(id: string): Promise<AxiosResponse<Declaration>> {
    return ApiClient.get(`/api/v1/declarations/${id}`);
  }

  approve(id: string): Promise<AxiosResponse> {
    return ApiClient.post(`/api/v1/declarations/${id}/approve`);
  }

  create(declaration: DeclarationDto): Promise<AxiosResponse<Declaration>> {
    return ApiClient.post(`/api/v1/declarations`, declaration);
  }

  update(declaration: DeclarationDto): Promise<AxiosResponse<Declaration>> {
    return ApiClient.put(`/api/v1/declarations/${declaration.id}`, declaration);
  }
}

export default DeclarationsRepository;
