import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import UserRepository from '../repository/UserRepository';
import { UserDTO } from '../../../types';
import Page from '../../../components/Page';
import UserForm from '../forms/UserForm';
import { ACCOUNT_BASE_ROUTE, USER_BASE_ROUTE } from '../index';
import { userToUserDTO } from '../utils';

interface EditUserProps {
  id?: string;
  isProfile: boolean;
}

const EditUser = (props: EditUserProps) => {
  const { id: passedId, isProfile } = props;
  const { id: paramId } = useParams<{ id?: string }>();
  const id = passedId || paramId || '';

  const repository = new UserRepository();
  const notifications = useSnackbar();
  const history = useHistory();
  const [user, setUser] = useState<UserDTO | null>(null);

  const baseUrl = isProfile ? ACCOUNT_BASE_ROUTE : `${USER_BASE_ROUTE}/${id}`;
  const pageTitle = isProfile ? 'Profiel bewerken' : 'Gebruiker bewerken';
  const showBreadcrumbs = !isProfile;

  const handleSubmit = (user: UserDTO) => {
    repository
      .update(id, user)
      .then(() => {
        history.push(baseUrl);
        notifications.enqueueSnackbar('Gebruiker is succesvol opgeslagen!', {
          variant: 'success',
        });
      })
      .catch(() =>
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het opslaan van de gebruiker.',
          { variant: 'error' },
        ),
      );
  };

  useEffect(
    () => {
      repository
        .find(id)
        .then((res) => setUser(userToUserDTO(res.data)))
        .catch(() => history.push(baseUrl));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Page title={pageTitle} breadcrumbs={showBreadcrumbs}>
      {user && <UserForm user={user} onSubmit={handleSubmit} />}
    </Page>
  );
};

export default EditUser;
