import { Declaration, DeclarationDto } from '../../types';

export const declarationToDto = (declaration: Declaration): DeclarationDto => ({
  id: declaration.id,
  delegateId: declaration.delegate.id,
  examId: declaration.exam?.id || '',
  baseCompensation: declaration.baseCompensation,
  travelMethod: declaration.travelMethod,
  travelCompensation: declaration.travelCompensation,
  additionalCosts: declaration.additionalCosts,
  kilometers: declaration.kilometers,
  kilometerCompensation: declaration.kilometerCompensation,
  explanation: declaration.explanation,
  creditDescription: declaration.creditDescription,
  attachments: declaration.attachments.map((a) => a.id),
});
