import { createContext, Dispatch, SetStateAction } from 'react';
import localforage from 'localforage';

import { AppState } from './App';
import RoleViewManager from './RoleViewManager';
import NullUser from './modules/users/domain/NullUser';

const AppContext = createContext<{
  appState: AppState | null;
  setAppState: Dispatch<SetStateAction<AppState>> | null;
  roleViewManager: RoleViewManager;
  localStore: LocalForage;
}>({
  appState: null,
  setAppState: null,
  roleViewManager: new RoleViewManager(NullUser, null),
  localStore: localforage.createInstance({ name: 'nrz-portal' }),
});

export default AppContext;
