import { useSelector } from 'react-redux';

import Route from '../../../routing/Route';
import Router from '../../../routing/Router';
import { User } from '../../../types';
import { UserState } from '../../../reducers/user';
import BlockedSLPList from '../components/BlockedSLPList';
import ExaminerLessonLocationList from '../components/ExaminerLessonLocationList';
import DelegateTimeSlotList from '../components/DelegateTimeSlotList';
import DelegateVacation from '../components/DelegateVacation';
import UserDeclarationList from '../components/UserDeclarationList';

const useUserTabs = (user: User | null, baseRoute: string) => {
  const { roleView } = useSelector((selector: { user: UserState }) => ({
    roleView: selector.user.roleView,
  }));

  if (!user) {
    return null;
  }

  const router = new Router();

  if (user.roles.includes('ROLE_EXAMINER')) {
    router.addRoute(
      new Route(
        'Leslocaties',
        `${baseRoute}/leslocaties`,
        <ExaminerLessonLocationList user={user} />,
      ).role(['ROLE_ADMIN', 'ROLE_EXAMINER']),
    );
  }

  if (user.roles.includes('ROLE_DELEGATE')) {
    router.addRoute(
      new Route(
        'Tijdslots',
        `${baseRoute}/tijdslots`,
        <DelegateTimeSlotList user={user} />,
      ).role(['ROLE_ADMIN', 'ROLE_DELEGATE']),
    );
    router.addRoute(
      new Route(
        'Vakantie',
        `${baseRoute}/vakantie`,
        <DelegateVacation user={user} />,
      ).role(['ROLE_ADMIN', 'ROLE_DELEGATE']),
    );
    router.addRoute(
      new Route(
        'Declaraties',
        `${baseRoute}/declaraties`,
        <UserDeclarationList user={user} />,
      ).role(['ROLE_ADMIN', 'ROLE_DELEGATE']),
    );
    router.addRoute(
      new Route(
        "Geblokkeerde ZLA's",
        `${baseRoute}/geblokkeerde-zwemlesaanbieders`,
        <BlockedSLPList user={user} />,
      ).role(['ROLE_ADMIN']),
    );
  }

  return router.processTabs(roleView);
};

export default useUserTabs;
