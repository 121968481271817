import { useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Box, FormLabel } from '@material-ui/core';
import AppContext from '../../../../AppContext';
import { Exam, MinimalUser } from '../../../../types';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import FormSection from '../../../../components/form/FormSection';
import TextOutput from '../../../../components/form/TextOutput';
import DateOutput from '../../../../components/form/DateOutput';
import ButtonRow from '../../../../components/ButtonRow';
import InlineButton from '../../../../components/InlineButton';
import InlineLink from '../../../../components/InlineLink';
import ExamRepository from '../../repository/ExamRepository';
import { EXAM_BASE_ROUTE, FUTURE_EXAM_OVERVIEW_ROUTE } from '../../index';
import { examHasStarted, moreThanOneDayBeforeExam } from '../../utils';
import ReadOnlyExamDiplomaList from '../../components/ReadOnlyExamDiplomaList';
import ExamLocationInfo from '../../components/ExamLocationInfo';
import FileChips from '../../../../components/file/FileChips';
import ExamSwimmingLessonProviderInfo from '../../components/ExamSwimmingLessonProviderInfo';

interface ExamFormSectionProps {
  exam: Exam;
  withActions: boolean;
}

const ExamSection = (props: ExamFormSectionProps) => {
  const { exam, withActions } = props;

  const { roleViewManager } = useContext(AppContext);

  const history = useHistory();
  const notifications = useSnackbar();

  const isAdmin = roleViewManager.isAdminView();
  const isSwimmingLessonProvider =
    roleViewManager.isSwimmingLessonProviderView();
  const isDelegate = roleViewManager.isDelegateView();

  const started = examHasStarted(exam);

  const canEdit = isAdmin || (isSwimmingLessonProvider && !started);
  const canDelete = isAdmin || (isSwimmingLessonProvider && !started);
  const canViewDelegate =
    isAdmin || isDelegate || !moreThanOneDayBeforeExam(exam);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = (exam: Exam) => {
    new ExamRepository()
      .delete(exam.id)
      .then(() => {
        notifications.enqueueSnackbar('Het examen is verwijderd.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het verwijderen van het examen.',
          {
            variant: 'error',
          },
        );
      })
      .finally(() => {
        history.push(FUTURE_EXAM_OVERVIEW_ROUTE);
      });
  };

  const examToLessonLocations = (exam: Exam) =>
    exam.lessonLocations
      .map((lessonLocation) => lessonLocation.name)
      .join(', ') || '';

  const formatDelegates = (delegates: MinimalUser[]) => {
    return delegates.map((user) => user.fullName).join(', ') || '';
  };

  return (
    <>
      <FormSection title="Over het examen">
        <TextOutput label="Nummer" value={exam.examNumber} />
        <DateOutput label="Datum" value={exam.date} />
        <Box maxWidth={500}>
          <FormLabel>Zwemlesaanbieder</FormLabel>
          <ExamSwimmingLessonProviderInfo
            examSwimmingLessonProvider={exam.swimmingLessonProvider}
          />
        </Box>
        <Box maxWidth={500}>
          <FormLabel>Afzwembad</FormLabel>
          <ExamLocationInfo examLocation={exam.examLocation} />
        </Box>
        {!exam.swimmingLessonProvider.centralExams && (
          <TextOutput label="Leslocaties" value={examToLessonLocations(exam)} />
        )}
        {exam.examiner && (
          <TextOutput label="Examinator" value={exam.examiner.fullName} />
        )}
        <ReadOnlyExamDiplomaList
          oneExaminerOverall={exam.examiner !== undefined}
          examLines={exam.examLines}
        />
        {exam.customizedDiplomaTypePdfs &&
          exam.customizedDiplomaTypePdfs.length >= 1 && (
            <FileChips files={exam.customizedDiplomaTypePdfs} />
          )}
        {canViewDelegate &&
          exam.confirmedDelegates &&
          exam.confirmedDelegates.length >= 1 && (
            <TextOutput
              label="Gedelegeerde"
              value={formatDelegates(exam.confirmedDelegates)}
            />
          )}
      </FormSection>
      {withActions && (canEdit || canDelete) && (
        <>
          <ButtonRow>
            {canEdit && (
              <InlineLink
                type="primary"
                to={`${EXAM_BASE_ROUTE}/${exam.id}/bewerken`}
              >
                Bewerken
              </InlineLink>
            )}
            {canDelete && (
              <InlineButton type="dangerous" onClick={() => openDeleteDialog()}>
                Verwijderen
              </InlineButton>
            )}
          </ButtonRow>
          {canDelete && (
            <ConfirmationDialog
              title="Examen verwijderen"
              query="Weet je zeker dat je dit examen wilt verwijderen?"
              buttonText="Verwijderen"
              isOpen={deleteDialogOpen}
              onClose={closeDeleteDialog}
              onConfirm={() => handleDelete(exam)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ExamSection;
