import React from 'react';

import { Box } from '@material-ui/core';
import DataTable, { Column, ItemAction } from '../../../components/DataTable';
import DataTableRowAction from '../../../components/DataTableRowAction';
import Page from '../../../components/Page';
import { ManagementQuery } from '../../../types';
import ManagementQueryRepository from '../repository/ManagementQueryRepository';

const ManagementQueryOverview = () => {
  const repository = new ManagementQueryRepository();

  const columns: Column[] = [
    {
      name: 'Titel',
      field: 'title',
      render: (item: ManagementQuery) => item.title,
    },
  ];

  const itemActions = (managementQuery: ManagementQuery) => {
    const actions: ItemAction[] = [];

    actions.push({
      key: 'export',
      element: (
        <DataTableRowAction
          title="Exporteren"
          icon="file-export"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/management-queries/${managementQuery.id}/export`;
          }}
        />
      ),
    });

    return (
      <Box>
        {actions.map((action) => (
          <React.Fragment key={action.key}>{action.element}</React.Fragment>
        ))}
      </Box>
    );
  };

  return (
    <Page title="Management queries">
      <DataTable
        id="management-query-table"
        repository={repository}
        columns={columns}
        actions={itemActions}
      />
    </Page>
  );
};

export default ManagementQueryOverview;
