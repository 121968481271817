import { useContext } from 'react';
import { useSelector } from 'react-redux';

import AppContext from '../../../AppContext';
import Route from '../../../routing/Route';
import Router from '../../../routing/Router';
import { SwimmingLessonProvider } from '../../../types';
import {
  SWIMMING_LESSON_PROVIDER_BASE_ROUTE,
  ORGANIZATION_BASE_ROUTE,
} from '../index';
import { UserState } from '../../../reducers/user';
import SLPBlockedDelegateList from '../components/SLPBlockedDelegateList';
import SLPLessonLocationList from '../components/SLPLessonLocationList';
import SLPExamLocationList from '../components/SLPExamLocationList';
import SLPExaminerList from '../components/SLPExaminerList';
import SLPUserList from '../components/SLPUserList';
import { RoleInterface } from '../../users/domain/Roles';

const useSwimmingLessonProviderTabs = (
  swimmingLessonProvider: SwimmingLessonProvider | null,
  isOrganization: boolean,
) => {
  const { roleViewManager } = useContext(AppContext);

  let baseRoute: string;
  let role: keyof RoleInterface;

  const { roleView } = useSelector((selector: { user: UserState }) => ({
    roleView: selector.user.roleView,
  }));

  if (!swimmingLessonProvider) {
    return null;
  }

  const isAtLeastManager =
    roleViewManager.isAdminView() ||
    roleViewManager.isSwimmingLessonProviderManagerView();

  if (isOrganization) {
    baseRoute = ORGANIZATION_BASE_ROUTE;
    role = 'ROLE_SWIMMING_LESSON_PROVIDER_USER';
  } else {
    baseRoute = `${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}/${swimmingLessonProvider.id}`;
    role = 'ROLE_ADMIN';
  }

  const router = new Router();

  router.addRoute(
    new Route(
      'Leslocaties',
      `${baseRoute}/leslocaties`,
      (
        <SLPLessonLocationList
          swimmingLessonProvider={swimmingLessonProvider}
          isAllowedToEdit={Boolean(isAtLeastManager)}
        />
      ),
    ).role([role]),
  );

  router.addRoute(
    new Route(
      'Afzwemlocaties',
      `${baseRoute}/afzwemlocaties`,
      (
        <SLPExamLocationList
          swimmingLessonProvider={swimmingLessonProvider}
          isAllowedToEdit={Boolean(isAtLeastManager)}
        />
      ),
    ).role([role]),
  );

  if (isAtLeastManager) {
    router.addRoute(
      new Route(
        'Gebruikers',
        `${baseRoute}/gebruikers`,
        <SLPUserList swimmingLessonProvider={swimmingLessonProvider} />,
      ).role([role]),
    );
  }

  router.addRoute(
    new Route(
      'Examinatoren',
      `${baseRoute}/examinatoren`,
      (
        <SLPExaminerList
          swimmingLessonProvider={swimmingLessonProvider}
          isAllowedToEdit={Boolean(isAtLeastManager)}
        />
      ),
    ).role([role]),
  );

  router.addRoute(
    new Route(
      'Geblokkeerde gedelegeerden',
      `${baseRoute}/geblokkeerde-gedelegeerden`,
      (
        <SLPBlockedDelegateList
          swimmingLessonProvider={swimmingLessonProvider}
        />
      ),
    ).role(['ROLE_ADMIN']),
  );

  return router.processTabs(roleView);
};

export default useSwimmingLessonProviderTabs;
