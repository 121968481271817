import { Route, Router } from '../../routing';
import Dashboard from './views/Dashboard';
import SwimmingLessonProviderOverview from './views/SwimmingLessonProviderOverview';
import SwimmingLessonProviderDetail from './views/SwimmingLessonProviderDetail';
import ViewSwimmingLessonProvider from './views/ViewSwimmingLessonProvider';
import MyOrganization from './MyOrganization';

export const SWIMMING_LESSON_PROVIDER_BASE_ROUTE = '/zwemlesaanbieders';
export const ORGANIZATION_BASE_ROUTE = '/mijn-organisatie';

const dashboard = new Route('Dashboard', '/', <Dashboard />, true).role([
  'ROLE_SWIMMING_LESSON_PROVIDER_USER',
]);
Router.addRoute(dashboard);

const overview = new Route(
  'Zwemlesaanbieders',
  SWIMMING_LESSON_PROVIDER_BASE_ROUTE,
  <SwimmingLessonProviderOverview />,
  true,
).role(['ROLE_ADMIN']);
Router.addRoute(overview);

const view = new Route(
  'Zwemlesaanbieder bekijken',
  '/:id',
  <ViewSwimmingLessonProvider isOrganization={false} />,
).role(['ROLE_ADMIN', 'ROLE_SWIMMING_LESSON_PROVIDER_USER']);
overview.addChild(view);

const update = new Route(
  'Zwemlesaanbieder bewerken',
  '/:id/bewerken',
  <SwimmingLessonProviderDetail />,
).role(['ROLE_ADMIN', 'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER']);
overview.addChild(update);

const slpOrganization = new Route(
  'Mijn organisatie',
  ORGANIZATION_BASE_ROUTE,
  <MyOrganization />,
).role(['ROLE_SWIMMING_LESSON_PROVIDER_USER']);
Router.addRoute(slpOrganization);
