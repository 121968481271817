import { useCallback, useEffect, useState } from 'react';

import { Column } from '../../../components/DataTable';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { SwimmingLessonProvider, User } from '../../../types';
import SwimmingExamProviderRepository from '../repository/SwimmingLessonProviderRepository';
import Roles, { RoleInterface } from '../../users/domain/Roles';

interface SLPUserListProps {
  swimmingLessonProvider: SwimmingLessonProvider;
}

const SLPUserList = (props: SLPUserListProps) => {
  const { swimmingLessonProvider } = props;

  const [users, setUsers] = useState<User[] | null>(null);

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (user: User) => user.fullName,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (user: User) => user.email,
    },
    {
      name: 'Rollen',
      field: 'roles',
      render: (user: User) => {
        return user?.explicitRoles
          ? user.explicitRoles
              .map((role: keyof RoleInterface) => Roles[role])
              .join(', ')
          : null;
      },
    },
  ];

  const loadUsers = useCallback(() => {
    new SwimmingExamProviderRepository()
      .getUsers(swimmingLessonProvider.id)
      .then((response) => setUsers(response.data));
  }, [swimmingLessonProvider.id]);

  useEffect(loadUsers, [loadUsers]);

  if (!users) {
    return <Loader inline />;
  }

  return <SimpleTable items={users} columns={columns} />;
};

export default SLPUserList;
