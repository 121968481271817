import ApiClient from '../../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  ManagementQuery,
  Repository,
} from '../../../types';
import { parseCriteria } from '../../../utils/common';

class ManagementQueryRepository implements Repository<ManagementQuery> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<ManagementQuery>> {
    const params = parseCriteria(criteria, {
      page: page || 0,
      perPage: perPage || 10,
    });

    return ApiClient.get(`/api/v1/management-queries`, params);
  }
}

export default ManagementQueryRepository;
