import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSnackbar } from 'notistack';

import { SwimmingLessonProviderDTO } from '../../../types';
import FormSection from '../../../components/form/FormSection';
import BooleanOutput from '../../../components/form/BooleanOutput';
import TextOutput from '../../../components/form/TextOutput';
import FileInput from '../../../components/form/FileInput';
import { flowUpload, formatAddress } from '../../../utils/common';
import { SWIMMING_LESSON_PROVIDER_BASE_ROUTE } from '../index';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';

type SwimmingLessonProviderFormProps = {
  swimmingLessonProviderDTO: SwimmingLessonProviderDTO;
};

const SwimmingLessonProviderForm = (props: SwimmingLessonProviderFormProps) => {
  const { swimmingLessonProviderDTO: initialSwimmingLessonProviderDTO } = props;

  const notifications = useSnackbar();
  const history = useHistory();

  const [swimmingLessonProviderDTO, setSwimmingLessonProviderDTO] =
    useState<SwimmingLessonProviderDTO>(initialSwimmingLessonProviderDTO);

  const [customizedDiplomaAPdf, setCustomizedDiplomaAPdf] =
    useState<File | null>(null);
  const [customizedDiplomaAPdfSaved, setCustomizedDiplomaAPdfSaved] =
    useState<boolean>(false);

  const [customizedDiplomaBPdf, setCustomizedDiplomaBPdf] =
    useState<File | null>(null);
  const [customizedDiplomaBPdfSaved, setCustomizedDiplomaBPdfSaved] =
    useState<boolean>(false);

  const [customizedDiplomaCPdf, setCustomizedDiplomaCPdf] =
    useState<File | null>(null);
  const [customizedDiplomaCPdfSaved, setCustomizedDiplomaCPdfSaved] =
    useState<boolean>(false);

  const filesSaved =
    customizedDiplomaAPdfSaved &&
    customizedDiplomaBPdfSaved &&
    customizedDiplomaCPdfSaved;

  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ name?: string; checked: boolean }>,
  ) => {
    setSwimmingLessonProviderDTO({
      ...swimmingLessonProviderDTO,
      [event.target.name as string]: event.target.checked,
    });
  };

  const doSubmit = () => {
    new SwimmingLessonProviderRepository()
      .update(swimmingLessonProviderDTO.id, swimmingLessonProviderDTO)
      .then(() => {
        history.push(
          `${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}/${swimmingLessonProviderDTO.id}`,
        );
        notifications.enqueueSnackbar(
          'Zwemlesaanbieder is succesvol opgeslagen!',
          {
            variant: 'success',
          },
        );
      })
      .catch(() => {
        setFormIsSubmitting(false);
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het opslaan van de zwemlesaanbieder.',
          { variant: 'error' },
        );
      });
  };

  const checkboxAttention = (
    <Checkbox
      name="attention"
      checked={swimmingLessonProviderDTO.attention}
      onChange={handleCheckboxChange}
    />
  );

  const checkboxCustodialInstitution = (
    <Checkbox
      name="custodialInstitution"
      checked={swimmingLessonProviderDTO.custodialInstitution}
      onChange={handleCheckboxChange}
    />
  );

  const handleCustomizedDiplomaAPdfRemoval = () => {
    setSwimmingLessonProviderDTO((oldState: SwimmingLessonProviderDTO) => {
      return {
        ...oldState,
        customizedDiplomaAPdf: undefined,
        customizedDiplomaAPdfId: '',
      };
    });
  };

  const handleCustomizedDiplomaAPdfChange = (files: File[]) => {
    if (files.length > 0) {
      setCustomizedDiplomaAPdf(files[0]);
      setCustomizedDiplomaAPdfSaved(false);
    }
  };

  const handleCustomizedDiplomaBPdfRemoval = () => {
    setSwimmingLessonProviderDTO((oldState: SwimmingLessonProviderDTO) => {
      return {
        ...oldState,
        customizedDiplomaBPdf: undefined,
        customizedDiplomaBPdfId: '',
      };
    });
  };

  const handleCustomizedDiplomaBPdfChange = (files: File[]) => {
    if (files.length > 0) {
      setCustomizedDiplomaBPdf(files[0]);
      setCustomizedDiplomaBPdfSaved(false);
    }
  };

  const handleCustomizedDiplomaCPdfRemoval = () => {
    setSwimmingLessonProviderDTO((oldState: SwimmingLessonProviderDTO) => {
      return {
        ...oldState,
        customizedDiplomaCPdf: undefined,
        customizedDiplomaCPdfId: '',
      };
    });
  };

  const handleCustomizedDiplomaCPdfChange = (files: File[]) => {
    if (files.length > 0) {
      setCustomizedDiplomaCPdf(files[0]);
      setCustomizedDiplomaCPdfSaved(false);
    }
  };

  const saveCustomizedDiplomaAPdf = () => {
    if (customizedDiplomaAPdf) {
      flowUpload(customizedDiplomaAPdf, (id) => {
        setSwimmingLessonProviderDTO((oldState) => ({
          ...oldState,
          customizedDiplomaAPdfId: id,
        }));
        setCustomizedDiplomaAPdfSaved(true);
      });
    } else {
      setCustomizedDiplomaAPdfSaved(true);
    }
  };

  const saveCustomizedDiplomaBPdf = () => {
    if (customizedDiplomaBPdf) {
      flowUpload(customizedDiplomaBPdf, (id) => {
        setSwimmingLessonProviderDTO((oldState) => ({
          ...oldState,
          customizedDiplomaBPdfId: id,
        }));
        setCustomizedDiplomaBPdfSaved(true);
      });
    } else {
      setCustomizedDiplomaBPdfSaved(true);
    }
  };

  const saveCustomizedDiplomaCPdf = () => {
    if (customizedDiplomaCPdf) {
      flowUpload(customizedDiplomaCPdf, (id) => {
        setSwimmingLessonProviderDTO((oldState) => ({
          ...oldState,
          customizedDiplomaCPdfId: id,
        }));
        setCustomizedDiplomaCPdfSaved(true);
      });
    } else {
      setCustomizedDiplomaCPdfSaved(true);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formIsSubmitting) {
      return;
    }

    setFormIsSubmitting(true);

    if (filesSaved) {
      doSubmit();
    } else {
      if (!customizedDiplomaAPdfSaved) {
        saveCustomizedDiplomaAPdf();
      }
      if (!customizedDiplomaBPdfSaved) {
        saveCustomizedDiplomaBPdf();
      }
      if (!customizedDiplomaCPdfSaved) {
        saveCustomizedDiplomaCPdf();
      }
    }
  };

  useEffect(
    () => {
      if (filesSaved) {
        doSubmit();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filesSaved],
  );

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <FormSection title="Gegevens">
        <TextOutput label="Naam" value={swimmingLessonProviderDTO.name} />
        <TextOutput
          label="Klantcode"
          value={swimmingLessonProviderDTO.afasId}
        />
        <TextOutput
          label="Type"
          value={swimmingLessonProviderDTO.typeReadable}
        />
        <TextOutput label="E-mail" value={swimmingLessonProviderDTO.email} />
        <TextOutput
          label="Telefoonnummer"
          value={swimmingLessonProviderDTO.phoneNumber}
        />
        <TextOutput
          label="Bezoekadres"
          value={
            formatAddress(swimmingLessonProviderDTO.visitingAddress) || '—'
          }
        />
        <TextOutput
          label="Correspondentieadres"
          value={
            formatAddress(swimmingLessonProviderDTO.shippingAddress) || '—'
          }
        />
        <TextOutput
          label="Beheerder"
          value={swimmingLessonProviderDTO.manager?.fullName || '—'}
        />
        <BooleanOutput
          label="Opleidingsinstituut C"
          value={swimmingLessonProviderDTO.trainingInstituteC}
        />
        <BooleanOutput
          label="Centrale examens"
          value={swimmingLessonProviderDTO.centralExams}
        />
        <TextOutput
          label="Leslocaties"
          value={
            swimmingLessonProviderDTO.lessonLocations
              .map((l) => l.name)
              .join('; ') || '—'
          }
        />
        <TextOutput
          label="Afzwemlocaties"
          value={
            swimmingLessonProviderDTO.examLocations
              .map((l) => l.name)
              .join('; ') || '—'
          }
        />
        <TextOutput
          label="Examinatoren"
          value={
            swimmingLessonProviderDTO.examiners
              .map((e) => e.fullName)
              .join('; ') || '—'
          }
        />
      </FormSection>

      <FormSection title="Maatwerkdiploma's">
        <FileInput
          label="Diploma A"
          file={swimmingLessonProviderDTO?.customizedDiplomaAPdf}
          documents
          onChange={handleCustomizedDiplomaAPdfChange}
          onDelete={handleCustomizedDiplomaAPdfRemoval}
        />
        <FileInput
          label="Diploma B"
          file={swimmingLessonProviderDTO?.customizedDiplomaBPdf}
          documents
          onChange={handleCustomizedDiplomaBPdfChange}
          onDelete={handleCustomizedDiplomaBPdfRemoval}
        />
        <FileInput
          label="Diploma C"
          file={swimmingLessonProviderDTO?.customizedDiplomaCPdf}
          documents
          onChange={handleCustomizedDiplomaCPdfChange}
          onDelete={handleCustomizedDiplomaCPdfRemoval}
        />
      </FormSection>

      <FormSection title="Instellingen">
        <FormGroup>
          <FormControlLabel
            control={checkboxAttention}
            label="Onder toezicht"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={checkboxCustodialInstitution}
            label="Justitiële inrichting"
          />
        </FormGroup>
      </FormSection>

      <Box mb={3}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={formIsSubmitting}
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
        >
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default SwimmingLessonProviderForm;
