import { AxiosResponse } from 'axios';

import { DiplomaCategory } from '../domain/types';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class DiplomaCategoryRepository implements Repository<DiplomaCategory> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<DiplomaCategory>> {
    const params = parseCriteria(criteria, { page: page || 0 });

    return ApiClient.get('/api/v1/diploma-categories', params);
  }

  getAll(): Promise<CollectionResponse<DiplomaCategory>> {
    return ApiClient.get('/api/v1/diploma-categories');
  }

  get(id: string): Promise<AxiosResponse<DiplomaCategory>> {
    return ApiClient.get(`/api/v1/diploma-categories/${id}`);
  }

  update(
    diplomaCategory: DiplomaCategory,
  ): Promise<AxiosResponse<DiplomaCategory>> {
    return ApiClient.put(
      `/api/v1/diploma-categories/${diplomaCategory.id}`,
      diplomaCategory,
    );
  }

  delete(id: string) {
    return ApiClient.delete(`/api/v1/diploma-categories/${id}`);
  }
}

export default DiplomaCategoryRepository;
