import { Exam, User } from '../../../types';
import { examHasStarted } from '../utils';

export function canCreate(subject: { account: User }) {
  return (
    subject.account.roles.includes('ROLE_ADMIN') ||
    subject.account.roles.includes('ROLE_SWIMMING_LESSON_PROVIDER_USER')
  );
}

export function canUpdate(subject: { exam: Exam; account: User }) {
  return (
    subject.account.roles.includes('ROLE_ADMIN') ||
    (subject.account.roles.includes('ROLE_SWIMMING_LESSON_PROVIDER_USER') &&
      !examHasStarted(subject.exam))
  );
}

export function canDelete(subject: { exam: Exam; account: User }) {
  return (
    subject.account.roles.includes('ROLE_ADMIN') ||
    (subject.account.roles.includes('ROLE_SWIMMING_LESSON_PROVIDER_USER') &&
      !examHasStarted(subject.exam))
  );
}
