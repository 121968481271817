import { Box } from '@material-ui/core';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { cancelOnRowClick } from '../../../components/DataTable';
import { Exam } from '../../../types';
import { ExamColumn, ExamReportEntry } from './types';
import ExamTime from '../components/ExamTime';
import ExamDiplomaCounts from '../components/ExamDiplomaCounts';
import ExamSwimmingLessonProvider from '../components/ExamSwimmingLessonProvider';
import Button from '../../../components/Button';
import RoleViewManager from '../../../RoleViewManager';
import { formatDelegates } from '../../users/utils';
import { EXAM_BASE_ROUTE } from '../index';
import { moreThanOneDayBeforeExam } from '../utils';

export const COLUMN_EXAM_NUMBER: ExamColumn = {
  name: '#',
  field: 'examNumber',
  sortable: true,
  render: (exam: Exam) => exam.examNumber,
};

export const COLUMN_EXAM_DATE: ExamColumn = {
  name: 'Datum',
  field: 'date',
  sortable: true,
  render: (exam: Exam) => <ExamTime exam={exam} />,
};

export const COLUMN_EXAM_DELEGATE: ExamColumn = {
  name: 'Gedelegeerde',
  field: 'delegates',
  render: (exam: Exam) => {
    if (exam.confirmedDelegates?.length) {
      return <strong>{formatDelegates(exam.confirmedDelegates)}</strong>;
    }

    if (exam.invitedDelegates?.length) {
      return <em>{formatDelegates(exam.invitedDelegates)}</em>;
    }

    return null;
  },
};

export const COLUMN_EXAM_DELEGATE_FOR_ZLA: ExamColumn = {
  name: 'Gedelegeerde',
  field: 'delegates',
  render: (exam: Exam) => {
    if (exam.confirmedDelegates?.length && !moreThanOneDayBeforeExam(exam)) {
      return <strong>{formatDelegates(exam.confirmedDelegates)}</strong>;
    }

    return null;
  },
};

export const COLUMN_EXAM_DIPLOMAS: ExamColumn = {
  name: 'Diplomas',
  field: 'examLines',
  render: (exam: Exam) => <ExamDiplomaCounts exam={exam} />,
};

export const COLUMN_EXAM_LOCATION: ExamColumn = {
  name: 'Locatie',
  field: 'examLocation',
  sortable: true,
  render: (exam: Exam) => exam.examLocation.name,
};

export const COLUMN_EXAM_LESSON_LOCATIONS: ExamColumn = {
  name: 'Leslocatie(s)',
  field: 'lessonLocations',
  sortable: true,
  render: (exam: Exam) => {
    const count = exam.lessonLocations.length;
    if (count === 0) {
      return null;
    }

    const { name } = exam.lessonLocations[0];

    return count === 1 ? name : `${name} (+${count - 1})`;
  },
};

export const COLUMN_EXAM_ADDRESS: ExamColumn = {
  name: 'Adres',
  field: 'address',
  sortable: false,
  render: (exam: Exam) => {
    const { visitingAddress } = exam.examLocation;

    if (!visitingAddress) {
      return '-';
    }

    return (
      <Box>
        <Box>
          {`${visitingAddress.street} ${visitingAddress.number} ${
            visitingAddress.suffix || ''
          }`}
        </Box>
        <Box>{`${visitingAddress.postalCode} ${visitingAddress.town}`}</Box>
      </Box>
    );
  },
};

export const COLUMN_EXAM_REGION: ExamColumn = {
  name: 'Regio',
  field: 'region',
  render: (exam: Exam) => exam.examLocation?.visitingAddress?.province || '',
};

export const COLUMN_EXAM_SWIMMING_LESSON_PROVIDER: ExamColumn = {
  name: 'Zwemlesaanbieder',
  field: 'swimmingLessonProvider',
  sortable: true,
  render: (exam: Exam) => <ExamSwimmingLessonProvider exam={exam} />,
};

export const COLUMN_EXAM_REPORT_DELEGATE: ExamColumn = {
  name: 'Verslag gedelegeerde.',
  field: 'delegateReport',
  sortable: false,
  render: (exam: Exam, roleViewManager: RoleViewManager) => {
    if ((exam?.confirmedDelegates || []).length === 0) {
      return null;
    }

    // in theory, there should be only one, not multiple
    const delegateReports: ExamReportEntry[] = exam.reports
      ? exam.reports.filter((r) => r.examReport.type === 'delegate')
      : [];

    let label: string;
    let icon: IconName;
    if (delegateReports.length > 0) {
      if (
        roleViewManager.isAdminView() &&
        delegateReports[0].valuation === undefined
      ) {
        icon = 'ballot-check';
        label = 'Verslag beoordelen';
      } else {
        icon = 'envelope-open-text';
        label = 'Afgerond (inzien)';
      }
    } else {
      if (roleViewManager.isOperatorView()) {
        return null;
      }
      icon = 'plus';
      label = 'Verslag toevoegen';
    }

    return (
      <Box onClick={cancelOnRowClick}>
        <Button
          icon={['fal', icon]}
          label={label}
          iconPosition="right"
          to={`${EXAM_BASE_ROUTE}/${exam.id}/gedelegeerdenrapport`}
        />
      </Box>
    );
  },
};

export const COLUMN_EXAM_REPORT_EXAMINER: ExamColumn = {
  name: 'Verslag examinator',
  field: 'examinerReport',
  sortable: false,
  render: (exam: Exam, roleViewManager: RoleViewManager) => {
    // in theory, there should be only one, not multiple
    const examinerReports: ExamReportEntry[] = exam.reports
      ? exam.reports.filter((r) => r.examReport.type === 'examiner')
      : [];

    let label: string;
    let icon: IconName;
    if (examinerReports.length > 0) {
      if (
        roleViewManager.isAdminView() &&
        examinerReports[0].valuation === undefined
      ) {
        icon = 'ballot-check';
        label = 'Verslag beoordelen';
      } else {
        icon = 'envelope-open-text';
        label = 'Afgerond (inzien)';
      }
    } else {
      if (roleViewManager.isOperatorView()) {
        return null;
      }
      icon = 'plus';
      label = 'Verslag toevoegen';
    }

    return (
      <Box onClick={cancelOnRowClick}>
        <Button
          icon={['fal', icon]}
          label={label}
          iconPosition="right"
          to={`${EXAM_BASE_ROUTE}/${exam.id}/examinatorrapport`}
        />
      </Box>
    );
  },
};

export const COLUMN_EXAM_DECLARATION: ExamColumn = {
  name: 'Declaratie',
  field: 'declaration',
  render: (exam: Exam, roleViewManager: RoleViewManager) => {
    const user = roleViewManager.getUser();
    if (!exam.reports?.find((r) => r.creator.id === user.id)) {
      return '-';
    }

    const declaration = exam.declarations?.find(
      (d) => d.delegate.id === user.id,
    );

    let label: string;
    let icon: IconName;
    let to: string;

    if (declaration) {
      label = 'Declaratie bekijken';
      icon = 'envelope-open-text';
      to = `/declaraties/${declaration.id}`;
    } else {
      label = 'Declaratie toevoegen';
      icon = 'plus';
      to = `${EXAM_BASE_ROUTE}/${exam.id}/declaraties`;
    }

    return (
      <Box onClick={cancelOnRowClick}>
        <Button
          icon={['fal', icon]}
          label={label}
          iconPosition="right"
          to={to}
        />
      </Box>
    );
  },
};
