import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import Page from '../../../components/Page';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';
import DataTable, {
  Column,
  Filter,
  ItemAction,
} from '../../../components/DataTable';
import DataTableRowLink from '../../../components/DataTableRowLink';
import SearchInput from '../../../components/search/SearchInput';
import { SwimmingLessonProvider, User } from '../../../types';
import SearchContext from '../../../components/search/SearchContext';
import { SWIMMING_LESSON_PROVIDER_BASE_ROUTE } from '../index';

const SwimmingLessonProviderOverview = () => {
  const history = useHistory();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const repository = new SwimmingLessonProviderRepository();

  const isSuperAdmin = account.roles.includes('ROLE_SUPER_ADMIN');

  const title = (
    <>
      Zwemlesaanbieders
      {totalCount && ` (${totalCount})`}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <SearchInput id="swimming-lesson-provider-overview-query" persistQuery />
    </Box>
  );

  const columns: Column[] = [];
  columns.push({
    name: 'Klantcode',
    field: 'afasId',
    sortable: true,
    render: (swimmingLessonProvider: SwimmingLessonProvider) =>
      swimmingLessonProvider.afasId,
  });
  columns.push({
    name: 'Naam',
    field: 'name',
    sortable: true,
    render: (swimmingLessonProvider: SwimmingLessonProvider) =>
      swimmingLessonProvider.name,
  });
  if (isSuperAdmin) {
    columns.push({
      name: 'Type',
      field: 'type',
      sortable: true,
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        swimmingLessonProvider.typeReadable,
    });
  }
  columns.push({
    name: 'Onder toezicht',
    field: 'attention',
    render: (swimmingLessonProvider: SwimmingLessonProvider) =>
      swimmingLessonProvider.attention ? 'Ja' : '',
  });
  columns.push({
    name: 'Buitenland',
    field: 'external',
    render: (swimmingLessonProvider: SwimmingLessonProvider) =>
      swimmingLessonProvider.external ? 'Ja' : '',
  });

  const filters: Filter[] = [
    {
      field: 'attention',
      name: 'Onder toezicht',
      type: 'checkbox',
      options: [
        { value: '1', label: 'Ja' },
        { value: '0', label: 'Nee' },
      ],
    },
  ];

  const itemActions = (swimmingLessonProvider: SwimmingLessonProvider) => {
    const actions: ItemAction[] = [];

    actions.push({
      key: 'edit',
      element: (
        <DataTableRowLink
          title="Bewerken"
          icon="edit"
          to={`${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}/${swimmingLessonProvider.id}/bewerken`}
        />
      ),
    });

    return (
      <Box>
        {actions.map((action) => (
          <React.Fragment key={action.key}>{action.element}</React.Fragment>
        ))}
      </Box>
    );
  };

  const onRowClick = (swimmingLessonProvider: SwimmingLessonProvider) => {
    history.push(
      `${SWIMMING_LESSON_PROVIDER_BASE_ROUTE}/${swimmingLessonProvider.id}`,
    );
  };

  const styleRow = (swimmingLessonProvider: SwimmingLessonProvider) =>
    swimmingLessonProvider?.inactive ? 'trHighlight' : 'tr';

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="swimming-lesson-provider-overview"
          repository={repository}
          columns={columns}
          filters={filters}
          persistFilters
          actions={itemActions}
          onRowClick={onRowClick}
          styleRow={styleRow}
          resultCounterText={{
            singular: 'zwemlesaanbieder',
            plural: 'zwemlesaanbieders',
          }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default SwimmingLessonProviderOverview;
