import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import AppContext from '../../../AppContext';
import Loader from '../../../components/Loader';
import TextOutput from '../../../components/form/TextOutput';
import { Exam } from '../../../types';
import {
  ExamReport,
  ExamReportEntry as ExamReportEntryInterface,
  ExamReportEntryValuation,
  ExamReportType,
} from '../domain/types';
import ExamReportContext from '../forms/ExamReportContext';
import ExamReportForm from '../forms/ExamReportForm';
import ExamRepository from '../repository/ExamRepository';
import ExamReportRepository from '../repository/ExamReportRepository';
import ExamReportEntryRepository from '../repository/ExamReportEntryRepository';
import PassedParticipants from './PassedParticipants';
import ExamReportEntryValuationSelector from './ExamReportEntryValuationSelector';
import { useSelector } from 'react-redux';
import { UserState } from '../../../reducers/user';

interface ExamReportEntryProps {
  exam: Exam;
  type: ExamReportType;
}

const ExamReportEntry = (props: ExamReportEntryProps) => {
  const history = useHistory();
  const notifications = useSnackbar();
  const { roleViewManager } = useContext(AppContext);

  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  const [report, setReport] = useState<ExamReport | null>(null);
  const [entry, setEntry] = useState<ExamReportEntryInterface | undefined>(
    undefined,
  );
  const [entryLoaded, setEntryLoaded] = useState<boolean>(false);
  const [data, setData] = useState<{
    [key: string]: { value: unknown; explanation?: string };
  }>({});

  // the report is read-only if any of the following cases are true:
  // - the report has already been filled out
  // - the report is a delegate report, but is accessed by someone who is not an admin or a delegate
  // - the report is an examiner report, but is accessed by someone who is not an admin or an examiner or a swimminglessonprovider
  const readOnly =
    entry?.id !== undefined ||
    (props.type === 'delegate' &&
      !roleViewManager.isAdminView() &&
      !roleViewManager.isDelegateView()) ||
    (props.type === 'examiner' &&
      !roleViewManager.isAdminView() &&
      !roleViewManager.isExaminerView() &&
      !roleViewManager.isSwimmingLessonProviderView());
  const contextValue = useMemo(
    () => ({
      data,
      changeField: (fieldId: string, value: unknown, explanation?: string) => {
        let newData = { value: value, explanation: explanation };
        return setData({
          ...data,
          [fieldId]: newData,
        });
      },
      locked: readOnly,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, setData],
  );

  const handleSuccess = () => {
    notifications.enqueueSnackbar('Het rapport is succesvol opgeslagen', {
      variant: 'success',
    });
    history.go(0);
  };

  const handleValuation = (
    valuation: keyof typeof ExamReportEntryValuation,
  ) => {
    if (!entry) return;

    new ExamReportEntryRepository()
      .valuate(props.exam.id, entry.id, valuation)
      .then(() => {
        notifications.enqueueSnackbar(
          'De beoordeling is succesvol opgeslagen',
          {
            variant: 'success',
          },
        );
        history.go(0);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets mis gegaan bij het opslaan van de beoordeling',
          {
            variant: 'error',
          },
        );
      });
  };

  /**
   * Load the default report, if there is no entry yet
   */
  useEffect(() => {
    // wait the for entry to load, before loading the default report
    if (report || !entryLoaded) {
      return;
    }
    const repository = new ExamReportRepository();

    let promise;
    switch (props.type) {
      case 'delegate':
        promise = repository.getDelegateReport();
        break;
      case 'examiner':
        promise = repository.getExaminerReport();
        break;
      case 'aspirant':
        promise = repository.getAspirantReport();
        break;
      default:
        promise = null;
    }
    if (promise !== null) {
      promise.then((response) => setReport(response.data));
    }
  }, [entryLoaded, report, props.type]);

  /**
   * Attempt to load the existing entry and the corresponding report
   */
  useEffect(() => {
    const repository = new ExamRepository();
    let promise;
    switch (props.type) {
      case 'delegate':
        promise = repository.getDelegateReport(props.exam.id);
        break;
      case 'examiner':
        promise = repository.getExaminerReport(props.exam.id);
        break;
      case 'aspirant':
        promise = repository.getAspirantReport(props.exam.id);
        break;
      default:
        promise = null;
    }

    if (promise !== null) {
      promise
        .then((response) => {
          const newData: {
            [key: string]: { value: unknown; explanation?: string };
          } = {};
          response.data.fields.forEach((field) => {
            newData[field.field.id] = {
              value: field.value,
              explanation: field.explanation,
            };
          });
          setReport(response.data.examReport);
          setEntry(response.data);
          setData(newData);
        })
        .catch(() => {
          // no entry for this report
        })
        .finally(() => setEntryLoaded(true));
    } else {
      setEntryLoaded(true);
    }
  }, [props.type, props.exam.id]);

  if (!entryLoaded || !report) {
    return <Loader inline />;
  }

  return (
    <>
      {props.type !== 'aspirant' && <PassedParticipants exam={props.exam} />}
      <ExamReportContext.Provider value={contextValue}>
        <ExamReportForm
          type={props.type}
          report={report}
          examId={props.exam.id}
          data={data}
          legacyPdf={entry?.legacyPdf}
          onSuccess={handleSuccess}
        />
        {(roleViewManager.isAdminView() ||
          account?.delegateProfile?.status === 'mentor') &&
          entry && (
            <>
              {entry?.valuationReadable ? (
                <TextOutput
                  label="Beoordeling"
                  value={entry.valuationReadable}
                />
              ) : (
                <ExamReportEntryValuationSelector
                  onSubmit={handleValuation}
                  type={props.type}
                />
              )}
            </>
          )}
      </ExamReportContext.Provider>
    </>
  );
};

export default ExamReportEntry;
