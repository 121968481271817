import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

import {
  Operator,
  SwimmingLessonProviderWithLessonLocations,
  User,
  UserDTO,
} from '../../../types';
import FormSection from '../../../components/form/FormSection';
import ButtonRow from '../../../components/ButtonRow';
import SwimmingLessonProviderSelect from '../../admin/forms/SwimmingLessonProviderSelect';
import OperatorSelect from '../../admin/forms/OperatorSelect';

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
}));

type assignableRoles = 'admin' | 'slpUser' | 'operator';

type UserFormProps = {
  user?: UserDTO;
  onSubmit: (user: UserDTO) => void | null;
};

const UserForm = (props: UserFormProps) => {
  const { user: initialUser, onSubmit } = props;

  const classes = useStyles();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );
  const isSuperAdmin = account.roles.includes('ROLE_SUPER_ADMIN');
  const isAdmin = account.roles.includes('ROLE_ADMIN');
  const isSLPManager = account.roles.includes(
    'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER',
  );

  const [user, setUser] = useState<UserDTO>(
    initialUser ||
      ({
        email: '',
        swimmingLessonProviderId: isSLPManager
          ? account.swimmingLessonProvider!.id
          : null,
        firstName: '',
        insertion: null,
        lastName: '',
      } as UserDTO),
  );
  const [userRole, setUserRole] = useState<assignableRoles>('admin');

  const selectSwimmingLessonProvider = (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations | null,
  ) => {
    setUser({
      ...user,
      swimmingLessonProviderId: swimmingLessonProvider
        ? swimmingLessonProvider.id
        : null,
    });
  };

  const selectOperator = (operator: Operator | null) => {
    setUser({
      ...user,
      operatorId: operator ? operator.id : null,
    });
  };

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newRole = event.target.value as assignableRoles;
    setUserRole(newRole);
    setUser({
      ...user,
      swimmingLessonProviderId: null,
      operatorId: null,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setUser({ ...user, [event.target.name as string]: event.target.value });
  };

  const handleMayInvoiceChange = (
    event: React.ChangeEvent<{ name?: string; checked: boolean }>,
  ) => {
    if (!user.delegateProfile) {
      return;
    }

    setUser({
      ...user,
      delegateProfile: {
        ...user.delegateProfile,
        mayInvoice: event.target.checked,
      },
    });
  };

  const handleMaxDistanceChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    if (!user.delegateProfile) {
      return;
    }

    const value = parseFloat(`${event.target.value}`.replace(/[^\d.-]/g, ''));

    setUser({
      ...user,
      delegateProfile: {
        ...user.delegateProfile,
        maxDistanceExamsInKm: value as number,
      },
    });
  };

  const handleSubmit = () => {
    onSubmit(user);
  };

  let mayInvoiceCheckbox = null;
  if (user.delegateProfile) {
    mayInvoiceCheckbox = (
      <Checkbox
        name="mayInvoice"
        checked={user.delegateProfile.mayInvoice}
        onChange={handleMayInvoiceChange}
      />
    );
  }

  return (
    <>
      <FormSection title="Gegevens">
        <>
          {isAdmin && !user.id && (
            <>
              <FormControl>
                <FormLabel>Rol</FormLabel>
                <RadioGroup
                  value={userRole}
                  name="action"
                  onChange={handleRoleChange}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Admin"
                    value="admin"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Zwemlesaanbieder (gebruiker)"
                    value="slpUser"
                  />
                  {isSuperAdmin && (
                    <FormControlLabel
                      control={<Radio />}
                      label="Exploitant (gebruiker)"
                      value="operator"
                    />
                  )}
                </RadioGroup>
              </FormControl>
              {userRole === 'slpUser' && (
                <SwimmingLessonProviderSelect
                  onSelect={selectSwimmingLessonProvider}
                />
              )}
              {userRole === 'operator' && (
                <OperatorSelect onSelect={selectOperator} />
              )}
            </>
          )}
        </>
        <TextField
          label="E-mail"
          className={classes.field}
          value={user.email}
          name="email"
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="Voornaam"
          className={classes.field}
          value={user.firstName}
          name="firstName"
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          label="Tussenvoegsel"
          className={classes.field}
          value={user.insertion}
          name="insertion"
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Achternaam"
          className={classes.field}
          value={user.lastName}
          name="lastName"
          onChange={handleChange}
          fullWidth
          required
        />
      </FormSection>
      {user.delegateProfile && isAdmin && (
        <FormSection title="Gedelegeerdenprofiel">
          <>
            {mayInvoiceCheckbox && (
              <FormGroup>
                <FormControlLabel
                  control={mayInvoiceCheckbox}
                  label="Mag factureren"
                />
              </FormGroup>
            )}
          </>
          <TextField
            type="number"
            label="Maximale afstand tot examens in km"
            className={classes.field}
            value={user.delegateProfile.maxDistanceExamsInKm}
            name="maxDistanceToExams"
            onChange={handleMaxDistanceChange}
            fullWidth
          />
        </FormSection>
      )}
      <ButtonRow>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
          onClick={handleSubmit}
        >
          Opslaan
        </Button>
      </ButtonRow>
    </>
  );
};

export default UserForm;
