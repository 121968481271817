import React, { useState } from 'react';

import { Button, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ButtonRow from '../../../components/ButtonRow';
import FormSection from '../../../components/form/FormSection';

import { OperatorDTO } from '../../../types';

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
}));

type OperatorFormProps = {
  operator?: OperatorDTO;
  onSubmit: (operator: OperatorDTO) => void | null;
};

const OperatorForm = (props: OperatorFormProps) => {
  const { operator: initialOperator, onSubmit } = props;

  const classes = useStyles();

  const [operator, setOperator] = useState<OperatorDTO>(
    initialOperator ||
      ({
        name: '',
      } as OperatorDTO),
  );

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setOperator({
      ...operator,
      [event.target.name as string]: event.target.value,
    });
  };

  const handleSubmit = () => {
    onSubmit(operator);
  };

  return (
    <>
      <FormSection title="Gegevens">
        <TextField
          label="Naam"
          className={classes.field}
          value={operator.name}
          name="name"
          onChange={handleChange}
          fullWidth
          required
        />
      </FormSection>
      <ButtonRow>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
          onClick={handleSubmit}
        >
          Opslaan
        </Button>
      </ButtonRow>
    </>
  );
};

export default OperatorForm;
