import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatTime } from '../../../utils/common';
import { ExamDiploma } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ReadOnlyExamDiplomaListProps {
  oneExaminerOverall: boolean;
  examLines: ExamDiploma[];
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
  },
  tableHead: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    fontSize: 18,
    padding: 0,
    borderBottom: 0,
    paddingRight: 20,
  },
  tableCell: {
    paddingTop: 0,
    paddingLeft: 0,
    borderBottom: 'none',
  },
}));

const ReadOnlyExamDiplomaList = (props: ReadOnlyExamDiplomaListProps) => {
  const { oneExaminerOverall, examLines } = props;
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>
            Diploma zwemmen voor
          </TableCell>
          {!oneExaminerOverall && (
            <TableCell variant="head" className={classes.tableHead}>
              Examinator
            </TableCell>
          )}
          <TableCell variant="head" className={classes.tableHead}>
            Aantal kandidaten
          </TableCell>
          <TableCell variant="head" className={classes.tableHead}>
            Starttijd
          </TableCell>
          <TableCell variant="head" className={classes.tableHead}>
            Eindtijd
          </TableCell>
          <TableCell variant="head" className={classes.tableHead} />
          <TableCell variant="head" className={classes.tableHead} />
        </TableRow>
      </TableHead>
      <TableBody>
        {examLines.map((examLine) => (
          <TableRow key={examLine.id}>
            <TableCell className={classes.tableCell}>
              {examLine.diplomaType.name}
            </TableCell>
            {!oneExaminerOverall && (
              <TableCell className={classes.tableCell}>
                {examLine.examiner?.fullName}
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              {examLine.numberParticipants}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {formatTime(new Date(examLine.beginTime))}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {formatTime(new Date(examLine.endTime))}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {examLine.hasDelegate && (
                <Tooltip title="Gedelegeerde aanwezig">
                  <FontAwesomeIcon
                    icon={['fal', 'user']}
                    style={{ width: 12, height: 12 }}
                  />
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ReadOnlyExamDiplomaList;
