import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import DataTable from '../../../components/DataTable';
import Page from '../../../components/Page';
import DiplomaCategoryRepository from '../repository/DiplomaCategoryRepository';
import DiplomaTypeRepository from '../repository/DiplomaTypeRepository';
import { DiplomaCategory, DiplomaType } from '../domain/types';
import { DIPLOMA_CATEGORY_OVERVIEW_ROUTE } from '../index';

const DiplomaCategoryTypeOverview = () => {
  const { id } = useParams<{ id: string }>();
  const repository = new DiplomaTypeRepository(id);
  const diplomaCategoryRepository = new DiplomaCategoryRepository();
  const [diplomaCategory, setDiplomaCategory] =
    useState<DiplomaCategory | null>(null);

  useEffect(
    () => {
      diplomaCategoryRepository
        .get(id)
        .then(({ data }) => {
          setDiplomaCategory(data);
        })
        .catch(() => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const columns = [
    {
      name: 'Naam',
      field: 'name',
      render: (diplomaType: DiplomaType) => (
        <Link
          to={`${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/${id}/diplomatypen/${diplomaType.id}/bewerken`}
        >
          {diplomaType.name}
        </Link>
      ),
    },
    {
      name: 'Afkorting',
      field: 'shortName',
    },
  ];

  const actions = (
    <Button
      icon={['fal', 'plus']}
      to={`${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/${id}/diplomatypen/toevoegen`}
      label="Diplomatype toevoegen"
    />
  );

  return (
    <Page
      title={`Diplomatypen in categorie "${diplomaCategory?.name || ''}"`}
      actions={actions}
    >
      <DataTable
        id="diploma-type-overview"
        repository={repository}
        columns={columns}
        deletable
        deleteItemMessage={(
          diplomaType: DiplomaType,
        ) => `Weet u zeker dat u diplomatype "${diplomaType.name}" 
              wilt verwijderen?`}
      />
    </Page>
  );
};

export default DiplomaCategoryTypeOverview;
