import { ChangeEvent, useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';

interface dayName {
  dayOfWeek: number;
  name: string;
}

const dayNames: dayName[] = [
  { dayOfWeek: 1, name: 'Maandag' },
  { dayOfWeek: 2, name: 'Dinsdag' },
  { dayOfWeek: 3, name: 'Woensdag' },
  { dayOfWeek: 4, name: 'Donderdag' },
  { dayOfWeek: 5, name: 'Vrijdag' },
  { dayOfWeek: 6, name: 'Zaterdag' },
  { dayOfWeek: 0, name: 'Zondag' },
];

interface DayOfWeekSelectorProps {
  value?: number;
  onSelect: (dayOfWeek: number) => void;
}

const DayOfWeekSelector = (props: DayOfWeekSelectorProps) => {
  const { value: initialValue = 1, onSelect } = props;

  const [dayOfWeek, setDayOfWeek] = useState<number>(initialValue);

  const handleSelect = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = parseInt(e.target.value as string, 10);

    setDayOfWeek(value);
    onSelect(value);
  };

  return (
    <Select
      labelId="day-of-week-select-label"
      id="day-of-week-select"
      value={dayOfWeek}
      name="dayOfWeek"
      onChange={handleSelect}
      MenuProps={{ elevation: 1 }}
    >
      {dayNames.map((dayName) => (
        <MenuItem key={dayName.dayOfWeek} value={dayName.dayOfWeek}>
          {dayName.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DayOfWeekSelector;
