import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackButton = () => {
  const history = useHistory();

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => history.goBack()}
      startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
    >
      Terug naar overzicht
    </Button>
  );
};

export default BackButton;
