import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import './App.scss';
import './config/icons';
import localforage from 'localforage';
import { AxiosResponse } from 'axios';
import { Box } from '@material-ui/core';
import theme from './config/theme';

import Navigation from './Navigation';
import { Route, Router } from './routing';
import { User } from './types';

// Modules
import './modules/admin';
import './modules/declarations';
import './modules/delegates';
import './modules/diploma';
import './modules/examiner';
import './modules/exams';
import './modules/mail-templates';
import './modules/swimming-lesson-provider';
import './modules/users';

import Login from './Login';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import UserRepository from './modules/users/repository/UserRepository';
import { setAccount, setRoleView } from './actions';
import { UserState } from './reducers/user';
import Loader from './components/Loader';
import AppContext from './AppContext';
import Footer from './Footer';
import RoleViewManager, {
  RoleViewInterface,
  getInitialRoleView,
  isRoleViewAvailable,
  ROLE_VIEW_STORE_KEY,
} from './RoleViewManager';
import NullUser from './modules/users/domain/NullUser';
import ErrorHandler from './ErrorHandler';

// Router
Router.addRoute(new Route('Login', '/', <Login />, true).anonymous());
Router.addRoute(
  new Route('2FA', '/2fa', <TwoFactorAuthentication />, true).anonymous(),
);

export interface AppState {
  user: User | null;
  loaded: boolean;
  errorStatusCode: number | null;
  errorDetails: React.ReactElement | null;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // Always show sidebar to prevent jumps
    minHeight: 'calc(100vh + 1px)',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 0 auto',
    zIndex: 1,
  },
}));

function App() {
  const classes = useStyles();
  const { isLoggedIn, isImitating, account, roleView } = useSelector(
    (selector: { user: UserState }) => ({
      isLoggedIn: selector.user.isLoggedIn,
      isImitating: selector.user.isImitating,
      account: selector.user.account,
      roleView: selector.user.roleView,
    }),
  );
  const notifications = useSnackbar();
  const dispatch = useDispatch();

  const [state, setState] = useState<AppState>({
    user: null,
    loaded: false,
    errorStatusCode: null,
    errorDetails: null,
  });

  const localStore = useMemo(
    () => localforage.createInstance({ name: 'nrz-portal' }),
    [],
  );
  const roleViewManager = useMemo(
    () => new RoleViewManager(account || NullUser, roleView),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, roleView],
  );

  /**
   * Attempt to get account data from API.
   */
  const fetchAccount = async () => {
    const repository = new UserRepository();

    if (!account) {
      let accountResponse: AxiosResponse<User>;

      try {
        accountResponse = await repository.getAccount();
      } catch (e) {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het inloggen!',
          { variant: 'error' },
        );
        return;
      }

      const initialRoleView = getInitialRoleView(accountResponse.data.roles);

      dispatch(setAccount(accountResponse.data));
      dispatch(setRoleView(initialRoleView));

      await localStore.setItem<keyof RoleViewInterface | null>(
        ROLE_VIEW_STORE_KEY,
        initialRoleView,
      );

      setState({
        ...state,
        loaded: true,
        user: accountResponse.data,
      });
    } else {
      setState({
        ...state,
        loaded: true,
      });
    }
  };

  /**
   * Trigger when isLoggedIn or isImitating changes.
   */
  useEffect(
    () => {
      if (!isLoggedIn) {
        document.body.classList.remove('user-authenticated');
        setState({
          ...state,
          user: null,
          loaded: true,
        });
        return;
      }

      document.body.classList.add('user-authenticated');
      fetchAccount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoggedIn, isImitating],
  );

  const routes = useMemo(
    () => (
      <BrowserRouter>
        <Navigation />
        <ErrorHandler />
        {state.errorStatusCode === null && Router.processPages(roleView)}
      </BrowserRouter>
    ),
    [roleView, state],
  );

  const appContextValue = useMemo(
    () => ({
      appState: state,
      setAppState: setState,
      roleViewManager,
      localStore,
    }),
    [state, roleViewManager, localStore],
  );

  // load current role view from store on page refresh
  useEffect(
    () => {
      localStore
        .getItem<keyof RoleViewInterface | null>(ROLE_VIEW_STORE_KEY)
        .then((value) => {
          if (
            value !== null &&
            account &&
            isRoleViewAvailable(account, value)
          ) {
            dispatch(setRoleView(value));
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!state.loaded) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <ThemeProvider theme={theme}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <Box width="100%">{routes}</Box>
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
