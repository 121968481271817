import { ChangeEvent, useState } from 'react';
import { Box, Button, FormControl, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DiplomaCategory } from '../domain/types';

type DiplomaCategoryFormProps = {
  diplomaCategory?: DiplomaCategory | null;
  onSubmit: (data: DiplomaCategory) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    maxWidth: 400,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  },
}));

const DiplomaCategoryForm = (props: DiplomaCategoryFormProps) => {
  const classes = useStyles();
  const { diplomaCategory: initialDiplomaCategory, onSubmit } = props;
  const [diplomaCategory, setDiplomaCategory] = useState<DiplomaCategory>(
    initialDiplomaCategory || {
      id: '',
      name: '',
      shortName: '',
      diplomaTypes: [],
    },
  );

  const handleTextFieldChange = (
    e: ChangeEvent<{ name: string; value: string }>,
  ) => {
    const { name, value } = e.target;
    setDiplomaCategory({ ...diplomaCategory, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(diplomaCategory);
  };

  return (
    <>
      <Box className={classes.formField}>
        <FormControl fullWidth margin="normal">
          <TextField
            name="name"
            label="Naam"
            onChange={handleTextFieldChange}
            value={diplomaCategory.name}
          />
        </FormControl>
      </Box>

      <Box mt={3}>
        <Button
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
        >
          Opslaan
        </Button>
      </Box>
    </>
  );
};

export default DiplomaCategoryForm;
