import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import UserImitator from '../domain/UserImitator';
import { User } from '../../../types';
import AppContext from '../../../AppContext';
import DataTableRowAction from '../../../components/DataTableRowAction';

interface UserImitateButtonProps {
  user: User;
}

const UserImitateButton = ({ user }: UserImitateButtonProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notifications = useSnackbar();

  const { localStore } = useContext(AppContext);

  const handleImitate = () => {
    new UserImitator(dispatch, localStore)
      .imitate(user.email)
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          `Kan de gebruiker "${user.fullNameLastNameFirst}" niet imiteren.`,
          { variant: 'error' },
        );
      });
  };

  return (
    <DataTableRowAction title="Imiteren" icon="ghost" onClick={handleImitate} />
  );
};

export default UserImitateButton;
