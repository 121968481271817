import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { ConflictingExam, Exam, ValidationViolation } from '../../../types';
import { formatDate } from '../../../utils/common';
import ExamLinesForm from '../forms/ExamLinesForm';
import ExamRepository, {
  ExamDiplomaDTO,
  ExamDTO,
} from '../repository/ExamRepository';
import { examToExamDTO } from '../utils';

interface UpdateExamLinesProps {
  exam: Exam;
}

const UpdateExamLines = (props: UpdateExamLinesProps) => {
  const { exam } = props;

  const history = useHistory();
  const notifications = useSnackbar();

  const [examDTO, setExamDTO] = useState<ExamDTO>(() => examToExamDTO(exam));

  const [violations, setViolations] = useState<ValidationViolation[]>([]);
  const [conflictingExam, setConflictingExam] =
    useState<ConflictingExam | null>(null);

  const examRepository = useMemo(() => new ExamRepository(false), []);

  const handleSubmit = () => {
    setViolations([]);
    setConflictingExam(null);

    // merge the exam date with the begin and end time of the exam lines, before sending the data to the server
    examDTO.diplomas = examDTO.diplomas.map(
      (examLine: ExamDiplomaDTO): ExamDiplomaDTO => {
        const beginTime =
          examDTO.date && examLine.beginTime
            ? new Date(
                examDTO.date.setHours(
                  examLine.beginTime.getHours(),
                  examLine.beginTime.getMinutes(),
                  0,
                  0,
                ),
              )
            : null;
        const endTime =
          examDTO.date && examLine.endTime
            ? new Date(
                examDTO.date.setHours(
                  examLine.endTime.getHours(),
                  examLine.endTime.getMinutes(),
                  0,
                  0,
                ),
              )
            : null;

        return {
          ...examLine,
          beginTime,
          endTime,
        };
      },
    );

    examRepository
      .updateExamLines(exam.id, examDTO)
      .then(() => {
        notifications.enqueueSnackbar(
          'De diplomaregels zijn succesvol opgeslagen',
          {
            variant: 'success',
          },
        );
        history.go(0);
      })
      .catch((err) => {
        const { data } = err.response;

        // Conflicting exam response.
        if ('error' in data && data.error === 'conflicting_exam') {
          setConflictingExam({
            beginTime: data.beginTime,
            endTime: data.endTime,
          });
        }

        // Validation failed response.
        if ('violations' in data) {
          setViolations(data.violations);
        }
      });
  };

  return (
    <>
      <ExamLinesForm
        exam={examDTO}
        setExam={setExamDTO}
        violations={violations}
      />
      <Box>
        {violations.length > 0 && (
          <Box mb={2} mt={2}>
            <Alert severity="error">
              Een aantal velden zijn niet correct ingevuld.
            </Alert>
          </Box>
        )}
      </Box>
      <Box>
        {conflictingExam && exam.date && (
          <Box mb={2} mt={2}>
            <Alert severity="error">
              {`Er is al een examen geboekt op ${formatDate(
                new Date(exam.date),
              )} van ${conflictingExam.beginTime} tot ${
                conflictingExam.endTime
              }`}
            </Alert>
          </Box>
        )}
      </Box>
      <Box mt={3}>
        <Button
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
        >
          Opslaan
        </Button>
      </Box>
    </>
  );
};

export default UpdateExamLines;
