import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import FormSection from '../../../components/form/FormSection';
import {
  MinimalLessonLocation,
  SwimmingLessonProviderWithLessonLocations,
} from '../../../types';
import { ExamDTO } from '../repository/ExamRepository';
import { Display } from '../views/CreateOrUpdateExam';
import SwimmingLessonProviderSelect from '../../admin/forms/SwimmingLessonProviderSelect';
import AppContext from '../../../AppContext';

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingBottom: theme.spacing(1),
    },
  },
  clearButton: {
    marginLeft: theme.spacing(2),
  },
}));

interface SwimmingLessonProviderFormProps {
  swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations | null;
  setSwimmingLessonProvider: Dispatch<
    SetStateAction<SwimmingLessonProviderWithLessonLocations | null>
  >;
  exam: ExamDTO;
  setExam: Dispatch<SetStateAction<ExamDTO>>;
  isNew: boolean;
  display: Display;
  setDisplay: Dispatch<SetStateAction<Display>>;
}

const SwimmingLessonProviderForm = (props: SwimmingLessonProviderFormProps) => {
  const {
    swimmingLessonProvider,
    setSwimmingLessonProvider,
    exam,
    setExam,
    isNew,
    display,
    setDisplay,
  } = props;

  const { roleViewManager } = useContext(AppContext);

  const examOnload = useRef<ExamDTO>(exam);

  const [selectedLessonLocations, setSelectedLessonLocations] = useState<
    MinimalLessonLocation[]
  >([]);

  const classes = useStyles();

  const handleSelect = (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations,
  ) => {
    setSwimmingLessonProvider(swimmingLessonProvider);
  };

  const handleClear = () => {
    setSwimmingLessonProvider(null);
  };

  const handleLessonLocationsChange = (
    _event: any,
    newValue: MinimalLessonLocation[],
  ) => {
    setSelectedLessonLocations(newValue);
  };

  useEffect(() => {
    if (!isNew) {
      return;
    }

    setExam((prev) => ({
      ...prev,
      swimmingLessonProviderId:
        swimmingLessonProvider !== null ? swimmingLessonProvider.id : '',
      examLocationId: '',
      lessonLocationIds: [],
      examinerId: '',
      diplomas: prev.diplomas.map((examLine) => ({
        ...examLine,
        diplomaTypeId: '',
        examinerId: '',
      })),
    }));
  }, [setExam, swimmingLessonProvider, isNew]);

  useEffect(() => {
    if (!isNew) {
      return;
    }

    setExam((prev) => ({
      ...prev,
      lessonLocationIds: selectedLessonLocations.map((l) => l.id),
      examinerId: '',
      diplomas: prev.diplomas.map((examLine) => ({
        ...examLine,
        diplomaTypeId: '',
        examinerId: '',
      })),
    }));
  }, [setExam, selectedLessonLocations, isNew]);

  useEffect(
    () => {
      if (!swimmingLessonProvider) {
        return;
      }

      if (swimmingLessonProvider.lessonLocations) {
        setSelectedLessonLocations(
          swimmingLessonProvider.lessonLocations.filter((location) =>
            examOnload.current.lessonLocationIds.includes(location.id),
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swimmingLessonProvider, examOnload.current],
  );

  return (
    <>
      <FormSection title="Zwemlesaanbieder">
        <Box>
          {swimmingLessonProvider ? (
            <Box>
              {swimmingLessonProvider.name}
              {roleViewManager.isAdminView() && isNew && (
                <Button
                  className={classes.clearButton}
                  onClick={handleClear}
                  variant="contained"
                  color="secondary"
                  startIcon={
                    <FontAwesomeIcon icon={['fal', 'long-arrow-left']} />
                  }
                >
                  Bewerken
                </Button>
              )}
            </Box>
          ) : (
            <SwimmingLessonProviderSelect onSelect={handleSelect} />
          )}
          {swimmingLessonProvider && !swimmingLessonProvider.centralExams && (
            <Box className={classes.formField}>
              <FormControl fullWidth>
                <FormLabel id="exam-locations-select-label">
                  Deelnemende leslocatie(s)
                </FormLabel>
                <Box mt={2}>
                  <Autocomplete
                    multiple
                    className={classes.autocomplete}
                    onChange={handleLessonLocationsChange}
                    value={selectedLessonLocations}
                    options={swimmingLessonProvider.lessonLocations}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...params}
                        fullWidth
                        placeholder="Selecteer leslocaties..."
                      />
                    )}
                  />
                </Box>
              </FormControl>
            </Box>
          )}
        </Box>
        {display === 'minimal' && selectedLessonLocations.length >= 1 && (
          <Box mt={3}>
            <Button
              onClick={() => setDisplay('full')}
              size="large"
              variant="contained"
              color="primary"
            >
              Volgende stap
            </Button>
          </Box>
        )}
      </FormSection>
    </>
  );
};

export default SwimmingLessonProviderForm;
