import { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { DiplomaCategory, DiplomaType, DiplomaTypeDTO } from '../domain/types';
import DiplomaCategoryRepository from '../repository/DiplomaCategoryRepository';

type DiplomaTypeFormProps = {
  diplomaType?: DiplomaType | null;
  categoryId?: string;
  onSubmit: (data: DiplomaTypeDTO) => void;
};

const useStyles = makeStyles(() => ({
  formField: {
    maxWidth: 400,
  },
}));

const DiplomaTypeForm = (props: DiplomaTypeFormProps) => {
  const classes = useStyles();
  const {
    diplomaType: initialDiplomaType,
    categoryId: initialCategoryId,
    onSubmit,
  } = props;
  const notifications = useSnackbar();
  const diplomaCategoryRepository = new DiplomaCategoryRepository();
  const [diplomaType, setDiplomaType] = useState<DiplomaTypeDTO>({
    id: initialDiplomaType?.id || '',
    name: initialDiplomaType?.name || '',
    shortName: initialDiplomaType?.shortName || '',
    diplomaCategoryId:
      initialDiplomaType?.diplomaCategory.id || initialCategoryId || '',
  });

  const [diplomaCategoryOptions, setDiplomaCategoryOptions] = useState<
    DiplomaCategory[]
  >([]);

  useEffect(
    () => {
      diplomaCategoryRepository
        .getAll()
        .then((response) => {
          setDiplomaCategoryOptions(response.data.items);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van de gegevens.',
            { variant: 'error' },
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleTextFieldChange = (
    e: ChangeEvent<{ name: string; value: string }>,
  ) => {
    const { name, value } = e.target;
    setDiplomaType({ ...diplomaType, [name]: value });
  };

  const handleSelectChange = (
    e: ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setDiplomaType({
      ...diplomaType,
      [e.target.name as string]: e.target.value,
    });
  };

  const handleSubmit = () => {
    onSubmit(diplomaType);
  };

  return (
    <>
      <Box className={classes.formField}>
        <FormControl fullWidth margin="normal">
          <TextField
            name="name"
            label="Naam"
            onChange={handleTextFieldChange}
            value={diplomaType.name}
            required
          />
        </FormControl>
      </Box>

      <Box className={classes.formField}>
        <FormControl fullWidth margin="normal">
          <TextField
            name="shortName"
            label="Afkorting (max. 3 tekens)"
            onChange={handleTextFieldChange}
            value={diplomaType.shortName}
            required
          />
        </FormControl>
      </Box>

      <Box className={classes.formField}>
        <FormControl fullWidth margin="normal">
          <FormLabel>Categorie</FormLabel>
          <Select
            name="diplomaCategoryId"
            value={diplomaType.diplomaCategoryId}
            onChange={handleSelectChange}
            required
          >
            <MenuItem value="" />
            {diplomaCategoryOptions.map((diplomaCategory) => (
              <MenuItem key={diplomaCategory.id} value={diplomaCategory.id}>
                {diplomaCategory.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mt={3}>
        <Button
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
        >
          Opslaan
        </Button>
      </Box>
    </>
  );
};

export default DiplomaTypeForm;
