import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import BackButton from '../../../components/BackButton';
import ButtonRow from '../../../components/ButtonRow';
import InlineButton from '../../../components/InlineButton';
import InlineLink from '../../../components/InlineLink';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import BooleanOutput from '../../../components/form/BooleanOutput';
import DateOutput from '../../../components/form/DateOutput';
import TextOutput from '../../../components/form/TextOutput';
import FormSection from '../../../components/form/FormSection';
import {
  SwimmingLessonProviderWithLessonLocations,
  User,
} from '../../../types';
import useUserTabs from '../hooks/user-tabs';
import UserRepository from '../repository/UserRepository';
import SLPDialog from '../../swimming-lesson-provider/components/SLPDialog';
import { ACCOUNT_BASE_ROUTE, USER_BASE_ROUTE } from '../index';
import Roles, { RoleInterface } from '../domain/Roles';

interface ViewUserProps {
  id?: string;
  isProfile: boolean;
}

const ViewUser = (props: ViewUserProps) => {
  const { id: passedId, isProfile } = props;
  const { id: paramId } = useParams<{ id?: string }>();
  const id = passedId || paramId || '';

  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );
  const isAdmin = account.roles.includes('ROLE_ADMIN');
  const isSuperAdmin = account.roles.includes('ROLE_SUPER_ADMIN');
  const isExaminer = account.roles.includes('ROLE_EXAMINER');

  const [user, setUser] = useState<User | null>(null);

  const baseRoute = isProfile ? ACCOUNT_BASE_ROUTE : `${USER_BASE_ROUTE}/${id}`;

  const tabs = useUserTabs(user, baseRoute);

  const roles = user?.explicitRoles
    ? user.explicitRoles
        .map((role: keyof RoleInterface) => Roles[role])
        .join(', ')
    : null;

  const isSLPUser = user?.explicitRoles?.includes(
    'ROLE_SWIMMING_LESSON_PROVIDER_USER',
  );
  const isSLPManager = user?.explicitRoles?.includes(
    'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER',
  );

  const userRepository = new UserRepository();

  const loadUser = () => {
    userRepository.find(id).then((response) => setUser(response.data));
  };

  const demoteManagerToUser = () => {
    userRepository.demoteManagerToUser(id).then(loadUser);
  };

  const promoteUserToManager = () => {
    userRepository.promoteUserToManager(id).then(loadUser);
  };

  const [SLPDialogOpen, setSLPDialogOpen] = useState<boolean>(false);

  const openSLPDialog = () => {
    setSLPDialogOpen(true);
  };

  const closeSLPDialog = () => {
    setSLPDialogOpen(false);
  };

  const linkToSwimmingLessonProvider = (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations,
  ) => {
    userRepository
      .linkToSwimmingLessonProvider(id, swimmingLessonProvider.id)
      .then(loadUser);

    closeSLPDialog();
  };

  const unlinkFromSwimmingLessonProvider = () => {
    userRepository.unlinkFromSwimmingLessonProvider(id).then(loadUser);
  };

  useEffect(
    () => {
      loadUser();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const title = isProfile ? (
    'Profiel bekijken'
  ) : (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1">Gebruiker bekijken</Typography>
    </Box>
  );

  if (!user) {
    return <Loader />;
  }

  return (
    <Page title={title}>
      <FormSection title="Gegevens">
        {(isAdmin || isExaminer) && (
          <TextOutput
            label={isExaminer ? 'Examinatorcode' : 'Afas ID'}
            value={user.afasId}
            hideIfEmpty
          />
        )}
        <TextOutput label="Naam" value={user.fullName} />
        <TextOutput label="E-mail" value={user.email} />
        <TextOutput
          label="Zwemlesaanbieder"
          value={user.swimmingLessonProvider?.name || ''}
          hideIfEmpty
        />
        <TextOutput
          label="Telefoon (privé)"
          value={user.telephonePrivate}
          hideIfEmpty
        />
        <TextOutput
          label="Telefoon (mobiel)"
          value={user.telephonePrivateMobile}
          hideIfEmpty
        />
        <TextOutput
          label="Telefoon (werk)"
          value={user.telephoneWork}
          hideIfEmpty
        />
        <TextOutput label="Rollen" value={roles} hideIfEmpty />
      </FormSection>
      {user.delegateProfile && (
        <FormSection title="Gedelegeerdenprofiel">
          <TextOutput
            label="Status"
            value={user.delegateProfile.statusReadable}
          />
          <DateOutput label="Sinds" value={user.delegateProfile.startDate} />
          <BooleanOutput
            label="Licentie voor ABC"
            value={user.delegateProfile.licenseSwimAbc}
          />
          <BooleanOutput
            label="Licentie voor snorkelen"
            value={user.delegateProfile.licenseSnorkeling}
          />
          <BooleanOutput
            label="Licentie voor survival"
            value={user.delegateProfile.licenseSurvival}
          />
          <TextOutput
            label="IBAN"
            value={user.delegateProfile.iban}
            hideIfEmpty
          />
          <BooleanOutput
            label="Mag declareren"
            value={user.delegateProfile.mayInvoice}
          />
          <TextOutput
            label="Maximale afstand tot examens in km"
            value={user.delegateProfile.maxDistanceExamsInKm ?? 'N.B.'}
          />
          <BooleanOutput
            label="Inzetbaar"
            value={user.delegateProfile.available}
          />
        </FormSection>
      )}
      <ButtonRow>
        <InlineLink type="primary" to={`${baseRoute}/bewerken`}>
          Bewerken
        </InlineLink>
        <InlineLink type="primary" to={`${baseRoute}/wachtwoord`}>
          Wachtwoord veranderen
        </InlineLink>
        {isSuperAdmin && (
          <>
            {isSLPUser && (
              <InlineButton type="default" onClick={promoteUserToManager}>
                Geef managerrol
              </InlineButton>
            )}
            {isSLPManager && (
              <InlineButton type="default" onClick={demoteManagerToUser}>
                Neem managerrol af
              </InlineButton>
            )}
            {isSLPManager || isSLPUser ? (
              <InlineButton
                type="default"
                onClick={unlinkFromSwimmingLessonProvider}
              >
                Loskoppelen van zwemlesaanbieder
              </InlineButton>
            ) : (
              <>
                <SLPDialog
                  isOpen={SLPDialogOpen}
                  onClose={closeSLPDialog}
                  onConfirm={linkToSwimmingLessonProvider}
                />
                <InlineButton type="default" onClick={openSLPDialog}>
                  Koppelen aan zwemlesaanbieder
                </InlineButton>
              </>
            )}
          </>
        )}
      </ButtonRow>
      {tabs}
    </Page>
  );
};

export default ViewUser;
