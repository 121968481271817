import { AxiosResponse } from 'axios';
import {
  ExamReportEntry,
  ExamReportEntryDTO,
  ExamReportEntryValuation,
} from '../domain/types';
import ApiClient from '../../../api/ApiClient';
import { ValidationFailedResponse } from '../../../types';

class ExamReportEntryRepository {
  create(
    examId: string,
    entry: ExamReportEntryDTO,
  ): Promise<AxiosResponse<ExamReportEntry | ValidationFailedResponse>> {
    return ApiClient.post(`/api/v1/exams/${examId}/reports`, entry);
  }
  valuate(
    examId: string,
    entryId: string,
    valuation: keyof typeof ExamReportEntryValuation,
  ): Promise<AxiosResponse<ExamReportEntry | ValidationFailedResponse>> {
    return ApiClient.put(`/api/v1/exams/${examId}/reports/${entryId}/valuate`, {
      valuation,
    });
  }
}

export default ExamReportEntryRepository;
