import { User } from '../types';
import { RoleViewInterface } from '../RoleViewManager';

export type UserState = {
  isPartiallyLoggedIn: boolean;
  isLoggedIn: boolean;
  isImitating: boolean;
  account: User | null;
  roleView: keyof RoleViewInterface | null;
};

const defaultState: UserState = {
  isPartiallyLoggedIn: false,
  isLoggedIn: false,
  isImitating: false,
  account: null,
  roleView: null,
};

const user = (
  state = defaultState,
  action: { type: string; payload?: unknown },
) => {
  switch (action.type) {
    case 'PARTIALLY_LOGIN_USER':
      return {
        ...state,
        isPartiallyLoggedIn: true,
        isLoggedIn: false,
      };
    case 'LOGIN_USER':
      return {
        ...state,
        isPartiallyLoggedIn: true,
        isLoggedIn: true,
      };
    case 'LOGOUT_USER':
      return {
        ...state,
        isPartiallyLoggedIn: false,
        isLoggedIn: false,
        account: null,
        roleView: null,
      };
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
        isImitating:
          typeof action.payload == 'object' &&
          action.payload !== null &&
          'isImitating' in action.payload &&
          action.payload.isImitating,
      };
    case 'SET_ROLE_VIEW':
      return {
        ...state,
        roleView: action.payload,
      };
    case 'SET_IMITATING':
      return {
        ...state,
        isImitating: action.payload,
      };
    default:
      return state;
  }
};

export default user;
