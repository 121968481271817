import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Operator, SwimmingLessonProvider, User } from '../../../types';

import OperatorAddSwimmingLessonProvider from './OperatorAddSwimmingLessonProvider';
import { canCreate, canRead, canDelete } from '../hooks/useOperatorPermissions';

import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import OperatorRepository from '../repository/OperatorRepository';
import { Column } from '../../../components/DataTable';
import { formatAddress } from '../../../utils/common';

interface OperatorSwimmingLessonProviderListProps {
  operator: Operator;
}

const OperatorSwimmingLessonProviderList = (
  props: OperatorSwimmingLessonProviderListProps,
) => {
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );
  const { operator } = props;

  const [swimmingLessonProviders, setSwimmingLessonProviders] = useState<
    SwimmingLessonProvider[] | null
  >(null);

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        swimmingLessonProvider.name,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        swimmingLessonProvider.email,
    },
    {
      name: 'Telefoon',
      field: 'telephone',
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        swimmingLessonProvider.phoneNumber,
    },
    {
      name: 'Adres',
      field: 'visitingAddress',
      render: (swimmingLessonProvider: SwimmingLessonProvider) =>
        formatAddress(swimmingLessonProvider.visitingAddress || null),
    },
  ];

  const loadSwimmingLessonProviders = useCallback(() => {
    new OperatorRepository()
      .getSwimmingLessonProviders(operator.id)
      .then((response) => setSwimmingLessonProviders(response.data));
  }, [operator.id]);

  const handleDelete = (id: string) => {
    new OperatorRepository()
      .removeSwimmingLessonProvider(operator.id, id)
      .then(() => loadSwimmingLessonProviders());
  };

  useEffect(loadSwimmingLessonProviders, [loadSwimmingLessonProviders]);

  if (!swimmingLessonProviders) {
    return <Loader inline />;
  }

  return (
    <>
      {canRead({ operator, account }) && (
        <SimpleTable
          items={swimmingLessonProviders}
          columns={columns}
          canDeleteItems={canDelete({ operator, account })}
          onItemDelete={handleDelete}
        />
      )}
      {canCreate({ account }) && (
        <OperatorAddSwimmingLessonProvider
          operator={operator}
          onSuccess={loadSwimmingLessonProviders}
        />
      )}
    </>
  );
};

export default OperatorSwimmingLessonProviderList;
