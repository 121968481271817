import { AxiosResponse } from 'axios';
import { ConfigValue } from '../exams/domain/types';
import ApiClient from '../../api/ApiClient';

class ConfigRepository {
  getConfig(): Promise<AxiosResponse<{ [key: string]: ConfigValue }>> {
    return ApiClient.get('/api/v1/config');
  }

  saveChangeSet(changeSet: {
    [key: string]: string | null;
  }): Promise<AxiosResponse> {
    return ApiClient.post('/api/v1/config', { changeSet });
  }
}

export default ConfigRepository;
