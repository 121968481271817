import { Link } from 'react-router-dom';

import DataTable from '../../../components/DataTable';
import Page from '../../../components/Page';
import DiplomaCategoryRepository from '../repository/DiplomaCategoryRepository';
import { DiplomaCategory } from '../domain/types';
import { DIPLOMA_CATEGORY_OVERVIEW_ROUTE } from '../index';

const DiplomaCategoryOverview = () => {
  const repository = new DiplomaCategoryRepository();

  const columns = [
    {
      name: 'Categorie',
      field: 'name',
      render: (category: DiplomaCategory) => (
        <Link to={`${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/${category.id}/bewerken`}>
          {category.name}
        </Link>
      ),
    },
    {
      name: 'Afkorting',
      field: 'shortName',
      render: (category: DiplomaCategory) => category.shortName,
    },
    {
      name: 'Diplomatypen',
      field: 'types',
      render: (category: DiplomaCategory) => (
        <Link
          to={`${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/${category.id}/diplomatypen`}
        >
          {`${category.diplomaTypes.length} diplomatype(n)`}
        </Link>
      ),
    },
  ];

  return (
    <Page title="Diplomacategorieën">
      <DataTable
        id="diploma-category-overview"
        repository={repository}
        columns={columns}
        deletable
        deleteItemMessage={(
          category: DiplomaCategory,
        ) => `Weet u zeker dat u categorie "${category.name}" 
          met alle daaronder vallende diplomatypen wilt verwijderen?`}
      />
    </Page>
  );
};

export default DiplomaCategoryOverview;
