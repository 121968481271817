import { Flow } from 'flowjs';
import { useEffect, useState } from 'react';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface FlowProgressBarProps {
  flow: Flow;
}

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number },
) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress
        color="primary"
        variant="determinate"
        value={props.value}
      />
    </Box>
    <Box minWidth={35}>
      <Typography variant="body2" color="textSecondary">
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box>
  </Box>
);

const FlowProgress = (props: FlowProgressBarProps) => {
  const { flow } = props;
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    flow.on('progress', () => setProgress(flow.progress()));
  }, [flow]);

  if (progress === 0) {
    return <div />;
  }

  return (
    <Box mt={2} mb={2}>
      <Box mb={2}>
        <Alert severity={progress < 1 ? 'info' : 'success'}>
          {progress < 1 && 'De bestand(en) worden geupload...'}
          {progress === 1 && 'De bestand(en) zijn succesvol geupload.'}
        </Alert>
      </Box>
      <LinearProgressWithLabel value={progress * 100} />
    </Box>
  );
};

export default FlowProgress;
