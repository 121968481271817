import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { Declaration, DeclarationDto, Exam, User } from '../../../types';
import DeclarationForm from '../../declarations/views/DeclarationForm';
import { ExamReportEntry } from '../domain/types';
import ExamRepository from '../repository/ExamRepository';
import {
  COLUMN_DECLARATION_DELEGATE,
  COLUMN_DECLARATION_STATUS,
  COLUMN_DECLARATION_TOTAL_COMPENSATION,
  DeclarationColumn,
} from '../../declarations/domain/columns';

interface ExamDeclarationListProps {
  exam: Exam;
}

const ExamDeclarationList = (props: ExamDeclarationListProps) => {
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );
  const isAdmin = account.roles.includes('ROLE_SUPER_ADMIN');

  const [declarations, setDeclarations] = useState<Declaration[]>([]);
  const [delegateReport, setDelegateReport] = useState<ExamReportEntry | null>(
    null,
  );

  const [declarationsLoaded, setDeclarationsLoaded] = useState<boolean>(false);
  const [delegateReportLoaded, setDelegateReportLoaded] =
    useState<boolean>(false);

  const loaded = declarationsLoaded && delegateReportLoaded;

  const columns: DeclarationColumn[] = [
    COLUMN_DECLARATION_DELEGATE,
    COLUMN_DECLARATION_TOTAL_COMPENSATION,
    COLUMN_DECLARATION_STATUS,
  ];

  const loadDeclarations = useCallback(() => {
    new ExamRepository()
      .getDeclarations(props.exam.id)
      .then((response) => setDeclarations(response.data))
      .finally(() => {
        setDeclarationsLoaded(true);
      });
  }, [props.exam.id, setDeclarations, setDeclarationsLoaded]);

  const loadDelegateReport = useCallback(() => {
    new ExamRepository()
      .getDelegateReport(props.exam.id)
      .then((response) => setDelegateReport(response.data))
      .finally(() => {
        setDelegateReportLoaded(true);
      });
  }, [props.exam.id, setDelegateReport, setDelegateReportLoaded]);

  const onSubmitHandler = (declaration: DeclarationDto) => {
    new ExamRepository()
      .createDeclaration(props.exam.id, declaration)
      .then(() => {
        setDeclarationsLoaded(false);
      });
  };

  useEffect(() => {
    if (declarationsLoaded) {
      return;
    }

    loadDeclarations();
  }, [declarationsLoaded, loadDeclarations]);

  useEffect(() => {
    if (delegateReportLoaded) {
      return;
    }

    loadDelegateReport();
  }, [delegateReportLoaded, loadDelegateReport]);

  if (!loaded) {
    return <Loader />;
  }

  if (isAdmin) {
    return <SimpleTable items={declarations} columns={columns} />;
  }

  return (
    <>
      {declarations.length >= 1 ? (
        <SimpleTable items={declarations} columns={columns} />
      ) : (
        <>
          {delegateReport && delegateReport.creator.id === account.id ? (
            <DeclarationForm
              examId={props.exam.id}
              onSubmit={onSubmitHandler}
            />
          ) : (
            <Box>
              Het is alleen mogelijk om een declaratie aan te maken als je het
              gedelegeerdenrapport hebt ingevuld.
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ExamDeclarationList;
