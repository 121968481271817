import { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import DelegateRepository from '../../delegates/repository/DelegateRepository';
import ExamRepository from '../repository/ExamRepository';
import { Delegate, Exam } from '../../../types';
import Loader from '../../../components/Loader';
import {
  METHOD_ASSIGN_DIRECTLY,
  METHOD_INVITE_MANUALLY,
} from './ExamDelegatesForm';

type Props = {
  examId: string;
  method: string;
  onBack: () => void;
  onClose: () => void;
};

const ExamDelegatesAssign = (props: Props) => {
  const { examId, method, onBack, onClose } = props;

  const notifications = useSnackbar();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [selectedDelegates, setSelectedDelegates] = useState<Delegate[]>([]);
  const [delegateOptions, setDelegateOptions] = useState<Delegate[]>([]);

  const repository = new ExamRepository();
  const delegateRepository = new DelegateRepository();

  let title = '';
  let submitCaption = '';
  let successMessage = '';
  let errorMessage = '';
  switch (method) {
    case METHOD_ASSIGN_DIRECTLY: {
      title = 'Wijs direct een gedelegeerde toe';
      submitCaption = 'Toewijzen';
      successMessage = 'De gedelegeerden zijn succesvol toegewezen!';
      errorMessage = 'Fout bij het toewijzen van de gedelegeerden.';
      break;
    }
    case METHOD_INVITE_MANUALLY: {
      title = 'Nodig handmatig gedelegeerde(n) uit';
      submitCaption = 'Uitnodigen';
      successMessage = 'De gedelegeerden zijn succesvol uitgenodigd!';
      errorMessage = 'Fout bij het uitnodigen van de gedelegeerden.';
      break;
    }
    default: {
      break;
    }
  }

  const handleDelegatesChange = (_event: any, newValue: Delegate[]) => {
    setSelectedDelegates(newValue);
  };

  const handleSubmit = () => {
    let promise: Promise<AxiosResponse<Exam>>;
    const selectedDelegateIds = selectedDelegates.map(({ id }) => id);

    switch (method) {
      case METHOD_ASSIGN_DIRECTLY: {
        promise = repository.assignExamDelegates(examId, selectedDelegateIds);
        break;
      }
      case METHOD_INVITE_MANUALLY: {
        promise = repository.inviteExamDelegates(examId, selectedDelegateIds);
        break;
      }
      default: {
        return;
      }
    }

    promise
      .then(() => {
        notifications.enqueueSnackbar(successMessage, { variant: 'success' });
        onClose();
      })
      .catch(() => {
        notifications.enqueueSnackbar(errorMessage, { variant: 'error' });
      });
  };

  const fetchDelegateOptions = async () => {
    try {
      const response = await delegateRepository.fetchAll();

      setDelegateOptions(response.data.items);
      setLoaded(true);
    } catch (e) {
      notifications.enqueueSnackbar('Fout bij het ophalen van de gegevens.', {
        variant: 'error',
      });
    }
  };

  const getOptionLabel = (option: Delegate) => {
    return option.delegateProfile.status !== 'delegate'
      ? `${option.fullName} (${option.delegateProfile.statusReadable})`
      : option.fullName;
  };

  useEffect(
    () => {
      fetchDelegateOptions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!loaded) {
    return <Loader inline />;
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <Autocomplete
            multiple
            onChange={handleDelegatesChange}
            value={selectedDelegates}
            options={delegateOptions}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <TextField
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...params}
                variant="outlined"
                placeholder="Selecteer gedelegeerde..."
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>Terug</Button>

        <Button onClick={handleSubmit}>{submitCaption}</Button>
      </DialogActions>
    </>
  );
};

export default ExamDelegatesAssign;
