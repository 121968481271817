import { NavLink } from 'react-router-dom';

import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Tab } from '../routing/Router';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    opacity: '50%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  active: {
    opacity: '100%',
  },
  tab: {
    display: 'inline',
    fontSize: 18,
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(2),
    padding: `0px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: '#d3deee',
    },
  },
}));

interface PageTabProps {
  tab: Tab;
}

const PageTab = (props: PageTabProps) => {
  const { tab } = props;

  const classes = useStyles();

  return (
    <NavLink
      className={classes.link}
      activeClassName={classes.active}
      to={tab.url}
    >
      <Typography className={classes.tab} component="div" variant="h2">
        {tab.label}
      </Typography>
    </NavLink>
  );
};

export default PageTab;
