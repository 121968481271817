import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { FileRejection, useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@material-ui/lab';
import FileList from './FileList';

interface FileUploadProps {
  onChange: (files: File[]) => void;
  multiple?: boolean;
  invalidFiles?: File[];
  documents?: boolean;
  audio?: boolean;
  video?: boolean;
  images?: boolean;
  json?: boolean;
  disabled?: boolean;
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    minWidth: 495,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: 24,
  },
}));

const FileUpload = (props: FileUploadProps) => {
  const classes = useStyles();
  const { onChange, invalidFiles, disabled } = props;
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const multiple = props.multiple !== undefined ? props.multiple : false;

  const handleRemoveFile = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setRejectedFiles([]);
      setFiles([
        ...files,
        ...acceptedFiles.filter((file) => {
          for (let i = 0; i < files.length; ++i) {
            if (files[i].name === file.name && files[i].size === file.size) {
              return false;
            }
          }

          return true;
        }),
      ]);
    },
    [files],
  );

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    setRejectedFiles(fileRejections);
  }, []);

  let accept: string[] = [];

  if (props.documents) {
    accept = accept.concat([
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/pdf',
      'application/x-pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/zip',
      'application/octet-stream',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
      'text/plain',
    ]);
  }

  if (props.images) {
    accept = accept.concat(['image/jpeg', 'image/png', 'image/bmp']);
  }

  if (props.audio) {
    accept = accept.concat([
      'audio/mpeg',
      'audio/vnd.wav',
      'audio/x-wav',
      'audio/webm',
      'audio/m4a',
    ]);
  }

  if (props.video) {
    accept = accept.concat([
      'video/mp4',
      'video/quicktime',
      'video/x-ms-wmv',
      'video/x-msvideo',
      'video/webm',
    ]);
  }

  if (props.json) {
    accept.push('application/json');
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  useEffect(
    () => onChange(files),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files],
  );

  return (
    <>
      {rejectedFiles.length > 0 && (
        <Alert severity="warning" className={classes.alert}>
          Onderstaande bestanden worden niet ondersteund:
          <List dense>
            {rejectedFiles.map((rejectedFile) => (
              <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={['fal', 'file']} />
                </ListItemIcon>
                <ListItemText>{rejectedFile.file.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Alert>
      )}

      <Paper
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps({ style, multiple })}
        className={classes.dropZone}
      >
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          !disabled && <input {...getInputProps()} />
        }
        {isDragActive && !isDragReject && (
          <Typography variant="body1">Sleep bestand hier naar toe</Typography>
        )}

        {isDragReject && (
          <Typography variant="body1">
            Dit type bestand wordt niet ondersteund.
          </Typography>
        )}

        {!isDragAccept && !isDragReject && (
          <Typography variant="body1">
            Sleep je bestanden hier, of klik om te selecteren.
          </Typography>
        )}
      </Paper>

      <FileList
        files={files}
        onRemove={handleRemoveFile}
        invalidFiles={invalidFiles}
      />
    </>
  );
};

export default FileUpload;
