import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { SwimmingLessonProviderDTO } from '../../../types';
import Page from '../../../components/Page';
import SwimmingLessonProviderForm from '../forms/SwimmingLessonProviderForm';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';
import { swimmingLessonProviderDTOFromResponse } from '../utils';
import { SWIMMING_LESSON_PROVIDER_BASE_ROUTE } from '../index';
import Loader from '../../../components/Loader';

declare type Props = {
  id?: string;
  isOrganization?: boolean;
};

const SwimmingLessonProviderDetail = (props: Props) => {
  const { id: passedId, isOrganization = false } = props;
  const { id: paramId } = useParams<{ id?: string }>();
  const id = passedId || paramId || '';

  const history = useHistory();

  const [swimmingLessonProviderDTO, setSwimmingLessonProviderDTO] =
    useState<SwimmingLessonProviderDTO | null>(null);

  const repository = new SwimmingLessonProviderRepository();

  useEffect(
    () => {
      repository
        .find(id)
        .then((response) =>
          setSwimmingLessonProviderDTO(
            swimmingLessonProviderDTOFromResponse(response),
          ),
        )
        .catch(() => history.push(SWIMMING_LESSON_PROVIDER_BASE_ROUTE));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!swimmingLessonProviderDTO) {
    return <Loader />;
  }

  return (
    <Page
      title={
        isOrganization
          ? 'Mijn organisatie bewerken'
          : 'Zwemlesaanbieder bewerken'
      }
      breadcrumbs
    >
      <SwimmingLessonProviderForm
        swimmingLessonProviderDTO={swimmingLessonProviderDTO}
      />
    </Page>
  );
};

export default SwimmingLessonProviderDetail;
