import { User } from '../types';
import { RoleViewInterface } from '../RoleViewManager';

export const partiallyLoginUser = () => ({
  type: 'PARTIALLY_LOGIN_USER',
});

export const loginUser = () => ({
  type: 'LOGIN_USER',
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
});

export const setAccount = (user: User) => ({
  type: 'SET_ACCOUNT',
  payload: user,
});

export const setRoleView = (roleView: keyof RoleViewInterface | null) => ({
  type: 'SET_ROLE_VIEW',
  payload: roleView,
});

export const setImitating = (status: boolean) => ({
  type: 'SET_IMITATING',
  payload: status,
});
