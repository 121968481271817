import { ReactNode } from 'react';

import { DiplomaCategory } from '../../diploma/domain/types';
import { Column } from '../../../components/DataTable';
import RoleViewManager from '../../../RoleViewManager';
import { Exam, File, MinimalUser } from '../../../types';

export interface ExamColumn extends Column {
  render(exam: Exam, roleViewManager?: RoleViewManager): ReactNode;
}

export type ExamReportType = 'delegate' | 'examiner' | 'aspirant';

export interface ExamReport {
  id: string;
  title: string;
  type: ExamReportType;
  description: string;
  fields: ExamReportField[];
  createdAt: string;
  updatedAt: string;
}

export interface ExamReportDTO {
  title: string;
  type: ExamReportType | null;
  description: string;
}

export type ExamReportFieldType = 'scale' | 'text' | 'bool' | 'contact';

export interface ExamReportField {
  id: string;
  label: string;
  description: string;
  type: ExamReportFieldType;
  typeLabel: string;
  explanation: boolean;
  required: boolean;
  diplomaCategories: DiplomaCategory[];
  sortOrder: number;
  createdAt: string;
  updatedAt: string;
}

export interface ExamReportFieldDTO {
  sortOrder: number;
  label: string;
  type: ExamReportFieldType;
  description: string;
  explanation: boolean;
  required: boolean;
  diplomaCategories: string[];
}

export interface ExamReportEntryField {
  id: string;
  field: ExamReportField;
  value: unknown;
  explanation?: string;
}

export enum ExamReportEntryValuation {
  sufficient = 'Voldoende',
  insufficient = 'Onvoldoende',
  attention = 'Extra aandacht',
  terminated = 'Stopt',
}

export interface ExamReportEntry {
  id: string;
  exam: Exam;
  examReport: ExamReport;
  creator: MinimalUser;
  fields: ExamReportEntryField[];
  legacyPdf?: File;
  valuation?: keyof typeof ExamReportEntryValuation;
  valuationReadable?: string;
}

export interface ExamReportEntryDTO {
  examReportId: string;
  fields: { fieldId: string; value: string; explanation?: string }[];
}

export interface ConfigValue {
  label: string;
  type: 'report';
  default: string | number | null;
  value?: string;
}
