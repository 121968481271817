import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Box,
  Checkbox,
  debounce,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import ConditionalWrapper from './ConditionalWrapper';
import { ApiFilterCriteria } from '../types';

export interface FilterColumnOption {
  value: string;
  label: string;
}

export interface FilterColumnOptionCallback {
  (): Promise<FilterColumnOption[]>;
}

export interface FilterColumnOptionSearchCallback {
  (query: string): Promise<FilterColumnOption[]>;
}

export interface FilterColumnConfig {
  // Settings for 'autocomplete' type.
  autoCompleteLabel?: string;
  autoCompletePlaceholder?: string;
}

interface FilterColumnProps {
  field: string;
  name: string;
  type: 'checkbox' | 'autocomplete' | 'datepicker';
  onChange: (options: FilterColumnOption[]) => void;
  criteria: ApiFilterCriteria;
  onDateChange?: (date: MaterialUiPickersDate) => void;
  options?: FilterColumnOption[] | FilterColumnOptionCallback;
  searchOptions?: FilterColumnOptionSearchCallback;
  config?: FilterColumnConfig;
  showLabel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    marginRight: theme.spacing(2),
  },
  name: {
    display: 'inline-block',
    marginRight: theme.spacing(0.5),
  },
  formGroup: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  optionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 450,
    width: 350,
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
  },
  autoComplete: {
    width: 250,
    margin: theme.spacing(2),
  },
}));

const FilterColumn = (props: FilterColumnProps) => {
  const {
    field,
    name,
    type,
    config,
    onChange,
    onDateChange,
    criteria,
    showLabel,
  } = props;
  const classes = useStyles();
  const [options, setOptions] = useState<FilterColumnOption[]>([]);
  const [filters, setFilters] = useState<{ [key: string]: boolean }>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [date, setDate] = useState<Moment | null>(null);
  const [currentCriteria, setCurrentCriteria] =
    useState<ApiFilterCriteria | null>(null);

  const selectedOptions = useMemo(
    () => options.filter((option) => filters[option.value] || false),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  const fetchOptions = async () => {
    if (props.options && typeof props.options === 'function') {
      const options = await props.options();
      setOptions(options);
    }

    if (props.searchOptions) {
      const newOptions = await props.searchOptions(inputValue);
      setOptions([...selectedOptions, ...newOptions]);
    }
  };

  const openMenu = async (target: HTMLDivElement) => {
    await fetchOptions();
    setAnchorEl(target);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    openMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setFilters({ ...filters, [name]: true });
    } else {
      delete filters[name];
      setFilters({ ...filters });
    }
  };

  const handleAutocompleteChange = (_event: any, options: any) => {
    const newFilters: {
      [key: string]: boolean;
    } = {};

    options.forEach((option: FilterColumnOption) => {
      newFilters[option.value] = true;
    });

    setFilters(newFilters);
  };

  const handleAutocompleteInputChange = debounce(
    (_event, newInputValue) => setInputValue(newInputValue),
    300,
  );

  const doDateChange = debounce((date: MaterialUiPickersDate) => {
    setDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  }, 800);

  const handleDateChange = (date: MaterialUiPickersDate) => doDateChange(date);

  const open = Boolean(anchorEl);

  useEffect(
    () => {
      if (!options) {
        return;
      }

      onChange(options.filter((option) => filters[option.value] || false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  useEffect(
    () => {
      fetchOptions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
  );

  /**
   * Handle initial load.
   */
  useEffect(
    () => {
      // If the criteria did not change, don't run update.
      if (
        criteria !== null &&
        JSON.stringify(criteria) === JSON.stringify(currentCriteria)
      ) {
        return;
      }

      if (props.options && typeof props.options !== 'function') {
        setOptions(props.options);
      }

      if (
        criteria.filters &&
        (props.options !== undefined || props.searchOptions !== undefined)
      ) {
        const fieldFilter = criteria.filters[field];

        if (
          (typeof fieldFilter === 'string' || Array.isArray(fieldFilter)) &&
          fieldFilter.length > 0
        ) {
          const newFilters: { [key: string]: boolean } = {};

          if (Array.isArray(fieldFilter)) {
            const currentOptionValues = options.map((o) => o.value);
            setOptions([
              ...options,
              ...fieldFilter.filter(
                (f) => !currentOptionValues.includes(f.value),
              ),
            ]);
            fieldFilter.forEach(({ value }) => {
              newFilters[value] = true;
            });
          } else {
            fieldFilter.split(',').forEach((id) => {
              newFilters[id] = true;
            });
          }

          setFilters(newFilters);
        } else {
          setFilters({});
        }
      }

      if (type === 'datepicker') {
        const filterValue = criteria.filters ? criteria.filters[field] : null;

        if (filterValue && typeof filterValue === 'string') {
          setDate(moment(filterValue));
        } else {
          setDate(null);
        }
      }

      setCurrentCriteria(criteria);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [criteria],
  );

  const hasFilters = useMemo(
    () => {
      if (type === 'datepicker') {
        return date !== null;
      }

      return Object.entries(filters).length > 0;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, date],
  );

  return (
    <>
      <Box className={classes.column} onClick={handleClick}>
        {showLabel && <div className={classes.name}>{name}</div>}
        <IconButton size="small">
          <ConditionalWrapper
            condition={hasFilters}
            wrapper={(children) => (
              <Badge color="secondary" variant="dot">
                {children}
              </Badge>
            )}
          >
            <FontAwesomeIcon icon={['fal', 'chevron-down']} />
          </ConditionalWrapper>
        </IconButton>
      </Box>
      <Popover
        id={open ? `filter-column-${name.toLowerCase()}` : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        elevation={1}
      >
        {options && type === 'checkbox' && (
          <FormGroup className={classes.formGroup}>
            <div className={classes.optionsContainer}>
              {options.map((option) => {
                const control = (
                  <Checkbox
                    checked={filters[option.value] || false}
                    onChange={handleCheckboxChange}
                    name={option.value}
                  />
                );

                return (
                  <FormControlLabel
                    control={control}
                    key={option.value}
                    label={option.label}
                  />
                );
              })}
            </div>
          </FormGroup>
        )}

        {type === 'autocomplete' && (
          <Autocomplete
            id={`filter-column-${field}`}
            getOptionLabel={(option) => option.label}
            options={options}
            onChange={handleAutocompleteChange}
            noOptionsText="Geen opties beschikbaar."
            value={selectedOptions}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                label={config?.autoCompleteLabel || ''}
                placeholder={config?.autoCompletePlaceholder || ''}
              />
            )}
            onInputChange={handleAutocompleteInputChange}
            className={classes.autoComplete}
            multiple
            autoComplete
          />
        )}

        {type === 'datepicker' && (
          <Box pl={2} pr={2}>
            <FormControl fullWidth margin="normal">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  format="DD-MM-YYYY"
                  orientation="landscape"
                  autoOk
                  margin="normal"
                  label="Datum"
                  value={date}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'Verander datum',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default FilterColumn;
