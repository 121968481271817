import { Box } from '@material-ui/core';

import InfoPopover from '../../../components/InfoPopover';
import { Exam } from '../../../types';

interface ExamInfoPopoverProps {
  exam: Exam;
}

const ExamInfoPopover = (props: ExamInfoPopoverProps) => {
  const { exam } = props;

  const lines: string[] = [];
  if (exam.menOnly) {
    lines.push('Alleen mannen');
  } else if (exam.womenOnly) {
    lines.push('Alleen vrouwen');
  }

  if (!lines.length) {
    return null;
  }

  const contents = lines.map((line: string) => <Box key={line}>{line}</Box>);

  return <InfoPopover contents={contents} />;
};

export default ExamInfoPopover;
