import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import DiplomaCategoryForm from '../forms/DiplomaCategoryForm';
import DiplomaCategoryRepository from '../repository/DiplomaCategoryRepository';
import { DiplomaCategory } from '../domain/types';
import { DIPLOMA_CATEGORY_OVERVIEW_ROUTE } from '../index';

const EditDiplomaCategory = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const notifications = useSnackbar();
  const repository = new DiplomaCategoryRepository();
  const [diplomaCategory, setDiplomaCategory] =
    useState<DiplomaCategory | null>(null);

  useEffect(
    () => {
      repository
        .get(id)
        .then(({ data }) => {
          setDiplomaCategory(data);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van de gegevens',
            { variant: 'error' },
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const submit = (data: DiplomaCategory) => {
    repository
      .update(data)
      .then(() => {
        history.push(DIPLOMA_CATEGORY_OVERVIEW_ROUTE);
        notifications.enqueueSnackbar('Succesvol bijgewerkt', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het bijwerken', {
          variant: 'error',
        });
      });
  };

  if (!diplomaCategory) {
    return <Loader />;
  }

  return (
    <Page title="Categorie bewerken">
      <DiplomaCategoryForm
        diplomaCategory={diplomaCategory}
        onSubmit={submit}
      />
    </Page>
  );
};

export default EditDiplomaCategory;
