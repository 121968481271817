import { Route, Router } from '../../routing';
import Dashboard from './views/Dashboard';
import ExamReportOverview from '../exams/views/ExamReportOverview';
import ReportForm from '../exams/forms/ReportForm';
import ExamReportFieldOverview from '../exams/views/ExamReportFieldOverview';
import ReportFieldForm from '../exams/forms/ReportFieldForm';
import Config from './views/Config';
import ManagementQueryOverview from '../management/views/ManagementQueryOverview';

export const EXAM_REPORT_OVERVIEW_ROUTE = '/beheer/rapporten';
export const EXAM_REPORT_CREATE_ROUTE = `${EXAM_REPORT_OVERVIEW_ROUTE}/aanmaken`;

export const ADMIN_CONFIG_ROUTE = '/beheer/configuratie';
export const ADMIN_MANAGEMENT_QUERY_OVERVIEW_ROUTE =
  '/beheer/management-queries';

const dashboard = new Route('Dashboard', '/', <Dashboard />, true).role(
  'ROLE_ADMIN',
);
Router.addRoute(dashboard);

const examReportOverview = new Route(
  'Rapporten',
  EXAM_REPORT_OVERVIEW_ROUTE,
  <ExamReportOverview />,
  true,
).role('ROLE_SUPER_ADMIN');
Router.addRoute(examReportOverview);

const examReportCreate = new Route(
  'Aanmaken',
  '/aanmaken',
  <ReportForm />,
).role('ROLE_SUPER_ADMIN');
examReportOverview.addChild(examReportCreate);

const examReportEdit = new Route(
  'Bewerken',
  '/:id/bewerken',
  <ReportForm />,
  true,
).role('ROLE_SUPER_ADMIN');
examReportOverview.addChild(examReportEdit);

const examReportFieldsOverview = new Route(
  'Velden',
  '/:id/velden',
  <ExamReportFieldOverview />,
  true,
).role('ROLE_SUPER_ADMIN');
examReportOverview.addChild(examReportFieldsOverview);

const examReportFieldsCreate = new Route(
  'Veld aanmaken',
  '/:reportId/velden/aanmaken',
  <ReportFieldForm />,
).role('ROLE_SUPER_ADMIN');
examReportOverview.addChild(examReportFieldsCreate);

const examReportFieldsEdit = new Route(
  'Veld bewerken',
  '/:reportId/velden/:id/bewerken',
  <ReportFieldForm />,
).role('ROLE_SUPER_ADMIN');
examReportOverview.addChild(examReportFieldsEdit);

const config = new Route('Configuratie', ADMIN_CONFIG_ROUTE, <Config />).role(
  'ROLE_SUPER_ADMIN',
);
Router.addRoute(config);

const managementQueryOverview = new Route(
  'Management queries',
  ADMIN_MANAGEMENT_QUERY_OVERVIEW_ROUTE,
  <ManagementQueryOverview />,
).role(['ROLE_ADMIN', 'ROLE_OPERATOR_USER']);
Router.addRoute(managementQueryOverview);
