import {
  ApiFilterCriteria,
  CollectionResponse,
  Exam,
  Repository,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class CandidateExamsRepository implements Repository<Exam> {
  private delegateId: string;

  constructor(delegateId: string) {
    this.delegateId = delegateId;
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<Exam>> {
    const params = parseCriteria(criteria, {
      page: page || 0,
      perPage: perPage || 10,
    });

    return ApiClient.get(
      `/api/v1/delegates/${this.delegateId}/candidate-exams`,
      params,
    );
  }
}

export default CandidateExamsRepository;
