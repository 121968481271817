import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Page from '../../../components/Page';
import { User } from '../../../types';
import { ACCOUNT_BASE_ROUTE, USER_BASE_ROUTE } from '../index';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import UserRepository from '../repository/UserRepository';

interface ChangePasswordProps {
  id?: string;
  isProfile: boolean;
}

const ChangePassword = (props: ChangePasswordProps) => {
  const { id: passedId, isProfile } = props;
  const { id: paramId } = useParams<{ id?: string }>();
  const id = passedId || paramId || '';
  const history = useHistory();

  const [user, setUser] = useState<User | null>(null);

  const baseUrl = isProfile ? ACCOUNT_BASE_ROUTE : `${USER_BASE_ROUTE}/${id}`;
  const showBreadcrumbs = !isProfile;

  const repository = new UserRepository();

  useEffect(
    () => {
      repository
        .find(id)
        .then((res) => setUser(res.data))
        .catch(() => history.push(baseUrl));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Page title="Wachtwoord veranderen" breadcrumbs={showBreadcrumbs}>
      {user && <ChangePasswordForm user={user} returnUrl={baseUrl} />}
    </Page>
  );
};

export default ChangePassword;
