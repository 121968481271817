import { ChangeEvent, useState } from 'react';

import { Box, Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { SwimmingLessonProvider } from '../../../types';
import SwimmingExamProviderRepository from '../repository/SwimmingLessonProviderRepository';

interface SLPAddExaminerProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  onSuccess: () => void;
}

const SLPAddExaminer = (props: SLPAddExaminerProps) => {
  const { swimmingLessonProvider, onSuccess } = props;

  const notifications = useSnackbar();

  const [code, setCode] = useState<string>('');
  const repository = new SwimmingExamProviderRepository();

  const handleCodeChange = (
    e: ChangeEvent<{ name: string; value: string }>,
  ) => {
    const { value } = e.target;
    setCode(value);
  };

  const addExaminer = () => {
    repository
      .addExaminer(swimmingLessonProvider.id, code)
      .then(() => {
        setCode('');
        onSuccess();
        notifications.enqueueSnackbar('Examinator succesvol toegevoegd.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het toevoegen van de examinator.',
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <>
      <Box mb={1}>
        <TextField
          label="Examinator uitnodigen o.b.v. code"
          name="code"
          value={code}
          fullWidth
          onChange={handleCodeChange}
        />
      </Box>
      <Button variant="contained" color="secondary" onClick={addExaminer}>
        Uitnodigen
      </Button>
    </>
  );
};

export default SLPAddExaminer;
