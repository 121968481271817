import { useSelector } from 'react-redux';
import Route from '../../../routing/Route';
import Router from '../../../routing/Router';
import { Exam } from '../../../types';
import ExamDelegateSection from '../forms/sections/ExamDelegateSection';
import { EXAM_BASE_ROUTE } from '../index';
import { UserState } from '../../../reducers/user';
import ExamDeclarationList from '../components/ExamDeclarationList';
import ExamReportEntry from '../components/ExamReportEntry';
import UpdateExamLines from '../components/UpdateExamLines';
import { examHasStarted } from '../utils';

const useExamTabs = (exam: Exam | null) => {
  const { account, roleView } = useSelector(
    (selector: { user: UserState }) => ({
      roleView: selector.user.roleView,
      account: selector.user.account,
    }),
  );

  if (!exam) {
    return null;
  }

  const hasStarted = examHasStarted(exam);
  const isAdmin = account && account.roles.includes('ROLE_ADMIN');
  const delegates = exam.confirmedDelegates || [];

  const router = new Router();

  router.addRoute(
    new Route(
      'Gedelegeerden',
      `${EXAM_BASE_ROUTE}/${exam.id}/gedelegeerden`,
      <ExamDelegateSection exam={exam} withActions />,
    ).role(['ROLE_ADMIN']),
  );

  if (!hasStarted) {
    router.addRoute(
      new Route(
        "Diploma's",
        `${EXAM_BASE_ROUTE}/${exam.id}/diplomas`,
        <UpdateExamLines exam={exam} />,
      ).role(['ROLE_ADMIN', 'ROLE_EXAMINER']),
    );
  }

  // the reports become available on the day of the exam
  if (hasStarted) {
    if (delegates.length >= 1) {
      router.addRoute(
        new Route(
          'Gedelegeerdenrapport',
          `${EXAM_BASE_ROUTE}/${exam.id}/gedelegeerdenrapport`,
          <ExamReportEntry exam={exam} type="delegate" />,
        ).role([
          'ROLE_ADMIN',
          'ROLE_SWIMMING_LESSON_PROVIDER_USER',
          'ROLE_DELEGATE',
          'ROLE_OPERATOR_USER',
        ]),
      );
    }

    // if there are two delegates, we assume it's because a mentor accompanies an aspirant
    // this workaround keeps the tab available if the aspirant promotes to a regular delegate
    // https://support.yardinternet.nl/index.php?vraag_id=30201
    if (
      delegates.length === 2 &&
      (account?.roles.includes('ROLE_ADMIN') ||
        account?.delegateProfile?.status === 'mentor')
    ) {
      router.addRoute(
        new Route(
          'Aspiranten beoordelingsrapport',
          `${EXAM_BASE_ROUTE}/${exam.id}/aspirantenbeoordelingsrapport`,
          <ExamReportEntry exam={exam} type="aspirant" />,
        ).role(['ROLE_ADMIN', 'ROLE_DELEGATE']),
      );
    }

    router.addRoute(
      new Route(
        'Examinatorrapport',
        `${EXAM_BASE_ROUTE}/${exam.id}/examinatorrapport`,
        <ExamReportEntry exam={exam} type="examiner" />,
      ).role([
        'ROLE_ADMIN',
        'ROLE_SWIMMING_LESSON_PROVIDER_USER',
        'ROLE_OPERATOR_USER',
        'ROLE_EXAMINER',
        'ROLE_DELEGATE',
      ]),
    );
  }

  if (
    isAdmin ||
    delegates.map((delegate) => delegate.id).includes(account?.id || '')
  ) {
    router.addRoute(
      new Route(
        'Declaraties',
        `${EXAM_BASE_ROUTE}/${exam.id}/declaraties`,
        <ExamDeclarationList exam={exam} />,
      ).role(['ROLE_ADMIN', 'ROLE_DELEGATE']),
    );
  }

  return router.processTabs(roleView);
};

export default useExamTabs;
