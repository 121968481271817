/* eslint-disable linebreak-style */
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ApiFilterCriteria, Repository } from '../types';
import { colors } from '../config/theme';
import FilterColumn, {
  FilterColumnConfig,
  FilterColumnOption,
  FilterColumnOptionCallback,
  FilterColumnOptionSearchCallback,
} from './FilterColumn';
import ConditionalWrapper from './ConditionalWrapper';
import SearchContext from './search/SearchContext';
import AppContext from '../AppContext';
import RoleViewManager from '../RoleViewManager';

interface Item {
  id: string;
  [key: string]: any;
}

export interface ItemAction {
  key: string;
  element: JSX.Element;
}

export interface Filter {
  type: 'checkbox' | 'autocomplete' | 'datepicker';
  field: string;
  name: string;
  options?: FilterColumnOption[] | FilterColumnOptionCallback;
  searchOptions?: FilterColumnOptionSearchCallback;
  config?: FilterColumnConfig;
}

export interface Column {
  // Name of the column as shown in the header.
  name: string;
  // Name of the field to show.
  field: string;
  // Determines if the user can sort on this column.
  sortable?: boolean;
  // Filters associated with the column.
  filter?: Filter | undefined;
  // Custom rendering function for this column.
  render?: (item: any, roleViewManager?: RoleViewManager) => ReactNode;
}

export const PlaceholderColumn: Column = {
  name: 'N/A',
  field: 'id',
  sortable: false,
  render: () => 'N/A',
};

interface DataTableProps {
  // A unique identifier for this data table.
  id: string;
  // The repository to use for retrieving the data.
  repository: Repository<unknown>;
  // All the columns of the data table.
  columns: Column[];
  // All filters of the data table.
  filters?: Filter[];
  // Whether items in the data table can be deleted or not.
  deletable?: boolean;
  // Callback when deleting an item fails.
  onDeleteFail?: (item: any) => void;
  // Callback that renders actions that are available for each item.
  actions?: (item: any) => ReactNode;
  // Callback when the data table loads data.
  onLoad?: (items: any, totalCount: number) => void;
  // Message when the user attempts to delete an item.
  deleteItemMessage?: (item: any) => string;
  // Custom class for the data table.
  className?: string;
  // Whether the data table is contained in a container or not.
  contained?: boolean;
  // Whether the filter state should be persisted.
  persistFilters?: boolean;
  // The filtering that's set on load.
  defaultFilters?: ApiFilterCriteria;
  // Custom display for when there are no results found.
  noResults?: ReactNode;
  // Callback when filters change.
  onFiltersChange?: (filters: ApiFilterCriteria) => void;
  // Callback that renders a table row.
  renderRow?: (
    columns: React.ReactElement,
    item: any,
    rowClassName: string,
    columnClassName: string,
  ) => React.ReactElement;
  // Callback that returns a style for a table row based on its item
  styleRow?: (item: any) => 'tr' | 'trHighlight';
  // Action that needs to be executed if a row is clicked
  onRowClick?: (item: any) => void;
  // Hides the table header.
  noHeader?: boolean;
  // Indicate the number of items per page
  perPage?: number;
  // Hides the pagination panel
  noPaginating?: boolean;
  // Hides the result counter.
  noResultCounter?: boolean;
  // Text of entity in result counter
  resultCounterText?: {
    singular: string;
    plural: string;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.light,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableWrapper: {
    position: 'relative',
    minHeight: 100,
    width: '100%',
  },
  table: {
    borderCollapse: 'initial',
    borderSpacing: 0,
    paddingTop: 0,
  },
  tableBody: {
    position: 'relative',
  },
  tr: {
    backgroundColor: colors.whiteLilac,
    '&:hover': {
      backgroundColor: colors.whiteIce,
    },
  },
  trHighlight: {
    backgroundColor: colors.softPeach,
    '&:hover': {
      backgroundColor: colors.softPeachDark,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  th: {
    fontSize: 18,
    fontWeight: theme.typography.h1.fontWeight,
    fontFamily: theme.typography.h1.fontFamily,
    background: colors.whiteIce,
    '&:first-child': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  td: {},
  cell: {
    color: theme.palette.primary.dark,
    borderBottom: `${theme.spacing(1)}px solid white`,
    padding: `${theme.spacing(2)}px 0`,
    '&:first-child': {
      paddingLeft: theme.spacing(1),
    },
    '&:last-child': {
      paddingRight: theme.spacing(1),
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  filtersContent: {
    padding: theme.spacing(2),
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: theme.spacing(2),
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: `calc(100% - ${theme.spacing(2)}px)`,
    opacity: 1,
  },
  loaderHidden: {
    opacity: 0,
    zIndex: -1,
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  clearFiltersButton: {
    position: 'absolute',
    top: 15,
    right: 20,
  },
  resetFiltersIcon: {
    position: 'absolute',
    right: -6,
    bottom: -2,
    fontSize: 12,
  },
  pagination: {
    '& ul': {
      justifyContent: 'center',
      '& li:first-child': {
        marginRight: theme.spacing(4),
        '& button': {
          background: colors.primaryLight,
          paddingTop: 0,
          boxShadow: 'none',
          '&:not(.Mui-disabled)': {
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.dark,
            '&:hover': {
              background: theme.palette.primary.main,
            },
          },
        },
      },
      '& li:last-child': {
        marginLeft: theme.spacing(3),
        '& button': {
          background: colors.primaryLight,
          paddingTop: 0,
          boxShadow: 'none',
          '&:not(.Mui-disabled)': {
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.dark,
            '&:hover': {
              background: theme.palette.primary.main,
            },
          },
        },
      },
      '& li': {
        marginRight: theme.spacing(1),
        '& button': {
          height: 40,
          width: 40,
          borderRadius: '50%',
          paddingTop: 5,
          fontSize: 18,
          fontFamily: theme.typography.h1.fontFamily,
          fontWeight: theme.typography.fontWeightBold,
          boxShadow: theme.shadows[2],
          '& svg': {
            fontSize: 24,
          },
        },
        '& button.Mui-selected': {
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.dark,
          '&:hover': {
            background: theme.palette.primary.main,
          },
        },
      },
    },
  },
  colorPrimaryDark: {
    color: theme.palette.primary.dark,
  },
  filters: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

// prevent the onRowClick near a button to avoid precise clicking
export const cancelOnRowClick = (e: React.MouseEvent<HTMLElement>) => {
  return e.stopPropagation();
};

const DataTable = (props: DataTableProps) => {
  const classes = useStyles();
  const {
    id,
    repository,
    columns,
    deletable,
    onDeleteFail,
    actions,
    deleteItemMessage,
    className,
    contained,
    persistFilters,
    defaultFilters,
    noResults,
    onFiltersChange,
    onLoad,
    renderRow,
    styleRow,
    onRowClick,
    resultCounterText,
  } = props;
  const { query } = useContext(SearchContext);
  const [items, setItems] = useState<Array<unknown>>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { appState, setAppState, localStore, roleViewManager } =
    useContext(AppContext);

  const itemsPerPage = props?.perPage || 10;
  const withDelete = deletable || false;
  const isContained = contained === undefined ? false : contained;

  const showActionsColumn = actions !== undefined || withDelete;

  const [paginator, setPaginator] = useState<{
    totalPages: number | null;
    totalItems: number | null;
    currentPage: number;
  }>({
    totalPages: null,
    totalItems: null,
    currentPage: 1,
  });

  const { currentPage } = paginator;

  const [filters, setFilters] = useState<ApiFilterCriteria>(
    defaultFilters || {
      filters: {},
      order: undefined,
    },
  );

  const [deleteState, setDeleteState] = useState<{
    showDialog: boolean;
    item: Item | null;
  }>({
    showDialog: false,
    item: null,
  });

  const resetPaginator = () =>
    setPaginator({ totalPages: null, totalItems: null, currentPage: 1 });

  const handleClearFilters = () => {
    setFilters({
      filters: {},
      order: undefined,
    });
    localStore.removeItem(`datatable-${id}-filters`);
  };

  /**
   * Data Loading
   */
  const loadItems = async (
    query: string,
    filters: ApiFilterCriteria,
    currentPage: number,
  ) => {
    setLoading(true);

    try {
      repository
        .findBy({ ...filters, query: query || '' }, currentPage, itemsPerPage)
        .then((response) => {
          let totalItems: number;
          let items;

          if ('data' in response) {
            const { data } = response;

            if ('hydra:member' in data && 'hydra:totalItems' in data) {
              items = data['hydra:member'];
              totalItems = data['hydra:totalItems'];
            } else {
              items = data.items;
              totalItems = data.totalItems;
            }
          } else {
            items = response.items;
            totalItems = response.totalItems;
          }

          if (onLoad) {
            onLoad(items, totalItems);
          }

          const totalPages = Math.ceil(totalItems / itemsPerPage);

          setItems(items);
          setPaginator({
            currentPage: currentPage <= totalPages ? currentPage : 1,
            totalItems,
            totalPages,
          });
        })
        .catch((err) => {
          handleClearFilters();
          if (err.response && setAppState && appState) {
            setAppState({ ...appState, errorStatusCode: err.response.status });
          }
        })
        .finally(() => setLoading(false));
    } catch (e: any) {
      handleClearFilters();
      resetPaginator();
      if (e.response && setAppState && appState) {
        setAppState({ ...appState, errorStatusCode: e.response.status });
      }
    }
  };

  const doSearch = useCallback(
    _.debounce((query, filters, currentPage) => {
      if (!ready) {
        return;
      }

      loadItems(query, filters, currentPage);
    }, 500),
    [ready],
  );

  useEffect(() => {
    doSearch(query, filters, currentPage);
  }, [query, filters, currentPage, doSearch]);

  /**
   * Pagination
   */
  const handlePaginate = (_e: ChangeEvent<unknown>, page: number) => {
    setPaginator((prev) => ({ ...prev, currentPage: page }));
  };

  /**
   * Deletion
   */
  const handleDelete = (item: any) => {
    setDeleteState({ item, showDialog: true });
  };

  const handleClose = () => {
    setDeleteState({ item: null, showDialog: false });
  };

  const doDelete = () => {
    if (typeof repository.delete !== 'function') {
      return;
    }

    if (deleteState.item !== null) {
      repository
        .delete(deleteState.item.id)
        .then(() => {
          loadItems(query, filters, currentPage);
        })
        .catch(() => {
          if (onDeleteFail) {
            onDeleteFail(deleteState.item);
          }
        });
    }

    setDeleteState({ item: null, showDialog: false });
  };

  /**
   * Sorting
   */
  const handleSort = (property: string) => () => {
    let order: 'asc' | 'desc' = 'asc';

    if (filters.order && filters.order.length > 0) {
      order = filters.order[0].order === 'asc' ? 'desc' : 'asc';
    }

    resetPaginator();
    setFilters({ ...filters, order: [{ field: property, order }] });
  };

  /**
   * Filtering
   */
  const handleFilterColumn = (field: string, options: FilterColumnOption[]) => {
    const updatedFilters = { ...filters };

    if (updatedFilters.filters === undefined) {
      updatedFilters.filters = {};
    }

    if (options.length === 0) {
      delete updatedFilters.filters[field];
    } else {
      updatedFilters.filters[field] = options;
    }

    resetPaginator();
    setFilters(updatedFilters);
  };

  const handleFilterColumnByDate = (
    column: Column,
    date: MaterialUiPickersDate,
  ) => {
    const updatedFilters = { ...filters };

    if (!date) {
      if (updatedFilters.filters && updatedFilters.filters[column.field]) {
        delete updatedFilters.filters[column.field];
        setFilters(updatedFilters);
      }

      return;
    }

    if (updatedFilters.filters === undefined) {
      updatedFilters.filters = {};
    }

    resetPaginator();
    updatedFilters.filters[column.field] = date.format('YYYY-MM-DD');
    setFilters(updatedFilters);
  };

  useEffect(
    () => {
      if (onFiltersChange) {
        onFiltersChange(filters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  /**
   * State persistence.
   */
  useEffect(
    () => {
      if (!persistFilters || !ready) {
        return;
      }

      localStore.setItem(`datatable-${id}-filters`, JSON.stringify(filters));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  useEffect(
    () => {
      if (!persistFilters) {
        setReady(true);
        return;
      }

      localStore
        .getItem<string>(`datatable-${id}-filters`)
        .then((storedFiltersStr) => {
          if (storedFiltersStr === null) {
            return;
          }

          const storedFilters = JSON.parse(storedFiltersStr);
          setFilters({ ...filters, ...storedFilters });
        })
        .finally(() => setReady(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Box className={className}>
      <Dialog
        open={deleteState.showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteItemMessage && deleteState.item !== null
              ? deleteItemMessage(deleteState.item)
              : 'Weet je zeker dat je dit item wilt verwijderen?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" autoFocus>
            Annuleren
          </Button>
          <Button onClick={doDelete} color="secondary">
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>

      <ConditionalWrapper
        condition={isContained}
        wrapper={(children) => (
          <Paper className={classes.root}>{children}</Paper>
        )}
      >
        <>
          {(!props.noResultCounter ||
            (props.filters && props.filters.length > 0)) && (
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              className={`${classes.colorPrimaryDark} ${classes.filters}`}
              mb={2}
              mt={1}
            >
              <Box display="flex" alignItems="center">
                {props.filters && props.filters.length >= 1 && (
                  <>
                    <Box mr={2}>Filter op:</Box>
                    {props.filters.map((filter) => {
                      const onChange = (options: FilterColumnOption[]) =>
                        handleFilterColumn(filter.field || '', options);

                      return (
                        <FilterColumn
                          key={filter.field}
                          field={filter.field}
                          name={filter.name}
                          type={filter.type}
                          onChange={onChange}
                          options={filter.options}
                          searchOptions={filter.searchOptions}
                          config={filter.config || {}}
                          criteria={filters}
                          showLabel
                        />
                      );
                    })}
                  </>
                )}
              </Box>
            </Box>
          )}
          <div className={classes.tableWrapper}>
            <TableContainer>
              <Table className={classes.table}>
                {!props.noHeader && (
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => {
                        const onChange = (options: FilterColumnOption[]) =>
                          handleFilterColumn(column.field, options);
                        const onDateChange = (date: MaterialUiPickersDate) =>
                          handleFilterColumnByDate(column, date);

                        return (
                          <TableCell
                            key={`cell-header-${column.field}`}
                            className={`${classes.th} ${classes.cell}`}
                          >
                            <Box display="flex">
                              {column.sortable && (
                                <TableSortLabel
                                  active={
                                    filters.order &&
                                    filters.order[0].field === column.field
                                  }
                                  direction={
                                    filters.order
                                      ? filters.order[0].order
                                      : 'asc'
                                  }
                                  onClick={handleSort(column.field)}
                                  data-testid={`column-sort-label-${column.field}`}
                                >
                                  {column.name}
                                </TableSortLabel>
                              )}
                              {column.filter !== undefined && (
                                <FilterColumn
                                  field={column.field}
                                  name={column.name}
                                  type={column.filter.type}
                                  options={column.filter.options}
                                  searchOptions={column.filter.searchOptions}
                                  config={column.filter.config || {}}
                                  onChange={onChange}
                                  onDateChange={onDateChange}
                                  criteria={filters}
                                  showLabel={!column.sortable}
                                />
                              )}
                              {!column.sortable &&
                                column.filter === undefined &&
                                column.name}
                            </Box>
                          </TableCell>
                        );
                      })}
                      {showActionsColumn && (
                        <TableCell className={`${classes.th} ${classes.cell}`}>
                          {Object.entries(filters.filters || {}).length > 0 && (
                            <Tooltip title="Filters verwijderen">
                              <IconButton
                                size="small"
                                className={classes.clearFiltersButton}
                                onClick={handleClearFilters}
                              >
                                <Box position="relative">
                                  <FontAwesomeIcon icon={['fal', 'filter']} />
                                  <FontAwesomeIcon
                                    icon="trash"
                                    size="sm"
                                    className={classes.resetFiltersIcon}
                                  />
                                </Box>
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                )}
                <TableBody className={classes.tableBody}>
                  {items.map((item) => {
                    const renderedColumns = (
                      <>
                        {columns.map((column) => (
                          <TableCell
                            key={`${(item as { id: string }).id}-cell-${
                              column.field
                            }`}
                            className={`${classes.td} ${classes.cell}`}
                          >
                            <Box>
                              {column.render &&
                                column.render(item, roleViewManager)}
                              {!column.render && (item as any)[column.field]}
                            </Box>
                          </TableCell>
                        ))}
                        {showActionsColumn && (
                          <TableCell
                            key={`${(item as { id: string }).id}-cell-actions`}
                            className={`${classes.td} ${classes.cell}`}
                          >
                            <Box
                              justifyContent="flex-end"
                              onClick={onRowClick ? cancelOnRowClick : () => {}}
                            >
                              <div className={classes.actions}>
                                {actions && actions(item)}
                                {withDelete && (
                                  <Tooltip title="Verwijderen">
                                    <IconButton
                                      size="small"
                                      onClick={() => handleDelete(item as any)}
                                    >
                                      <FontAwesomeIcon
                                        icon={['fal', 'trash']}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </Box>
                          </TableCell>
                        )}
                      </>
                    );

                    let trStyle = classes.tr;
                    if (styleRow) {
                      trStyle = classes[styleRow(item)];
                    }
                    if (onRowClick) {
                      trStyle += ` ${classes.pointer}`;
                    }

                    if (renderRow) {
                      return renderRow(
                        renderedColumns,
                        item,
                        trStyle,
                        `${classes.td} ${classes.cell}`,
                      );
                    }

                    return (
                      <TableRow
                        key={(item as { id: string }).id}
                        data-testid="table-row"
                        className={trStyle}
                        onClick={() => (onRowClick ? onRowClick(item) : {})}
                      >
                        {renderedColumns}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {!loading && items.length === 0 && (
              <div className={classes.noResults}>
                {noResults === undefined &&
                  `Geen ${
                    resultCounterText ? resultCounterText.plural : 'resultaten'
                  } gevonden.`}
                {noResults !== undefined && noResults}
              </div>
            )}
            <div
              className={`${classes.loader} ${
                loading ? '' : classes.loaderHidden
              }`}
            >
              <CircularProgress />
            </div>
          </div>
          {!props.noPaginating &&
            paginator.totalPages !== null &&
            paginator.totalPages > 1 && (
              <Box p={2}>
                <Pagination
                  count={paginator.totalPages}
                  page={paginator.currentPage}
                  onChange={handlePaginate}
                  className={classes.pagination}
                />
              </Box>
            )}
        </>
      </ConditionalWrapper>
    </Box>
  );
};

export default DataTable;
