import React, { useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormFieldProps } from './index';
import ExamReportContext from '../ExamReportContext';

const useStyles = makeStyles(() => ({
  subHeader: {
    fontWeight: 'lighter',
    fontStyle: 'italic',
    '& > p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
}));

const ScaleField: React.FC<FormFieldProps> = (props) => {
  const { field, error } = props;
  const { data, changeField, locked } = useContext(ExamReportContext);

  const classes = useStyles();

  const handleChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    changeField(field.id, value);
  };

  return (
    <FormControl component="fieldset" disabled={locked}>
      <FormLabel error={error !== undefined} component="legend">
        {field.label}
        <div
          className={classes.subHeader}
          dangerouslySetInnerHTML={{ __html: field.description }}
        />
      </FormLabel>
      <RadioGroup
        aria-label={field.label}
        name={field.id}
        value={data[field.id]?.value}
        onChange={handleChange}
      >
        <FormControlLabel value="2" control={<Radio />} label="Goed" />
        <FormControlLabel value="1" control={<Radio />} label="Voldoende" />
        <FormControlLabel value="0" control={<Radio />} label="Te verbeteren" />
      </RadioGroup>
    </FormControl>
  );
};

export default ScaleField;
