import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import { ExamReportDTO, ExamReportType } from '../domain/types';
import ExamReportRepository from '../repository/ExamReportRepository';
import { EXAM_REPORT_OVERVIEW_ROUTE } from '../../admin';

const repository = new ExamReportRepository();

const ReportForm = () => {
  const { id } = useParams<{ id?: string }>();
  const [report, setReport] = useState<ExamReportDTO>({
    title: '',
    type: null,
    description: '',
  });
  const notifications = useSnackbar();
  const history = useHistory();

  const { title, type, description } = report;

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReport({ ...report, [event.target.name]: event.target.value });
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReport({ ...report, type: event.target.value as ExamReportType });
  };

  const handleSubmit = () => {
    (id ? repository.update(id, report) : repository.create(report))
      .then(() => {
        notifications.enqueueSnackbar('Het rapport is succesvol aangemaakt!', {
          variant: 'success',
        });
        history.push(EXAM_REPORT_OVERVIEW_ROUTE);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het opslaan van het rapport', {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    if (id) {
      repository
        .find(id)
        .then((response) => {
          setReport(response.data);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van het rapport!',
            { variant: 'error' },
          );
          history.push(EXAM_REPORT_OVERVIEW_ROUTE);
        });
    }
  }, [id, history, notifications]);

  return (
    <Page title={id ? 'Rapport bewerken' : 'Rapport aanmaken'} breadcrumbs>
      <FormControl fullWidth margin="normal">
        <FormLabel>Titel</FormLabel>
        <TextField
          value={title}
          style={{ maxWidth: 400 }}
          onChange={handleFieldChange}
          name="title"
        />
      </FormControl>
      <Box my={3}>
        <FormControl>
          <FormLabel>Kies een type</FormLabel>
          <RadioGroup value={type} name="action" onChange={handleTypeChange}>
            <FormControlLabel
              value="examiner"
              control={<Radio />}
              label="Examinator"
            />
            <FormControlLabel
              value="delegate"
              control={<Radio />}
              label="Gedelegeerde"
            />
            <FormControlLabel
              value="aspirant"
              control={<Radio />}
              label="Aspirant"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <FormControl fullWidth margin="normal">
        <FormLabel>Beschrijving</FormLabel>
        <TextField
          value={description}
          rows={4}
          style={{ maxWidth: 400 }}
          multiline
          onChange={handleFieldChange}
          name="description"
        />
      </FormControl>

      <Box mt={3}>
        <Button
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
        >
          Opslaan
        </Button>
      </Box>
    </Page>
  );
};

export default ReportForm;
