import { useEffect, useState } from 'react';

import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import {
  SwimmingLessonProviderType,
  SwimmingLessonProviderWithLessonLocations,
} from '../../../types';
import ExamRepository from '../../exams/repository/ExamRepository';

interface SwimmingLessonProviderSelectProps {
  onSelect: (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations,
  ) => void;
  type?: SwimmingLessonProviderType;
}

const SwimmingLessonProviderSelect = (
  props: SwimmingLessonProviderSelectProps,
) => {
  const { onSelect } = props;

  const notifications = useSnackbar();

  const repository = new ExamRepository();

  const [swimmingLessonProviderOptions, setSwimmingLessonProviderOptions] =
    useState<SwimmingLessonProviderWithLessonLocations[]>([]);

  const onSelectHandler = (
    value: SwimmingLessonProviderWithLessonLocations | null,
  ) => {
    if (value !== null) {
      onSelect(value);
    }
  };

  useEffect(
    () => {
      repository
        .getSwimmingLessonProviders(props.type)
        .then((response) => {
          setSwimmingLessonProviderOptions(response.data.items);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van de zwemlesaanbieders.',
            { variant: 'error' },
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        onChange={(_e, value) => onSelectHandler(value)}
        options={swimmingLessonProviderOptions}
        getOptionLabel={(provider) => provider.name}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            label="Zwemlesaanbieder"
            variant="outlined"
            placeholder="Maak een keuze..."
          />
        )}
      />
    </FormControl>
  );
};

export default SwimmingLessonProviderSelect;
