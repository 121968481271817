import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { grey } from '@material-ui/core/colors';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';

import DateFnsUtils from '@date-io/date-fns';
import { nl } from 'date-fns/locale';
import { isValid } from 'date-fns';
import { useSnackbar } from 'notistack';
import { ExamDiplomaDTO, ExamDTO } from '../repository/ExamRepository';
import { Examiner, ExamLocation, ValidationViolation } from '../../../types';
import ExamLocationInfo from '../components/ExamLocationInfo';
import ExamDiplomaList from '../components/ExamDiplomaList';
import { hasViolationForField } from '../../../utils/common';
import FormSection from '../../../components/form/FormSection';
import Loader from '../../../components/Loader';
import ExamLocationRepository from '../../swimming-lesson-provider/repository/ExamLocationRepository';
import SwimmingLessonProviderRepository from '../../swimming-lesson-provider/repository/SwimmingLessonProviderRepository';

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    maxWidth: 400,
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(4),
    },
  },
  placeholder: {
    color: grey[500],
  },
}));

interface ExamFormProps {
  exam: ExamDTO;
  setExam: Dispatch<SetStateAction<ExamDTO>>;
  minDate: Date;
  violations: ValidationViolation[];
}

const ExamForm = (props: ExamFormProps) => {
  const { exam, setExam, minDate, violations } = props;

  const classes = useStyles();
  const notifications = useSnackbar();

  const [examLocationOptions, setExamLocationOptions] = useState<
    ExamLocation[]
  >([]);
  const [examinerOptions, setExaminerOptions] = useState<Examiner[]>([]);
  const [currentExamLocation, setCurrentExamLocation] =
    useState<ExamLocation | null>(null);

  const [oneExaminerOverall, setOneExaminerOverall] = useState<boolean>(
    exam.oneExaminerOverall,
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const swimmingLessonProviderRepository =
    new SwimmingLessonProviderRepository();

  // not the prettiest solution, but it does the trick
  // it checks for the property path 'examinerId' and paths like 'diploma[0][examinerId]'
  const hasExaminerViolation =
    violations.filter((violation) =>
      violation.propertyPath.includes('examinerId'),
    ).length >= 1;

  const handleDateChange = (date: any) => {
    setExam({ ...exam, date });
  };

  const handleOneExaminerOverallChange = () => {
    if (oneExaminerOverall) {
      // if the old value was 'one examinator overall', clear this examinator
      setExam((oldExam) => ({
        ...oldExam,
        oneExaminerOverall: false,
        examinerId: '',
      }));
    } else {
      // if the old value was 'one examinator per diploma line', then clear these examinators
      setExam((oldExam) => ({
        ...oldExam,
        oneExaminerOverall: true,
        diplomas: oldExam.diplomas.map((diplomaLine) => ({
          ...diplomaLine,
          examinerId: '',
        })),
      }));
    }

    setOneExaminerOverall((oldValue) => !oldValue);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setExam({ ...exam, [event.target.name as string]: event.target.value });
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ name?: string; checked: boolean }>,
  ) => {
    const { name, checked } = event.target as {
      name: string;
      checked: boolean;
    };
    const newExam = { ...exam, [name]: checked };

    // Ensure men & women only are mutually exclusive.
    if (name === 'womenOnly' && checked && exam.menOnly) {
      newExam.menOnly = false;
    }

    if (name === 'menOnly' && checked && exam.womenOnly) {
      newExam.womenOnly = false;
    }

    setExam(newExam);
  };

  const handleExamDiplomaListChange = (diplomas: ExamDiplomaDTO[]) => {
    setExam({ ...exam, diplomas });
  };

  /**
   * Load all options for the form on load.
   */
  useEffect(() => {
    if (exam.swimmingLessonProviderId.length === 0) {
      return;
    }

    swimmingLessonProviderRepository
      .getExamLocations(exam.swimmingLessonProviderId)
      .then((response) => {
        setExamLocationOptions(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het ophalen van de afzwembaden.',
          { variant: 'error' },
        );
      });
  }, [exam.swimmingLessonProviderId]);

  useEffect(
    () => {
      if (exam.swimmingLessonProviderId.length === 0) {
        return;
      }

      swimmingLessonProviderRepository
        .getExaminersForExam(
          exam.swimmingLessonProviderId,
          exam.lessonLocationIds,
        )
        .then((response) => {
          setExaminerOptions(response.data);
        })
        .catch(() => {
          notifications.enqueueSnackbar(
            'Fout bij het ophalen van de examinatoren.',
            { variant: 'error' },
          );
        })
        .finally(() => {
          setIsLoaded(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exam.swimmingLessonProviderId, exam.lessonLocationIds],
  );

  /**
   * Load detail information of exam location.
   */
  useEffect(() => {
    if (exam.examLocationId.length === 0) {
      return;
    }

    // Don't fetch details when same location.
    if (currentExamLocation && currentExamLocation.id === exam.examLocationId) {
      return;
    }

    new ExamLocationRepository()
      .getExamLocation(exam.examLocationId)
      .then((response) => {
        setCurrentExamLocation(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het ophalen van afzwembad.', {
          variant: 'error',
        });
      });
  }, [exam.examLocationId]);

  useEffect(
    () => {
      if (!isLoaded) {
        return;
      }

      const examinerIds = examinerOptions.map((examiner) => examiner.id);

      // unset examiners that can no longer be selected, because their licenses are insufficient
      setExam((prev) => ({
        ...prev,
        examinerId: examinerIds.includes(prev.examinerId)
          ? prev.examinerId
          : '',
        diplomas: prev.diplomas.map((diploma) => ({
          ...diploma,
          examinerId: examinerIds.includes(diploma.examinerId)
            ? diploma.examinerId
            : '',
        })),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded, examinerOptions],
  );

  if (!isLoaded) {
    return <Loader inline />;
  }

  const menOnlyControl = (
    <Checkbox
      name="menOnly"
      checked={exam.menOnly}
      onChange={handleCheckboxChange}
    />
  );

  const womenOnlyControl = (
    <Checkbox
      name="womenOnly"
      checked={exam.womenOnly}
      onChange={handleCheckboxChange}
    />
  );

  return (
    <>
      <FormSection title="Datum en locatie">
        <Box className={classes.formField}>
          <FormControl fullWidth margin="normal">
            <FormLabel>Op welke dag is het afzwemmen?</FormLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
              <KeyboardDatePicker
                disableToolbar
                format="dd-MM-yyyy"
                helperText=""
                orientation="landscape"
                minDate={minDate}
                autoOk
                margin="normal"
                value={exam.date}
                onChange={handleDateChange}
                cancelLabel="Annuleren"
                KeyboardButtonProps={{
                  'aria-label': 'Verander datum',
                }}
                error={!isValid(exam.date)}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Box>
        <Box className={classes.formField}>
          <FormControl fullWidth>
            <FormLabel id="exam-locations-select-label">
              Kies een afzwembad
            </FormLabel>
            <Select
              labelId="exam-locations-select-label"
              id="exam-locations-select"
              value={exam.examLocationId}
              name="examLocationId"
              onChange={handleSelectChange}
              MenuProps={{ elevation: 1 }}
              error={hasViolationForField('examLocationId', violations)}
              renderValue={(value) => {
                if (value === '') {
                  return (
                    <span className={classes.placeholder}>
                      Selecteer een afzwembad...
                    </span>
                  );
                }

                return examLocationOptions.find((l) => l.id === value)?.name;
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Selecteer een afzwembad...
              </MenuItem>
              {examLocationOptions.map((examLocation) => (
                <MenuItem key={examLocation.id} value={examLocation.id}>
                  {examLocation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {currentExamLocation && (
            <Box mt={1}>
              <ExamLocationInfo examLocation={currentExamLocation} />
            </Box>
          )}
        </Box>
      </FormSection>
      <FormSection title="Examinator en diploma's">
        <Box className={classes.formField} mt={3}>
          <FormControl fullWidth>
            <FormLabel>Examinatorselectie</FormLabel>
            <RadioGroup
              value={oneExaminerOverall}
              name="action"
              onChange={handleOneExaminerOverallChange}
            >
              <FormControlLabel
                value
                control={<Radio />}
                label="één examinator voor het hele examen"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="één examinator per diplomatype of tijdslot"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <>
          {oneExaminerOverall && (
            <Box className={classes.formField} mt={3}>
              <FormControl fullWidth>
                <FormLabel id="examiner-select-label">
                  Kies een examinator
                </FormLabel>
                <Select
                  labelId="examiner-select-label"
                  id="examiner-select"
                  value={exam.examinerId}
                  name="examinerId"
                  onChange={handleSelectChange}
                  MenuProps={{ elevation: 1 }}
                  displayEmpty
                  error={hasViolationForField('examinerId', violations)}
                >
                  <MenuItem value="">
                    <em className={classes.placeholder}>Maak een keuze</em>
                  </MenuItem>
                  {examinerOptions.map((examiner) => (
                    <MenuItem key={examiner.id} value={examiner.id}>
                      {examiner.extendedName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </>
        {hasExaminerViolation && (
          <Alert severity="error">
            {`Controleer of de gekozen ${
              oneExaminerOverall ? 'examinator' : 'examinatoren'
            } de juiste licentie ${oneExaminerOverall ? 'heeft' : 'hebben'}.`}
          </Alert>
        )}
        <Box mt={4}>
          <ExamDiplomaList
            exam={exam}
            oneExaminerOverall={oneExaminerOverall}
            examinerOptions={examinerOptions}
            onChange={handleExamDiplomaListChange}
            allViolations={violations}
          />
        </Box>
        <Box mt={4}>
          <FormLabel id="exam-particularities-label">
            Bijzonderheden afzwemmen
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={menOnlyControl}
              label="Wegens religie mogen hier alleen mannen aanwezig zijn"
            />
            <FormControlLabel
              control={womenOnlyControl}
              label="Wegens religie mogen hier alleen vrouwen aanwezig zijn"
            />
          </FormGroup>
        </Box>
      </FormSection>
    </>
  );
};

export default ExamForm;
