import { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, TextField, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Home from './Home';
import ApiClient from './api/ApiClient';
import { loginUser, logoutUser } from './actions';
import { useDispatch } from 'react-redux';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    maxWidth: 375,
    minWidth: 375,
  },
  formTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  buttonRow: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));

const TwoFactorAuthentication = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [authCode, setAuthCode] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState<{ authCode?: boolean }>({});

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (authCode === undefined) {
      setErrors((prev) => ({ ...prev, authCode: true }));
    }

    ApiClient.post(`/2fa_check`, { _auth_code: authCode })
      .then(() => {
        dispatch(loginUser());
        history.push('/');
      })
      .catch(() => {
        setErrors((prev) => ({ ...prev, authCode: true }));
      });
  };

  const goBack = () => {
    dispatch(logoutUser());
    history.push('/');
  };

  const handleAuthCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = !Number.isNaN(e.target.value)
      ? Number.parseInt(e.target.value, 10)
      : null;
    if (value !== null) {
      setAuthCode(value);
    }
  };

  return (
    <Home>
      <Box className={classes.form}>
        <Typography variant="h2" className={classes.formTitle}>
          Verificatiecode
        </Typography>
        <Box className="mb-2">
          Vul hier de 6-cijferige code in, die je per e-mail hebt ontvangen.
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            value={authCode || ''}
            onChange={handleAuthCodeChange}
            error={errors.authCode}
            label="Verificatiecode"
            fullWidth
          />
          <Box className={classes.buttonRow}>
            <Button variant="contained" color="secondary" type="submit">
              Inloggen
            </Button>
            <Button variant="contained" color="secondary" onClick={goBack}>
              Annuleren
            </Button>
          </Box>
        </form>
      </Box>
    </Home>
  );
};

export default TwoFactorAuthentication;
