import React, { useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormSection from '../../../components/form/FormSection';
import { MailTemplateDTO } from '../types';
import SafeHtml from '../../../components/SafeHtml';
import HtmlEditor from '../../../components/HtmlEditor';

type MailTemplateFormProps = {
  mailTemplateDTO?: MailTemplateDTO;
  onSubmit: (mailTemplateDTO: MailTemplateDTO) => void | null;
};

const MailTemplateForm = (props: MailTemplateFormProps) => {
  const { mailTemplateDTO: initialMailTemplateDTO, onSubmit } = props;

  const [mailTemplateDTO, setMailTemplateDTO] = useState<MailTemplateDTO>(
    initialMailTemplateDTO || {
      typeReadable: '',
      description: '',
      subject: '',
      content: '',
    },
  );

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMailTemplateDTO({
      ...mailTemplateDTO,
      [event.target.name]: event.target.value,
    });
  };

  const handleContentChange = (_e: any, editor: any) => {
    setMailTemplateDTO({ ...mailTemplateDTO, content: editor.getData() });
  };

  const handleSubmit = () => {
    onSubmit(mailTemplateDTO);
  };

  return (
    <>
      <FormSection title={mailTemplateDTO.typeReadable}>
        <SafeHtml html={mailTemplateDTO.description} />

        <FormControl fullWidth margin="normal">
          <FormLabel>Onderwerpregel</FormLabel>
          <TextField
            value={mailTemplateDTO.subject}
            onChange={handleFieldChange}
            name="subject"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel>Tekst</FormLabel>
          <Box>
            <HtmlEditor
              data={mailTemplateDTO.content}
              onChange={handleContentChange}
            />
          </Box>
        </FormControl>
      </FormSection>

      <Box mb={3}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fad', 'save']} />}
          onClick={handleSubmit}
        >
          Opslaan
        </Button>
      </Box>
    </>
  );
};

export default MailTemplateForm;
