import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Theme, Typography } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import { gradients } from '../config/theme';
import settings from '../config/settings';

interface PageContainerProps {
  children: ReactNode;
  actions?: ReactNode;
  centered?: boolean;
  title?: string | React.ReactElement;
  breadcrumbs?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    marginTop: `${theme.spacing(11) + settings.header.height}px`,
    marginBottom: 70,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  centered: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
  gradient: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    height: 500,
    width: '100%',
    background: gradients.primaryLight,
  },
}));

const PageContainer = (props: PageContainerProps) => {
  const classes = useStyles(props);
  const { children, actions, title, centered, breadcrumbs } = props;

  return (
    <div>
      <Container
        className={`${classes.container} ${centered && classes.centered}`}
      >
        {breadcrumbs && <Breadcrumbs />}
        <Box className={classes.header}>
          {title && <Typography variant="h1">{title}</Typography>}
          {actions}
        </Box>
        {children}
      </Container>
    </div>
  );
};

export default PageContainer;
