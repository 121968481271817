import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import DataTable from '../../../components/DataTable';
import SearchInput from '../../../components/search/SearchInput';
import SearchContext from '../../../components/search/SearchContext';
import Page from '../../../components/Page';
import { ApiFilterCriteria, Exam } from '../../../types';
import { EXAM_BASE_ROUTE } from '../../exams';
import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_LOCATION,
} from '../../exams/domain/columns';
import { ExamColumn } from '../../exams/domain/types';
import ExaminerExamRepository from '../repository/ExaminerExamRepository';

const PlannedExamOverview = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const repository = new ExaminerExamRepository();

  const columns: ExamColumn[] = [COLUMN_EXAM_DATE, COLUMN_EXAM_LOCATION];

  const defaultFilters: ApiFilterCriteria = {
    filters: {
      past: false,
    },
  };

  const title = (
    <>
      {totalCount !== null && `Geplande examens (${totalCount})`}
      {totalCount === null && 'Geplande examens'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <SearchInput />
    </Box>
  );

  const onRowClick = (exam: Exam) => {
    history.push(`${EXAM_BASE_ROUTE}/${exam.id}`);
  };

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="planned-exam-overview"
          repository={repository}
          columns={columns}
          defaultFilters={defaultFilters}
          resultCounterText={{ singular: 'examen', plural: 'examens' }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          onRowClick={onRowClick}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default PlannedExamOverview;
