import React, { useCallback, useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  children: React.ReactElement;
  lock?: boolean;
  windowScroll?: boolean;
  onTrigger?: () => void;
  className?: string;
}

const InfiniteScroll = ({
  children,
  lock = false,
  windowScroll = true,
  onTrigger = () => {},
  className = '',
}: InfiniteScrollProps) => {
  const container = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    () => {
      if (lock) {
        return;
      }

      const element = container.current;
      if (element === null) {
        return;
      }

      const { scrollHeight, clientHeight } = element;
      const triggerOffset = scrollHeight - 0.15 * clientHeight;

      if (!windowScroll && element.scrollTop + clientHeight >= triggerOffset) {
        onTrigger();
      }

      if (
        windowScroll &&
        window.scrollY + window.innerHeight >
          element.offsetTop + element.clientHeight
      ) {
        onTrigger();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lock, onTrigger],
  );

  useEffect(
    () => {
      const elem = container.current;
      if (elem === null) {
        return () => {};
      }

      if (!windowScroll) {
        elem.addEventListener('scroll', handleScroll);
        return () => elem.removeEventListener('scroll', handleScroll);
      }

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [container, onTrigger, lock],
  );

  /**
   * On initial load.
   */
  useEffect(() => {
    if (container.current) {
      container.current.scrollTop = 0;
    }
  }, [container]);

  return (
    <div ref={container} className={className}>
      {children}
    </div>
  );
};

export default InfiniteScroll;
