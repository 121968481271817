import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';

import ButtonRow from '../../../components/ButtonRow';
import InlineButton from '../../../components/InlineButton';
import InlineLink from '../../../components/InlineLink';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import TextOutput from '../../../components/form/TextOutput';
import DateOutput from '../../../components/form/DateOutput';
import FormSection from '../../../components/form/FormSection';
import {
  Declaration,
  DeclarationStatus,
  DeclarationTravelMethod,
  User,
} from '../../../types';
import { formatCurrency } from '../../../utils/common';
import DeclarationsRepository from '../repository/DeclarationsRepository';
import { DECLARATIONS_BASE_ROUTE } from '../index';
import FileChips from '../../../components/file/FileChips';

const ViewDeclaration = () => {
  const notifications = useSnackbar();
  const history = useHistory();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const { id } = useParams<{ id: string }>();

  const [declaration, setDeclaration] = useState<Declaration | null>(null);

  const repository = useMemo(() => new DeclarationsRepository(), []);

  const isAdmin = account.roles.includes('ROLE_ADMIN');

  const loadDeclaration = useCallback(() => {
    repository
      .find(id)
      .then((response) => setDeclaration(response.data))
      .catch(() => {
        history.push('/');
      });
  }, [repository, id]);

  const handleApprove = () => {
    repository
      .approve(id)
      .then(() => {
        notifications.enqueueSnackbar(
          'De declaratie is succesvol geaccordeerd!',
          { variant: 'success' },
        );
        loadDeclaration();
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het accorderen van de declaratie!',
          { variant: 'error' },
        );
      });
  };

  useEffect(() => {
    loadDeclaration();
  }, [loadDeclaration]);

  const title = <Typography variant="h1">Declaratie bekijken</Typography>;

  if (!declaration) {
    return <Loader />;
  }

  const canEdit =
    declaration.status === 'open' ||
    (isAdmin && declaration.status === 'approved');
  const canApprove = isAdmin && declaration.status === 'open';

  return (
    <Page title={title}>
      <FormSection title="Over de declaratie">
        {isAdmin && (
          <TextOutput label="Gebruiker" value={declaration.delegate.fullName} />
        )}
        {isAdmin && (
          <TextOutput
            label="Status"
            value={DeclarationStatus[declaration.status]}
          />
        )}
        <TextOutput
          label="Vervoer"
          value={DeclarationTravelMethod[declaration.travelMethod]}
        />
        {declaration.travelMethod === 'own' && (
          <TextOutput label="Kilometers" value={declaration.kilometers} />
        )}
        <TextOutput
          label="Vaste vergoeding"
          value={formatCurrency(declaration.baseCompensation)}
        />
        {declaration.travelCompensation > 0 && (
          <TextOutput
            label="Reiskosten"
            value={formatCurrency(declaration.travelCompensation)}
          />
        )}
        {declaration.additionalCosts > 0 && (
          <TextOutput
            label="Overige kosten"
            value={formatCurrency(declaration.additionalCosts)}
          />
        )}
        <TextOutput
          label="Totale vergoeding"
          value={formatCurrency(declaration.totalCompensation)}
        />
        <TextOutput
          label="Toelichting"
          value={declaration.explanation}
          hideIfEmpty
        />
        {isAdmin && (
          <TextOutput
            label="Omschrijving bankafschrift"
            value={declaration.descriptionForSepa}
          />
        )}
        {declaration.attachments && declaration.attachments.length >= 1 && (
          <FileChips files={declaration.attachments} />
        )}
      </FormSection>
      {declaration.exam && (
        <FormSection title="Over het examen">
          <TextOutput
            label="Examennummer"
            value={declaration.exam.examNumber}
          />
          <DateOutput label="Datum" value={declaration.exam.date} />
        </FormSection>
      )}
      {(canEdit || canApprove) && (
        <>
          <ButtonRow>
            {canEdit && (
              <InlineLink
                type="primary"
                to={`${DECLARATIONS_BASE_ROUTE}/${declaration.id}/bewerken`}
              >
                Bewerken
              </InlineLink>
            )}
            {canApprove && (
              <InlineButton
                onClick={handleApprove}
                disabled={declaration.totalCompensation <= 0}
              >
                Accorderen
              </InlineButton>
            )}
          </ButtonRow>
        </>
      )}
    </Page>
  );
};

export default ViewDeclaration;
