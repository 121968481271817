import { useState } from 'react';

import {
  Checkbox,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ExamDiploma } from '../../../types';
import { formatTime } from '../../../utils/common';

interface ExamLineSelectorProps {
  examLines: ExamDiploma[];
  onChange: (examLines: ExamDiploma[]) => void;
}

const ExamLineSelector = (props: ExamLineSelectorProps) => {
  const { examLines, onChange } = props;

  const [selectedExamLines, setSelectedExamLines] = useState<ExamDiploma[]>([]);

  const handleExamLineChange = (examLine: ExamDiploma, checked: boolean) => {
    const selected = [...selectedExamLines];

    if (checked) {
      selected.push(examLine);
    } else {
      const existingExamIndex = selected.findIndex(
        (el) => el.id === examLine.id,
      );

      if (existingExamIndex !== -1) {
        selected.splice(existingExamIndex, 1);
      }
    }

    setSelectedExamLines(selected);
    onChange(selected);
  };

  return (
    <FormControl>
      <FormLabel>Bij welke onderdelen wil je aanwezig zijn?</FormLabel>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" />
            <TableCell variant="head">Diploma</TableCell>
            <TableCell variant="head">Aantal</TableCell>
            <TableCell variant="head">Starttijd</TableCell>
            <TableCell variant="head">Eindtijd</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {examLines.map((examLine) => (
            <TableRow>
              <TableCell>
                <Checkbox
                  value={selectedExamLines.find((el) => el.id === examLine.id)}
                  onChange={(_event, checked) => {
                    handleExamLineChange(examLine, checked);
                  }}
                />
              </TableCell>
              <TableCell>{examLine.diplomaType.name}</TableCell>
              <TableCell>{examLine.numberParticipants}</TableCell>
              <TableCell>{formatTime(new Date(examLine.beginTime))}</TableCell>
              <TableCell>{formatTime(new Date(examLine.endTime))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormControl>
  );
};

export default ExamLineSelector;
