import { Route, Router } from '../../routing';
import Dashboard from './views/Dashboard';
import PlannedExamOverview from './views/PlannedExamOverview';
import VisitedExamOverview from './views/VisitedExamOverview';

export const EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE = '/geplande-examens';
export const EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE = '/bijgewoonde-examens';

const examinerDashboard = new Route('Dashboard', '/', <Dashboard />, true).role(
  ['ROLE_EXAMINER'],
);
Router.addRoute(examinerDashboard);

const plannedExamOverview = new Route(
  'Geplande examens',
  EXAMINER_PLANNED_EXAM_OVERVIEW_ROUTE,
  <PlannedExamOverview />,
  true,
).role(['ROLE_EXAMINER']);
Router.addRoute(plannedExamOverview);

const visitedExamOverview = new Route(
  'Bijgewoonde examens',
  EXAMINER_VISITED_EXAM_OVERVIEW_ROUTE,
  <VisitedExamOverview />,
  true,
).role(['ROLE_EXAMINER']);
Router.addRoute(visitedExamOverview);
