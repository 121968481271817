import { useState } from 'react';

import { Box, Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import { DelegateTimeSlotDto, User } from '../../../types';
import TimeInput from '../../../components/form/TimeInput';
import DelegateRepository from '../repository/DelegateRepository';
import DayOfWeekSelector from './DayOfWeekSelector';

interface AddDelegateTimeSlotProps {
  delegate: User;
  onSuccess: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  flex: {
    display: 'flex',
  },
  label: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

const AddDelegateTimeSlot = (props: AddDelegateTimeSlotProps) => {
  const { delegate, onSuccess } = props;

  const classes = useStyles();

  const notifications = useSnackbar();

  const initialFrom = new Date('1970-01-01');
  const initialUntil = new Date('1970-01-01');
  initialFrom.setHours(7, 0, 0);
  initialUntil.setHours(22, 0, 0);

  const [timeSlot, setTimeSlot] = useState<DelegateTimeSlotDto>({
    dayOfWeek: 1,
    from: initialFrom,
    until: initialUntil,
  });

  const repository = new DelegateRepository();

  const handleDayOfWeekSelect = (dayOfWeek: number) => {
    setTimeSlot((prevState) => ({
      ...prevState,
      dayOfWeek,
    }));
  };

  const handleFrom = (date: Date | null) => {
    setTimeSlot((prevState) => ({
      ...prevState,
      from: date,
    }));
  };

  const handleUntil = (date: Date | null) => {
    setTimeSlot((prevState) => ({
      ...prevState,
      until: date,
    }));
  };

  const addTimeSlot = () => {
    repository
      .addTimeSlot(delegate.id, timeSlot)
      .then(() => {
        onSuccess();
        notifications.enqueueSnackbar('Tijdslot succesvol toegevoegd.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het toevoegen van het tijdslot.',
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <>
      <Typography variant="h6">Nieuw tijdslot</Typography>
      <Box mb={1} display="flex" alignItems="center">
        <DayOfWeekSelector
          value={timeSlot.dayOfWeek}
          onSelect={handleDayOfWeekSelect}
        />
        <Box className={classes.label}>Van</Box>
        <TimeInput name="from" value={timeSlot.from} onBlur={handleFrom} />
        <Box className={classes.label}>Tot</Box>
        <TimeInput name="until" value={timeSlot.until} onBlur={handleUntil} />
      </Box>
      <Button variant="contained" color="secondary" onClick={addTimeSlot}>
        Toevoegen
      </Button>
    </>
  );
};

export default AddDelegateTimeSlot;
