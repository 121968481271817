import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import { Theme } from '@material-ui/core';
import { MinimalUser, User } from '../../../types';

interface AvatarProps {
  user: MinimalUser | User;
  imitating?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  imitatingBorder: {
    border: `3px solid ${orange[700]}`,
    height: 36,
    width: 36,
    borderRadius: '50%',
  },
  imitatorGhost: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 4,
    left: 37,
    width: 9,
    height: 9,
    padding: theme.spacing(1),
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
  educatorImitator: {
    '& $imitatorGhost': {
      background: theme.palette.secondary.main,
    },
  },
}));

const Avatar = (props: AvatarProps) => {
  const { imitating, className } = props;
  const classes = useStyles();
  const classNames = [className];

  if (imitating) {
    classNames.push(classes.imitatingBorder);
  }

  return (
    <div className={classNames.join(' ')}>
      {imitating && (
        <div className={classes.imitatorGhost}>
          <FontAwesomeIcon
            icon={['fal', 'ghost']}
            style={{ width: 14, height: 14 }}
          />
        </div>
      )}
      <FontAwesomeIcon
        icon={['fal', 'user-circle']}
        style={{ width: 36, height: 36 }}
      />
    </div>
  );
};

export default Avatar;
