import { AxiosResponse } from 'axios';
import { MailTemplate, MailTemplateDTO } from './types';

// remove these two lines once the file contains multiple exports

export const mailTemplateDTOFromResponse = (
  response: AxiosResponse<MailTemplate>,
): MailTemplateDTO => ({
  typeReadable: response.data.typeReadable,
  description: response.data.description,
  subject: response.data.subject,
  content: response.data.content,
});
