import { ReactNode } from 'react';

import { Box, FormControl, FormLabel, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  value: {
    border: `1px solid ${grey[200]}`,
    padding: theme.spacing(1),
    minWidth: '400px',
  },
}));

export interface OutputProps {
  label: string;
  value: any;
  hideIfEmpty?: boolean;
}

interface TextOutputProps extends OutputProps {
  value: ReactNode;
}

const TextOutput = (props: TextOutputProps) => {
  const { label, value, hideIfEmpty } = props;

  const classes = useStyles();

  if (hideIfEmpty && !value) {
    return null;
  }

  return (
    <Box>
      <FormControl>
        <FormLabel>
          <Box className={classes.label}>{label}</Box>
        </FormLabel>
        <Box className={classes.value}>{value}</Box>
      </FormControl>
    </Box>
  );
};

export default TextOutput;
