import { Route, Router } from '../../routing';
import OperatorOverview from './views/OperatorOverview';
import ViewOperator from '../operators/views/ViewOperator';
import CreateOperator from '../operators/views/CreateOperator';
import UpdateOperator from '../operators/views/UpdateOperator';
import MyOrganization from './MyOrganization';
import CustomRedirect from '../../CustomRedirect';

export const OPERATOR_BASE_ROUTE = '/exploitanten';
export const OPERATOR_CREATE_URI = '/aanmaken';
export const OPERATOR_CREATE_ROUTE = OPERATOR_BASE_ROUTE + OPERATOR_CREATE_URI;
export const ORGANIZATION_BASE_ROUTE = '/mijn-organisatie';

const dashboard = new Route(
  'Dashboard',
  '/',
  <CustomRedirect to={'/aangemelde-examens'} />,
  true,
).role('ROLE_OPERATOR_USER');

const operators = new Route(
  'Exploitant',
  OPERATOR_BASE_ROUTE,
  <OperatorOverview />,
  true,
).role(['ROLE_ADMIN']);

const view = new Route(
  'Exploitant bekijken',
  '/:id',
  <ViewOperator isOrganization={false} />,
).role(['ROLE_ADMIN']);
operators.addChild(view);

const create = new Route(
  'Exploitant aanmaken',
  '/aanmaken',
  <CreateOperator />,
).role(['ROLE_SUPER_ADMIN']);
operators.addChild(create);

const update = new Route(
  'Exploitant bewerken',
  '/:id/bewerken',
  <UpdateOperator />,
).role(['ROLE_SUPER_ADMIN']);
operators.addChild(update);

const opsOrganization = new Route(
  'Mijn organisatie',
  ORGANIZATION_BASE_ROUTE,
  <MyOrganization />,
).role(['ROLE_OPERATOR_USER']);

Router.addRoute(dashboard);
Router.addRoute(operators);
Router.addRoute(opsOrganization);
