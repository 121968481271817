import { ReactNode } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';

interface InfoTextProps {
  children: ReactNode;
}

const InfoText = (props: InfoTextProps) => {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <FontAwesomeIcon icon={['fal', 'info-circle']} />
      <Box ml={1}>{props.children}</Box>
    </Box>
  );
};

export default InfoText;
