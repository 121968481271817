import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DECLARATION,
  COLUMN_EXAM_LOCATION,
  COLUMN_EXAM_REPORT_DELEGATE,
} from '../../exams/domain/columns';
import AppContext from '../../../AppContext';
import DataTable, { ItemAction } from '../../../components/DataTable';
import InfoPopover from '../../../components/InfoPopover';
import SearchInput from '../../../components/search/SearchInput';
import SearchContext from '../../../components/search/SearchContext';
import Page from '../../../components/Page';
import ExamsRepository from '../repository/ExamsRepository';
import { ExamColumn } from '../../exams/domain/types';
import { ApiFilterCriteria, Exam, User } from '../../../types';
import { EXAM_BASE_ROUTE } from '../../exams';

const DelegateExamOverview = () => {
  const history = useHistory();
  const { roleViewManager } = useContext(AppContext);
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const delegateId = roleViewManager.getUser().id || '';
  const repository = new ExamsRepository(delegateId);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const columns: ExamColumn[] = [
    COLUMN_EXAM_DATE,
    COLUMN_EXAM_LOCATION,
    COLUMN_EXAM_REPORT_DELEGATE,
    COLUMN_EXAM_DECLARATION,
  ];

  const defaultFilters: ApiFilterCriteria = {
    filters: {
      past: true,
    },
  };

  const title = (
    <>
      {totalCount !== null && `Bijgewoonde examens (${totalCount})`}
      {totalCount === null && 'Bijgewoonde examens'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <SearchInput />
    </Box>
  );

  const itemActions = (exam: Exam) => {
    const actions: ItemAction[] = [];
    if (exam?.aspirant && account.id !== exam.aspirant.id) {
      actions.push({
        key: 'info',
        element: (
          <InfoPopover
            contents={`Aspirant ${exam.aspirant.fullName} aanwezig`}
          />
        ),
      });
    }

    return (
      <Box>
        {actions.map((action) => (
          <React.Fragment key={action.key}>{action.element}</React.Fragment>
        ))}
      </Box>
    );
  };

  const onRowClick = (exam: Exam) => {
    history.push(`${EXAM_BASE_ROUTE}/${exam.id}`);
  };

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="exam-overview"
          repository={repository}
          columns={columns}
          actions={itemActions}
          defaultFilters={defaultFilters}
          resultCounterText={{ singular: 'examen', plural: 'examens' }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          onRowClick={onRowClick}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default DelegateExamOverview;
