import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import Page from '../../../components/Page';
import Button from '../../../components/Button';
import DataTable, { Column } from '../../../components/DataTable';
import SearchInput from '../../../components/search/SearchInput';
import SearchContext from '../../../components/search/SearchContext';
import OperatorRepository from '../repository/OperatorRepository';
import { OPERATOR_BASE_ROUTE, OPERATOR_CREATE_ROUTE } from '../index';

import { Operator, User } from '../../../types';

const OperatorOverview = () => {
  const history = useHistory();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const repository = new OperatorRepository();

  const title = (
    <>
      Exploitanten&nbsp;
      {totalCount && `(${totalCount})`}
    </>
  );

  const operatorCreateAction = (
    <Button
      icon={['fal', 'plus']}
      to={OPERATOR_CREATE_ROUTE}
      label="Exploitant aanmaken"
    />
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>{operatorCreateAction}</Box>
      <SearchInput id="operator-overview-query" persistQuery />
    </Box>
  );

  const columns: Column[] = [];
  columns.push({
    name: 'Naam',
    field: 'name',
    sortable: true,
    render: (operator: Operator) => operator.name,
  });
  columns.push({
    name: 'Aantal locaties',
    field: 'lessonLocationsCount',
    sortable: true,
    render: (operator: Operator) => operator.swimmingLessonProviderCount,
  });

  const onRowClick = (operator: Operator) => {
    history.push(`${OPERATOR_BASE_ROUTE}/${operator.id}`);
  };

  const styleRow = (operator: Operator) =>
    operator?.deleted ? 'trHighlight' : 'tr';

  const deleteItemMessage = (operator: Operator) =>
    `Weet je zeker dat je exploitant ${operator.id} wilt verwijderen?`;

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="operator-overview"
          repository={repository}
          columns={columns}
          onRowClick={onRowClick}
          styleRow={styleRow}
          resultCounterText={{
            singular: 'exploitant',
            plural: 'exploitanten',
          }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          deletable={account.roles.includes('ROLE_SUPER_ADMIN')}
          deleteItemMessage={deleteItemMessage}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default OperatorOverview;
