import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Flow as IFlow } from 'flowjs';

import DeclarationTotalAmount from '../components/DeclarationTotalAmount';
import AppContext from '../../../AppContext';
import ButtonRow from '../../../components/ButtonRow';
import InlineButton from '../../../components/InlineButton';
import Loader from '../../../components/Loader';
import FileUpload from '../../../components/file/FileUpload';
import FlowProgress from '../../../components/file/FlowProgress';
import AmountInput from '../../../components/form/AmountInput';
import { createFlow } from '../../../utils/common';
import { DECLARATIONS_BASE_ROUTE } from '../index';
import {
  DeclarationDto,
  DeclarationTravelMethod,
  Delegate,
  File as FileInterface,
} from '../../../types';
import DelegateSelect from '../../admin/forms/DelegateSelect';
import ConfigRepository from '../../config/ConfigRepository';

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    height: theme.typography.h1.fontSize,
  },
  explanation: {
    width: '100%',
    maxWidth: 300,
  },
}));

interface DeclarationFormProps {
  declaration?: DeclarationDto;
  examId?: string;
  delegateId?: string;
  onSubmit: (declaration: DeclarationDto) => void;
}

const DeclarationForm = (props: DeclarationFormProps) => {
  const {
    declaration: existingDeclaration,
    examId: initialExamId,
    delegateId: initialDelegateId,
    onSubmit,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const { roleViewManager } = useContext(AppContext);

  const [declaration, setDeclaration] = useState<DeclarationDto>(
    existingDeclaration || {
      id: null,
      examId: initialExamId || '',
      delegateId: initialDelegateId || '',
      baseCompensation: 0,
      travelMethod: null,
      travelCompensation: 0,
      additionalCosts: 0,
      kilometers: null,
      kilometerCompensation: 0,
      explanation: null,
      creditDescription: '',
      attachments: [],
    },
  );

  const flow: IFlow = createFlow();

  const [files, setFiles] = useState<File[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const allowManualBaseCompensation =
    roleViewManager.isSuperAdminView() && !declaration.examId;

  const handleDelegateChange = (delegate: Delegate | null) => {
    setDeclaration({
      ...declaration,
      delegateId: delegate ? delegate.id : '',
    });
  };

  const handleBaseCompensationChange = (value: number | null) => {
    setDeclaration({
      ...declaration,
      baseCompensation: value || 0,
    });
  };

  const handleTravelMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeclaration({
      ...declaration,
      travelMethod: event.target.value as keyof typeof DeclarationTravelMethod,
    });
  };

  const handleTravelCompensationChange = (value: number | null) => {
    setDeclaration({
      ...declaration,
      travelCompensation: value || 0,
    });
  };

  const handleAdditionalCostsChange = (value: number | null) => {
    setDeclaration({
      ...declaration,
      additionalCosts: value || 0,
    });
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeclaration({
      ...declaration,
      [event.target.name]:
        event.target.value.length > 0 ? parseFloat(event.target.value) : '',
    });
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeclaration({ ...declaration, [event.target.name]: event.target.value });
  };

  const handleUploadChange = (files: File[]) => setFiles(files);

  const submitUploadRequest = () => {
    const data = { ...declaration };

    files.forEach((f) => flow.addFile(f));

    flow.on('fileSuccess', (_flowFile, message) => {
      const response: FileInterface = JSON.parse(message);
      data.attachments = [...data.attachments, response.id];
    });

    flow.on('complete', () => onSubmit(data));

    flow.upload();
  };

  const handleSubmit = () => {
    submitUploadRequest();
  };

  const handleCancel = () => {
    if (declaration.id) {
      history.push(`${DECLARATIONS_BASE_ROUTE}/${declaration.id}`);
    } else {
      history.go(-1);
    }
  };

  useEffect(() => {
    new ConfigRepository().getConfig().then((response) => {
      const baseCompensation = parseInt(
        response.data.declaration_base_compensation.value || '0',
        10,
      );
      const kilometerCompensation = parseInt(
        response.data.declaration_kilometer_compensation.value || '0',
        10,
      );
      setDeclaration((prev) => ({
        ...prev,
        baseCompensation: allowManualBaseCompensation ? 0 : baseCompensation,
        kilometerCompensation: kilometerCompensation,
      }));
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  return (
    <>
      {roleViewManager.isSuperAdminView() && !declaration.id && (
        <Box my={3}>
          <FormLabel>Gedelegeerde</FormLabel>
          <Box mt={2}>
            <DelegateSelect onSelect={handleDelegateChange} />
          </Box>
        </Box>
      )}
      {roleViewManager.isSuperAdminView() && !declaration.examId && (
        <Box my={3}>
          <FormLabel>Omschrijving bankafschrift</FormLabel>
          <Box mt={2}>
            <TextField
              name="creditDescription"
              onChange={handleTextChange}
              fullWidth
              value={declaration.creditDescription}
            />
          </Box>
        </Box>
      )}
      <FormLabel id="fixed-compensation-select-label">
        Vaste vergoeding
      </FormLabel>
      <Box mt={2}>
        <AmountInput
          name="baseCompensation"
          value={declaration.baseCompensation}
          onBlur={handleBaseCompensationChange}
          disabled={!allowManualBaseCompensation}
        />
      </Box>
      <Box my={3}>
        <FormControl>
          <FormLabel>Vervoer</FormLabel>
          <RadioGroup
            value={declaration.travelMethod}
            name="action"
            onChange={handleTravelMethodChange}
          >
            <FormControlLabel
              value="own"
              control={<Radio />}
              label="Eigen gelegenheid (auto, motor)"
            />
            <FormControlLabel
              value="public_transport"
              control={<Radio />}
              label="Openbaar"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {declaration.travelMethod === 'own' && (
        <Box my={3}>
          <Grid container spacing={3}>
            <Grid item>
              <FormLabel>Opgegeven kilometers</FormLabel>
              <Box mt={2}>
                <TextField
                  type="number"
                  name="kilometers"
                  onChange={handleNumberChange}
                  value={declaration.kilometers}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {declaration.travelMethod === 'public_transport' && (
        <Box my={3}>
          <Grid container spacing={3}>
            <Grid item>
              <FormLabel>Gemaakte kosten</FormLabel>
              <Box mt={2}>
                <AmountInput
                  name="travelCompensation"
                  onBlur={handleTravelCompensationChange}
                  value={declaration.travelCompensation}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box my={3}>
        <FormLabel>Overige kosten</FormLabel>
        <Box mt={2}>
          <AmountInput
            name="additionalCosts"
            onBlur={handleAdditionalCostsChange}
            value={declaration.additionalCosts}
          />
        </Box>
      </Box>
      <Box my={3}>
        <FormLabel>Toelichting</FormLabel>
        <Box mt={2}>
          <TextField
            multiline
            minRows={5}
            fullWidth
            name="explanation"
            onChange={handleTextChange}
            className={classes.explanation}
            defaultValue={declaration.explanation}
          />
        </Box>
      </Box>
      <Box my={3}>
        <FormLabel>Bijlagen</FormLabel>
        <FileUpload onChange={handleUploadChange} documents images />
      </Box>
      <Box>
        <FlowProgress flow={flow} />
      </Box>
      <Box my={3}>
        <DeclarationTotalAmount declaration={declaration} />
      </Box>
      <ButtonRow>
        <InlineButton type="primary" onClick={handleSubmit}>
          Opslaan
        </InlineButton>
        <InlineButton onClick={handleCancel}>Annuleren</InlineButton>
      </ButtonRow>
    </>
  );
};

export default DeclarationForm;
