import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Input, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchContext from './SearchContext';
import AppContext from '../../AppContext';

interface SearchInputProps {
  id?: string | null;
  className?: string;
  placeholder?: string;
  persistQuery?: boolean;
}

const SearchInput = ({
  id = null,
  className = '',
  placeholder = 'Zoeken...',
  persistQuery = false,
}: SearchInputProps) => {
  const [ready, setReady] = useState<boolean>(false);
  const { query, setQuery } = useContext(SearchContext);
  const { localStore } = useContext(AppContext);

  if (persistQuery && !id) {
    console.warn('Search input requires ID in order to persist query.');
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!setQuery) {
      return;
    }

    const { value } = e.target;

    setQuery(value);

    if (persistQuery && id) {
      localStore.setItem<string>(`${id}_search_query`, value);
    }
  };

  useEffect(() => {
    if (persistQuery && id && setQuery) {
      localStore
        .getItem<string>(`${id}_search_query`)
        .then((value) => {
          if (value) {
            setQuery(value);
          }
        })
        .finally(() => setReady(true));
    } else {
      setReady(true);
    }
  }, []);

  if (!ready) {
    return <div />;
  }

  const startAdornment = (
    <InputAdornment position="start">
      <FontAwesomeIcon icon={['fal', 'search']} />
    </InputAdornment>
  );

  return (
    <Input
      type="text"
      onChange={handleSearch}
      value={query}
      data-testid="search-box"
      className={className}
      placeholder={placeholder}
      startAdornment={startAdornment}
    />
  );
};

export default SearchInput;
