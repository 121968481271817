import { Box, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { Exam } from '../../../types';
import { formatDate } from '../../../utils/common';

interface ExamTime {
  exam: Exam;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.dark,
  },
  time: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const ExamTime = (props: ExamTime) => {
  const { exam } = props;
  const classes = useStyles();

  let beginTime: Date | null = null;
  let endTime: Date | null = null;

  exam.examLines.forEach((line) => {
    const lineBegin = new Date(line.beginTime);
    const lineEnd = new Date(line.endTime);

    if (!beginTime || !endTime) {
      beginTime = lineBegin;
      endTime = lineEnd;
      return;
    }

    if (lineBegin < beginTime) {
      beginTime = lineBegin;
    }

    if (lineEnd > endTime) {
      endTime = lineEnd;
    }
  });

  return (
    <Box className={classes.root}>
      <Box display="block">{formatDate(new Date(exam.date))}</Box>
      {beginTime && endTime && (
        <Box className={classes.time}>
          {`${format(beginTime, 'HH:mm')} - ${format(endTime, 'HH:mm')}`}
        </Box>
      )}
    </Box>
  );
};

export default ExamTime;
