import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { colors } from '../config/theme';

interface ButtonProps {
  icon: IconProp;
  label: string;
  variant?: 'default' | 'contained';
  color?: 'default' | 'primary';
  iconColor?: 'default' | 'green' | 'red';
  iconClassName?: string;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  to?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: 0,
    borderRadius: 50,
    fontFamily: theme.typography.body1.fontFamily,
    '&:hover': {
      cursor: 'pointer',
      '& $icon': {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
        '& .svg-inline--fa': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
  primaryColor: {
    '&$contained': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.dark,
        '& $icon': {
          color: theme.palette.primary.dark,
          background: '#FFF',
          '& .svg-inline--fa': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
  },
  contained: {
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    background: colors.whiteLilac,
    transition: theme.transitions.create(['background', 'color']),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    width: 28,
    height: 28,
    borderRadius: '50%',
    background: '#FFF',
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    transition: theme.transitions.create(['background', 'color']),
    '& .svg-inline--fa': {
      width: 16,
      height: 16,
      transition: theme.transitions.create(['background', 'color']),
    },
  },
  iconColorGreen: {
    '& .svg-inline--fa': {
      color: colors.green,
    },
  },
  iconColorRed: {
    '& .svg-inline--fa': {
      color: colors.red,
    },
  },
  reverse: {
    flexDirection: 'row-reverse',
    '& $icon': {
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
  },
}));

const Button = ({
  variant,
  color,
  icon,
  iconColor,
  label,
  onClick,
  iconClassName,
  iconPosition = 'left',
  to,
}: ButtonProps) => {
  const classes = useStyles();
  const history = useHistory();

  const rootClasses = [classes.root];

  if (variant === 'contained') {
    rootClasses.push(classes.contained);
  }

  if (color === 'primary') {
    rootClasses.push(classes.primaryColor);
  }

  if (iconPosition === 'right') {
    rootClasses.push(classes.reverse);
  }

  const iconClasses = [classes.icon];

  if (iconColor === 'green') {
    iconClasses.push(classes.iconColorGreen);
  } else if (iconColor === 'red') {
    iconClasses.push(classes.iconColorRed);
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (to !== undefined) {
      history.push(to);
    }
  };

  return (
    <button
      type="button"
      className={rootClasses.join(' ')}
      onClick={handleClick}
    >
      <span className={iconClasses.join(' ')}>
        <FontAwesomeIcon icon={icon} className={iconClassName} />
      </span>
      <span>{label}</span>
    </button>
  );
};

export default Button;
