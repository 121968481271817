import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Page';
import DiplomaTypeForm from '../forms/DiplomaTypeForm';
import DiplomaTypeRepository from '../repository/DiplomaTypeRepository';
import { DiplomaTypeDTO } from '../domain/types';
import { DIPLOMA_CATEGORY_OVERVIEW_ROUTE } from '../index';

const CreateDiplomaType = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const history = useHistory();
  const notifications = useSnackbar();
  const repository = new DiplomaTypeRepository(categoryId);

  const submit = (data: DiplomaTypeDTO) => {
    repository
      .create(data)
      .then(() => {
        history.push(
          `${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/${categoryId}/diplomatypen`,
        );
        notifications.enqueueSnackbar('Succesvol aangemaakt', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het aanmaken', {
          variant: 'error',
        });
      });
  };

  return (
    <Page title="Diplomatype aanmaken">
      <DiplomaTypeForm categoryId={categoryId} onSubmit={submit} />
    </Page>
  );
};

export default CreateDiplomaType;
