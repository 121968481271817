import React, { ReactNode } from 'react';
import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from './PageContainer';

interface PageProps {
  children: ReactNode;
  title?: string | React.ReactElement;
  actions?: ReactNode;
  breadcrumbs?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const Page = (props: PageProps) => {
  const { children, title, actions, breadcrumbs } = props;

  const classes = useStyles();

  return (
    <PageContainer title={title} actions={actions} breadcrumbs={breadcrumbs}>
      <Paper className={classes.paper}>{children}</Paper>
    </PageContainer>
  );
};

export default Page;
