import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface HtmlEditorProps {
  data?: string;
  onChange?: (event: any, editor: any) => void;
  placeholder?: string;
  disabled?: boolean;
}

const HtmlEditor = ({
  data = '',
  placeholder = '',
  onChange,
  disabled = false,
}: HtmlEditorProps) => (
  <CKEditor
    editor={ClassicEditor}
    data={data}
    config={{
      removePlugins: ['MediaEmbed', 'EasyImage', 'ImageUpload'],
      placeholder,
      link: {
        addTargetToExternalLinks: true,
      },
    }}
    onChange={onChange}
    disabled={disabled}
  />
);

export default HtmlEditor;
