import { AxiosResponse } from 'axios';

import {
  ApiFilterCriteria,
  CollectionResponse,
  Declaration,
  DeclarationDto,
  DelegateAssignmentOfExam,
  Exam,
  Repository,
  SwimmingLessonProvider,
  SwimmingLessonProviderType,
  ValidationFailedResponse,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';
import { ExamReportEntry } from '../domain/types';

export interface ConflictingExamResponse extends AxiosResponse {
  data: {
    error: 'conflicting_exam';
    beginTime: string;
    endTime: string;
  };
}

export interface ExamDiplomaDTO {
  id: string;
  diplomaTypeId: string;
  examinerId: string;
  beginTime: Date | null;
  endTime: Date | null;
  amount: number;
  amountPassed: number;
}

export interface ExamDTO {
  swimmingLessonProviderId: string;
  examNumber?: number | null;
  date: Date | null;
  oneExaminerOverall: boolean;
  examLocationId: string;
  examinerId: string;
  diplomas: ExamDiplomaDTO[];
  menOnly: boolean;
  womenOnly: boolean;
  lessonLocationIds: string[];
}

export type DelegateAssignmentActionType = 'decline' | 'confirm' | 'cancel';

class ExamRepository implements Repository<Exam> {
  private readonly past: boolean;

  constructor(past: boolean = false) {
    this.past = past;
  }

  find(id: string): Promise<AxiosResponse<Exam>> {
    return ApiClient.get(`/api/v1/exams/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<Exam>> {
    const params = parseCriteria(criteria, {
      page: page || 0,
      perPage: perPage || 10,
      past: this.past,
    });

    return ApiClient.get(`/api/v1/exams`, params);
  }

  getSwimmingLessonProviders(
    type?: SwimmingLessonProviderType,
  ): Promise<CollectionResponse<SwimmingLessonProvider>> {
    return ApiClient.get('/api/v1/swimming-lesson-providers', {
      page: 1,
      perPage: 1e6, // one million
      type,
      activeOnly: true,
    });
  }

  create(
    exam: ExamDTO,
  ): Promise<
    AxiosResponse<Exam> | ValidationFailedResponse | ConflictingExamResponse
  > {
    return ApiClient.post(`/api/v1/exams`, exam);
  }

  update(
    id: string,
    exam: ExamDTO,
  ): Promise<
    AxiosResponse<Exam> | ValidationFailedResponse | ConflictingExamResponse
  > {
    return ApiClient.put(`/api/v1/exams/${id}`, exam);
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/v1/exams/${id}`);
  }

  updateExamLines(
    id: string,
    exam: ExamDTO,
  ): Promise<
    AxiosResponse<Exam> | ValidationFailedResponse | ConflictingExamResponse
  > {
    return ApiClient.put(`/api/v1/exams/${id}/exam-lines`, exam);
  }

  getDelegates(id: string): Promise<AxiosResponse<DelegateAssignmentOfExam[]>> {
    return ApiClient.get(`/api/v1/exams/${id}/delegates`);
  }

  assignExamDelegates(
    id: string,
    delegateIds: string[],
  ): Promise<AxiosResponse<Exam>> {
    return ApiClient.post(`/api/v1/exams/${id}/delegates/assign`, {
      delegateIds,
    });
  }

  inviteExamDelegates(
    id: string,
    delegateIds: string[],
  ): Promise<AxiosResponse<Exam>> {
    return ApiClient.post(`/api/v1/exams/${id}/delegates/invite`, {
      delegateIds,
    });
  }

  deleteAssignment(
    id: string,
    delegateId: string,
  ): Promise<AxiosResponse<Exam>> {
    return ApiClient.delete(
      `/api/v1/exams/${id}/delegate-assignments/${delegateId}`,
    );
  }

  handleAssignment(
    id: string,
    delegateId: string,
    action: DelegateAssignmentActionType,
  ): Promise<AxiosResponse<Exam>> {
    return ApiClient.put(
      `/api/v1/exams/${id}/delegate-assignments/${delegateId}/${action}`,
    );
  }

  getExaminerReport(id: string): Promise<AxiosResponse<ExamReportEntry>> {
    return ApiClient.get(`/api/v1/exams/${id}/examiner-report`);
  }

  getDelegateReport(id: string): Promise<AxiosResponse<ExamReportEntry>> {
    return ApiClient.get(`/api/v1/exams/${id}/delegate-report`);
  }

  getAspirantReport(id: string): Promise<AxiosResponse<ExamReportEntry>> {
    return ApiClient.get(`/api/v1/exams/${id}/aspirant-report`);
  }

  updatePassedParticipants(
    id: string,
    examLines: ExamDiplomaDTO[],
  ): Promise<AxiosResponse> {
    return ApiClient.put(`/api/v1/exams/${id}/passed-participants`, {
      diplomas: examLines,
    });
  }

  getDeclarations(id: string): Promise<AxiosResponse<Declaration[]>> {
    return ApiClient.get(`/api/v1/exams/${id}/declarations`);
  }

  createDeclaration(
    id: string,
    declaration: DeclarationDto,
  ): Promise<AxiosResponse> {
    return ApiClient.post(`/api/v1/exams/${id}/declarations`, declaration);
  }
}

export default ExamRepository;
