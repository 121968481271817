import { createContext } from 'react';

const ExamReportContext = createContext<{
  data: { [key: string]: { value: unknown; explanation?: string } };
  changeField: (fieldId: string, value: unknown, explanation?: string) => void;
  locked: boolean;
}>({
  data: {},
  changeField: () => {},
  locked: false,
});

export default ExamReportContext;
