import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  Examiner,
  LessonLocation,
  SwimmingLessonProvider,
} from '../../../types';

import Loader from '../../../components/Loader';
import SLPExaminerSelector from './SLPExaminerSelector';
import AppContext from '../../../AppContext';
import SimpleTable from '../../../components/SimpleTable';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';
import { Column } from '../../../components/DataTable';
import { formatAddress, formatDate } from '../../../utils/common';

interface SLPLessonLocationListProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  isAllowedToEdit: boolean;
}

const SLPLessonLocationList = (props: SLPLessonLocationListProps) => {
  const { swimmingLessonProvider, isAllowedToEdit } = props;

  const history = useHistory();
  const notifications = useSnackbar();
  const { roleViewManager } = useContext(AppContext);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [lessonLocations, setLessonLocations] = useState<
    LessonLocation[] | null
  >(null);

  const repository = new SwimmingLessonProviderRepository();

  const canLinkExaminers =
    !swimmingLessonProvider.centralExams && isAllowedToEdit;

  const handleItemSelect = (ids: string[]) => {
    setSelectedIds(ids);
  };

  const handleSubmit = (examiner: Examiner) => {
    repository
      .linkExaminerToLessonLocations(
        swimmingLessonProvider.id,
        examiner.id,
        selectedIds,
      )
      .then(() => {
        notifications.enqueueSnackbar(
          'De examinator is succesvol gekoppeld aan de leslocaties!',
          {
            variant: 'success',
          },
        );
        history.go(0);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het koppelen van de examinator aan de leslocaties.',
          {
            variant: 'error',
          },
        );
      });
  };

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (lessonLocation: LessonLocation) => lessonLocation.name,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (lessonLocation: LessonLocation) => lessonLocation.email,
    },
    {
      name: 'Telefoon',
      field: 'telephone',
      render: (lessonLocation: LessonLocation) => lessonLocation.telephone,
    },
    {
      name: 'Adres',
      field: 'visitingAddress',
      render: (lessonLocation: LessonLocation) =>
        formatAddress(lessonLocation.visitingAddress),
    },
    {
      name: 'Licenties',
      field: 'licenses',
      render: (lessonLocation: LessonLocation) => {
        const licenses = [];
        if (lessonLocation.licenseSwimAbc) {
          licenses.push('zwem-ABC');
        }
        if (lessonLocation.licenseSnorkeling) {
          licenses.push('snorkelen');
        }
        if (lessonLocation.licenseSurvival) {
          licenses.push('survival');
        }
        return licenses.join(', ');
      },
    },
  ];

  if (roleViewManager.isAdminView()) {
    columns.push({
      name: 'Laatste bezoek',
      field: 'mostRecentVisit',
      render: (lessonLocation: LessonLocation) =>
        lessonLocation?.mostRecentVisitDate
          ? formatDate(new Date(lessonLocation.mostRecentVisitDate))
          : null,
    });
  }

  useEffect(
    () => {
      repository
        .getLessonLocations(swimmingLessonProvider.id)
        .then((response) => setLessonLocations(response.data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swimmingLessonProvider.id],
  );

  if (!lessonLocations) {
    return <Loader inline />;
  }

  return (
    <>
      <SimpleTable
        items={lessonLocations}
        columns={columns}
        canSelectItems={canLinkExaminers}
        onItemSelect={handleItemSelect}
      />
      {canLinkExaminers && (
        <SLPExaminerSelector
          swimmingLessonProvider={swimmingLessonProvider}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default SLPLessonLocationList;
