import { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';

import AppContext from '../../../AppContext';
import Button from '../../../components/Button';
import DataTable, { Filter } from '../../../components/DataTable';
import SearchInput from '../../../components/search/SearchInput';
import SearchContext from '../../../components/search/SearchContext';
import Page from '../../../components/Page';
import DeclarationsRepository from '../repository/DeclarationsRepository';
import { Declaration, DeclarationStatus } from '../../../types';
import { DECLARATIONS_BASE_ROUTE } from '../index';
import {
  COLUMN_DECLARATION_DELEGATE,
  COLUMN_DECLARATION_EXAM_DATE,
  COLUMN_DECLARATION_LATEST_ACTION,
  COLUMN_DECLARATION_STATUS,
  COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER,
  COLUMN_DECLARATION_TOTAL_COMPENSATION,
  DeclarationColumn,
} from '../domain/columns';

const DeclarationsOverview = () => {
  const history = useHistory();
  const { roleViewManager } = useContext(AppContext);

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const repository = new DeclarationsRepository();

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const columns: DeclarationColumn[] = [
    COLUMN_DECLARATION_EXAM_DATE,
    COLUMN_DECLARATION_SWIMMING_LESSON_PROVIDER,
    COLUMN_DECLARATION_DELEGATE,
    COLUMN_DECLARATION_TOTAL_COMPENSATION,
    COLUMN_DECLARATION_STATUS,
    COLUMN_DECLARATION_LATEST_ACTION,
  ];

  const filters: Filter[] = [
    {
      field: 'status',
      name: 'Status',
      type: 'checkbox',
      options: Object.entries(DeclarationStatus).map(([value, label]) => ({
        value,
        label,
      })),
    },
  ];

  const handleRowClick = (declaration: Declaration) =>
    history.push(`${DECLARATIONS_BASE_ROUTE}/${declaration.id}`);

  const title = (
    <>
      {totalCount !== null && `Declaraties (${totalCount})`}
      {totalCount === null && 'Declaraties'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      {roleViewManager.isSuperAdminView() && (
        <Box mr={2}>
          <Button
            icon={['fal', 'plus']}
            to={`${DECLARATIONS_BASE_ROUTE}/aanmaken`}
            label="Declaratie aanmaken"
          />
        </Box>
      )}
      <SearchInput id="declarations-overview-query" persistQuery />
    </Box>
  );

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="declarations-overview"
          repository={repository}
          columns={columns}
          filters={filters}
          persistFilters
          styleRow={(declaration: Declaration) => {
            return declaration.status === 'open' ? 'trHighlight' : 'tr';
          }}
          resultCounterText={{ singular: 'declaratie', plural: 'declaraties' }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          onRowClick={handleRowClick}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default DeclarationsOverview;
