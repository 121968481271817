import { Box, Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ExamLocation } from '../../../types';
import { colors } from '../../../config/theme';

interface ExamLocationInfoProps {
  examLocation: ExamLocation;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    width: '100%',
    background: colors.blackSqueeze,
    padding: theme.spacing(2),
  },
}));

const ExamLocationInfo = (props: ExamLocationInfoProps) => {
  const classes = useStyles();
  const { examLocation } = props;
  const { visitingAddress: address } = examLocation;

  return (
    <Paper className={classes.root}>
      {examLocation.name}
      {address && (
        <div>
          <Box>
            {`${address.street} ${address.number} ${address.suffix || ''}`}
          </Box>
          <Box>{`${address.postalCode} ${address.town}`}</Box>
        </div>
      )}
      {examLocation.telephone && <div>{examLocation.telephone}</div>}
      {examLocation.email && <div>{examLocation.email}</div>}
    </Paper>
  );
};

export default ExamLocationInfo;
