import { useState } from 'react';

import { useSnackbar } from 'notistack';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import InlineButton from '../../../components/InlineButton';
import { SwimmingLessonProvider } from '../../../types';
import SwimmingLessonProviderRepository from '../repository/SwimmingLessonProviderRepository';

interface ConvertToSLPButtonProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  onSuccess: () => void;
}

const ConvertToSLPButton = (props: ConvertToSLPButtonProps) => {
  const notifications = useSnackbar();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const convertToSwimmingLessonProvider = () => {
    new SwimmingLessonProviderRepository()
      .convertToSwimmingLessonProvider(props.swimmingLessonProvider.id)
      .then(() => {
        notifications.enqueueSnackbar(
          'De zelfstandige leslocatie is succesvol omgezet in een hoofdlocatie.',
          { variant: 'success' },
        );
        props.onSuccess();
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het omzetten van de zelfstandige leslocatie.',
          { variant: 'error' },
        );
      })
      .finally(() => closeConfirmationDialog());
  };

  return (
    <>
      <InlineButton type="dangerous" onClick={openConfirmationDialog}>
        Veranderen in hoofdlocatie
      </InlineButton>
      <ConfirmationDialog
        title="Veranderen in hoofdlocatie"
        query="Weet je zeker dat je deze zelfstandige leslocatie wilt omzetten in een hoofdlocatie?"
        buttonText="Veranderen"
        isOpen={confirmationDialogOpen}
        onClose={closeConfirmationDialog}
        onConfirm={() => convertToSwimmingLessonProvider()}
      />
    </>
  );
};

export default ConvertToSLPButton;
