import { Box, Theme } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { Exam, ExamDiploma } from '../../../types';

interface ExamDiplomaCountsProps {
  exam: Exam;
}

const useStyles = makeStyles((theme: Theme) => ({
  examLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  name: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  amount: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const ExamDiplomaCounts = (props: ExamDiplomaCountsProps) => {
  const { exam } = props;
  const classes = useStyles();

  const examLines = exam.examLines.sort(
    (examLine1: ExamDiploma, examLine2: ExamDiploma) => {
      return examLine1.diplomaType.shortName.localeCompare(
        examLine2.diplomaType.shortName,
      );
    },
  );

  return (
    <Box display="flex">
      {examLines.map((line) => (
        <Box key={line.diplomaType.shortName} className={classes.examLine}>
          <Box display="flex" className={classes.name}>
            {line.diplomaType.shortName}
          </Box>
          <Box display="flex" className={classes.amount}>
            {line.numberParticipants}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ExamDiplomaCounts;
