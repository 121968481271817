import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PageContainer from '../PageContainer';
import AppContext from '../../AppContext';

interface ErrorPageProps {
  title: string;
  illustration?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  description?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
  },
  illustration: {
    height: 250,
    width: 'auto',
    marginBottom: theme.spacing(3),
  },
  title: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 19,
    fontWeight: 300,
  },
}));

const ErrorPage = (props: ErrorPageProps) => {
  const { title, description } = props;
  const classes = useStyles();
  const history = useHistory();
  const { appState } = useContext(AppContext);
  const { errorDetails } = appState!;

  const Illustration = props.illustration;

  return (
    <PageContainer centered>
      <Box className={classes.container} mt={5}>
        {Illustration && <Illustration className={classes.illustration} />}
        <Typography variant="h1" component="h1" className={classes.title}>
          {title}
        </Typography>
        {description && <h3 className={classes.text}>{description}</h3>}
        {errorDetails}
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push('/')}
          >
            Terug naar startpagina
          </Button>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ErrorPage;
