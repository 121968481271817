import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
  ValidationFailedResponse,
} from '../../../types';
import { parseCriteria } from '../../../utils/common';
import { MailTemplate, MailTemplateDTO } from '../types';

class MailTemplateRepository implements Repository<MailTemplate> {
  find(id: string): Promise<AxiosResponse<MailTemplate>> {
    return ApiClient.get(`/api/v1/mail-templates/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<MailTemplate>> {
    const params = parseCriteria(criteria, {
      page: page || 0,
      perPage: perPage || 10,
    });

    return ApiClient.get(`/api/v1/mail-templates`, params);
  }

  update(
    id: string,
    mailTemplateDTO: MailTemplateDTO,
  ): Promise<AxiosResponse<MailTemplate> | ValidationFailedResponse> {
    return ApiClient.put(`/api/v1/mail-templates/${id}`, mailTemplateDTO);
  }
}

export default MailTemplateRepository;
