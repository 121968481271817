import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import {
  ApiFilterCriteria,
  Declaration,
  HydraCollectionResponse,
  Repository,
  User,
  UserDTO,
} from '../../../types';
import { parseCriteria } from '../../../utils/common';

class UserRepository implements Repository<User> {
  getAccount(): Promise<AxiosResponse<User>> {
    return ApiClient.get('/api/v1/account');
  }

  find(id: string): Promise<AxiosResponse<User>> {
    return ApiClient.get(`/api/v1/users/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page: number = 1,
    perPage: number = 10,
  ): Promise<HydraCollectionResponse<User>> {
    const params = parseCriteria(criteria, {
      page,
      itemsPerPage: perPage,
    });

    return ApiClient.get('/api/v1/users', params);
  }

  update(id: string, user: UserDTO) {
    return ApiClient.put(`/api/v1/users/${id}`, user);
  }

  create(user: UserDTO) {
    return ApiClient.post('/api/v1/users', user);
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/v1/users/${id}`);
  }

  activateUser(user: User) {
    return ApiClient.post('/api/v1/users/activate', [user.id]);
  }

  demoteManagerToUser(id: string) {
    return ApiClient.post(`/api/v1/users/${id}/demote-manager-to-user`);
  }

  promoteUserToManager(id: string) {
    return ApiClient.post(`/api/v1/users/${id}/promote-user-to-manager`);
  }

  linkToSwimmingLessonProvider(id: string, swimmingLessonProviderId: string) {
    return ApiClient.post(
      `/api/v1/users/${id}/link-to-swimming-lesson-provider`,
      {
        swimmingLessonProviderId,
      },
    );
  }

  unlinkFromSwimmingLessonProvider(id: string) {
    return ApiClient.post(
      `/api/v1/users/${id}/unlink-from-swimming-lesson-provider`,
    );
  }

  getLessonLocations(id: string) {
    return ApiClient.get(`/api/v1/users/${id}/lesson-locations`);
  }

  getDeclarations(id: string): Promise<AxiosResponse<Declaration[]>> {
    return ApiClient.get(`/api/v1/users/${id}/declarations`);
  }
}

export default UserRepository;
