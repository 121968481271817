import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Examiner,
  ExamLocation,
  LessonLocation,
  Repository,
  SwimmingLessonProvider,
  SwimmingLessonProviderDTO,
  User,
  ValidationFailedResponse,
} from '../../../types';
import { parseCriteria } from '../../../utils/common';
import { DiplomaCategory } from '../../diploma/domain/types';

class SwimmingLessonProviderRepository
  implements Repository<SwimmingLessonProvider>
{
  find(id: string): Promise<AxiosResponse<SwimmingLessonProvider>> {
    return ApiClient.get(`/api/v1/swimming-lesson-providers/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<SwimmingLessonProvider>> {
    const params = parseCriteria(criteria, {
      page: page || 0,
      perPage: perPage || 10,
    });

    return ApiClient.get(`/api/v1/swimming-lesson-providers`, params);
  }

  update(
    id: string,
    swimmingLessonProviderDTO: SwimmingLessonProviderDTO,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.put(
      `/api/v1/swimming-lesson-providers/${id}`,
      swimmingLessonProviderDTO,
    );
  }

  getDiplomaCategoriesForExam(
    id: string,
    lessonLocationIds: string[],
  ): Promise<AxiosResponse<DiplomaCategory[]>> {
    return ApiClient.get(
      `/api/v1/swimming-lesson-providers/${id}/diploma-categories-for-exam`,
      { lessonLocations: lessonLocationIds.join(',') },
    );
  }

  getExaminersForExam(
    id: string,
    lessonLocationIds: string[],
  ): Promise<AxiosResponse<Examiner[]>> {
    return ApiClient.get(
      `/api/v1/swimming-lesson-providers/${id}/examiners-for-exam`,
      { lessonLocations: lessonLocationIds.join(',') },
    );
  }

  getLessonLocations(id: string): Promise<AxiosResponse<LessonLocation[]>> {
    return ApiClient.get(
      `/api/v1/swimming-lesson-providers/${id}/lesson-locations`,
    );
  }

  getExamLocations(id: string): Promise<AxiosResponse<ExamLocation[]>> {
    return ApiClient.get(
      `/api/v1/swimming-lesson-providers/${id}/exam-locations`,
    );
  }

  getExaminers(id: string): Promise<AxiosResponse<Examiner[]>> {
    return ApiClient.get(`/api/v1/swimming-lesson-providers/${id}/examiners`);
  }

  getBlockedDelegates(id: string): Promise<AxiosResponse<User[]>> {
    return ApiClient.get(
      `/api/v1/swimming-lesson-providers/${id}/blocked-delegates`,
    );
  }

  getUsers(id: string): Promise<AxiosResponse<User[]>> {
    return ApiClient.get(`/api/v1/swimming-lesson-providers/${id}/users`);
  }

  addExamLocation(
    id: string,
    examLocationId: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.post(
      `/api/v1/swimming-lesson-providers/${id}/exam-locations`,
      {
        examLocationId,
      },
    );
  }

  removeExamLocation(
    id: string,
    examLocationId: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.delete(
      `/api/v1/swimming-lesson-providers/${id}/exam-locations/${examLocationId}`,
    );
  }

  addExaminer(
    id: string,
    code: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.post(`/api/v1/swimming-lesson-providers/${id}/examiners`, {
      code,
    });
  }

  removeExaminer(
    id: string,
    examinerId: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.delete(
      `/api/v1/swimming-lesson-providers/${id}/examiners/${examinerId}`,
    );
  }

  linkExaminerToLessonLocations(
    id: string,
    examinerId: string,
    lessonLocationIds: string[],
  ): Promise<AxiosResponse | ValidationFailedResponse> {
    return ApiClient.post(
      `/api/v1/swimming-lesson-providers/${id}/examiners/${examinerId}/lesson-locations`,
      {
        lessonLocationIds,
      },
    );
  }

  addBlockedDelegate(
    id: string,
    delegateId: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.post(
      `/api/v1/swimming-lesson-providers/${id}/blocked-delegates`,
      {
        delegateId,
      },
    );
  }

  removeBlockedDelegate(
    id: string,
    delegateId: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.delete(
      `/api/v1/swimming-lesson-providers/${id}/blocked-delegates/${delegateId}`,
    );
  }

  convertToSwimmingLessonProvider(
    id: string,
  ): Promise<AxiosResponse | ValidationFailedResponse> {
    return ApiClient.post(
      `/api/v1/swimming-lesson-providers/${id}/convert-to-swimming-lesson-provider`,
    );
  }

  placeUnderSwimmingLessonProvider(
    id: string,
    targetProviderId: string,
  ): Promise<AxiosResponse | ValidationFailedResponse> {
    return ApiClient.post(
      `/api/v1/swimming-lesson-providers/${id}/place-under-swimming-lesson-provider`,
      {
        targetSwimmingLessonProviderId: targetProviderId,
      },
    );
  }
}

export default SwimmingLessonProviderRepository;
