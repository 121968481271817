import { Link } from 'react-router-dom';

import { IconButton, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    margin: `0 ${theme.spacing(0.5)}px`,
  },
}));

interface DataTableLinkItemProps {
  title: string;
  icon: IconName;
  to: string;
}

const DataTableRowLink = (props: DataTableLinkItemProps) => {
  const { title, icon, to } = props;

  const classes = useStyles();

  return (
    <Tooltip title={title}>
      <IconButton
        size="small"
        className={classes.icon}
        component={Link}
        to={to}
      >
        <FontAwesomeIcon icon={['fal', icon]} />
      </IconButton>
    </Tooltip>
  );
};

export default DataTableRowLink;
