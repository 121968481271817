import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { nl } from 'date-fns/locale';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InfoText from '../../../components/InfoText';
import { User, DelegateHolidayPeriod, Address } from '../../../types';
import DelegateRepository from '../../delegates/repository/DelegateRepository';

interface DelegateVacationProps {
  user: User;
}

const DelegateVacation = (props: DelegateVacationProps) => {
  const { user } = props;

  const history = useHistory();

  const initialFrom = user.delegateProfile?.holidayFrom
    ? new Date(user.delegateProfile?.holidayFrom)
    : null;
  const initialUntil = user.delegateProfile?.holidayUntil
    ? new Date(user.delegateProfile?.holidayUntil)
    : null;
  const initialAvailable = user.delegateProfile?.availableOnHoliday ?? false;
  const initialAddress = (({ lat, lon, lat_lon_searched_on, ...o }) => o)(
    user.delegateProfile?.holidayAddress || {
      street: '',
      number: '',
      suffix: '',
      postalCode: '',
      town: '',
      province: '',
      country: '',
    },
  );
  const initialRadius =
    user.delegateProfile?.holidayPostalCodeRadiusInKm ?? null;

  const [holidayPeriod, setHolidayPeriod] = useState<DelegateHolidayPeriod>({
    from: initialFrom,
    until: initialUntil,
    available: initialAvailable,
    address: initialAddress,
    radius: initialRadius,
  });

  const [holidayAddress, setHolidayAddress] = useState<Address>(initialAddress);
  const notifications = useSnackbar();

  const repository = new DelegateRepository();

  useEffect(() => {
    setHolidayPeriod((prevState) => ({
      ...prevState,
      address: holidayAddress,
    }));
  }, [holidayAddress]);

  const handleFrom = (date: MaterialUiPickersDate | null) => {
    const from = date?.valueOf() ?? '';
    setHolidayPeriod((prevState) => ({
      ...prevState,
      from: new Date(from) ?? null,
    }));
  };

  const handleUntil = (date: MaterialUiPickersDate | null) => {
    const until = date?.valueOf() ?? '';
    setHolidayPeriod((prevState) => ({
      ...prevState,
      until: new Date(until) ?? null,
    }));
  };

  const handleAvailability = () => {
    setHolidayPeriod((prevState) => ({
      ...prevState,
      available: !holidayPeriod.available,
    }));
  };

  const handleHolidayAddress = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    event.persist();
    setHolidayAddress((prevState) => ({
      ...prevState,
      [event.target.name as string]: event.target.value,
    }));
  };
  const handleRadius = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radius = Number(event.target.value);
    setHolidayPeriod((prevState) => ({
      ...prevState,
      radius,
    }));
  };

  const showMissingCoordinateWarning =
    user.delegateProfile?.holidayAddress?.lat_lon_searched_on &&
    (!user.delegateProfile?.holidayAddress?.lat ||
      !user.delegateProfile?.holidayAddress?.lon);

  const saveHolidayPeriod = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    repository
      .saveHolidayPeriod(user.id, holidayPeriod)
      .then(() => {
        notifications.enqueueSnackbar('Vakantieperiode succesvol opgeslagen.', {
          variant: 'success',
        });
        history.go(0);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het opslaan van de vakantieperiode.',
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <>
      <InfoText>
        Stel je vakantieperiode in en geef aan of je al dan niet beschikbaar
        bent vanaf je vakantieadres.
      </InfoText>
      <form onSubmit={saveHolidayPeriod}>
        <FormControl>
          <Box display="grid" gridRowGap="1rem" mb={2}>
            <FormGroup>
              <FormLabel>Periode</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd-MM-yyyy"
                  helperText=""
                  orientation="landscape"
                  autoOk
                  margin="normal"
                  value={holidayPeriod.from}
                  onChange={handleFrom}
                  cancelLabel="Annuleren"
                  KeyboardButtonProps={{
                    'aria-label': 'Verander datum',
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd-MM-yyyy"
                  helperText=""
                  orientation="landscape"
                  autoOk
                  margin="normal"
                  value={holidayPeriod.until}
                  onChange={handleUntil}
                  cancelLabel="Annuleren"
                  KeyboardButtonProps={{
                    'aria-label': 'Verander datum',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormGroup>
            {holidayPeriod.from && (
              <FormGroup>
                <FormLabel>Inzetbaar</FormLabel>
                <RadioGroup
                  row
                  defaultValue={`${false}`}
                  name="action"
                  value={`${holidayPeriod.available}`}
                  onChange={handleAvailability}
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Nee"
                  />
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Ja"
                  />
                </RadioGroup>
              </FormGroup>
            )}
            {holidayPeriod.available && (
              <>
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <TextField
                    type="string"
                    label="Straat"
                    value={holidayPeriod.address?.street}
                    name="street"
                    required
                    onChange={handleHolidayAddress}
                  />
                  <TextField
                    type="string"
                    label="huisnummer"
                    value={holidayPeriod.address?.number}
                    name="number"
                    required
                    onChange={handleHolidayAddress}
                  />
                  <TextField
                    type="string"
                    label="toevoeging"
                    value={holidayPeriod.address?.suffix}
                    name="suffix"
                    onChange={handleHolidayAddress}
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <TextField
                    type="string"
                    label="Plaatsnaam"
                    value={holidayPeriod.address?.town}
                    name="town"
                    required
                    onChange={handleHolidayAddress}
                  />
                  <TextField
                    type="string"
                    label="Postcode"
                    value={holidayPeriod.address?.postalCode}
                    name="postalCode"
                    required
                    onChange={handleHolidayAddress}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    style={{ width: '200px' }}
                    type="number"
                    label="Straal (km)"
                    value={holidayPeriod.radius}
                    onChange={handleRadius}
                  />
                </FormGroup>
              </>
            )}
          </Box>
        </FormControl>
        {showMissingCoordinateWarning && (
          <InfoText>
            Dit adres werd niet gevonden door Google Maps. De speciaal voor jou
            geselecteerde examens in het dashboard tonen daardoor niet de
            examens o.b.v. het vakantieadres. Maak hiervan een melding bij je
            regioadviseur.
          </InfoText>
        )}
        <Box mt={1}>
          <Button variant="contained" color="secondary" type="submit">
            Opslaan
          </Button>
        </Box>
      </form>
    </>
  );
};

export default DelegateVacation;
