import React, { useContext, useState } from 'react';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';
import AppContext from '../../../AppContext';
import { formatTime } from '../../../utils/common';
import { Exam, ExamDiploma } from '../../../types';
import ButtonRow from '../../../components/ButtonRow';
import ExamRepository from '../repository/ExamRepository';
import { examLineToExamLineDTO } from '../utils';

interface PassedParticipantsProps {
  exam: Exam;
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
  },
  tableHead: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    fontSize: 18,
    padding: 0,
    borderBottom: 0,
    paddingRight: 20,
  },
  tableCell: {
    paddingTop: 0,
    paddingLeft: 0,
    borderBottom: 'none',
  },
}));

const PassedParticipants = (props: PassedParticipantsProps) => {
  const { exam } = props;
  const { roleViewManager } = useContext(AppContext);

  const classes = useStyles();
  const notifications = useSnackbar();

  const [examLines, setExamLines] = useState<ExamDiploma[]>(exam.examLines);

  const hasPassedParticipants =
    examLines.filter((examLine: ExamDiploma) => examLine.numberPassed >= 1)
      .length >= 1;
  const [hasAlreadyBeenSaved, setHasAlreadyBeenSaved] = useState<boolean>(
    hasPassedParticipants,
  );
  const canEdit =
    roleViewManager.isAdminView() ||
    (!hasAlreadyBeenSaved &&
      (roleViewManager.isSwimmingLessonProviderView() ||
        roleViewManager.isExaminerView()));

  const handleAmountChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    index: number,
  ) => {
    // persisting the event is necessary in React v16 and lower,
    // otherwise the event can only be called once
    event.persist();
    setExamLines((prev) => {
      const updatedExamLines = [...prev];
      const field = event.target.name as 'numberParticipants' | 'numberPassed';
      updatedExamLines[index][field] = parseInt(
        event.target.value as string,
        10,
      );
      return updatedExamLines;
    });
  };

  const handleSubmit = () => {
    new ExamRepository()
      .updatePassedParticipants(
        exam.id,
        examLines.map((examLine: ExamDiploma) =>
          examLineToExamLineDTO(examLine),
        ),
      )
      .then(() => {
        setHasAlreadyBeenSaved(hasPassedParticipants);
        notifications.enqueueSnackbar(
          'Het aantal geslaagde kandidaten is succesvol opgeslagen!',
          {
            variant: 'success',
          },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          `Er is iets fout gegaan. Controleer de invoer.`,
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={classes.tableHead}>
              Diplomatype
            </TableCell>
            <TableCell variant="head" className={classes.tableHead}>
              Starttijd
            </TableCell>
            <TableCell variant="head" className={classes.tableHead}>
              Eindtijd
            </TableCell>
            <TableCell variant="head" className={classes.tableHead}>
              Aantal kandidaten
            </TableCell>
            <TableCell variant="head" className={classes.tableHead}>
              Aantal geslaagd
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {examLines.map((examLine, index) => (
            <TableRow key={examLine.id}>
              <TableCell className={classes.tableCell}>
                {examLine.diplomaType.name}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatTime(new Date(examLine.beginTime))}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatTime(new Date(examLine.endTime))}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {canEdit ? (
                  <TextField
                    type="number"
                    name="numberParticipants"
                    value={examLine.numberParticipants}
                    onChange={(
                      event: React.ChangeEvent<{
                        name?: string;
                        value: unknown;
                      }>,
                    ) => handleAmountChange(event, index)}
                    error={examLine.numberParticipants < 0}
                  />
                ) : (
                  examLine.numberParticipants
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {canEdit ? (
                  <TextField
                    type="number"
                    name="numberPassed"
                    value={examLine.numberPassed}
                    onChange={(
                      event: React.ChangeEvent<{
                        name?: string;
                        value: unknown;
                      }>,
                    ) => handleAmountChange(event, index)}
                    error={examLine.numberPassed < 0}
                  />
                ) : (
                  examLine.numberPassed
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {canEdit && (
        <ButtonRow>
          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Opslaan
          </Button>
        </ButtonRow>
      )}
    </>
  );
};

export default PassedParticipants;
