import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import { Exam } from '../../../types';
import BackButton from '../../../components/BackButton';
import Page from '../../../components/Page';
import ExamRepository from '../repository/ExamRepository';
import ExamSection from '../forms/sections/ExamSection';
import Loader from '../../../components/Loader';
import useExamTabs from '../hooks/exam-tabs';

const ViewExam = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const [exam, setExam] = useState<Exam | null>(null);

  const tabs = useExamTabs(exam);

  const examRepository = new ExamRepository(false);

  const loadExam = () => {
    examRepository
      .find(id)
      .then((response) => setExam(response.data))
      .catch((error) => {
        switch (error.response.status) {
          case 403:
            history.goBack();
            break;
          default:
            history.push('/');
        }
      });
  };

  useEffect(
    () => {
      loadExam();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const title = (
    <Box display="flex" alignItems="center" mb={3}>
      <Box mr={3}>
        <BackButton />
      </Box>
      <Typography variant="h1">Examen bekijken</Typography>
    </Box>
  );

  if (!exam) {
    return <Loader />;
  }

  return (
    <Page title={title}>
      <ExamSection exam={exam} withActions />
      {tabs}
    </Page>
  );
};

export default ViewExam;
