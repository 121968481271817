import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

interface CustomRedirectProps {
  to: string;
}

const CustomRedirect = (props: CustomRedirectProps) => {
  const { to } = props;

  const history = useHistory();

  useEffect(() => {
    history.replace(to);
  }, [history, to]);

  return null;
};

export default CustomRedirect;
