import { AxiosResponse } from 'axios';

import { CollectionResponse, ExamLocation, Repository } from '../../../types';
import ApiClient from '../../../api/ApiClient';

class ExamLocationRepository implements Repository<ExamLocation> {
  findBy(): Promise<CollectionResponse<ExamLocation>> {
    return ApiClient.get('/api/v1/exam-locations');
  }

  findAll(): Promise<CollectionResponse<ExamLocation>> {
    return ApiClient.get('/api/v1/exam-locations', {
      page: 1,
      perPage: 1e6,
      activeOnly: true,
    });
  }

  getExamLocation(id: string): Promise<AxiosResponse<ExamLocation>> {
    return ApiClient.get(`/api/v1/exam-locations/${id}`);
  }
}

export default ExamLocationRepository;
