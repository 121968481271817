import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';

interface ExamDelegateActionProps {
  tooltip: string;
  color: string;
  icon: IconProp;
  onClick: () => void;
}

const ExamDelegateAction = (props: ExamDelegateActionProps) => {
  const { tooltip, color, icon, onClick } = props;

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} size="small">
        <FontAwesomeIcon color={color} icon={icon} />
      </IconButton>
    </Tooltip>
  );
};

export default ExamDelegateAction;
