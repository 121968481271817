import { useSelector } from 'react-redux';

import { UserState } from '../../../reducers/user';
import ViewUser from './ViewUser';

const MyAccount = () => {
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  return <ViewUser id={account?.id} isProfile />;
};

export default MyAccount;
