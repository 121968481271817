import React, { useContext } from 'react';
import { FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormFieldProps } from './index';
import HtmlEditor from '../../../../components/HtmlEditor';
import ExamReportContext from '../ExamReportContext';

const useStyles = makeStyles(() => ({
  subHeader: {
    fontWeight: 'lighter',
    fontStyle: 'italic',
    '& > p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
}));

const TextField: React.FC<FormFieldProps> = (props) => {
  const { field, error } = props;
  const { data, changeField, locked } = useContext(ExamReportContext);

  const classes = useStyles();

  const handleChange = (_event: any, editor: any) => {
    changeField(field.id, editor.getData());
  };

  return (
    <>
      <FormLabel
        disabled={locked}
        error={error !== undefined}
        component="legend"
      >
        {field.label}
        <div
          className={classes.subHeader}
          dangerouslySetInnerHTML={{ __html: field.description }}
        />
      </FormLabel>
      <HtmlEditor
        data={(data[field.id]?.value as string | undefined) || ''}
        onChange={handleChange}
        disabled={locked}
      />
    </>
  );
};

export default TextField;
