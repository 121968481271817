import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ExamDiplomaListItem from './ExamDiplomaListItem';
import Button from '../../../components/Button';
import { ExamDiplomaDTO, ExamDTO } from '../repository/ExamRepository';
import { DiplomaCategory, DiplomaType } from '../../diploma/domain/types';
import { Examiner, ValidationViolation } from '../../../types';
import Loader from '../../../components/Loader';
import SwimmingLessonProviderRepository from '../../swimming-lesson-provider/repository/SwimmingLessonProviderRepository';

interface ExamDiplomaListProps {
  exam: ExamDTO;
  examinerOptions: Examiner[];
  oneExaminerOverall: boolean;
  onChange: (diplomas: ExamDiplomaDTO[]) => void;
  allViolations?: ValidationViolation[];
}

export type DiplomaViolation = {
  diplomaTypeId: boolean;
  examinerId: boolean;
  amount: boolean;
  beginTime: boolean;
  endTime: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
  },
  tableHead: {
    color: theme.palette.primary.dark,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    fontSize: 18,
    padding: 0,
    borderBottom: 0,
  },
}));

const DIPLOMA_VIOLATION_REGEX = /diplomas\[(\d.*?)\]\[(\w.*?)\]/;

const ExamDiplomaList = (props: ExamDiplomaListProps) => {
  const classes = useStyles();
  const { exam, examinerOptions, oneExaminerOverall, onChange, allViolations } =
    props;

  const [diplomaCategories, setDiplomaCategories] = useState<
    DiplomaCategory[] | null
  >(null);
  const [items, setItems] = useState<ExamDiplomaDTO[]>(exam.diplomas);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const violations: { [key: number]: DiplomaViolation } = {};

  (allViolations || []).forEach((violation) => {
    const matches = violation.propertyPath.match(DIPLOMA_VIOLATION_REGEX);

    if (!matches) return;

    const index: number = parseInt(matches[1], 10);
    const field = matches[2] as keyof DiplomaViolation;

    // Create new violation if none exists yet.
    if (violations[index] === undefined) {
      violations[index] = {
        diplomaTypeId: false,
        examinerId: false,
        amount: false,
        beginTime: false,
        endTime: false,
      };
    }

    violations[index] = {
      ...violations[index],
      [field]: true,
    };
  });

  const handleItemCreate = () => {
    const newItems = [...items];
    newItems.push({
      id: uniqueId(),
      diplomaTypeId: '',
      examinerId: '',
      beginTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      amount: 1,
      amountPassed: 0,
    });
    setItems(newItems);
    onChange([...newItems]);
  };

  const handleItemDelete = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    onChange([...newItems]);
  };

  /**
   * Call onChange callback when items change.
   */
  const handleItemChange = (index: number, item: ExamDiplomaDTO) => {
    const copyItems = [...items];
    copyItems[index] = item;
    setItems(copyItems);
    onChange([...copyItems]);
  };

  /**
   * Refresh items when diplomas change at the top level
   */
  useEffect(() => {
    setItems(exam.diplomas);
  }, [exam.diplomas]);

  /**
   * Initialize diploma categories on mount.
   */
  useEffect(() => {
    if (exam.swimmingLessonProviderId.length === 0) {
      return;
    }

    new SwimmingLessonProviderRepository()
      .getDiplomaCategoriesForExam(
        exam.swimmingLessonProviderId,
        exam.lessonLocationIds,
      )
      .then((response) => {
        setDiplomaCategories(response.data);
        setIsLoaded(true);
      });
  }, [exam.swimmingLessonProviderId, exam.lessonLocationIds]);

  useEffect(
    () => {
      if (!isLoaded) {
        return;
      }

      const diplomaTypeIds =
        diplomaCategories
          ?.flatMap((category: DiplomaCategory) => category.diplomaTypes)
          ?.map((type: DiplomaType) => type.id) || [];

      // unset diploma types that can no longer be selected,
      // which can happen if a lesson location was added that doesn't have the license for it
      onChange(
        items.map((item: ExamDiplomaDTO) => ({
          ...item,
          diplomaTypeId: diplomaTypeIds.includes(item.diplomaTypeId)
            ? item.diplomaTypeId
            : '',
        })),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded, diplomaCategories],
  );

  if (!diplomaCategories) {
    return <Loader inline />;
  }

  return (
    <Box>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell variant="head" className={classes.tableHead}>
              Diplomatype
            </TableCell>
            {!oneExaminerOverall && (
              <TableCell variant="head" className={classes.tableHead}>
                Examinator
              </TableCell>
            )}
            <TableCell variant="head" className={classes.tableHead}>
              Aantal kandidaten
            </TableCell>
            <TableCell variant="head" className={classes.tableHead}>
              Starttijd
            </TableCell>
            <TableCell variant="head" className={classes.tableHead}>
              Eindtijd
            </TableCell>
            <TableCell variant="head" className={classes.tableHead} />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <ExamDiplomaListItem
              // eslint-disable-next-line react/no-array-index-key
              key={`exam-diploma-list-item-${item.id}-${index}`}
              item={item}
              onDelete={() => handleItemDelete(index)}
              onChange={(item: ExamDiplomaDTO) => handleItemChange(index, item)}
              diplomaCategories={diplomaCategories}
              examinerOptions={examinerOptions}
              oneExaminerOverall={oneExaminerOverall}
              violation={violations[index]}
            />
          ))}
        </TableBody>
      </Table>
      <Box mt={2} mb={3}>
        <Button
          icon={['fal', 'plus']}
          label="Toevoegen"
          iconColor="green"
          onClick={handleItemCreate}
        />
      </Box>
    </Box>
  );
};

export default ExamDiplomaList;
