import { ReactElement } from 'react';
import { Box, Button, Chip, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DataTable from '../../../components/DataTable';
import DashboardSection from '../../../components/dashboard/DashboardSection';
import { ApiFilterCriteria, Exam, Repository } from '../../../types';
import { colors } from '../../../config/theme';
import ExamInfoPopover from './ExamInfoPopover';
import { ExamColumn } from '../domain/types';
import DataTableRowLink from '../../../components/DataTableRowLink';
import { EXAM_BASE_ROUTE } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    marginTop: -theme.spacing(1),
  },
  chip: {
    background: colors.linkWater,
    fontSize: 12,
    height: 18,
  },
  detailButton: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    '& .MuiButton-endIcon': {
      marginTop: -5,
    },
  },
  fontWeightLight: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

interface ExamDashboardSectionProps {
  id: string;
  title: string;
  repository: Repository<unknown>;
  itemCount: Number | null;
  columns: ExamColumn[];
  defaultFilters?: ApiFilterCriteria;
  styleRow?: (item: Exam) => 'tr' | 'trHighlight';
  onLoad: (items: Exam[], totalCount: number | null) => void;
  allExamsUrl: string;
  allExamsText: string;
  canView: boolean;
  canEdit: boolean;
  canViewInfo: boolean;
}

const ExamDashboardSection = (props: ExamDashboardSectionProps) => {
  const {
    id,
    title,
    repository,
    itemCount,
    columns,
    defaultFilters,
    styleRow,
    onLoad,
    allExamsUrl,
    allExamsText,
    canView,
    canEdit,
    canViewInfo,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const sectionTitle = (
    <Box display="flex" alignItems="center">
      <Box mr={2}>{title}</Box>
      <Chip
        label={itemCount !== undefined ? itemCount?.toString() : ''}
        className={classes.chip}
      />
    </Box>
  );

  const actions = (exam: Exam) => {
    const elements: ReactElement[] = [];

    if (canViewInfo) {
      elements.push(<ExamInfoPopover key="info" exam={exam} />);
    }

    if (canEdit) {
      elements.push(
        <DataTableRowLink
          key="edit"
          title="Bewerken"
          icon="edit"
          to={`${EXAM_BASE_ROUTE}/${exam.id}/bewerken`}
        />,
      );
    }

    return elements.length ? (
      <Box>{elements.map((element: ReactElement) => element)}</Box>
    ) : undefined;
  };

  const onRowClick = (exam: Exam) => {
    history.push(`${EXAM_BASE_ROUTE}/${exam.id}`);
  };

  return (
    <DashboardSection title={sectionTitle}>
      <DataTable
        id={id}
        repository={repository}
        columns={columns}
        defaultFilters={defaultFilters}
        actions={actions}
        styleRow={styleRow}
        onRowClick={canView ? onRowClick : undefined}
        onLoad={onLoad}
        className={classes.dataTable}
        noHeader
        perPage={5}
        noPaginating
        noResultCounter
      />
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button
          component={Link}
          to={allExamsUrl}
          endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
          className={classes.detailButton}
        >
          {allExamsText}
        </Button>
      </Box>
    </DashboardSection>
  );
};

export default ExamDashboardSection;
