import { DelegateDTO, MinimalUser, User, UserDTO } from '../../types';

export const userToUserDTO = (user: User): UserDTO => {
  const delegateProfile = user?.delegateProfile?.id
    ? ({
        id: user.delegateProfile.id,
        mayInvoice: user.delegateProfile.mayInvoice,
        maxDistanceExamsInKm: user.delegateProfile.maxDistanceExamsInKm,
      } as DelegateDTO)
    : undefined;

  return {
    id: user.id,
    email: user.email,
    swimmingLessonProviderId: user?.swimmingLessonProvider?.id || null,
    firstName: user.firstName,
    insertion: user.insertion || '',
    lastName: user.lastName,
    delegateProfile,
  } as UserDTO;
};

export const formatDelegates = (delegates: MinimalUser[]) =>
  delegates.map((user) => user.fullName).join(', ');
