import { Route, Router } from '../../routing';
import DiplomaCategoryOverview from './views/DiplomaCategoryOverview';
import EditDiplomaCategory from './views/EditDiplomaCategory';
import DiplomaCategoryTypeOverview from './views/DiplomaCategoryTypeOverview';
import EditDiplomaType from './views/EditDiplomaType';
import CreateDiplomaType from './views/CreateDiplomaType';

export const DIPLOMA_CATEGORY_OVERVIEW_ROUTE = '/beheer/diplomacategorieen';

const diplomaCategoryOverview = new Route(
  'Diplomacategorieën',
  DIPLOMA_CATEGORY_OVERVIEW_ROUTE,
  <DiplomaCategoryOverview />,
  true,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(diplomaCategoryOverview);

const diplomaCategoryTypeOverview = new Route(
  'Diplomatypen',
  `${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/:id/diplomatypen`,
  <DiplomaCategoryTypeOverview />,
  true,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(diplomaCategoryTypeOverview);

const editDiplomaCategory = new Route(
  'Bewerken',
  `${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/:id/bewerken`,
  <EditDiplomaCategory />,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(editDiplomaCategory);

const createDiplomaType = new Route(
  'Aanmaken',
  `${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/:categoryId/diplomatypen/toevoegen`,
  <CreateDiplomaType />,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(createDiplomaType);

const editDiplomaType = new Route(
  'Bewerken',
  `${DIPLOMA_CATEGORY_OVERVIEW_ROUTE}/:categoryId/diplomatypen/:id/bewerken`,
  <EditDiplomaType />,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(editDiplomaType);
