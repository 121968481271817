import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import SwimmingLessonProviderSelect from '../../admin/forms/SwimmingLessonProviderSelect';
import { SwimmingLessonProviderWithLessonLocations } from '../../../types';

interface PlaceUnderSLPDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (targetProviderId: string) => void;
}

const PlaceUnderSLPDialog = (props: PlaceUnderSLPDialogProps) => {
  const [swimmingLessonProvider, setSwimmingLessonProvider] =
    useState<SwimmingLessonProviderWithLessonLocations | null>(null);

  const handleSelect = (
    swimmingLessonProvider: SwimmingLessonProviderWithLessonLocations,
  ) => {
    setSwimmingLessonProvider(swimmingLessonProvider);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>Plaatsen onder hoofdlocatie</DialogTitle>
      <DialogContent>
        <SwimmingLessonProviderSelect
          onSelect={handleSelect}
          type="regular_swimming_lesson_provider"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Annuleren
        </Button>
        <Button
          onClick={
            swimmingLessonProvider
              ? () => props.onConfirm(swimmingLessonProvider.id)
              : undefined
          }
          color="secondary"
        >
          Plaatsen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlaceUnderSLPDialog;
