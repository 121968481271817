import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Column } from '../../../components/DataTable';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { Delegate, SwimmingLessonProvider, User } from '../../../types';
import SwimmingExamProviderRepository from '../repository/SwimmingLessonProviderRepository';
import { FormControl, FormLabel } from '@material-ui/core';
import ButtonRow from '../../../components/ButtonRow';
import InlineButton from '../../../components/InlineButton';
import DelegateSelect from '../../admin/forms/DelegateSelect';

interface SLPBlockedDelegateListProps {
  swimmingLessonProvider: SwimmingLessonProvider;
}

const SLPBlockedDelegateList = (props: SLPBlockedDelegateListProps) => {
  const notifications = useSnackbar();

  const [delegates, setDelegates] = useState<User[] | null>(null);
  const [selectedDelegate, setSelectedDelegate] = useState<Delegate | null>(
    null,
  );

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (user: User) => user.fullName,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (user: User) => user.email,
    },
    {
      name: 'Telefoon (werk)',
      field: 'telephoneWork',
      render: (user: User) => user?.telephoneWork,
    },
    {
      name: 'Telefoon (privé)',
      field: 'telephonePrivate',
      render: (user: User) => user?.telephonePrivate,
    },
    {
      name: 'Telefoon (mobiel)',
      field: 'telephone',
      render: (user: User) => user?.telephonePrivateMobile,
    },
  ];

  const loadDelegates = useCallback(() => {
    new SwimmingExamProviderRepository()
      .getBlockedDelegates(props.swimmingLessonProvider.id)
      .then((response) => setDelegates(response.data));
  }, [props.swimmingLessonProvider.id]);

  const handleDelegateChange = (delegate: Delegate | null) => {
    setSelectedDelegate(delegate);
  };

  const handleSubmit = () => {
    if (!selectedDelegate) {
      return;
    }

    new SwimmingExamProviderRepository()
      .addBlockedDelegate(props.swimmingLessonProvider.id, selectedDelegate.id)
      .then(() => {
        notifications.enqueueSnackbar(
          'De geblokkeerde gedelegeerde is succesvol toegevoegd!',
          {
            variant: 'success',
          },
        );
        loadDelegates();
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er is iets fout gegaan bij het toevoegen van de geblokkeerde gedelegeerde.',
          {
            variant: 'error',
          },
        );
      });
  };

  const handleDelete = (id: string) => {
    new SwimmingExamProviderRepository()
      .removeBlockedDelegate(props.swimmingLessonProvider.id, id)
      .then(() => loadDelegates());
  };

  useEffect(loadDelegates, [loadDelegates]);

  if (!delegates) {
    return <Loader inline />;
  }

  return (
    <>
      <SimpleTable
        items={delegates}
        columns={columns}
        canDeleteItems
        onItemDelete={handleDelete}
      />
      <FormControl>
        <FormLabel id="delegate-select-label">Gedelegeerde toevoegen</FormLabel>
        <DelegateSelect onSelect={handleDelegateChange} />
      </FormControl>
      <ButtonRow>
        <InlineButton type="primary" onClick={handleSubmit}>
          Toevoegen
        </InlineButton>
      </ButtonRow>
    </>
  );
};

export default SLPBlockedDelegateList;
