import { Box, Theme } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { Exam } from '../../../types';

interface ExamSwimmingLessonProviderProps {
  exam: Exam;
}

const useStyles = makeStyles((theme: Theme) => ({
  afasId: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const ExamSwimmingLessonProvider = (props: ExamSwimmingLessonProviderProps) => {
  const { exam } = props;
  const classes = useStyles();

  return (
    <Box>
      <Box>{exam.swimmingLessonProvider.name}</Box>
      <Box className={classes.afasId}>{exam.swimmingLessonProvider.afasId}</Box>
    </Box>
  );
};

export default ExamSwimmingLessonProvider;
