import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import { Delegate } from '../../../types';
import DelegateRepository from '../../delegates/repository/DelegateRepository';

interface DelegateSelectProps {
  onSelect: (delegate: Delegate | null) => void;
}

const DelegateSelect = (props: DelegateSelectProps) => {
  const notifications = useSnackbar();

  const [delegateOptions, setDelegateOptions] = useState<Delegate[]>([]);
  const [selectedDelegate, setSelectedDelegate] = useState<Delegate | null>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const onChangeHandler = (_event: ChangeEvent<{}>, value: Delegate | null) => {
    setSelectedDelegate(value);
    props.onSelect(value);
  };

  const fetchDelegateOptions = useCallback(() => {
    new DelegateRepository()
      .fetchAll()
      .then((response) => {
        setDelegateOptions(response.data.items);
      })
      .catch(() => {
        notifications.enqueueSnackbar('Fout bij het ophalen van de gegevens.', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [setDelegateOptions, setLoaded]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    fetchDelegateOptions();
  }, [loaded, fetchDelegateOptions]);

  return (
    <Autocomplete
      onChange={onChangeHandler}
      value={selectedDelegate}
      options={delegateOptions}
      getOptionLabel={(option) => option.fullName}
      renderInput={(params) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          variant="outlined"
          placeholder="Selecteer gedelegeerde..."
        />
      )}
    />
  );
};

export default DelegateSelect;
