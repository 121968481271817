import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface LoaderProps {
  inline?: boolean;
}

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
  inline: {
    display: 'inline-flex',
    width: 'auto',
    height: 'auto',
    padding: 20,
  },
}));

const Loader = ({ inline }: LoaderProps) => {
  const classes = useStyles();

  const classNames = [classes.loader];

  if (inline) {
    classNames.push(classes.inline);
  }

  return (
    <div className={classNames.join(' ')}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
