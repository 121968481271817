import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatTime } from '../../utils/common';

const useStyles = makeStyles(() => ({
  time: {
    width: '100px',
  },
}));

interface TimeInputProps {
  name: string;
  value?: Date | null;
  onBlur: (date: Date | null) => void;
  hasServerViolation?: boolean;
}

// matches a time value of the form 'hh:mm', 'hhmm' or 'hh' (or even 'hh:'), where
// - the initial zero is optional
// - the colon can be substituted by a period
// if the regex is valid:
// - the first match will be the entire string
// - the second match will be the number of hours
// - the third match will be the colon (or `undefined`)
// - the fourth match will be the number of minutes (or `undefined`)
const timeRegex = /^([01]?[0-9]|2[0-3])([:.])?([0-5][0-9])?$/;

const TimeInput = (props: TimeInputProps) => {
  const {
    name,
    value: initialValue = null,
    onBlur,
    hasServerViolation = false,
  } = props;

  const classes = useStyles();

  const initialInputValue = initialValue ? formatTime(initialValue) : '';
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [hasError, setHasError] = useState<boolean>(hasServerViolation);

  // used to prevent validation until the user finishes inputting a value
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const inputIsValid = (value: string) => {
    const matches = value.match(timeRegex);

    return matches !== null;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    // consider the input untouched if the user corrects a value
    // until the user focuses out again
    if (isTouched && inputIsValid(event.target.value)) {
      setIsTouched(false);
      setHasError(false);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const matches = event.target.value.match(timeRegex);

    let date = null;
    if (matches !== null) {
      const hours = parseInt(matches[1], 10);
      const minutes = matches[3] ? parseInt(matches[3], 10) : 0;

      date = new Date('1970-01-01');
      date.setHours(hours, minutes, 0);

      // add the colon, which may have been omitted
      setInputValue(`${hours}:${`${minutes}`.padStart(2, '0')}`);
      setHasError(false);
    } else {
      setHasError(true);
    }

    setIsTouched(true);
    onBlur(date);
  };

  useEffect(() => {
    setHasError(hasServerViolation);
  }, [hasServerViolation]);

  return (
    <TextField
      className={classes.time}
      name={name}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={hasError}
      placeholder="uu:mm"
      required
    />
  );
};

export default TimeInput;
