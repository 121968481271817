import React, { useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormFieldProps } from './index';
import ExamReportContext from '../ExamReportContext';
import HtmlEditor from '../../../../components/HtmlEditor';

const useStyles = makeStyles(() => ({
  subHeader: {
    fontWeight: 'lighter',
    fontStyle: 'italic',
    '& > p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
}));

const BoolField: React.FC<FormFieldProps> = (props) => {
  const { field, error } = props;
  const { data, changeField, locked } = useContext(ExamReportContext);

  const classes = useStyles();

  const handleChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    changeField(field.id, value, data[field.id]?.explanation);
  };

  const handleChangeExplanation = (_event: any, editor: any) => {
    changeField(field.id, data[field.id]?.value, editor.getData());
  };

  return (
    <>
      <FormControl
        component="fieldset"
        disabled={locked}
        style={{ width: '100%' }}
      >
        <FormLabel error={error !== undefined} component="legend">
          {field.label}
          <div
            className={classes.subHeader}
            dangerouslySetInnerHTML={{ __html: field.description }}
          />
        </FormLabel>
        <RadioGroup
          aria-label={field.label}
          name={field.id}
          value={data[field.id]?.value}
          onChange={handleChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="Ja" />
          <FormControlLabel value="0" control={<Radio />} label="Nee" />
        </RadioGroup>
      </FormControl>
      {field.explanation && (
        <FormControl
          component="fieldset"
          disabled={locked}
          style={{ width: '100%' }}
        >
          <FormLabel error={error !== undefined} component="legend">
            Toelichting:
          </FormLabel>
          <HtmlEditor
            data={(data[field.id]?.explanation as string | undefined) || ''}
            onChange={handleChangeExplanation}
            disabled={locked}
          />
        </FormControl>
      )}
    </>
  );
};

export default BoolField;
