import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DelegateAssignmentOfExam, Exam } from '../../../types';
import {
  allowedDelegateAssignmentActions,
  delegateAssignmentToText,
} from '../utils';
import ExamDelegateAction from './ExamDelegateAction';
import ExamRepository, {
  DelegateAssignmentActionType,
} from '../repository/ExamRepository';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: 'auto',
    minWidth: '50%',
    marginBottom: theme.spacing(1),
  },
  tr: {
    '& > th': {
      fontWeight: theme.typography.h1.fontWeight,
      fontFamily: theme.typography.h1.fontFamily,
    },
    '& > th, & > td': {
      padding: theme.spacing(0.5),
      borderBottom: 0,
    },
  },
}));

interface ExamDelegateListProps {
  exam: Exam;
  delegateAssignments: DelegateAssignmentOfExam[];
  withActions: boolean;
  onRefresh: () => void;
}

const ExamDelegateList = (props: ExamDelegateListProps) => {
  const { exam, delegateAssignments, withActions, onRefresh } = props;

  const classes = useStyles();

  const examRepository = new ExamRepository();

  const handleDelegateAssignmentAction = (
    delegateId: string,
    action: DelegateAssignmentActionType,
  ) => {
    examRepository
      .handleAssignment(exam.id, delegateId, action)
      .then(() => onRefresh());
  };

  const handleDelegateAssignmentDeletion = (delegateId: string) => {
    examRepository
      .deleteAssignment(exam.id, delegateId)
      .then(() => onRefresh());
  };

  const onCancel = (delegateId: string) =>
    handleDelegateAssignmentAction(delegateId, 'cancel');
  const onConfirm = (delegateId: string) =>
    handleDelegateAssignmentAction(delegateId, 'confirm');
  const onDecline = (delegateId: string) =>
    handleDelegateAssignmentAction(delegateId, 'decline');

  const onDelete = (delegateId: string) =>
    handleDelegateAssignmentDeletion(delegateId);

  return (
    <>
      {delegateAssignments.length ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tr}>
              <TableCell>Gedelegeerde</TableCell>
              <TableCell>Status</TableCell>
              {withActions && (
                <>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {delegateAssignments.map(
                (delegateAssignment: DelegateAssignmentOfExam) => {
                  const statusText =
                    delegateAssignmentToText(delegateAssignment);

                  const { canCancel, canConfirm, canDecline, canDelete } =
                    allowedDelegateAssignmentActions(delegateAssignment);

                  return (
                    <TableRow className={classes.tr}>
                      <TableCell>
                        {delegateAssignment.delegate.fullName}
                      </TableCell>
                      <TableCell>{statusText}</TableCell>
                      {withActions && (
                        <>
                          <TableCell>
                            {canConfirm && (
                              <ExamDelegateAction
                                tooltip="Bevestigen"
                                color="green"
                                icon={['fal', 'thumbs-up']}
                                onClick={() => onConfirm(delegateAssignment.id)}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {canDecline && (
                              <ExamDelegateAction
                                tooltip="Weigeren"
                                color="red"
                                icon={['fal', 'thumbs-down']}
                                onClick={() => onDecline(delegateAssignment.id)}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {canCancel && (
                              <ExamDelegateAction
                                tooltip="Annuleren"
                                color="red"
                                icon={['fal', 'times']}
                                onClick={() => onCancel(delegateAssignment.id)}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {canDelete && (
                              <ExamDelegateAction
                                tooltip="Verwijderen"
                                color="red"
                                icon={['fal', 'trash']}
                                onClick={() => onDelete(delegateAssignment.id)}
                              />
                            )}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                },
              )}
            </>
          </TableBody>
        </Table>
      ) : (
        <Box>Er zijn geen gedelegeerden gekoppeld aan dit examen.</Box>
      )}
    </>
  );
};

export default ExamDelegateList;
