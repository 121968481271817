import { Dispatch } from 'redux';

import ApiClient from './ApiClient';
import { logoutUser } from '../actions';
import { ROLE_VIEW_STORE_KEY } from '../RoleViewManager';

class UserAuthenticator {
  private dispatch: Dispatch;

  private readonly localStore: LocalForage | undefined;

  constructor(dispatch: Dispatch, localStore?: LocalForage) {
    this.dispatch = dispatch;
    this.localStore = localStore;
  }

  logout(callback?: () => void) {
    ApiClient.post('/auth/logout').then(() => {
      this.dispatch(logoutUser());

      if (this.localStore) {
        this.localStore.removeItem(ROLE_VIEW_STORE_KEY);
      }

      if (callback) {
        callback();
      }
    });
  }
}

export default UserAuthenticator;
