import { Paper, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { SwimmingLessonProvider } from '../../../types';
import { colors } from '../../../config/theme';
interface ExamSwimmingLessonProviderInfoProps {
  examSwimmingLessonProvider: SwimmingLessonProvider;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    width: '100%',
    background: colors.blackSqueeze,
    padding: theme.spacing(2),
  },
}));

const ExamSwimmingLessonProviderInfo = (
  props: ExamSwimmingLessonProviderInfoProps,
) => {
  const classes = useStyles();
  const { examSwimmingLessonProvider } = props;

  return (
    <Paper className={classes.root}>
      {examSwimmingLessonProvider.name}
      {examSwimmingLessonProvider.phoneNumber && (
        <div>{examSwimmingLessonProvider.phoneNumber}</div>
      )}
      {examSwimmingLessonProvider.email && (
        <div>{examSwimmingLessonProvider.email}</div>
      )}
    </Paper>
  );
};

export default ExamSwimmingLessonProviderInfo;
