import { useEffect, useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Column } from '../../../components/DataTable';
import InfoText from '../../../components/InfoText';
import Loader from '../../../components/Loader';
import SimpleTable from '../../../components/SimpleTable';
import { Examiner, SwimmingLessonProvider } from '../../../types';
import { formatDate } from '../../../utils/common';
import SwimmingExamProviderRepository from '../repository/SwimmingLessonProviderRepository';
import SLPAddExaminer from './SLPAddExaminer';

const useStyles = makeStyles(() => ({
  red: {
    color: 'red',
  },
}));

interface SLPExaminerListProps {
  swimmingLessonProvider: SwimmingLessonProvider;
  isAllowedToEdit: boolean;
}

const SLPExaminerList = (props: SLPExaminerListProps) => {
  const { swimmingLessonProvider, isAllowedToEdit } = props;

  const classes = useStyles();

  const [examiners, setExaminers] = useState<Examiner[] | null>(null);

  const repository = new SwimmingExamProviderRepository();

  const columns: Column[] = [
    {
      name: 'Naam',
      field: 'name',
      render: (examiner: Examiner) => examiner.fullName,
    },
    {
      name: 'E-mail',
      field: 'email',
      render: (examiner: Examiner) => examiner.email,
    },
    {
      name: 'Telefoon (werk)',
      field: 'telephoneWork',
      render: (examiner: Examiner) => examiner?.telephoneWork,
    },
    {
      name: 'Telefoon (privé)',
      field: 'telephonePrivate',
      render: (examiner: Examiner) => examiner?.telephonePrivate,
    },
    {
      name: 'Telefoon (mobiel)',
      field: 'telephone',
      render: (examiner: Examiner) => examiner?.telephonePrivateMobile,
    },
    {
      name: 'Licenties',
      field: 'licenses',
      render: (examiner: Examiner) => {
        if (
          !examiner.examinerLicenses ||
          examiner.examinerLicenses.length === 0
        ) {
          return 'Geen licentie';
        }

        return examiner.examinerLicenses.map((license) => {
          const text = `${license.diplomaCategory.name} sinds ${formatDate(
            new Date(license.obtainedAt),
          )}`;

          return license.isValid ? (
            <Box>{text}</Box>
          ) : (
            <Tooltip title="Verlopen (meer dan 4 jaar oud)">
              <Box color="red">{text}</Box>
            </Tooltip>
          );
        });
      },
    },
  ];

  const loadExaminers = () => {
    repository
      .getExaminers(swimmingLessonProvider.id)
      .then((response) => setExaminers(response.data));
  };

  const handleDelete = (id: string) => {
    repository
      .removeExaminer(swimmingLessonProvider.id, id)
      .then(() => loadExaminers());
  };

  useEffect(
    () => {
      loadExaminers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!examiners) {
    return <Loader inline />;
  }

  return (
    <Box>
      <InfoText>
        Een licentie is 4 jaar geldig. Een verlopen licentie is{' '}
        <span className={classes.red}>rood</span> gekleurd.
      </InfoText>
      <SimpleTable
        items={examiners}
        columns={columns}
        canDeleteItems={isAllowedToEdit}
        onItemDelete={handleDelete}
      />
      {isAllowedToEdit && (
        <SLPAddExaminer
          swimmingLessonProvider={swimmingLessonProvider}
          onSuccess={loadExaminers}
        />
      )}
    </Box>
  );
};

export default SLPExaminerList;
