import { useHistory } from 'react-router-dom';

import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Page';
import { DeclarationDto } from '../../../types';
import { DECLARATIONS_BASE_ROUTE } from '../index';
import DeclarationsRepository from '../repository/DeclarationsRepository';
import DeclarationForm from './DeclarationForm';

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    height: theme.typography.h1.fontSize,
  },
}));

const CreateDeclaration = () => {
  const classes = useStyles();
  const history = useHistory();
  const notifications = useSnackbar();

  const title = (
    <Typography variant="h1" className={classes.h1}>
      Declaratie aanmaken
    </Typography>
  );

  const onSubmitHandler = (declaration: DeclarationDto) => {
    new DeclarationsRepository().create(declaration).then((response) => {
      notifications.enqueueSnackbar('De declaratie is succesvol opgeslagen!', {
        variant: 'success',
      });

      history.push(`${DECLARATIONS_BASE_ROUTE}/${response.data.id}`);
    });
  };

  return (
    <Page title={title}>
      <DeclarationForm onSubmit={onSubmitHandler} />
    </Page>
  );
};

export default CreateDeclaration;
