import { AxiosResponse } from 'axios';
import ApiClient from '../../../api/ApiClient';
import { CollectionResponse, Notification } from '../../../types';

class NotificationRepository {
  getNotifications(
    page: number = 1,
    perPage: number = 10,
  ): Promise<CollectionResponse<Notification>> {
    return ApiClient.get('/api/notifications', { page, perPage });
  }

  getUnreadNotifications(): Promise<CollectionResponse<Notification>> {
    return ApiClient.get('/api/notifications/unread');
  }

  markAllAsRead(): Promise<AxiosResponse> {
    return ApiClient.post('/api/notifications/mark-all-as-read');
  }

  markNotificationAsRead(
    notification: Notification,
  ): Promise<AxiosResponse<Notification>> {
    return ApiClient.post('/api/notifications/mark-read', {
      id: notification.id,
    });
  }
}

export default NotificationRepository;
