import { Route, Router } from '../../routing';

import MailTemplateOverview from './views/MailTemplateOverview';
import MailTemplateDetail from './views/MailTemplateDetail';

export const ROUTE_MAIL_TEMPLATE_OVERVIEW = '/e-mailtemplates';

const overview = new Route(
  'E-mailtemplates',
  ROUTE_MAIL_TEMPLATE_OVERVIEW,
  <MailTemplateOverview />,
  true,
).role(['ROLE_SUPER_ADMIN']);
Router.addRoute(overview);

overview.addChild(
  new Route(
    'E-mailtemplate bewerken',
    '/:id/bewerken',
    <MailTemplateDetail />,
  ).role(['ROLE_SUPER_ADMIN']),
);
