import { makeStyles } from '@material-ui/styles';
import { Box, Button, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { grey } from '@material-ui/core/colors';
import { Notification as NotificationInterface } from '../../../types';
import SafeHtml from '../../../components/SafeHtml';
import ConditionalWrapper from '../../../components/ConditionalWrapper';
import notificationTypes from '../domain/notificationTypes';

interface NotificationProps {
  notification: NotificationInterface;
  onClick?: (notification: NotificationInterface, url?: string | null) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.light,
    width: '100%',
  },
  read: {
    borderColor: theme.palette.primary.light,
    background: '#FFF',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 30,
    height: 30,
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '50%',
    fontSize: 22,
    background: theme.palette.primary.main,
  },
  message: {
    fontSize: 14,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  button: {
    padding: 0,
    textTransform: 'none',
    textAlign: 'left',
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  date: {
    color: grey[600],
    fontSize: 12,
    width: '100%',
  },
}));

const Notification = ({
  notification,
  onClick = () => {},
}: NotificationProps) => {
  const classes = useStyles();
  const { message, read, createdAt } = notification;
  const { icon, target, newWindow } = notificationTypes[notification.type];

  /**
   * Handle click on the notification.
   */
  const handleClick = async () => {
    // Redirect user to target URL if available.
    const url = target !== undefined ? await target(notification) : null;

    if (url !== null) {
      if (newWindow) {
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        // TODO: temporary fix for an issue where the assignment form was only being partially reloaded when clicking
        //  on a second notification of the same type (assignment review for educator).
        window.location.href = url;
      }
    }

    onClick(notification, url);
  };

  return (
    <ConditionalWrapper
      condition={target !== undefined || !read}
      wrapper={(children) => (
        <Button className={classes.button} onClick={handleClick} fullWidth>
          {children}
        </Button>
      )}
    >
      <div className={`${classes.root} ${read && classes.read}`}>
        <Box className={classes.icon} display="flex" alignItems="center">
          <FontAwesomeIcon icon={['fal', icon]} />
        </Box>
        <div className={classes.content}>
          <SafeHtml html={message} className={classes.message} />
          <div className={classes.date}>
            {moment(createdAt).locale('nl').fromNow()}
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default Notification;
