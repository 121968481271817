import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

import {
  COLUMN_EXAM_DATE,
  COLUMN_EXAM_DELEGATE,
  COLUMN_EXAM_DIPLOMAS,
  COLUMN_EXAM_LOCATION,
  COLUMN_EXAM_NUMBER,
  COLUMN_EXAM_REGION,
  COLUMN_EXAM_REPORT_DELEGATE,
  COLUMN_EXAM_REPORT_EXAMINER,
  COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
} from '../domain/columns';
import ExamRepository from '../repository/ExamRepository';
import { Exam, User } from '../../../types';
import DataTable, {
  Column,
  Filter,
  ItemAction,
} from '../../../components/DataTable';
import DataTableRowLink from '../../../components/DataTableRowLink';
import ExamInfoPopover from '../components/ExamInfoPopover';
import SearchInput from '../../../components/search/SearchInput';
import SearchContext from '../../../components/search/SearchContext';
import Page from '../../../components/Page';
import { EXAM_BASE_ROUTE } from '../index';
import { canUpdate } from '../hooks/useExamPermissions';

const PastExamOverview = () => {
  const history = useHistory();
  const account = useSelector(
    (selector: {
      user: {
        account: User;
      };
    }) => selector.user.account,
  );

  const [query, setQuery] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const searchContextValue = useMemo(
    () => ({ query, setQuery }),
    [query, setQuery],
  );

  const repository = new ExamRepository(true);

  const isAdmin = account.roles.includes('ROLE_ADMIN');
  const isSwimmingLessonProvider = account.roles.includes(
    'ROLE_SWIMMING_LESSON_PROVIDER_USER',
  );
  const isOperator = account.roles.includes('ROLE_OPERATOR_USER');

  const columns: Column[] = [];
  if (isAdmin || isOperator) {
    columns.push(
      COLUMN_EXAM_NUMBER,
      COLUMN_EXAM_DATE,
      COLUMN_EXAM_SWIMMING_LESSON_PROVIDER,
      COLUMN_EXAM_REGION,
      {
        ...COLUMN_EXAM_DELEGATE,
        name: 'Ged.',
      },
      {
        ...COLUMN_EXAM_REPORT_DELEGATE,
        name: 'Verslag ged.',
      },
      {
        ...COLUMN_EXAM_REPORT_EXAMINER,
        name: 'Verslag exmn.',
      },
    );
  } else {
    columns.push(
      COLUMN_EXAM_NUMBER,
      COLUMN_EXAM_DATE,
      COLUMN_EXAM_LOCATION,
      COLUMN_EXAM_DIPLOMAS,
      COLUMN_EXAM_DELEGATE,
      COLUMN_EXAM_REPORT_EXAMINER,
    );
  }

  const filters: Filter[] = [];
  if (isAdmin || isSwimmingLessonProvider || isOperator) {
    filters.push({
      field: 'invitationStatus',
      name: 'Uitnodigingsstatus',
      type: 'checkbox',
      options: [
        { value: 'noneConfirmed', label: 'Geen' },
        { value: 'confirmed', label: 'Bevestigd' },
      ],
    });
  }
  if (isAdmin) {
    filters.push({
      field: 'examinerReportStatus',
      name: 'Examinatorrapportstatus',
      type: 'checkbox',
      options: [{ value: 'toAdd', label: 'Toe te voegen' }],
    });
    filters.push({
      field: 'delegateReportStatus',
      name: 'Gedelegeerdenrapportstatus',
      type: 'checkbox',
      options: [
        { value: 'toAdd', label: 'Toe te voegen' },
        { value: 'toGrade', label: 'Te beoordelen' },
        { value: 'graded', label: 'Beoordeeld' },
      ],
    });
    filters.push({
      field: 'particularities',
      name: 'Bijzonderheden',
      type: 'checkbox',
      options: [
        { value: 'menOnly', label: 'Alleen mannen' },
        { value: 'womenOnly', label: 'Alleen vrouwen' },
      ],
    });
  }

  const itemActions = (exam: Exam) => {
    const actions: ItemAction[] = [];

    actions.push({
      key: 'info',
      element: <ExamInfoPopover exam={exam} />,
    });

    if (canUpdate({ exam, account })) {
      actions.push({
        key: 'edit',
        element: (
          <DataTableRowLink
            title="Bewerken"
            icon="edit"
            to={`${EXAM_BASE_ROUTE}/${exam.id}/bewerken`}
          />
        ),
      });
    }

    return (
      <Box>
        {actions.map((action) => (
          <React.Fragment key={action.key}>{action.element}</React.Fragment>
        ))}
      </Box>
    );
  };

  const title = (
    <>
      {totalCount !== null && `Afgelegde examens (${totalCount})`}
      {totalCount === null && 'Afgelegde examens'}
    </>
  );

  const actions = (
    <Box display="flex" alignItems="center">
      <SearchInput id="past-exam-overview-query" persistQuery />
    </Box>
  );

  const onRowClick = (exam: Exam) => {
    history.push(`${EXAM_BASE_ROUTE}/${exam.id}`);
  };

  const deleteItemMessage = (exam: Exam) =>
    `Weet je zeker dat je examen ${exam.examNumber} wilt verwijderen?`;

  return (
    <SearchContext.Provider value={searchContextValue}>
      <Page title={title} actions={actions}>
        <DataTable
          id="past-exam-overview"
          repository={repository}
          columns={columns}
          filters={filters}
          persistFilters
          actions={itemActions}
          onRowClick={onRowClick}
          resultCounterText={{ singular: 'examen', plural: 'examens' }}
          onLoad={(_items, totalCount) => setTotalCount(totalCount)}
          deletable={account.roles.includes('ROLE_ADMIN')}
          deleteItemMessage={deleteItemMessage}
        />
      </Page>
    </SearchContext.Provider>
  );
};

export default PastExamOverview;
