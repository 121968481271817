import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { Alert } from '@material-ui/lab';
import { Exam, ExamDiploma } from '../../../types';
import ExamLineSelector from './ExamLineSelector';
import DelegateAssignmentRepository from '../repository/DelegateAssignmentRepository';

interface CandidateExamRegisterDialogProps {
  exam: Exam;
  open: boolean;
  onClose: () => void;
}

const CandidateExamRegisterDialog = (
  props: CandidateExamRegisterDialogProps,
) => {
  const { exam, open, onClose } = props;

  const notifications = useSnackbar();

  const [selectedExamLines, setSelectedExamLines] = useState<ExamDiploma[]>([]);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const isValid = selectedExamLines.length >= 1;

  const handleExamLinesChange = (examLines: ExamDiploma[]) => {
    setSelectedExamLines(examLines);
  };

  const handleSubmit = async () => {
    setIsTouched(true);

    if (!isValid) {
      return;
    }

    new DelegateAssignmentRepository(exam.id)
      .register(selectedExamLines.map((el) => el.id))
      .then(() => {
        notifications.enqueueSnackbar(
          'Je bent succesvol aangemeld voor het examen.',
          { variant: 'success' },
        );
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Fout bij het aanmelden voor het examen.',
          { variant: 'error' },
        );
      })
      .finally(onClose);
  };

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Aanmelden voor examen</Typography>
      </DialogTitle>
      <DialogContent>
        {!isValid && isTouched && (
          <Box mb={2}>
            <Alert severity="error">Selecteer minimaal een onderdeel.</Alert>
          </Box>
        )}
        <ExamLineSelector
          examLines={exam.examLines}
          onChange={handleExamLinesChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Annuleren
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Aanmelden
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateExamRegisterDialog;
