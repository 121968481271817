import { useSelector } from 'react-redux';
import { UserState } from '../../reducers/user';
import ViewOperator from './views/ViewOperator';

const MyOrganization = () => {
  const { account } = useSelector((selector: { user: UserState }) => ({
    account: selector.user.account,
  }));

  return (
    <ViewOperator id={account?.operator?.id} canEdit={false} isOrganization />
  );
};

export default MyOrganization;
