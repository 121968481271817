import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
} from '../../../types';
import { ExamReport, ExamReportDTO } from '../domain/types';
import { parseCriteria } from '../../../utils/common';
import ApiClient from '../../../api/ApiClient';

class ExamReportRepository implements Repository<ExamReport> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<ExamReport>> {
    const params = parseCriteria(criteria, { page: page || 1 });

    return ApiClient.get('/api/v1/exam-reports', params);
  }

  findAll(): Promise<CollectionResponse<ExamReport>> {
    return ApiClient.get('/api/v1/exam-reports', { page: 1, perPage: 10000 });
  }

  find(id: string): Promise<AxiosResponse<ExamReport>> {
    return ApiClient.get(`/api/v1/exam-reports/${id}`);
  }

  create(report: ExamReportDTO): Promise<AxiosResponse<ExamReport>> {
    return ApiClient.post('/api/v1/exam-reports', report);
  }

  update(id: string, report: ExamReportDTO) {
    return ApiClient.put(`/api/v1/exam-reports/${id}`, report);
  }

  getExaminerReport() {
    return ApiClient.get('/api/v1/exam-reports/examiner-report');
  }

  getDelegateReport() {
    return ApiClient.get('/api/v1/exam-reports/delegate-report');
  }

  getAspirantReport() {
    return ApiClient.get('/api/v1/exam-reports/aspirant-report');
  }
}

export default ExamReportRepository;
