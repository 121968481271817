import { AxiosResponse } from 'axios';

import { SwimmingLessonProvider, SwimmingLessonProviderDTO } from '../../types';

// remove these two lines once the file contains multiple exports

export const swimmingLessonProviderDTOFromResponse = (
  response: AxiosResponse<SwimmingLessonProvider>,
): SwimmingLessonProviderDTO => ({
  id: response.data.id,
  name: response.data.name,
  afasId: response.data.afasId || '',
  type: response.data.type || '',
  typeReadable: response.data.typeReadable || '',
  email: response.data.email || '',
  phoneNumber: response.data.phoneNumber || '',
  trainingInstituteC: response.data.trainingInstituteC,
  custodialInstitution: response.data.custodialInstitution,
  centralExams: response.data.centralExams,
  attention: response.data.attention,
  visitingAddress: response.data.visitingAddress || null,
  shippingAddress: response.data.shippingAddress || null,
  lessonLocations: response.data.lessonLocations,
  examLocations: response.data.examLocations,
  manager: response.data.manager || null,
  examiners: response.data.examiners,
  customizedDiplomaAPdfId: response.data.customizedDiplomaAPdf?.id || '',
  customizedDiplomaAPdf: response.data.customizedDiplomaAPdf,
  customizedDiplomaBPdfId: response.data.customizedDiplomaBPdf?.id || '',
  customizedDiplomaBPdf: response.data.customizedDiplomaBPdf,
  customizedDiplomaCPdfId: response.data.customizedDiplomaCPdf?.id || '',
  customizedDiplomaCPdf: response.data.customizedDiplomaCPdf,
});
