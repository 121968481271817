import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import { DeclarationDto } from '../../../types';
import DeclarationsRepository from '../repository/DeclarationsRepository';
import { DECLARATIONS_BASE_ROUTE } from '../index';
import { declarationToDto } from '../utils';
import DeclarationForm from './DeclarationForm';

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    height: theme.typography.h1.fontSize,
  },
}));

const UpdateDeclaration = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const history = useHistory();
  const notifications = useSnackbar();

  const [declaration, setDeclaration] = useState<DeclarationDto | undefined>(
    undefined,
  );

  useEffect(() => {
    new DeclarationsRepository()
      .find(id)
      .then((response) => {
        setDeclaration(declarationToDto(response.data));
      })
      .catch(() => history.push('/projecten'));
  }, []);

  const title = (
    <Typography variant="h1" className={classes.h1}>
      Declaratie bewerken
    </Typography>
  );

  const onSubmitHandler = (declaration: DeclarationDto) => {
    new DeclarationsRepository().update(declaration).then((response) => {
      notifications.enqueueSnackbar('De declaratie is succesvol opgeslagen!', {
        variant: 'success',
      });

      history.push(`${DECLARATIONS_BASE_ROUTE}/${response.data.id}`);
    });
  };

  if (!declaration) {
    return <Loader />;
  }

  return (
    <Page title={title}>
      <DeclarationForm declaration={declaration} onSubmit={onSubmitHandler} />
    </Page>
  );
};

export default UpdateDeclaration;
