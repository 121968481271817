import { AxiosResponse } from 'axios';

import ApiClient from '../../../api/ApiClient';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Repository,
  Operator,
  OperatorDTO,
  User,
  ValidationFailedResponse,
  SwimmingLessonProvider,
} from '../../../types';
import { parseCriteria } from '../../../utils/common';

class OperatorRepository implements Repository<Operator> {
  find(id: string): Promise<AxiosResponse<Operator>> {
    return ApiClient.get(`/api/v1/operators/${id}`);
  }

  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<CollectionResponse<Operator>> {
    const params = parseCriteria(criteria, {
      page: page || 0,
      perPage: perPage || 10,
    });

    return ApiClient.get(`/api/v1/operators`, params);
  }

  fetchAll(): Promise<CollectionResponse<Operator>> {
    return ApiClient.get('/api/v1/operators', {
      page: 1,
      perPage: 9999,
    });
  }

  create(operator: OperatorDTO) {
    return ApiClient.post('/api/v1/operators', operator);
  }

  update(
    id: string,
    operator: OperatorDTO,
  ): Promise<AxiosResponse<Operator> | ValidationFailedResponse> {
    return ApiClient.put(`/api/v1/operators/${id}`, operator);
  }

  delete(id: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/v1/operators/${id}`);
  }

  getUsers(id: string): Promise<AxiosResponse<User[]>> {
    return ApiClient.get(`/api/v1/operators/${id}/users`);
  }

  getSwimmingLessonProviders(
    id: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider[]>> {
    return ApiClient.get(`/api/v1/operators/${id}/swimming-lesson-providers`);
  }

  addSwimmingLessonProvider(
    id: string,
    code: string,
  ): Promise<AxiosResponse<Operator> | ValidationFailedResponse> {
    return ApiClient.post(`/api/v1/operators/${id}/swimming-lesson-providers`, {
      code,
    });
  }

  removeSwimmingLessonProvider(
    id: string,
    swimmingLessonProviderId: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider> | ValidationFailedResponse> {
    return ApiClient.delete(
      `/api/v1/operators/${id}/swimming-lesson-providers/${swimmingLessonProviderId}`,
    );
  }
}

export default OperatorRepository;
