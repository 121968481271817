import { User } from './types';
import { RoleInterface } from './modules/users/domain/Roles';

export const ROLE_VIEW_STORE_KEY = 'roleView';

export type RoleView = {
  label: string;
  roles: (keyof RoleInterface)[];
};

export interface RoleViewInterface {
  ROLE_VIEW_ADMIN: RoleView;
  ROLE_VIEW_SWIMMING_LESSON_PROVIDER: RoleView;
  ROLE_VIEW_EXAMINER: RoleView;
  ROLE_VIEW_DELEGATE: RoleView;
  ROLE_VIEW_OPERATOR: RoleView;
}

/**
 * List of mutually exclusive role views of which only one can be active
 */
export const RoleViews: RoleViewInterface = {
  ROLE_VIEW_ADMIN: {
    label: 'admin',
    roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
  },
  ROLE_VIEW_SWIMMING_LESSON_PROVIDER: {
    label: 'zwemlesaanbieder',
    roles: [
      'ROLE_SWIMMING_LESSON_PROVIDER_MANAGER',
      'ROLE_SWIMMING_LESSON_PROVIDER_USER',
    ],
  },
  ROLE_VIEW_DELEGATE: {
    label: 'gedelegeerde',
    roles: ['ROLE_DELEGATE'],
  },
  ROLE_VIEW_EXAMINER: {
    label: 'examinator',
    roles: ['ROLE_EXAMINER'],
  },
  ROLE_VIEW_OPERATOR: {
    label: 'exploitant',
    roles: ['ROLE_OPERATOR_USER'],
  },
};

/**
 * Returns the first role view that matches the set of roles
 */
export const getInitialRoleView = (
  roles: (keyof RoleInterface)[],
): keyof RoleViewInterface | null => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in RoleViews) {
    if (
      RoleViews[key as keyof RoleViewInterface].roles.filter((roleInGroup) =>
        roles.includes(roleInGroup),
      ).length >= 1
    ) {
      return key as keyof RoleViewInterface;
    }
  }

  return null;
};

/**
 * Checks whether the role view is available for a certain user
 */
export const isRoleViewAvailable = (
  user: User,
  roleView: keyof RoleViewInterface,
): boolean => {
  return (
    user.roles.filter((role: keyof RoleInterface) =>
      RoleViews[roleView].roles.includes(role),
    ).length >= 1
  );
};

/**
 * This class manages the role-specific views the user can view.
 */
class RoleViewManager {
  /**
   * The current logged in user.
   * @private
   */
  private readonly user: User;

  private readonly roleView: keyof RoleViewInterface | null;

  constructor(user: User, roleView: keyof RoleViewInterface | null) {
    this.user = user;
    this.roleView = roleView;
  }

  getRoleViewName(): string {
    return this.roleView !== null ? RoleViews[this.roleView].label : '';
  }

  /**
   * Determine if the user is viewing as a super admin.
   */
  isSuperAdminView(): boolean {
    return (
      this.user.roles.includes('ROLE_SUPER_ADMIN') &&
      this.roleView === 'ROLE_VIEW_ADMIN'
    );
  }

  /**
   * Determine if the user is viewing as an admin.
   */
  isAdminView(): boolean {
    return (
      this.user.roles.includes('ROLE_ADMIN') &&
      this.roleView === 'ROLE_VIEW_ADMIN'
    );
  }

  /**
   * Determine if the user is viewing as a swimming lesson provider manager.
   */
  isSwimmingLessonProviderManagerView(): boolean {
    return (
      this.user.roles.includes('ROLE_SWIMMING_LESSON_PROVIDER_MANAGER') &&
      this.roleView === 'ROLE_VIEW_SWIMMING_LESSON_PROVIDER'
    );
  }

  /**
   * Determine if the user is viewing as a swimming lesson provider.
   */
  isSwimmingLessonProviderView(): boolean {
    return (
      this.user.roles.includes('ROLE_SWIMMING_LESSON_PROVIDER_USER') &&
      this.roleView === 'ROLE_VIEW_SWIMMING_LESSON_PROVIDER'
    );
  }

  /**
   * Determine if the user is viewing as an examiner.
   */
  isExaminerView(): boolean {
    return (
      this.user.roles.includes('ROLE_EXAMINER') &&
      this.roleView === 'ROLE_VIEW_EXAMINER'
    );
  }

  /**
   * Determine if the user is viewing as a delegate.
   */
  isDelegateView(): boolean {
    return (
      this.user.roles.includes('ROLE_DELEGATE') &&
      this.roleView === 'ROLE_VIEW_DELEGATE'
    );
  }

  /**
   * Determine if the user is viewing as an operator.
   */
  isOperatorView(): boolean {
    return (
      this.user.roles.includes('ROLE_OPERATOR_USER') &&
      this.roleView === 'ROLE_VIEW_OPERATOR'
    );
  }

  getUser(): User {
    return this.user;
  }

  /**
   * Returns the list of available role views, excluding the current role view
   */
  getAvailableRoleViews(
    roles: (keyof RoleInterface)[],
  ): (keyof RoleViewInterface)[] {
    const availableRoleViews: (keyof RoleViewInterface)[] = [];

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in RoleViews) {
      const roleView = RoleViews[key as keyof RoleViewInterface];
      if (
        roleView.roles.filter((role) => roles.includes(role)).length >= 1 &&
        key !== this.roleView
      ) {
        availableRoleViews.push(key as keyof RoleViewInterface);
      }
    }

    return availableRoleViews;
  }
}

export default RoleViewManager;
